import React from 'react';
import { ApiContext } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
/* istanbul ignore next */
export const useContextToken = () => {
    var _a;
    const [token, setToken] = React.useState('');
    const headers = (_a = React.useContext(ApiContext).requestInit) === null || _a === void 0 ? void 0 : _a.headers;
    React.useEffect(() => {
        if (headers) {
            const authHeader = Object.entries(headers)
                .filter(([key]) => key === 'Authorization')
                .pop();
            const tokenTmp = (authHeader === null || authHeader === void 0 ? void 0 : authHeader.pop().split(' ').pop()) || '';
            setToken(tokenTmp);
        }
    }, [headers]);
    return { token };
};
export default useContextToken;
