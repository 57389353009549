import React from 'react';
import { useApplicationContext } from '@samc/screen-config-api';
const useEntitlementsForDomain = (domainId) => {
    const { Entitlements, currentUser } = useApplicationContext();
    return React.useMemo(() => domainId === undefined
        ? { canAdd: undefined, canUpdate: undefined, canDelete: undefined }
        : {
            canAdd: currentUser.hasEntitlement(Entitlements.GenerateDomainAddEntitlementName(domainId)),
            canUpdate: currentUser.hasEntitlement(Entitlements.GenerateDomainUpdateEntitlementName(domainId)),
            canDelete: currentUser.hasEntitlement(Entitlements.GenerateDomainDeleteEntitlementName(domainId)),
        }, [Entitlements, currentUser, domainId]);
};
export default useEntitlementsForDomain;
