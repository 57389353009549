import React from 'react';
import { Button } from '@samc/react-ui-core';
import { SelectEntryField } from '@samc/react-ui-form';
import { InputType } from '@samc/screen-config-api';
import { FetchItems } from '../../../hooks/useConfigureFieldsMeta/fetchItems';
export const InputTypeSelect = (props) => {
    const { onChange, value, disabled, openPicklistConfiguration, domainField } = props;
    // eslint-disable-next-line eqeqeq
    const showPicklistButton = value == InputType.Picklist;
    const items = React.useMemo(() => FetchItems.fetchInputTypes('id', 'type', domainField).filter((t) => t.type !== 'MultiSelect'), [domainField]);
    return (React.createElement("div", { style: { display: 'flex', width: '100%' } },
        React.createElement(SelectEntryField, { valueProperty: "id", displayProperty: "type", menuItems: items, onChange: onChange, defaultSelectedKey: value, disabled: disabled }),
        showPicklistButton && (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { padding: 5 } }),
            React.createElement(Button, { text: "Configure", buttonType: "secondary", onClick: openPicklistConfiguration })))));
};
export default InputTypeSelect;
