import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getValidationViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const VALIDATIONS_KEY = `${SCREEN_CONFIG_API_KEY}validation_views`;
export const useValidationViews = () => {
    const api = React.useContext(ApiContext);
    const query = () => getValidationViews(api.AggregatorApi, undefined, api.requestInit);
    return useRequest(VALIDATIONS_KEY, query, ApiContext);
};
