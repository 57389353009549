import React from 'react';
import { ApplicationContextProvider } from '@samc/screen-config-api';
import { Entitlements, useCurrentUser } from '@samc/single-spa-authentication';

/**
 * Adapter which conveys information from @samc/single-spa-authentication into an
 * ApplicationContextProvider to be consumed by child components downstream
 */
export const ApplicationContextAdapter = ({
    children,
}: {
    children: React.ReactNode | null;
}): React.ReactElement | null => {
    const user = useCurrentUser();

    return (
        <ApplicationContextProvider
            currentUser={{
                id: user.id.toString(),
                hasEntitlement: (e: string) => user.hasEntitlement(e),
            }}
            Entitlements={{
                AccessAllPortfolioData: Entitlements.DataAccess.AccessAllPortfolioData,
                AccessLimitedCompanyData: Entitlements.DataAccess.AccessLimitedCompanyData,
                AssignRoles: Entitlements.User.AssignRoles,
                ChangeWorkflow: Entitlements.DataAccess.ChangeWorkflow,
                CreateUsers: Entitlements.User.CreateUsers,
                DisableUsers: Entitlements.User.DisableUsers,
                GenerateDomainAddEntitlementName: Entitlements.DataAccess.GenerateDomainAddEntitlementName,
                GenerateDomainDeleteEntitlementName: Entitlements.DataAccess.GenerateDomainDeleteEntitlementName,
                GenerateDomainUpdateEntitlementName: Entitlements.DataAccess.GenerateDomainUpdateEntitlementName,
                GenerateFormViewLinkEntitlementName: Entitlements.FormViewLink.GenerateFormViewLinkEntitlementName,
                GenerateViewSetActionEntitlementName: Entitlements.ViewSetAction.GenerateViewSetActionEntitlementName,
                GenerateViewSetTabEntitlementName: Entitlements.ViewSetTab.GenerateViewSetTabEntitlementName,
                GenerateViewUpdateDownloadEntitlement: Entitlements.DocumentType.GenerateViewUpdateDownloadEntitlement,
                RequestUsers: Entitlements.User.RequestUsers,
                UpdateUsers: Entitlements.User.UpdateUsers,
                ManageReports: Entitlements.Reporting.ManageReports,
            }}
        >
            {children}
        </ApplicationContextProvider>
    );
};

export default ApplicationContextAdapter;
