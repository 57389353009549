import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getChildFormViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const CHILD_FORMVIEWS_KEY = `${SCREEN_CONFIG_API_KEY}child_formviews_key`;
export const useChildFormViews = (domainId) => {
    const api = React.useContext(ApiContext);
    const getter = (domId) => domId ? getChildFormViews(api.AggregatorApi, domId, api.requestInit) : Promise.resolve([]);
    return useRequest(CHILD_FORMVIEWS_KEY, getter, ApiContext, domainId);
};
export default useChildFormViews;
