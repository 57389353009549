import { DOMAIN_KEY } from '@samc/screen-config-api/lib/useDomain/useDomain';
import { LIST_VIEW_KEY } from '@samc/screen-config-api/lib/useListView/useListView';
import { LISTVIEWS_BY_DOMAIN_KEY } from '@samc/screen-config-api/lib/useListViewsByDomain/useListViewsByDomain';
import React from 'react';
import { useQueryClient } from 'react-query';
import { ViewTypeContextProvider, CustomModal, ViewEditor } from '@samc/vmsnext-querybuilder';
import { useLookupsForLiftAndSift } from '../../../hooks/useLookupsForExpressions/useLookupsForExpressions';
const noop = () => undefined;
export const ListViewEditorModal = (props) => {
    const { onClose, listViewId, onSave, defaultFolderId } = props;
    const queryClient = useQueryClient();
    const viewContext = React.useContext(ViewTypeContextProvider);
    useLookupsForLiftAndSift();
    const handleOnClose = (view) => {
        if (onSave)
            onSave(view);
        onClose();
        // clear cache for list views
        queryClient.invalidateQueries(LIST_VIEW_KEY);
        queryClient.invalidateQueries(LISTVIEWS_BY_DOMAIN_KEY);
        // clear cache for domains because they may have added a field
        queryClient.invalidateQueries(DOMAIN_KEY);
        viewContext.fetchViewList(true);
    };
    return (React.createElement(CustomModal, { onDismiss: onClose },
        React.createElement(ViewEditor, { defaultValues: defaultFolderId ? { folder: { folderGuid: defaultFolderId } } : undefined, templateId: listViewId, onClose: handleOnClose, onDelete: noop, setDrawerOpen: onClose })));
};
export default ListViewEditorModal;
