import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getListViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const LIST_VIEWS_KEY = `${SCREEN_CONFIG_API_KEY}list_views`;
export const useListViews = () => {
    const api = React.useContext(ApiContext);
    const query = () => getListViews(api.AggregatorApi, undefined, api.requestInit);
    return useRequest(LIST_VIEWS_KEY, query, ApiContext);
};
