import React from 'react';
import { useQueryClient } from 'react-query';
import { saveFieldConfiguration } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { ApiContext } from '../ApiContext';
import { FIELD_CONFIGURATION_KEY } from '../useFieldConfiguration/useFieldConfiguration';
import { FIELD_CONFIGURATIONS_BY_DOMAIN_KEY } from '../useFieldConfigurationByDomain/useFieldConfigurationByDomain';
/**
 * Returns an array containing a method to save a FieldConfiguration and an object
 * representing the current state of the save request. Calling the save function
 * will invalidate the cache for any requests for a FieldConfiguration with the same ID.
 * @param fieldConfiguration FieldConfiguration to be saved.
 */
export const useFieldConfigurationSaver = (fieldConfiguration) => {
    const api = React.useContext(ApiContext);
    const queryClient = useQueryClient();
    const query = (fc) => saveFieldConfiguration(api.AggregatorApi, api.requestInit)(fc)
        .then((res) => {
        api.ShowMessage('FieldConfiguration saved successfully', 'success');
        // also reset cache if fetched by domain
        queryClient.invalidateQueries([FIELD_CONFIGURATION_KEY, fc.id]);
        queryClient.invalidateQueries([FIELD_CONFIGURATIONS_BY_DOMAIN_KEY, fc.domainId]);
        return res;
    })
        .catch((err) => {
        api.ShowMessage('Failed to save FieldConfiguration', 'error');
        throw err;
    });
    return useMutation(query, [
        FIELD_CONFIGURATION_KEY,
        api.AggregatorApi,
        fieldConfiguration.id,
    ]);
};
export default useFieldConfigurationSaver;
