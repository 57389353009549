var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { isFolder } from '@samc/folder-ui-core';
import { ApiContext } from '@samc/screen-config-api';
import { ListViewFolderEditor as BaseListViewFolderEditor } from './ListViewFolderEditor.Base';
export const ListViewFolderEditor = ({ domainId, className, editOnClick, multiselect, newListViewFolderId, onSelectionChanged, setNewListViewFolderId, setTargetListViewId, targetListViewId, }) => {
    const api = React.useContext(ApiContext);
    const isDevToolsEnabled = React.useRef(localStorage.getItem('devtools') === 'true').current;
    const getListViewAndCopyToClipboard = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const resp = yield fetch(`/domainmetadata/api/v1/ListView/${id}`, api.requestInit);
            const capitalizedJsonString = JSON.stringify(yield resp.json(), undefined, 2).replace(/"([\w]+)":/g, (_match, group) => `"${group.charAt(0).toUpperCase()}${group.slice(1)}":`);
            navigator.clipboard.writeText(capitalizedJsonString);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    });
    const getContextMenuItems = ({ node }) => {
        if (!node)
            return [];
        const { data } = node;
        if (!data)
            return [];
        if (isFolder(data)) {
            return [{ action: () => setNewListViewFolderId(data.id), name: 'Add List View' }];
        }
        if (isDevToolsEnabled) {
            return [
                { action: () => setTargetListViewId(data.sequentialItemId), name: 'Edit List View' },
                { action: () => getListViewAndCopyToClipboard(data.id), name: 'Dev: Copy to Clipboard' },
            ];
        }
        return [{ action: () => setTargetListViewId(data.sequentialItemId), name: 'Edit List View' }];
    };
    return (React.createElement(BaseListViewFolderEditor, { className: className, domainId: domainId, editOnClick: editOnClick, getContextMenuItems: getContextMenuItems, multiselect: multiselect, newListViewFolderId: newListViewFolderId, onSelectionChanged: onSelectionChanged, setNewListViewFolderId: setNewListViewFolderId, setTargetListViewId: setTargetListViewId, targetListViewId: targetListViewId }));
};
export default ListViewFolderEditor;
