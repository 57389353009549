import React from 'react';
import { usePicklistFormFields } from '@samc/picklist-core';
import { convertFormViewToFormSkeleton } from './Utils';
import { usePicklistGridFieldConfigurations as _usePicklistGridFieldConfigurations } from '../usePicklistGridFieldConfigurations/usePicklistGridFieldConfigurations';
import { useGridFieldsFromListview } from '../useGridFieldsFromListview/useGridFieldsFromListview';
import { useGridInjector } from '../useGridInjector/useGridInjector';
const useGridFieldConfigurations = _usePicklistGridFieldConfigurations.withHooks({
    useGridFieldsFromListview,
    useGridInjector,
});
const DEFAULT_FIELDS = {};
/**
 * Takes the form view and maps it down to a {@link ScreenConfigFormSkeleton}
 *
 * Injects all renderers and onFieldReady listeners needed for picklists, etc.
 *
 * DOES NOT call useMissingViewDependencies to resolve fields in expressions
 */
export const useFormSkeletonFromFormView = (params) => {
    const { formView, fieldConfigurationMembers, filters } = params;
    const baseForm = React.useMemo(() => convertFormViewToFormSkeleton(formView, filters), [formView, filters]);
    const { fields: baseFields } = baseForm !== null && baseForm !== void 0 ? baseForm : {};
    const { wrappedFields: picklistFields } = usePicklistFormFields({
        fieldConfigurationMembers,
        formFields: baseFields !== null && baseFields !== void 0 ? baseFields : DEFAULT_FIELDS,
        filters,
        useGridFieldConfigurations,
    });
    const outputForm = React.useMemo(() => {
        if (!baseForm)
            return undefined;
        return Object.assign(Object.assign({}, baseForm), { fields: picklistFields });
    }, [baseForm, picklistFields]);
    return { formSkeleton: outputForm };
};
export default useFormSkeletonFromFormView;
