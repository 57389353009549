import { LayoutType } from '@samc/react-ui-form';
import { FormSkeletonMappers } from '../mappers/FormSkeletonMappers/FormSkeletonMappers';
/**
 * An implementation of the FormTab interface to make converting between FormTab and FormViewTab easier.
 * @deprecated use {@link FormSkeletonMappers} for mapping
 */
export class FormTabViewModel {
    constructor(gridFieldCount, formViewMemberCount, gridFields, tab) {
        var _a;
        if (tab) {
            this.title = tab.title;
            this.sections = tab.sections;
            this.visibleExpression = tab.visibleExpression;
            this.baselineFilterExpression = tab.baselineFilterExpression;
            this.layout = tab.layout || {
                type: LayoutType.VerticalStack,
                children: Array.from(Array(tab.sections.length || 0).keys()).map((idx) => {
                    return {
                        index: idx,
                        type: LayoutType.VerticalStack,
                        children: [],
                    };
                }),
            };
        }
        else {
            this.title = 'Tab 1';
            this.baselineFilterExpression = null;
            this.sections = [
                {
                    titleExpression: "'Section 1'",
                    fieldLayout: {
                        type: LayoutType.VerticalStack,
                        // This is simply meant to give us an array of [0, ..., fields.length - 1].
                        children: Array.from(Array(formViewMemberCount).keys()).map((idx) => {
                            return {
                                index: idx,
                                type: LayoutType.VerticalStack,
                                children: [],
                            };
                        }),
                    },
                },
                // Create an additional section for each grid field
                ...((_a = gridFields === null || gridFields === void 0 ? void 0 : gridFields.map((gridField, index) => ({
                    titleExpression: gridField.field,
                    gridFieldIndex: index,
                }))) !== null && _a !== void 0 ? _a : []),
            ];
            this.layout = {
                type: LayoutType.VerticalStack,
                children: Array.from(Array(1 + gridFieldCount).keys()).map((idx) => {
                    return {
                        index: idx,
                        type: LayoutType.VerticalStack,
                        children: [],
                    };
                }),
            };
        }
    }
}
/**
 * Convert back to FormViewTab for saving, most likely.
 * @param tab The FormTabViewModel object to convert.
 * @deprecated use {@link FormSkeletonMappers}
 */
FormTabViewModel.toFormViewTab = (tab) => {
    return FormSkeletonMappers.Tab.toFormViewTab(tab);
};
/**
 * Convert to FormTabViewModel from FormViewTab for saving, most likely.
 * @param tab The FormTab object to convert.
 */
FormTabViewModel.fromFormTab = (tab) => {
    const objectInstance = Object.create(FormTabViewModel);
    Object.assign(objectInstance, tab); // 1-1 attributes
    return objectInstance;
};
export default FormTabViewModel;
