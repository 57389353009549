var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { ControlBar } from '@samc/react-ui-core';
/**
 * Simple wrapper around the SitusHeader to allow for Modal specific functionality.
 * When we find ourselves starting to use more modals we probably want to break this into a reusable component.
 * @param props Extension of the ScreenControlBarProps to pass extra props for modal management.
 */
export function ModalControlBar(props) {
    const { headerText, onDismiss, additionalControls, suppressSaveButton, suppressSaveAndCloseButton, submit, cancel } = props, controlBarProps = __rest(props, ["headerText", "onDismiss", "additionalControls", "suppressSaveButton", "suppressSaveAndCloseButton", "submit", "cancel"]);
    return (React.createElement(SitusHeader, { title: headerText || '' },
        React.createElement(ControlBar, Object.assign({}, controlBarProps, { submit: suppressSaveButton ? undefined : submit, submitAndClose: 
            // eslint-disable-next-line no-nested-ternary
            suppressSaveAndCloseButton
                ? undefined
                : submit
                    ? () => __awaiter(this, void 0, void 0, function* () {
                        yield (submit === null || submit === void 0 ? void 0 : submit());
                        onDismiss();
                    })
                    : undefined, cancel: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (cancel)
                        yield cancel();
                    onDismiss();
                }
                catch (e) {
                    // pass!
                }
            }), additionalControls: typeof additionalControls === 'function' ? additionalControls(props) : additionalControls }))));
}
export default ModalControlBar;
