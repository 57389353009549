var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-restricted-globals */
import React from 'react';
import { Spinner } from '@fluentui/react';
import { ApiContext, TaskStatus, useDomain, useTaskSubmit, } from '@samc/screen-config-api';
import { useAgGridApi } from '@samc/react-ui-grid';
import { FileUploadModal, useDocuments, useDropzoneFillEffect, DocumentActions, } from '@samc/screen-config-docs';
import { useStrata } from '@samc/filestorage-react';
import { useFieldConfiguration } from '@samc/picklist-api';
import { toastError, toastSuccess, ToastMessage, useDirtinessPrompt, usePromptContext, } from '@samc/react-ui-core';
import { GetListViewManager } from '../../../../molecules/GetListViewManager/GetListViewManager';
import { TaskResponsePopup } from '../../../../molecules/TaskResponsePopup/TaskResponsePopup';
import { useGridInjector } from '../../../../hooks/useGridInjector/useGridInjector';
import { useContextToken } from '../../../../hooks/useContextToken/useContextToken';
import { handleSubmit, mapDocumentCreationPayload, mapListViewForStrataDocuments, strataModel, dropzoneColDefs, handlePromptModal, getOnStrataFileAdded, getOnDZGridDrop, mapDocumentGridData, mapStrataData, mapStrataErrors, generateStrataResponses, generateStrataRequest, getEntityIdFromQueryParams, } from '../DocumentsModal.Utilities';
import { mapErrors } from '../../../GridScreen/GridScreen.utilities';
import { useDropzoneData } from '../../../../hooks/useDropzoneData/useDropzoneData';
import { useLSAutoFillActions } from '../../../../hooks/useLSAutoFillActions/useLSAutoFillActions';
const SHORT_NAME_FIELD = 'FileName';
export const AddDocumentsModalWrapper = (props) => {
    var _a, _b;
    const { isOpen, onDismiss, listView, entityId, onSaved, customSave, filters, children, showDropzone = true, className = 'document-management-file-uploader', panelTitle, } = props;
    const { token } = useContextToken();
    const api = React.useContext(ApiContext);
    const childDirtinessScope = React.useRef(null);
    const modalDirtinessScope = React.useRef(null);
    const { fieldConfigurationId } = listView;
    const domain = useDomain(listView.domainId);
    const primaryKey = (_a = domain.data) === null || _a === void 0 ? void 0 : _a.primaryKey;
    const { promptAndContinue } = usePromptContext();
    const { isDirty: isChildDirty, forceReset: resetChild } = useDirtinessPrompt({ scope: childDirtinessScope });
    const { state, saveActions: { disableSaveButton }, } = useDocuments();
    // Configuring components based on listView members
    const gridFields = React.useMemo(() => mapListViewForStrataDocuments(listView), [listView]);
    const { wrappedFields, frameworkComponents, isLoading } = useGridInjector(fieldConfigurationId, gridFields, filters);
    const [lastChangedCell, setLastChangedCell] = React.useState();
    const autofillActions = useLSAutoFillActions(listView.autoCompleteActions || []);
    useDropzoneFillEffect(lastChangedCell, autofillActions, 'DocumentTypeId');
    const [gridApi, _onGridReady] = useAgGridApi((event) => {
        event.api.addEventListener('rowDataUpdated', (e) => {
            var _a;
            const rows = (_a = event.api) === null || _a === void 0 ? void 0 : _a.getRenderedNodes();
            disableSaveButton(true);
            if (rows.length >= 1) {
                rows.forEach((node) => {
                    setLastChangedCell(Object.assign(Object.assign({}, event), { node, data: node.data }));
                    const { status } = node === null || node === void 0 ? void 0 : node.data;
                    if (status === 'Complete') {
                        disableSaveButton(false);
                    }
                });
            }
        });
        event.api.addEventListener('cellEditingStopped', (e) => {
            setLastChangedCell(Object.assign(Object.assign({}, event), { node: e.node, data: e.node.data }));
        });
        event.api.addEventListener('dragStopped', (e) => {
            var _a;
            const rows = (_a = event.api) === null || _a === void 0 ? void 0 : _a.getRenderedNodes();
            if (rows.length >= 1) {
                rows.forEach((node) => setLastChangedCell(Object.assign(Object.assign({}, event), { node, data: node.data })));
            }
        });
    });
    // Picklist items to pass to dropzone grid
    const [errorToastIsVisible, setErrorToastIsVisible] = React.useState(false);
    const [strataResponsesVisibility, setStrataResponsesVisibility] = React.useState(false);
    const [isStrataDirty, setIsStrataDirty] = React.useState(false);
    const currentEntity = React.useMemo(() => getEntityIdFromQueryParams() || entityId || '', []);
    const handleDataChanged = React.useCallback((args) => {
        if (args.errors.length >= 1)
            disableSaveButton(true);
        else
            disableSaveButton(false);
    }, [disableSaveButton]);
    const domainMap = React.useMemo(() => ({ [listView.domainId]: SHORT_NAME_FIELD }), [listView.domainId]);
    const { data: fieldConfiguration } = useFieldConfiguration(fieldConfigurationId);
    const { data: dropzoneData, isLoading: isDropzoneLoading, onStateChange, } = useDropzoneData({
        fieldName: 'DocumentTypeId',
        filters,
        fieldConfiguration,
    });
    const dropzoneGridDefs = {
        colDefs: dropzoneColDefs,
        rowDefs: dropzoneData,
        isLoading: isDropzoneLoading,
        onDrop: getOnDZGridDrop(dropzoneData),
        onStateChange,
        idField: 'id',
        dataFields: [
            {
                mapFromDzField: 'categoryIds',
                mapToGridField: 'CategoryIds',
            },
            {
                mapFromDzField: currentEntity,
                mapToGridField: 'ValuationId',
            },
            {
                mapFromDzField: currentEntity,
                mapToGridField: 'EntityId',
            },
        ],
        dataFieldsDropzone: [
            {
                mapFromDzField: 'id',
                mapToGridField: 'DocumentTypeId',
            },
            {
                mapFromDzField: 'categoryIds',
                mapToGridField: 'CategoryIds',
            },
            {
                mapFromDzField: currentEntity,
                mapToGridField: 'ValuationId',
            },
            {
                mapFromDzField: currentEntity,
                mapToGridField: 'EntityId',
            },
        ],
        fileTypesField: 'fileTypeNames',
        title: 'DOC TYPE DROP ZONES',
        gridApi,
    };
    const strataActions = {
        onFileAdded: getOnStrataFileAdded(setIsStrataDirty),
    };
    const [taskSubmit, taskLoading, lastRequests] = useTaskSubmit();
    const [lastResponses, setLastResponses] = React.useState();
    const [serverErrors, setServerErrors] = React.useState({
        data: {},
        errors: [],
    });
    const [strataResponses, setStrataResponses] = React.useState();
    const [strataRequests, setStrataRequests] = React.useState();
    const { saveActions: { onSaveDocument, getDocuments: setDropZoneFiles }, } = useStrata();
    const processErrors = React.useCallback((e) => mapErrors(e, primaryKey, gridApi), [gridApi, primaryKey]);
    // Cb that Strata executes when a data change action occurs (currently only for 'delete' and 'create' type actions)
    const onStrataDataChangedCb = (callbackData) => {
        var _a, _b;
        let requestPayload = {};
        const dataFromStrata = callbackData;
        // For errors without any data from Strata
        if (dataFromStrata.error) {
            if (!dataFromStrata.data || dataFromStrata.data.length === 0) {
                toastError(React.createElement(ToastMessage, { title: "Saved failed", message: ((_a = dataFromStrata.error) === null || _a === void 0 ? void 0 : _a.message) || 'Saved failed on Strata' }));
                return;
            }
            // For errors with data coming for Strata (Usually an array of rows from Strata's grid errors)
            const currentGridRows = gridApi === null || gridApi === void 0 ? void 0 : gridApi.getRenderedNodes();
            if (!currentGridRows) {
                return;
            }
            // Gets errors coming from Strata and maps them into responses for TaskResponsePopup
            const gridErrors = mapStrataErrors(dataFromStrata, currentGridRows);
            const generatedStrataResponses = generateStrataResponses(gridErrors);
            // Gets current Strata grid data and maps into requests for TaskResponsePopup
            const gridData = mapStrataData(currentGridRows, entityId);
            const generatedStrataRequests = generateStrataRequest(gridData);
            setStrataResponses(generatedStrataResponses);
            setStrataRequests(generatedStrataRequests);
            setStrataResponsesVisibility(true);
            return;
        }
        if (dataFromStrata.action === 'delete') {
            const gridRows = (_b = gridApi === null || gridApi === void 0 ? void 0 : gridApi.getRenderedNodes()) !== null && _b !== void 0 ? _b : [];
            if (dataFromStrata.data.length >= (gridRows === null || gridRows === void 0 ? void 0 : gridRows.length)) {
                disableSaveButton(true);
            }
        }
        // 'create' action is triggered by the save button in the modal (onSaveDocument action coming from Strata)
        // after Strata generates GUID's and uploads a document only on Strata
        if (dataFromStrata.action === 'create') {
            let gridData = {};
            dataFromStrata.data.forEach((documentRow, idx) => {
                const docIndex = `-${idx + 1}`;
                gridData = Object.assign(Object.assign({}, gridData), { [documentRow.fileUuid]: mapDocumentGridData(documentRow, entityId) });
                requestPayload = Object.assign(Object.assign({}, requestPayload), { [docIndex]: mapDocumentCreationPayload(documentRow, entityId, docIndex, dropzoneData) });
            });
            handleSubmit(listView, requestPayload, taskSubmit, gridData, processErrors, setServerErrors, setErrorToastIsVisible)
                .then((response) => {
                const { statusCode } = response;
                setLastResponses([response]);
                if (statusCode === TaskStatus.Completed) {
                    if (!customSave) {
                        toastSuccess(React.createElement(ToastMessage, { title: "Successful saved", message: "Document(s) saved" }));
                        if (onDismiss)
                            onDismiss();
                        if (onSaved)
                            onSaved();
                    }
                    else {
                        customSave(listView, true);
                    }
                }
            })
                .finally(() => {
                setDropZoneFiles(undefined);
            });
        }
    };
    const handleClose = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield resetChild();
        onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss();
    }), [onDismiss, resetChild]);
    React.useEffect(() => {
        if (state.requestType === DocumentActions.SAVE) {
            if (isStrataDirty) {
                // Default save based on strata grid and callback
                onSaveDocument();
            }
            else {
                customSave === null || customSave === void 0 ? void 0 : customSave(listView);
            }
        }
        else if (state.requestType === DocumentActions.DISMISS) {
            handlePromptModal(state.isSaveButtonDisabled, handleClose, () => promptAndContinue(handleClose));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    // ensure child dirtiness is reflected
    React.useEffect(() => {
        if (isChildDirty && children)
            disableSaveButton(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChildDirty]);
    if (!wrappedFields)
        return React.createElement(Spinner, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(FileUploadModal, { className: className, isOpen: isOpen, isReady: !isLoading, isSavingData: taskLoading.isLoading, token: token, tenantId: `vms${(_b = api.tenantName) === null || _b === void 0 ? void 0 : _b.toLocaleLowerCase()}`, strataUrl: `${location.protocol}//${location.host}${api.StrataApi}`, fileManagerUrl: "/manager-service", fileMetadataService: "/metadata-service/graphql", columns: wrappedFields, model: strataModel, onStrataDataChanged: onStrataDataChangedCb, customGridOptions: {
                frameworkComponents,
                rowsPerPage: 500,
                onChangeData: handleDataChanged,
                autofillActions,
            }, dropzoneGridDefs: showDropzone ? dropzoneGridDefs : undefined, strataActions: strataActions, disableWorker: true, onHandleGridReadyEvent: _onGridReady, panelTitle: panelTitle, customProps: { docTypes: (dropzoneGridDefs === null || dropzoneGridDefs === void 0 ? void 0 : dropzoneGridDefs.rowDefs) || [] } },
            React.createElement("div", { ref: modalDirtinessScope }, children && React.createElement("div", { ref: childDirtinessScope }, children))),
        serverErrors && lastRequests && lastResponses && (React.createElement(TaskResponsePopup, { responses: lastResponses, requests: lastRequests, visible: errorToastIsVisible, setVisible: setErrorToastIsVisible, domainNameMap: domainMap })),
        strataResponses && strataRequests && (React.createElement(TaskResponsePopup, { responses: strataResponses, requests: strataRequests, visible: strataResponsesVisibility, setVisible: setStrataResponsesVisibility, domainNameMap: domainMap }))));
};
/**
 * Special modal for displaying document grid with upload capabilities
 * @param props
 * @returns
 */
export const AddDocumentsModal = (props) => {
    const { isOpen, onDismiss, viewId, entityId, onSaved, customSave, filters, children, showDropzone, className, panelTitle, } = props;
    if (!isOpen) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(GetListViewManager, { key: `${viewId}${entityId}`, listViewId: viewId }, ({ listView }) => (React.createElement(AddDocumentsModalWrapper, { listView: listView, isOpen: isOpen, onDismiss: onDismiss, entityId: entityId, onSaved: onSaved, customSave: customSave, filters: filters, showDropzone: showDropzone, className: className, panelTitle: panelTitle }, children))));
};
export default AddDocumentsModal;
