import React from 'react';
import {
    toastError,
    toastInfo,
    ToastMessage,
    ToastMessageContainer,
    toastSuccess,
    toastWarning,
} from '@samc/react-ui-core';
import { ApiProvider } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import { globals } from '@samc/vmsnext-querybuilder';
import 'react-toastify/dist/ReactToastify.css';
import { MessageType } from '@samc/react-ui-request';

const ShowMessage = (msg: string, messageType?: MessageType): void => {
    switch (messageType) {
        case 'error':
            toastError(<ToastMessage title="Error" message={msg} />);
            break;
        case 'success':
            toastSuccess(<ToastMessage title="Success" message={msg} />);
            break;
        case 'warning':
            toastWarning(<ToastMessage title="Warning" message={msg} />);
            break;
        case 'info':
            toastInfo(<ToastMessage title="Info" message={msg} />);
            break;
        default:
            toastInfo(<ToastMessage title="API Message" message={msg} />);
    }
};

export interface BaseRootProps {
    headers: HeadersInit | undefined;
    tenantName: string;
    tenantId: string;
}

/**
 * Adapter which wraps children within an ApiProvider with toast message functionality
 */
export const ApiContextAdapter = (
    props: BaseRootProps & { children: React.ReactNode | null },
): React.ReactElement | null => {
    const { headers, tenantName, tenantId, children } = props;

    const [staticRequestInit] = React.useState<RequestInit>({
        headers,
    });

    // by maintaining a static request init, it never becomes stale
    React.useEffect(() => {
        Object.assign<RequestInit, RequestInit>(staticRequestInit, { headers });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers]);

    return (
        <ApiProvider
            value={{
                requestInit: staticRequestInit,
                tenantName,
                tenantId,
                AggregatorApi: '/screenaggregator',
                LiftSiftApi: '/_apis/querybuilder',
                QueryAuthorizationApi: '/_apis/query-authorization',
                TaskApi: '/taskgateway',
                CompanyCatalogApi: '/_apis/company-catalog',
                StrataApi: '/_apis/strata',
                FolderApi: '/screenaggregator/folderapi',
                ReportingApi: '/_apis/reporting',
                ShowMessage,
            }}
        >
            {children}
            <ToastMessageContainer
                className="single-spa-screen-config"
                position="top-center"
                draggable
                pauseOnHover
                pauseOnFocusLoss
                autoClose={4000}
            />
            {/* Additional toast container to catch L&S messages that don't use the default RUI containerId */}
            <ToastMessageContainer
                className="single-spa-screen-config"
                containerId={globals.__TOAST_CONTAINER_ID}
                draggable
                pauseOnHover
                pauseOnFocusLoss
                autoClose={4000}
                // Hide duplicate close button from L&S toasts
                closeButton={false}
                // Hide duplicate icon from L&S toasts
                icon={false}
            />
        </ApiProvider>
    );
};
