import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getDomainRelationships } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const DOMAIN_RELATIONSHIPS_KEY = `${SCREEN_CONFIG_API_KEY}domain_relationships`;
export function useDomainRelationships(targetDomainId, sourceDomainId) {
    const api = React.useContext(ApiContext);
    let type = '_source';
    if (targetDomainId && sourceDomainId)
        type = '_sourceAndTarget';
    else if (targetDomainId)
        type = '_target';
    const getter = (tDomainId, sDomainId) => tDomainId || sDomainId
        ? getDomainRelationships(api.AggregatorApi, tDomainId, sDomainId, api.requestInit)
        : Promise.resolve([]);
    return useRequestBase({
        key: DOMAIN_RELATIONSHIPS_KEY + type,
        query: getter,
        requestContext: ApiContext,
        params: [targetDomainId, sourceDomainId],
        options: {
            staleTime: CacheTime.default,
        },
    });
}
