/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { ViewType } from '@samc/screen-config-api';
import { ToastMessage, toastError } from '@samc/react-ui-core';
import { ConfirmationViewModal } from '../../modals/ConfirmationViewModal/ConfirmationViewModal';
import { ConfirmationViewSetModal } from '../../modals/ConfirmationViewSetModal/ConfirmationViewSetModal';
import { ViewSetModal } from '../../modals/ViewSetModal/ViewSetModal';
/**
 * Displays the correct modal based on the type that the current action is for.
 * - ViewModal
 * - ViewSetModal
 * - DocumentModal
 * @param props
 * @returns
 */
export const ConfirmationModalAction = (props) => {
    const { action, onDismiss: onDismissProp, filters, defaultData, primaryKeyValue: primaryKeyProp, onSaved, ViewModal, FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const { defaultValues, defaultFilterExpression, viewId, viewType, displayText } = action;
    const mergedFilters = React.useMemo(() => [...(filters !== null && filters !== void 0 ? filters : []), defaultFilterExpression].filter((e) => typeof e === 'string'), [filters, defaultFilterExpression]);
    const mergedDefaultData = React.useMemo(() => (Object.assign(Object.assign({}, defaultData), defaultValues)), [defaultData, defaultValues]);
    const [primaryKeyValue, setPrimaryKeyValue] = React.useState(primaryKeyProp);
    const onDismiss = React.useCallback(() => {
        onDismissProp();
        setPrimaryKeyValue(undefined);
    }, [onDismissProp]);
    React.useEffect(() => {
        if (!viewId || viewType == null) {
            toastError(React.createElement(ToastMessage, { title: "Missing View Id", message: "View Id is missing from this Action but is required for a Confirmation Modal" }));
        }
    }, [viewId, viewType]);
    if (!viewId)
        return null;
    if (viewType === ViewType.Form) {
        return (React.createElement(ConfirmationViewModal, { viewId: viewId, viewType: viewType, isOpen: true, dismissOnSave: true, headerText: displayText, onDismiss: onDismiss, onSaved: onSaved, filters: mergedFilters, defaultData: mergedDefaultData, primaryKeyValue: primaryKeyValue, setPrimaryKeyValue: setPrimaryKeyValue, FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet, ViewModal: ViewModal }));
    }
    if (viewType === ViewType.ViewSet) {
        return (React.createElement(ConfirmationViewSetModal, { isOpen: true, dismissOnSave: true, viewSetId: viewId, onSaved: onSaved, defaultData: mergedDefaultData, headerText: displayText, onDismiss: onDismiss, filters: mergedFilters, primaryKeyValue: primaryKeyValue, setPrimaryKeyValue: setPrimaryKeyValue, ServiceDrivenViewSet: ServiceDrivenViewSet, ViewSetModal: ViewSetModal }));
    }
    return null;
};
export default ConfirmationModalAction;
