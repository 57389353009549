var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useTaskSubmit, useFormView, } from '@samc/screen-config-api';
import { ProgressIndicator, MessageBar, MessageBarType } from '@fluentui/react';
import { ClassNameBuilder } from '@samc/react-ui-core';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { FormScreen } from '../FormScreen/FormScreen';
import { usePreparedEvaluatorConfigurationByFieldConfiguration } from '../../hooks/usePreparedEvaluatorConfigurationByFieldConfiguration/usePreparedEvaluatorConfigurationByFieldConfiguration';
import useEntitlementsForDomain from '../../hooks/useEntitlementsForDomain/useEntitlementsForDomain';
import { useEntitledFormView } from '../../hooks/useEntitledFormView/useEntitledFormView';
/**
 * Pulls in the FormView from the Api to then render a FormScreen
 * @param props
 * @returns
 */
export const FormScreenWrapper = (props) => {
    const { formViewId, onSubmit, primaryKeyValue, style, childrenStyle, className, formWrapperClassName, filters, defaultData, children, showContent, onChange, onFormStateChange, disabled, parentRequestIdentifier, sidePanelForm, controlVisibilities, onSelectAndShowSelectionChange, dataEntryFormProps, parentScope: scope, useFormView: _useFormView, exportMode, initialTabIndexOrName, submitAllAttributes, onTabChanged, taskResponses, beforeReset, selectAndShowOptionOverrides, onModalSaved, selectAndShowInitialValue, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const [taskSubmit, taskLoading] = useTaskSubmit();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = _useFormView ? _useFormView(formViewId) : useFormView(formViewId);
    const entitledFormView = useEntitledFormView(result.data, primaryKeyValue);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const sidePanelFormView = useFormView(sidePanelForm);
    const { canAdd, canUpdate } = useEntitlementsForDomain(entitledFormView === null || entitledFormView === void 0 ? void 0 : entitledFormView.domainId);
    const userCanEdit = primaryKeyValue === undefined ? canAdd : canUpdate;
    const evaluatorConfiguration = usePreparedEvaluatorConfigurationByFieldConfiguration(entitledFormView === null || entitledFormView === void 0 ? void 0 : entitledFormView.fieldConfigurationId);
    const handleSubmit = React.useCallback((requests, formState) => __awaiter(void 0, void 0, void 0, function* () {
        const { formView } = formState;
        const responses = yield taskSubmit(...requests);
        if (onSubmit) {
            onSubmit(formView, requests, responses);
        }
        return responses;
    }), [onSubmit, taskSubmit]);
    if (result.isLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading Form View..." });
    }
    if (sidePanelFormView.isLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading Side Panel..." });
    }
    if (result.isError) {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.error }, `Error loading Form View: ${formViewId}! ${result.error}`));
    }
    if (!evaluatorConfiguration.isInitialized) {
        return React.createElement(ProgressIndicator, { label: "Loading Field Configurations..." });
    }
    if (entitledFormView) {
        return (React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfiguration },
            taskLoading.isLoading && React.createElement(ProgressIndicator, null),
            React.createElement(FormScreen, { FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet, beforeReset: beforeReset, selectAndShowOptionOverrides: selectAndShowOptionOverrides, submitAllAttributes: submitAllAttributes, exportMode: exportMode, parentScope: scope, showContent: showContent, formView: entitledFormView, onSubmit: handleSubmit, primaryKeyValue: primaryKeyValue, className: ClassNameBuilder('form-screen-wrapper', className), formWrapperClassName: formWrapperClassName, style: style, childrenStyle: childrenStyle, filters: filters, defaultData: defaultData, onChange: onChange, onFormStateChange: onFormStateChange, disabled: disabled || !userCanEdit, initialTabIndexOrName: initialTabIndexOrName, parentRequestIdentifier: parentRequestIdentifier, sidePanelForm: exportMode ? undefined : sidePanelFormView.data, controlVisibilities: controlVisibilities, onSelectAndShowSelectionChange: onSelectAndShowSelectionChange, dataEntryFormProps: dataEntryFormProps, onTabChanged: onTabChanged, taskResponses: taskResponses, onModalSaved: onModalSaved, selectAndShowInitialValue: selectAndShowInitialValue }, children)));
    }
    return React.createElement("div", null, "Invalid screen configuration!");
};
export default FormScreenWrapper;
