import { useEntityEntitlementContext } from '@samc/screen-config-api';
const VIEW_UPLOAD_DOWNLOAD = 'View/Upload/Download';
const ALL_ACCESS_PORTFOLIO_DATA = 'Access All Portfolio Data';
export const useEntitlementsDocumentType = () => {
    var _a;
    let concatDocTypes = '';
    let docTypeFilter = '';
    const { currentEntityEntitlements } = (_a = useEntityEntitlementContext()) !== null && _a !== void 0 ? _a : {};
    if (currentEntityEntitlements) {
        if (currentEntityEntitlements[ALL_ACCESS_PORTFOLIO_DATA] === true) {
            docTypeFilter = '';
        }
        else {
            Object.entries(currentEntityEntitlements).forEach((docTypeEntitlement) => {
                if (docTypeEntitlement[0].includes(VIEW_UPLOAD_DOWNLOAD) && docTypeEntitlement[1] === true) {
                    const aux = docTypeEntitlement[0].replace(/\s/g, '').split(':')[1];
                    concatDocTypes += `${aux},`;
                }
            });
            concatDocTypes = concatDocTypes.slice(0, -1);
            docTypeFilter = `DoesContain([Code],'${concatDocTypes}')`;
        }
    }
    return docTypeFilter;
};
export default useEntitlementsDocumentType;
