import { useCustomColumns } from '@samc/react-ui-grid/lib/hooks/useCustomColumns/useCustomColumns';
import { useDomain, useLinks, } from '@samc/screen-config-api';
import React from 'react';
import { mergeDisplayOptions } from '../../organisms/GridScreen/GridScreen.utilities';
import { useGridCellLinks } from '../useGridCellLinks/useGridCellLinks';
/**
 * Takes the attributes of a list view and maps
 * them down to GridFieldConfigurations and associated models.
 *
 * Merges in custom columns, resolves links, injects picklists et al
 *
 * DOES NOT call useMissingViewDependencies to resolve fields in expressions
 */
export const useGridFieldsFromListview = (params) => {
    var _a, _b;
    const { useGridInjector, listView, additionalMembers, setCurrentEditLink, promptAndContinue, filters, gridDisplayOptions, suppressLinks, } = params;
    const { domainId, listViewMembers, controlVisibilities, fieldConfigurationId } = listView || {};
    const domain = useDomain(domainId);
    const { isLoading: isLoading1 } = domain;
    const links = useLinks(suppressLinks ? undefined : domainId);
    const { isLoading: isLoading2 } = links;
    const allMembers = React.useMemo(() => {
        const output = [];
        if (listViewMembers)
            output.push(...listViewMembers);
        if (additionalMembers)
            output.push(...additionalMembers);
        return output;
    }, [additionalMembers, listViewMembers]);
    const displayOptions = React.useMemo(() => mergeDisplayOptions(gridDisplayOptions !== null && gridDisplayOptions !== void 0 ? gridDisplayOptions : {}, controlVisibilities), [controlVisibilities, gridDisplayOptions]);
    const gridCellLinks = useGridCellLinks(links.data, (_a = domain.data) === null || _a === void 0 ? void 0 : _a.primaryKey, allMembers, setCurrentEditLink, promptAndContinue, (_b = domain.data) === null || _b === void 0 ? void 0 : _b.fields, displayOptions.wrapText);
    const customColumns = useCustomColumns(domainId);
    const gridFields = React.useMemo(() => {
        if (customColumns) {
            customColumns.forEach((x) => {
                gridCellLinks.splice(x.index, 0, x);
            });
        }
        return gridCellLinks;
    }, [customColumns, gridCellLinks]);
    const { wrappedFields, isLoading: isLoading3, frameworkComponents, fieldConfigurationMembers, } = useGridInjector(fieldConfigurationId, gridFields, filters);
    return {
        wrappedFields,
        isLoading: isLoading1 || isLoading2 || isLoading3,
        frameworkComponents,
        links,
        domain,
        displayOptions,
        fieldConfigurationMembers,
    };
};
export default useGridFieldsFromListview;
