var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/display-name */
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Button, ConfirmationDialog } from '@samc/react-ui-core';
import { CalculationFrequency, useFieldCalculator } from '@samc/screen-config-api';
import React from 'react';
import { useStyletron } from 'styletron-react';
import { ExpressionField } from '../ExpressionField/ExpressionField';
export const CalculationRuleField = (props) => {
    const { fieldConfigurationMember, domainId, primaryKeyField, onChange, saveForm } = props;
    const { viewFieldName, calculationCondition, calculationFrequency, calculationRule } = fieldConfigurationMember;
    const [css] = useStyletron();
    const [calculateFields, calculationResult] = useFieldCalculator(domainId);
    const [showPrompt, setShowPrompt] = React.useState(false);
    return (React.createElement("div", { className: css({ display: 'flex', width: '100%' }) },
        showPrompt && (React.createElement(ConfirmationDialog, { giveAnswer: (r) => __awaiter(void 0, void 0, void 0, function* () {
                setShowPrompt(false);
                if (!r)
                    return;
                if (!primaryKeyField)
                    return;
                if (!calculationRule || !viewFieldName)
                    return;
                if (calculationFrequency === CalculationFrequency.Never)
                    return;
                if (saveForm)
                    yield saveForm();
                yield calculateFields(primaryKeyField, [
                    {
                        fieldName: viewFieldName,
                        calculationCondition: calculationCondition !== null && calculationCondition !== void 0 ? calculationCondition : 'true',
                        calculationRule,
                    },
                ]);
            }), title: "Are you sure?", cancelText: "Go back", confirmText: "Yes", detail: "This will save the field configuration and recalculate against all records on this domain." })),
        React.createElement(ExpressionField, { value: calculationRule || '', className: css({ flex: '1' }), onChange: onChange, domainId: domainId, editingFieldName: "Calculation Rule" }),
        React.createElement("div", { className: css({ padding: '5px' }) }),
        React.createElement(Button, { type: "button", text: "Recalculate", buttonType: "secondary", disabled: calculationResult.isLoading ||
                !primaryKeyField ||
                !calculationRule ||
                calculationFrequency === undefined ||
                (!calculationCondition && calculationFrequency !== CalculationFrequency.Always) ||
                calculationFrequency === CalculationFrequency.Never, onClick: () => setShowPrompt(true) }),
        calculationResult.isLoading && React.createElement(Spinner, { className: css({ marginLeft: '5px' }), size: SpinnerSize.small })));
};
export const getCalculationRuleField = (domainId, primaryKeyField, saveForm) => (props) => {
    var _a;
    const { value, data, onChange } = props;
    return (React.createElement(CalculationRuleField, { domainId: domainId, primaryKeyField: primaryKeyField, fieldConfigurationMember: Object.assign(Object.assign({}, data), { calculationRule: (_a = value) !== null && _a !== void 0 ? _a : '' }), onChange: onChange, saveForm: saveForm }));
};
export default CalculationRuleField;
