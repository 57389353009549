import React from 'react';
import { useDomain, useListView } from '@samc/screen-config-api';
import { LoadingModal } from '@samc/react-ui-core/lib/molecules/LoadingModal/LoadingModal';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { ViewTypeContextProvider, getItemsFromLiftSiftFolder } from '@samc/vmsnext-querybuilder';
import { ListViewEditorModal } from '../../../../../molecules/modals/ListViewEditorModal/ListViewEditorModal';
import { QueryBuilderContextProvider, useQueryContext, } from '../../../QuerybuilderContextProvider/QuerybuilderContextProvider';
const ConfigureListViewEditorInner = (props) => {
    const { listViewId, isOpen, onClose, onSave } = props;
    const viewContext = React.useContext(ViewTypeContextProvider);
    const { viewList } = viewContext;
    React.useEffect(() => {
        viewContext.fetchViewList();
    }, [viewContext]);
    const items = React.useMemo(() => getItemsFromLiftSiftFolder(viewList ? [viewList] : undefined), [viewList]);
    const selectedListView = React.useMemo(
    // eslint-disable-next-line eqeqeq
    () => items.filter((x) => x.guid == listViewId || x.id == listViewId)[0], [items, listViewId]);
    return (React.createElement("div", null, isOpen && React.createElement(ListViewEditorModal, { onClose: onClose, listViewId: selectedListView === null || selectedListView === void 0 ? void 0 : selectedListView.id, onSave: onSave })));
};
export const ConfigureListViewEditor = (props) => {
    const { listViewId } = props;
    const { data: listView, isLoading: isListViewLoading } = useListView(listViewId);
    const { data: domain, isLoading: isDomainLoading } = useDomain(listView === null || listView === void 0 ? void 0 : listView.domainId);
    const { queryProps: { domainId: currentDomainId }, } = useQueryContext();
    const isLoading = isListViewLoading || isDomainLoading;
    if (!domain || !listView) {
        if (isLoading)
            return React.createElement(LoadingModal, null);
        return null;
    }
    // if the editor is spawned inside a QueryBuilderContextProvider of a different domain, get one for the proper one.
    const { sequentialId: listViewDomainId } = domain;
    if (!isNullOrUndefined(listViewDomainId) && listViewDomainId !== currentDomainId) {
        return (React.createElement(QueryBuilderContextProvider, { domainId: listViewDomainId },
            React.createElement(ConfigureListViewEditorInner, Object.assign({}, props))));
    }
    return React.createElement(ConfigureListViewEditorInner, Object.assign({}, props));
};
export default ConfigureListViewEditor;
