import React from 'react';
import { useSearchParams as _useSearchParams } from 'react-router-dom';
/**
 * Get, set, and delete the select and show URL search params.
 * @returns The id (primaryKey) of the selected ss value in the ss domain.
 * @returns A function to set the id in the urlSearchParams.
 * @returns A function to delete all ss keys in the urlSearchParams.
 */
export const useSelectAndShowURLSearchParams = (useSearchParams = _useSearchParams) => {
    const [params, setSearchParams] = useSearchParams();
    return [
        (domainId) => {
            if (!domainId)
                return null;
            return params.get(`ss_${domainId}`);
        },
        (domainId, ssId) => {
            params.set(`ss_${domainId}`, ssId);
            setSearchParams(params);
        },
        React.useCallback(() => {
            setSearchParams((prevSearchParams) => {
                prevSearchParams.forEach((_, key) => {
                    if (key.startsWith('ss_'))
                        prevSearchParams.delete(key);
                });
                return prevSearchParams;
            });
        }, [params, setSearchParams]),
    ];
};
export default useSelectAndShowURLSearchParams;
