import React from 'react';
import { InputType as UIInputType, getValueProperty, getDisplayProperty, } from '@samc/react-ui-form';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { InputType } from '@samc/screen-config-api';
import { GetCoreDropdownCellEditor } from '../../molecules/cellEditors/DropdownCellEditor/DropdownCellEditor';
import { getUnitOfCurrencyProperties } from '../../organisms/FormScreen/utilities';
import { GetCoreUnitOfMeasureCurrencyCellEditor } from '../../molecules/cellEditors/UnitOfMeasureCurrencyCellEditor/UnitOfMeasureCurrencyCellEditor';
import { FormSkeletonMappers } from '../../mappers/FormSkeletonMappers/FormSkeletonMappers';
const getItems = (fieldConfig) => {
    var _a, _b, _c, _d, _e, _f;
    if ((_a = fieldConfig.lookupProperties) === null || _a === void 0 ? void 0 : _a.lookupValues) {
        return (_b = fieldConfig.lookupProperties) === null || _b === void 0 ? void 0 : _b.lookupValues;
    }
    if ((_c = fieldConfig.unitOfMeasureProperties) === null || _c === void 0 ? void 0 : _c.units) {
        return (_d = fieldConfig.unitOfMeasureProperties) === null || _d === void 0 ? void 0 : _d.units;
    }
    if ((_e = fieldConfig.unitOfCurrencyProperties) === null || _e === void 0 ? void 0 : _e.units) {
        return (_f = getUnitOfCurrencyProperties(fieldConfig.unitOfCurrencyProperties)) === null || _f === void 0 ? void 0 : _f.units;
    }
    return undefined;
};
export const transformSelectGridFieldConfigurations = (gridFields, fieldConfigurationMembers) => {
    if (!gridFields)
        return gridFields;
    const gridFieldConfigs = gridFields.map((f) => {
        var _a, _b;
        const fieldConfig = fieldConfigurationMembers[f.field]; // find associated field config
        if (isNullOrUndefined(fieldConfig))
            return f;
        const { inputType, picklistField } = fieldConfig;
        if (picklistField || isNullOrUndefined(inputType))
            return f;
        const uiInputType = FormSkeletonMappers.InputType.toFrontendInputType(inputType);
        if (uiInputType !== UIInputType.Select && uiInputType !== UIInputType.MultiSelect)
            return f;
        const field = Object.assign({}, f);
        const { displayProperty, valueProperty } = field;
        const items = getItems(fieldConfig);
        if (!items)
            return f;
        // for validations
        field.menuItems = items;
        field.type = 'select';
        const valProperty = valueProperty || getValueProperty({}, items);
        const disProperty = displayProperty || getDisplayProperty({}, items);
        field.valueProperty = valProperty;
        field.displayProperty = disProperty;
        if (fieldConfig.inputType === InputType.UnitOfMeasure && !((_a = field.measurementProperties) === null || _a === void 0 ? void 0 : _a.isUnitOfMeasureField)) {
            field.measurementProperties = {
                isUnitOfMeasureField: true,
            };
            field.editor = GetCoreUnitOfMeasureCurrencyCellEditor(items, disProperty, valProperty, fieldConfig.confirmOptions);
            return field;
        }
        if (fieldConfig.inputType === InputType.UnitOfCurrency && !((_b = field.currencyProperties) === null || _b === void 0 ? void 0 : _b.isUnitOfCurrencyField)) {
            field.currencyProperties = {
                isUnitOfCurrencyField: true,
            };
            field.editor = GetCoreUnitOfMeasureCurrencyCellEditor(items, disProperty, valProperty, fieldConfig.confirmOptions);
            return field;
        }
        field.editor = GetCoreDropdownCellEditor(items, disProperty, valProperty, fieldConfig.confirmOptions);
        return field;
    });
    return gridFieldConfigs;
};
export const useSelectGridFields = (fieldConfigurationMembers, gridFieldConfigurations) => {
    return React.useMemo(() => {
        const r = transformSelectGridFieldConfigurations(gridFieldConfigurations, fieldConfigurationMembers);
        return r;
    }, [fieldConfigurationMembers, gridFieldConfigurations]);
};
export default useSelectGridFields;
