import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getLinks } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
/**
 * @param domainId if undefined will fetch nothing
 */
export const useLinks = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (dId) => dId ? getLinks(api.AggregatorApi, dId, api.requestInit) : Promise.resolve([]);
    return useRequestBase({
        key: `${SCREEN_CONFIG_API_KEY}links`,
        query,
        requestContext: ApiContext,
        params: [domainId],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
export default useLinks;
