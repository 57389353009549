import React from 'react';
import { useDomain } from '@samc/screen-config-api';
import { ExpressionField, DomainContextProvider, DomainFilterContextProvider } from '@samc/vmsnext-querybuilder';
import { useStyletron } from 'styletron-react';
import { IconButton, Spinner } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
import { QueryBuilderContextProvider } from '../../../organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
/**
 * Map to an array of viewFieldNames.
 * @param domainFields Array of ListDomainFields or FormDomainFields.
 */
export const mapDomainFieldsToFields = (domainFields) => {
    return domainFields.filter((m) => m.fieldName).map((m) => m.fieldName);
};
export const ExpressionEditorInner = (props) => {
    const { params } = props;
    const { value, onChange, inputRef, colDef, registerContainerGetter, unregisterContainerGetter } = params;
    const { headerName, field } = colDef;
    const [showEditor, setShowEditor] = React.useState(false);
    const [showValidationDrawer, setShowValidationDrawer] = React.useState(false);
    React.useEffect(() => {
        if (!showEditor)
            return undefined;
        const getter = () => true;
        registerContainerGetter(getter);
        return () => unregisterContainerGetter(getter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEditor]);
    const [css] = useStyletron();
    const theme = useThemeContext();
    const { functionList } = React.useContext(DomainFilterContextProvider);
    const { domainFieldList, lookups } = React.useContext(DomainContextProvider);
    const functions = React.useMemo(() => (functionList || []).map((v) => v.name), [functionList]);
    const handleOnChange = (event) => {
        onChange(event, false);
    };
    return (React.createElement("div", { className: css({ display: 'flex', height: '100%' }) },
        React.createElement(ExpressionField, { className: css({ flex: '1', height: '100%' }), inputRef: inputRef, expressionLanguage: "Centric", value: value, onChange: handleOnChange, allowEnter: true, inline: true, fields: domainFieldList || [], functions: functions, lookups: lookups || [], showEditor: showEditor, setShowEditor: setShowEditor, editingFieldName: headerName || field, showValidationDrawer: showValidationDrawer, setShowValidationDrawer: setShowValidationDrawer }),
        React.createElement(IconButton, { onClick: () => setShowEditor(true), styles: {
                icon: { color: theme.TextColors.primary.toString() },
            }, className: css({
                backgroundColor: theme.FormColors.backgroundColors.dark.toString(),
                height: '100%',
            }), iconProps: { iconName: 'Edit' } })));
};
export const DomainExpressionEditor = (props) => {
    const { domainId } = props;
    const { isLoading, isError, data: domain } = useDomain(domainId);
    if (isLoading)
        return React.createElement(Spinner, null);
    if (isError || !domain)
        return React.createElement("div", null, "An Error Occurred");
    return (React.createElement(QueryBuilderContextProvider, { domainId: domain.sequentialId },
        React.createElement(ExpressionEditorInner, Object.assign({}, props, { domain: domain }))));
};
export const ExpressionEditor = (props) => {
    const { domain } = props;
    return (React.createElement(QueryBuilderContextProvider, { domainId: domain.sequentialId },
        React.createElement(ExpressionEditorInner, Object.assign({}, props))));
};
/**
 * Get Expression Cell Editor.
 * @param domainId target domain id
 */
export function GetExpressionEditor(domain) {
    return domain
        ? (p) => React.createElement(ExpressionEditor, { params: p, domain: domain })
        : undefined;
}
export default ExpressionEditor;
