export const MapToFormViewMember = (domainField, fieldConfiguration) => {
    const fcMember = fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers.find((member) => member.viewFieldName === domainField.fieldName);
    return {
        defaultValueExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.defaultValueExpression,
        editableExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.editableExpression,
        viewFieldName: domainField.fieldName,
        requiredExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.requiredExpression,
        visibleExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.visibleExpression,
        formatId: fcMember === null || fcMember === void 0 ? void 0 : fcMember.formatId,
        lookupProperties: fcMember === null || fcMember === void 0 ? void 0 : fcMember.lookupProperties,
        tooltip: fcMember === null || fcMember === void 0 ? void 0 : fcMember.tooltip,
        calculationCondition: fcMember === null || fcMember === void 0 ? void 0 : fcMember.calculationCondition,
        calculationFrequency: fcMember === null || fcMember === void 0 ? void 0 : fcMember.calculationFrequency,
        calculationRule: fcMember === null || fcMember === void 0 ? void 0 : fcMember.calculationRule,
        displayNameExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.displayNameExpression,
        hexFillRule: fcMember === null || fcMember === void 0 ? void 0 : fcMember.hexFillRule,
        inputType: fcMember === null || fcMember === void 0 ? void 0 : fcMember.inputType,
        isKeyField: fcMember === null || fcMember === void 0 ? void 0 : fcMember.isKeyField,
    };
};
export default MapToFormViewMember;
