import React from 'react';
import { FilterControlBarItem } from './FilterControlBarItem/FilterControlBarItem';
import { ListViewControlBarItem } from './ListViewControlBarItem/ListViewControlBarItem';
const QueryBuilderControlBarItems = (props) => {
    const { selectedFilterIds, setSelectedFilterIds, selectedViewId, setSelectedViewId, refreshView, refreshFilter, promptAndContinue, } = props;
    return (React.createElement("div", null,
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', alignContent: 'center' } },
            typeof selectedViewId !== 'undefined' && (React.createElement(ListViewControlBarItem, { promptAndContinue: promptAndContinue, enableDrawer: setSelectedViewId !== undefined, selectedViewId: selectedViewId, setSelectedViewId: setSelectedViewId, refreshView: refreshView })),
            typeof selectedFilterIds !== 'undefined' && (React.createElement(FilterControlBarItem, { promptAndContinue: promptAndContinue, enableDrawer: setSelectedFilterIds !== undefined, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, refreshFilter: refreshFilter })))));
};
export default QueryBuilderControlBarItems;
