/* istanbul ignore file */
import React from 'react';
/**
 * High-level context for screen config components to consume shared functionality without the rigidity of direct imports.
 * Allows functionality to be swapped out/overridden as needed, or injected from another source without the need for a direct dependency.
 */
export const ApplicationContext = React.createContext({
    providerExists: false,
    currentUser: {
        id: undefined,
        hasEntitlement: () => {
            throw new Error('Using the default implementation of ApplicationContext.currentUser.hasEntitlement - this means the context is being consumed outside of a valid provider, which is likely a bug}');
        },
    },
    Entitlements: {},
});
/**
 * Wraps children in an ApplicationContext provider configured with the provided arguments
 */
export const ApplicationContextProvider = ({ currentUser, Entitlements, children, }) => {
    const value = React.useMemo(() => ({
        providerExists: true,
        currentUser,
        Entitlements,
    }), [Entitlements, currentUser]);
    return React.createElement(ApplicationContext.Provider, { value: value }, children);
};
/**
 * Use information from the Screen Config ApplicationContext
 */
export const useApplicationContext = () => {
    const c = React.useContext(ApplicationContext);
    if (!c.providerExists)
        // eslint-disable-next-line no-console
        console.error('Warning: useApplicationContext() is being called outside of a valid ApplicationContextProvider - this will almost definitely cause bugs when trying to consume context state');
    return c;
};
