import React from 'react';
import { SelectEntryField } from '@samc/react-ui-form';
import { useDomains } from '@samc/screen-config-api';
import { Spinner } from '@fluentui/react';
export const DomainPicker = (props) => {
    const { id, onDomainChange } = props;
    const { data: domains, isLoading } = useDomains();
    const menuItems = React.useMemo(() => domains === null || domains === void 0 ? void 0 : domains.sort((a, b) => {
        const aLower = (a.domainName || '').toLowerCase();
        const bLower = (b.domainName || '').toLowerCase();
        if (aLower < bLower)
            return -1;
        if (aLower > bLower)
            return 1;
        return 0;
    }), [domains]);
    if (isLoading)
        return React.createElement(Spinner, { labelPosition: "right", label: "Loading Domains..." });
    return (React.createElement("div", { style: { width: 300 } },
        React.createElement(SelectEntryField, { displayProperty: "domainName", valueProperty: "id", onChange: onDomainChange, menuItems: menuItems, defaultSelectedKey: id })));
};
export default DomainPicker;
export const getDomainPickerFormField = () => 
// eslint-disable-next-line react/display-name
(props) => {
    const { value, onChange } = props;
    const onDomainChange = (domain) => {
        onChange(domain === null || domain === void 0 ? void 0 : domain.id);
    };
    return (React.createElement(DomainPicker, { id: value !== undefined && value !== null ? String(value) : '', onDomainChange: onDomainChange }));
};
