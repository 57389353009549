// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Strata Upload Wrapper Styles */

.document-management-file-uploader {
    padding: 0px 15px 15px
}

.document-management-file-uploader  .ag-theme-balham .ag-root-wrapper {
    height: calc(65vh - 150px);
}

.document-management-file-uploader .ag-body-viewport {
    overflow-y: auto;
}

.document-management-file-uploader .ag-root .picklist-cell-editor .searchable-dropdown input {
    width: 100%;
}

.document-management-file-uploader .upload__grid .ag-theme-centricity > div {
    height: auto !important;
}

/* Quick Dropzone Styles  */

.strata .App__dropZone {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.strata .App__dropZone .container-wraper-dropzone {
    width: 100%;
}

.quick-dropzone-root {
    margin: 15px 15px 10px 15px;
    border: 1px dashed #b1b3b4;
    border-radius: 4px;
    text-decoration: none solid rgb(0, 0, 0);
    font-family: Source Sans Pro;
    font-size: 14px;
    color: #000000;
    text-align: center;
}

.strata .App__dropZone .dropzone-drag-active .quick-dropzone-root {
    border: 3px dashed #b1b3b4;
    cursor: no-drop !important;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-screen-config-docs-virtual-fafc813b65/1/packages/docs/lib/organisms/FileUploader/FileUploader.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;IACI;AACJ;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B;;AAEA,2BAA2B;;AAE3B;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,0BAA0B;IAC1B,kBAAkB;IAClB,wCAAwC;IACxC,4BAA4B;IAC5B,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;AAC9B","sourcesContent":["/* Strata Upload Wrapper Styles */\n\n.document-management-file-uploader {\n    padding: 0px 15px 15px\n}\n\n.document-management-file-uploader  .ag-theme-balham .ag-root-wrapper {\n    height: calc(65vh - 150px);\n}\n\n.document-management-file-uploader .ag-body-viewport {\n    overflow-y: auto;\n}\n\n.document-management-file-uploader .ag-root .picklist-cell-editor .searchable-dropdown input {\n    width: 100%;\n}\n\n.document-management-file-uploader .upload__grid .ag-theme-centricity > div {\n    height: auto !important;\n}\n\n/* Quick Dropzone Styles  */\n\n.strata .App__dropZone {\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n}\n\n.strata .App__dropZone .container-wraper-dropzone {\n    width: 100%;\n}\n\n.quick-dropzone-root {\n    margin: 15px 15px 10px 15px;\n    border: 1px dashed #b1b3b4;\n    border-radius: 4px;\n    text-decoration: none solid rgb(0, 0, 0);\n    font-family: Source Sans Pro;\n    font-size: 14px;\n    color: #000000;\n    text-align: center;\n}\n\n.strata .App__dropZone .dropzone-drag-active .quick-dropzone-root {\n    border: 3px dashed #b1b3b4;\n    cursor: no-drop !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
