/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useStyletron } from 'styletron-react';
import './DocCountLink.css';
/**
 * Returns a FluentUI icon, FabricFolder by default. onClick is mandatory but any other props are mapped directly to Icon
 */
export const DocCountLink = (props) => {
    const { value } = props;
    const [css] = useStyletron();
    const isValueMoreThanZero = typeof value !== 'undefined' && value !== null && value > 0;
    return (React.createElement("div", { className: `doccount ${css({
            height: '100%',
            color: 'rgb(0, 120, 212)',
            display: 'flex',
            alignContent: 'center',
            width: 'min-content',
        })}` },
        React.createElement(Icon, Object.assign({ iconName: isValueMoreThanZero ? 'FabricFolderFill' : 'FabricFolder' }, props)),
        isValueMoreThanZero && (React.createElement("div", { className: css({ marginTop: '-1px', marginLeft: '2px' }) },
            React.createElement("sup", null, value)))));
};
export default DocCountLink;
