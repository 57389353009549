import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getFieldConfiguration } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const FIELD_CONFIGURATION_KEY = `${SCREEN_CONFIG_API_KEY}field_configuration`;
export const useFieldConfiguration = (id) => {
    const api = React.useContext(ApiContext);
    const query = (_id) => _id ? getFieldConfiguration(api.AggregatorApi, _id, api.requestInit) : Promise.resolve(undefined);
    return useRequestBase({
        key: FIELD_CONFIGURATION_KEY,
        query,
        requestContext: ApiContext,
        params: [id],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
