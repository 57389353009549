/* istanbul ignore file */
import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getEntityEntitlements } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
export const ENTITY_ENTITLEMENTS_KEY = 'entity_entitlements';
export const EntityEntitlementContext = React.createContext({
    providerExists: false,
});
const EMPTY_ARRAY = [];
const useEntityEntitlements = (request) => {
    const api = React.useContext(ApiContext);
    return useRequestBase({
        key: ENTITY_ENTITLEMENTS_KEY,
        query: (r) => getEntityEntitlements(api.QueryAuthorizationApi, r, api.requestInit),
        requestContext: ApiContext,
        params: [request],
        options: {
            enabled: request.targetIds.length > 0,
            staleTime: CacheTime.default,
        },
    });
};
export const EntityEntitlementContextProvider = ({ domainId, children, entityId, targetEntitlementNames, targetEntitlementTypes, }) => {
    const { data } = useEntityEntitlements({
        domainId,
        targetIds: entityId ? [entityId] : EMPTY_ARRAY,
        entitlementNames: targetEntitlementNames,
        entitlementTypes: targetEntitlementTypes,
    });
    const currentEntityEntitlements = entityId ? data === null || data === void 0 ? void 0 : data[entityId] : undefined;
    const value = React.useMemo(() => ({
        providerExists: true,
        currentEntityEntitlements,
    }), [currentEntityEntitlements]);
    return React.createElement(EntityEntitlementContext.Provider, { value: value }, children);
};
export const useEntityEntitlementContext = () => {
    const c = React.useContext(EntityEntitlementContext);
    if (!c.providerExists)
        // eslint-disable-next-line no-console
        console.error('Warning: useEntityEntitlementContext() is being called outside of a valid EntityEntitlementContextProvider - this will almost definitely cause bugs when trying to consume context state');
    return c;
};
