import React from 'react';
import { InputType } from '@samc/react-ui-form';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { FormSkeletonMappers } from '../../mappers/FormSkeletonMappers/FormSkeletonMappers';
const processDateFromClipboard = (params) => {
    const { value } = params;
    const date = new Date(value);
    if (Number.isNaN(date.getTime()))
        return value; // bad date - just send it back.
    /*
     * This cell holds a date with no time e.g. '05/24/2024'
     *
     * JS will parse that with a local timezone offset, but that may be interpreted as the
     * previous/former day when translated to UTC.
     *
     * Example:
     *  05/24/2024 (input)
     *  => 05/24/2024 00:00:00 -01:00 (parsed as 5/24/2024 at 00:00 local time)
     *  => 05/23/2024 23:00:00 +00:00 (translated to UTC time)
     *  => 05/23/2024 (The date of the UTC timestamp is now one day off from the provided value)
     *
     * To avoid this, we drop the time here and pretend it was 00:00:00.000Z all along.
     */
    return date.toISOString().replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/gm, 'T00:00:00.000Z');
};
/**
 * Transform the provided gridfields to customize the way their cells will interact with the clipboard
 *
 * @param gridFields
 * @param fieldConfigurationMembers
 * @returns
 */
export const transformGridFieldConfigurationsForClipboard = (gridFields, fieldConfigurationMembers) => {
    if (!gridFields)
        return gridFields;
    const gridFieldConfigs = gridFields.map((f) => {
        if (!Object.keys(fieldConfigurationMembers).some((k) => k === f.field))
            return f;
        const fieldConfig = fieldConfigurationMembers[f.field];
        const isPicklistField = !!fieldConfig.picklistField;
        if (isPicklistField)
            return f;
        const field = Object.assign({}, f);
        const uiInputType = isNullOrUndefined(fieldConfig.inputType)
            ? undefined
            : FormSkeletonMappers.InputType.toFrontendInputType(fieldConfig.inputType);
        switch (uiInputType) {
            case InputType.Date:
                field.processCellFromClipboard = processDateFromClipboard;
                break;
            default:
                break;
        }
        return field;
    });
    return gridFieldConfigs;
};
export const useGridFieldsWithCustomClipboard = (gridFieldConfigs, fieldConfigurationMembers) => {
    const newGFConfigs = React.useMemo(() => transformGridFieldConfigurationsForClipboard(gridFieldConfigs, fieldConfigurationMembers), [fieldConfigurationMembers, gridFieldConfigs]);
    return newGFConfigs;
};
export default useGridFieldsWithCustomClipboard;
