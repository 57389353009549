import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router as CustomRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LayerWrapper, PromptContextProvider, WysiwygLicenseProvider } from '@samc/react-ui-core';
import { LSThemeWrapper } from '@samc/vmsnext-querybuilder';
import { AuthorizationWrapper, useCurrentUser } from '@samc/single-spa-authentication';
import { StrataProvider } from '@samc/filestorage-react';
import './style/screen-config.css';
import { HeaderContextProvider, HistoryProvider } from '@samc/react-ui-history';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { ThemeProvider, defaultTheme } from '@samc/react-ui-theme';
import { ApplicationContextAdapter } from './context/ApplicationContextAdapter';
import { ApiContextAdapter } from './context/ApiContextAdapter';
import { Router } from './templates/Router/Router';
import type { SingleSpaProps } from './root.component';

const _queryClient = new QueryClient();
type RootBaseProps = SingleSpaProps & {
    authToken: string | undefined;
    clientConfiguration: ClientConfiguration;
    jwt: string | undefined;
    tenantName: string;
    tenantId: string;
};

interface AuthorizedRootBaseProps extends Pick<SingleSpaProps, 'browserHistory' | 'hashHistory' | 'queryClient'> {
    headers: Record<string, string>;
    tenantName: string;
    tenantId: string;
}

export const AuthorizedRootBase = (props: AuthorizedRootBaseProps): React.ReactElement<RootBaseProps> => {
    const { hashHistory, browserHistory, headers, tenantName, tenantId, queryClient } = props;

    const { id: currentUserIdGuid } = useCurrentUser();
    const [location, setLocation] = React.useState(hashHistory.location);

    React.useEffect(() => {
        return hashHistory.listen((l) => setLocation(l.location));
    }, [hashHistory]);

    return (
        <QueryClientProvider client={queryClient ?? _queryClient}>
            <LSThemeWrapper>
                <ThemeProvider style={{ width: '100%', height: '100%' }} value={defaultTheme}>
                    <LayerWrapper>
                        <HistoryProvider value={{ browserHistory, hashHistory }}>
                            <PromptContextProvider>
                                <HeaderContextProvider>
                                    <EvaluatorConfigurationProvider
                                        value={{ currentUserId: currentUserIdGuid.toString() }}
                                    >
                                        <DndProvider backend={HTML5Backend}>
                                            <StrataProvider>
                                                <CustomRouter location={location} navigator={hashHistory}>
                                                    <ApplicationContextAdapter>
                                                        <ApiContextAdapter
                                                            headers={headers}
                                                            tenantName={tenantName}
                                                            tenantId={tenantId}
                                                        >
                                                            <Router />
                                                        </ApiContextAdapter>
                                                    </ApplicationContextAdapter>
                                                </CustomRouter>
                                            </StrataProvider>
                                        </DndProvider>
                                    </EvaluatorConfigurationProvider>
                                </HeaderContextProvider>
                            </PromptContextProvider>
                        </HistoryProvider>
                    </LayerWrapper>
                </ThemeProvider>
            </LSThemeWrapper>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

/**
 * Inner Root layer which takes validated parameters from the Check layers and injects them into the component hierarchy
 */
export const RootBase = (props: RootBaseProps): React.ReactElement | null => {
    const { authToken, clientConfiguration, jwt, tenantName } = props;

    const headers = {
        Authorization: `Bearer ${authToken}`,
        'X-Tenant': tenantName,
    };

    return (
        <AgGridLicenseProvider license={clientConfiguration.agGridLicense}>
            <WysiwygLicenseProvider value={clientConfiguration.tinyMceLicense}>
                <AuthorizationWrapper
                    config={{
                        ...clientConfiguration,
                        defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
                    }}
                    jwt={jwt}
                >
                    <AuthorizedRootBase {...props} headers={headers} />
                </AuthorizationWrapper>
            </WysiwygLicenseProvider>
        </AgGridLicenseProvider>
    );
};

export default RootBase;
