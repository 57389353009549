var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { submitMultipleTasks } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { ApiContext, useApiContext } from '../ApiContext';
/**
 * Returns an array containing a method to submit a task, an object
 * representing the current state of the task request, and the last request.
 */
export const useTaskSubmit = () => {
    const { TaskApi } = React.useContext(ApiContext);
    const api = useApiContext();
    const [lastRequests, setLastRequests] = React.useState();
    const [saveTaskRequest, saveMutation] = useMutation(submitMultipleTasks(TaskApi, api.requestInit));
    const submit = React.useCallback((...requests) => __awaiter(void 0, void 0, void 0, function* () {
        setLastRequests(requests);
        const res = yield saveTaskRequest(requests);
        return res;
    }), [saveTaskRequest]);
    return [submit, saveMutation, lastRequests];
};
export default useTaskSubmit;
