import { InputType } from '@samc/screen-config-api';
import { GetDatePickerCellEditor } from '../controls/GetDatePickerCellEditor/GetDatePickerCellEditor';
import { GetTimePickerCellEditor } from '../controls/GetTimePickerCellEditor/GetTimePickerCellEditor';
const GetFieldEditor = (member) => {
    switch (member.inputType) {
        case InputType.Date:
            return GetDatePickerCellEditor();
        case InputType.DateTime:
            return GetDatePickerCellEditor(true);
        case InputType.Time:
            return GetTimePickerCellEditor();
        default:
            return undefined;
    }
};
export const ConfigureListView = (listView) => {
    var _a;
    const updatedListView = Object.assign({}, listView);
    updatedListView.listViewMembers = (_a = listView === null || listView === void 0 ? void 0 : listView.listViewMembers) === null || _a === void 0 ? void 0 : _a.map((member) => {
        return Object.assign(Object.assign({}, member), { editor: GetFieldEditor(member) });
    });
    return updatedListView;
};
export default ConfigureListView;
