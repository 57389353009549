import React from 'react';
import { useAgGridApi } from '@samc/react-ui-grid';
/**
 * @returns [onBodyGridReady, isOpen, handleAddRecord, handleRecordAdded, handleDismissed]
 */
export const useNewRecordPanel = (params) => {
    const { onBodyGridReady } = params !== null && params !== void 0 ? params : {};
    const [open, setOpen] = React.useState(false);
    const [gridApi, handleGridReady] = useAgGridApi(onBodyGridReady);
    const handleAddRecord = React.useCallback(() => setOpen(true), []);
    const handleRecordAdded = React.useCallback(() => {
        if (gridApi) {
            gridApi.refreshServerSide({ purge: true });
        }
        setOpen(false);
    }, [gridApi]);
    const handleDismissed = React.useCallback(() => setOpen(false), []);
    return [handleGridReady, open, handleAddRecord, handleRecordAdded, handleDismissed];
};
export default useNewRecordPanel;
