import React from 'react';
import { DocumentActions, } from '../../model/File/FileUploader';
const DocumentsContext = React.createContext({});
export function useDocuments() {
    const context = React.useContext(DocumentsContext);
    if (Object.keys(context).length === 0) {
        throw new Error('useDocuments must be used within the DocumentsProvider');
    }
    return context;
}
export const reducer = (state, action) => {
    switch (action.type) {
        case DocumentActions.SAVE:
            return Object.assign(Object.assign({}, state), { requestType: DocumentActions.SAVE });
        case DocumentActions.DISMISS:
            return Object.assign(Object.assign({}, state), { requestType: DocumentActions.DISMISS });
        case DocumentActions.DISABLEDSAVEBUTTON:
            return Object.assign(Object.assign({}, state), { isSaveButtonDisabled: action.payload, requestType: DocumentActions.DISABLEDSAVEBUTTON });
        default:
            return Object.assign({}, state);
    }
};
export function useDocumentsActions(dispatch) {
    const onSaveDocuments = React.useCallback(() => {
        dispatch({
            type: DocumentActions.SAVE,
            payload: '',
        });
    }, [dispatch]);
    const onDismissDocuments = React.useCallback(() => {
        dispatch({
            type: DocumentActions.DISMISS,
            payload: '',
        });
    }, [dispatch]);
    const disableSaveButton = React.useCallback((value) => {
        dispatch({
            type: DocumentActions.DISABLEDSAVEBUTTON,
            payload: value,
        });
    }, [dispatch]);
    return {
        onSaveDocuments,
        onDismissDocuments,
        disableSaveButton,
    };
}
export const DocumentsProvider = ({ value, children, }) => {
    const [state, dispatch] = React.useReducer(reducer, {
        requestType: DocumentActions.NULL,
        isSaveButtonDisabled: true,
    });
    const saveActions = useDocumentsActions(dispatch);
    const providerState = React.useMemo(() => {
        return {
            state,
            saveActions,
        };
    }, [state, saveActions]);
    return React.createElement(DocumentsContext.Provider, { value: providerState }, children);
};
