// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-management-attach-file{
  padding: 20px;
}

.document-management-attach-file .ag-theme-balham .ag-root-wrapper {
  height: 25vh;
}

.document-management-attach-file .ag-body-viewport {
  overflow-y: auto;
}

.document-management-attach-file .ag-root .picklist-cell-editor .searchable-dropdown input {
  width: 100%;
}

.document-management-attach-file .upload__grid .ag-theme-centricity > div {
  height: auto !important;
}`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-screen-config-core-virtual-2101c279b0/1/packages/core/lib/organisms/modals/DocumentsModal/AttachDocumentsGrid/AttachDocumentsGrid.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".document-management-attach-file{\n  padding: 20px;\n}\n\n.document-management-attach-file .ag-theme-balham .ag-root-wrapper {\n  height: 25vh;\n}\n\n.document-management-attach-file .ag-body-viewport {\n  overflow-y: auto;\n}\n\n.document-management-attach-file .ag-root .picklist-cell-editor .searchable-dropdown input {\n  width: 100%;\n}\n\n.document-management-attach-file .upload__grid .ag-theme-centricity > div {\n  height: auto !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
