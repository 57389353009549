import React from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@samc/react-ui-grid/lib/styles/ag-theme-react-ui-library.css';
import { DomainRelationshipType, useChildViews, useDomains, useViewSetsByDomain, } from '@samc/screen-config-api';
import { generateTreeData } from './ConfigureViewSetHelper';
import { ConfigureViewSetRenderer } from './ConfigureViewSetRenderer/ConfigureViewSetRenderer';
import { FormSkeletonMappers } from '../../../mappers/FormSkeletonMappers/FormSkeletonMappers';
const useForceUpdate = () => {
    const [, setValue] = React.useState(0); // integer state
    return () => setValue((value) => {
        let _value = value;
        return ++_value;
    });
};
export const ConfigureViewSet = (props) => {
    const { domainId, domainFields, fieldConfigurationId } = props;
    const viewSets = useViewSetsByDomain(domainId !== null && domainId !== void 0 ? domainId : '').data;
    const childViewsResult = useChildViews(domainId !== null && domainId !== void 0 ? domainId : '');
    const domainsResult = useDomains();
    const rowData = React.useMemo(() => generateTreeData(viewSets), [viewSets]);
    const childGrids = React.useMemo(() => {
        if (!childViewsResult.data || !domainsResult.data)
            return [];
        const viewsByDomain = childViewsResult.data.reduce((out, cur) => {
            if (out[cur.domainId])
                return out;
            const newOut = Object.assign({}, out);
            newOut[cur.domainId] = cur;
            return newOut;
        }, {});
        return domainsResult.data
            .map((domain) => {
            var _a, _b;
            const { domainName, id: dId } = domain;
            const listView = viewsByDomain[dId];
            if (!listView)
                return undefined;
            return FormSkeletonMappers.GridConfiguration.fromFormViewGridField({
                field: domainName,
                viewId: (_a = listView.id) !== null && _a !== void 0 ? _a : '',
                domainId: (_b = listView.domainId) !== null && _b !== void 0 ? _b : '',
                sourceField: '', // Only needed when loading data, irrelevant here
                targetField: '', // Only needed when loading data, irrelevant here
                relationshipType: DomainRelationshipType.Single, // irrelevant here
            }, {
                viewName: listView.viewName,
            });
        })
            .filter((v) => !!v);
    }, [childViewsResult, domainsResult]);
    const handleForceUpdate = useForceUpdate();
    return (React.createElement(ConfigureViewSetRenderer, { rowData: rowData, domainId: domainId, domainFields: domainFields, childGrids: childGrids, viewSets: viewSets, fieldConfigurationId: fieldConfigurationId, key: viewSets && viewSets.length > 0 ? viewSets[0].id : '', forceUpdate: handleForceUpdate }));
};
export default ConfigureViewSet;
