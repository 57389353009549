import { GlobalDirtinessTracker, SplitButton, useDirtinessPrompt } from '@samc/react-ui-core';
import React from 'react';
import { ViewSetActionType } from '@samc/screen-config-api/lib/model/ViewSet';
import { useEvaluator } from '@samc/expressions-react';
import { useHistoryContext } from '@samc/react-ui-history';
import { DeleteAction } from '../DeleteAction/DeleteAction';
import { AddModalAction } from '../AddModalAction/AddModalAction';
import { useActionOverrides } from '../../../contexts/ActionOverrideContext/ActionOverrideContext';
import { ConfirmationModalAction } from '../ConfirmationModalAction/ConfirmationModalAction';
import { ViewSetModal } from '../../modals/ViewSetModal/ViewSetModal';
/**
 * Displays a ViewSet's Actions split button with all actions
 * @param param
 * @returns
 */
export const ControlBarActions = ({ actionItems = [], data, onChangePrimaryKey, dirtinessScope, filters, defaultData, primaryKeyValue, selectedData, GridScreenWrapper, FormScreenWrapper, ServiceDrivenViewSet, afterDelete, ViewModal, }) => {
    const { evaluate } = useEvaluator();
    const [targetItem, setTargetItem] = React.useState();
    const { browserHistory } = useHistoryContext();
    const onClickActionOverrides = useActionOverrides();
    // data coming from the dirtiness tracker
    const [trackedData, _setTrackedData] = React.useState();
    const { promptAndContinue, forceReset } = useDirtinessPrompt({ scope: dirtinessScope });
    const onDismiss = React.useCallback(() => {
        setTargetItem(undefined);
    }, []);
    const onDeleteSuccess = React.useCallback(() => {
        if (afterDelete)
            afterDelete();
        onChangePrimaryKey === null || onChangePrimaryKey === void 0 ? void 0 : onChangePrimaryKey(undefined);
        forceReset === null || forceReset === void 0 ? void 0 : forceReset();
    }, [afterDelete, onChangePrimaryKey, forceReset]);
    const dataForEvaluate = React.useMemo(() => {
        if (selectedData && Object.keys(selectedData).length > 0)
            return Object.assign(Object.assign({}, defaultData), selectedData);
        if (trackedData && Object.keys(trackedData).length > 0)
            return trackedData;
        if (data && Object.keys(data).length > 0)
            return data;
        return defaultData;
    }, [data, defaultData, trackedData, selectedData]);
    const buttonItems = React.useMemo(() => {
        return actionItems.map((i) => {
            const href = i.type === ViewSetActionType.Link && i.linkTargetExpression
                ? String(evaluate(dataForEvaluate || {}, i.linkTargetExpression, true))
                : undefined;
            const openItem = (e) => {
                const { viewId } = i;
                if (onClickActionOverrides && viewId && onClickActionOverrides[viewId]) {
                    promptAndContinue(() => onClickActionOverrides[viewId](i));
                }
                else if (href) {
                    if (href.includes('#'))
                        browserHistory.push(href);
                    else
                        browserHistory.push(`${href}#`);
                    e.preventDefault();
                    return false;
                }
                else
                    promptAndContinue(() => setTargetItem(i));
                return true;
            };
            const evaluatedExprs = i.displayExpression
                ? evaluate(dataForEvaluate || {}, i.displayExpression, true)
                : true;
            const isVisible = i.displayExpression === '' || (evaluatedExprs !== false && evaluatedExprs !== null);
            return {
                displayText: i.displayText,
                onClick: openItem,
                tooltip: i.tooltip,
                isVisible,
                href,
            };
        });
    }, [actionItems, evaluate, dataForEvaluate, onClickActionOverrides, promptAndContinue, browserHistory]);
    // update dirtiness data
    React.useEffect(() => {
        let scope;
        if ('current' in dirtinessScope)
            scope = dirtinessScope.current;
        else
            scope = dirtinessScope;
        if (!scope)
            return;
        GlobalDirtinessTracker.addDataListener((ev) => {
            if (ev.value)
                _setTrackedData(ev.value);
        }, { emitInitialEvent: true, scope: { element: scope }, includeClean: true });
    }, [dirtinessScope]);
    if (!actionItems || actionItems.length === 0) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SplitButton, { buttonType: "primary", items: buttonItems, text: "Actions" }),
        targetItem && targetItem.type === ViewSetActionType.AddModal && (React.createElement(AddModalAction, { action: targetItem, data: trackedData, defaultData: defaultData, onDismiss: onDismiss, filters: filters, primaryKeyValue: primaryKeyValue, onSaved: forceReset, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, ViewModal: ViewModal, ViewSetModal: ViewSetModal, ServiceDrivenViewSet: ServiceDrivenViewSet })),
        targetItem && targetItem.type === ViewSetActionType.ConfirmationModal && (React.createElement(ConfirmationModalAction, { action: targetItem, data: trackedData, defaultData: defaultData, onDismiss: onDismiss, filters: filters, primaryKeyValue: primaryKeyValue, onSaved: forceReset, ViewModal: ViewModal, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper })),
        targetItem && targetItem.type === ViewSetActionType.Delete && (React.createElement(DeleteAction, { action: targetItem, primaryKeyValue: primaryKeyValue, data: trackedData, onDismiss: onDismiss, onSuccess: onDeleteSuccess, ViewModal: ViewModal, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper }))));
};
export default ControlBarActions;
