import React from 'react';
export const useCustomSort = (onStateChange, initialState) => {
    const [sortState, setSortState] = React.useState(initialState || {});
    const onHeaderClick = React.useCallback((fieldName) => {
        let newSortState = Object.assign({}, sortState);
        if (sortState.orderBy === fieldName) {
            if (sortState.order === 'asc')
                newSortState.order = 'desc';
            if (sortState.order === 'desc')
                newSortState = {};
        }
        else {
            newSortState = { orderBy: fieldName, order: 'asc' };
        }
        setSortState(newSortState);
        onStateChange({
            eventType: 'sort',
            sortState: newSortState,
        });
    }, [onStateChange, sortState]);
    return { onHeaderClick, sortState };
};
export default useCustomSort;
