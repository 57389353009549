var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useFieldConfiguration, useFieldConfigurationSaver, } from '@samc/screen-config-api';
import { confirm } from '@samc/react-ui-core';
import './ConfigureDomain.css';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { useEvaluator } from '@samc/expressions-react';
export var ConfigureDomainTab;
(function (ConfigureDomainTab) {
    ConfigureDomainTab["Fields"] = "fields";
    ConfigureDomainTab["GridViews"] = "grid-views";
    ConfigureDomainTab["FormsAndLayouts"] = "forms-and-layouts";
    ConfigureDomainTab["Validations"] = "validations";
    ConfigureDomainTab["Screens"] = "screens";
    ConfigureDomainTab["GridFilters"] = "grid-filters";
})(ConfigureDomainTab || (ConfigureDomainTab = {}));
export const validateDeleteExpression = (fieldConfig, evaluate) => {
    if (fieldConfig.deleteExpression) {
        const testData = {};
        fieldConfig.fieldConfigurationMembers.forEach((x) => {
            testData[x.viewFieldName] = '';
        });
        try {
            evaluate(testData, fieldConfig.deleteExpression, false);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    return true;
};
export const ConfigureDomainTabHeaders = [
    { header: 'Fields', id: ConfigureDomainTab.Fields },
    { header: 'Grid Views', id: ConfigureDomainTab.GridViews },
    { header: 'Grid Filters', id: ConfigureDomainTab.GridFilters },
    { header: 'Forms & Layouts', id: ConfigureDomainTab.FormsAndLayouts },
    { header: 'Validations', id: ConfigureDomainTab.Validations },
    { header: 'Screens', id: ConfigureDomainTab.Screens },
];
export const ConfigureDomain = (props) => {
    const { children, fieldConfigurationId, domainFields, initialSelectedTabId, onTabChanged } = props;
    const { evaluate } = useEvaluator();
    const fieldConfigResponse = useFieldConfiguration(fieldConfigurationId);
    const [fieldConfiguration, _setFieldConfiguration] = React.useState(undefined);
    /**
     * User clicked the Cancel button.
     * Reset entire component to a clean state
     */
    const resetFieldConfiguration = React.useCallback(() => {
        _setFieldConfiguration(fieldConfigResponse.data);
    }, [fieldConfigResponse.data]);
    const [_saveFieldConfiguration] = useFieldConfigurationSaver(fieldConfiguration || {});
    /**
     * Helper function to POST a FieldConfiguration object.
     * @param fieldConfig The FieldConfiguration object to POST.
     */
    const unvalidatedSaveFieldConfiguration = React.useCallback((fieldConfig) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const savedFieldConfiguration = yield _saveFieldConfiguration(fieldConfig);
            _setFieldConfiguration(savedFieldConfiguration);
        }
        catch (_a) {
            // eslint-disable-next-line no-console
            console.log('Error in ConfigureDomain saving FieldConfiguration.');
        }
    }), [_saveFieldConfiguration]);
    /**
     * Generates error string if any field config members have invalid expressions
     */
    const generateErrorString = React.useCallback((invalidRules, fieldConfigMember) => __awaiter(void 0, void 0, void 0, function* () {
        const errorString = `In the field, ${fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.viewFieldName}, the ${invalidRules.map((rule) => rule)} is invalid. `;
        return errorString;
    }), []);
    /**
     * validates the rules of a field config member
     * returns invalid rules array or empty if no invalid rules were found
     */
    const validateRules = React.useCallback((fieldConfigMember) => __awaiter(void 0, void 0, void 0, function* () {
        const expressions = [];
        const invalidExpressions = [];
        const invalidRules = [];
        if (fieldConfigMember.requiredExpression)
            expressions.push(fieldConfigMember.requiredExpression);
        if (fieldConfigMember.displayNameExpression)
            expressions.push(fieldConfigMember.displayNameExpression);
        if (fieldConfigMember.editableExpression)
            expressions.push(fieldConfigMember.editableExpression);
        if (fieldConfigMember.visibleExpression)
            expressions.push(fieldConfigMember.visibleExpression);
        if (fieldConfigMember.defaultValueExpression)
            expressions.push(fieldConfigMember.defaultValueExpression);
        if (fieldConfigMember.calculationCondition)
            expressions.push(fieldConfigMember.calculationCondition);
        if (fieldConfigMember.calculationRule)
            expressions.push(fieldConfigMember.calculationRule);
        if (fieldConfigMember.hexFillRule)
            expressions.push(fieldConfigMember.hexFillRule);
        const testData = {};
        domainFields.forEach((x) => {
            testData[x.fieldName] = '';
        });
        expressions.forEach((expression) => {
            try {
                evaluate(testData, expression, false);
            }
            catch (_a) {
                if (expression === fieldConfigMember.displayNameExpression &&
                    /^[0-9A-Za-z /()_]+$/.test(expression)) {
                    let wrapped = expression;
                    if (wrapped === '"' || !(wrapped[0] === '"' && wrapped.slice(-1) === '"')) {
                        wrapped = `'${wrapped}'`;
                    }
                    try {
                        evaluate(testData, wrapped, false);
                    }
                    catch (_b) {
                        invalidExpressions.push(expression);
                    }
                }
                else {
                    invalidExpressions.push(expression);
                }
            }
        });
        if (invalidExpressions.length !== 0) {
            invalidExpressions.forEach((expres) => {
                const rule = Object.keys(fieldConfigMember)[Object.values(fieldConfigMember).indexOf(expres)];
                switch (rule) {
                    case 'requiredExpression':
                        invalidRules.push('Required Rule');
                        break;
                    case 'displayNameExpression':
                        invalidRules.push('Display Rule');
                        break;
                    case 'editableExpression':
                        invalidRules.push('Editable Rule');
                        break;
                    case 'visibleExpression':
                        invalidRules.push('Visible Rule');
                        break;
                    case 'defaultValueExpression':
                        invalidRules.push('Default Value Rule');
                        break;
                    case 'calculationCondition':
                        invalidRules.push('Calculation Condition');
                        break;
                    case 'calculationRule':
                        invalidRules.push('Calculation Rule');
                        break;
                    case 'hexFillRule':
                        invalidRules.push('HexFill Rule');
                        break;
                    default:
                        break;
                }
            });
            return invalidRules;
        }
        return [];
    }), [domainFields, evaluate]);
    /**
     * Validate FieldConfigurationMember expressions.
     * Returns true if there are errors.
     */
    const validateFieldConfigurationMember = React.useCallback((fieldConfigMember) => __awaiter(void 0, void 0, void 0, function* () {
        const invalidRules = yield validateRules(fieldConfigMember);
        if (invalidRules.length > 0) {
            const error = yield generateErrorString(invalidRules, fieldConfigMember);
            const options = {
                title: 'Following expressions are invalid',
                detail: error,
                confirmText: 'Ok',
                cancelText: 'Cancel',
            };
            yield confirm(options);
            return true;
        }
        return false;
    }), [generateErrorString, validateRules]);
    /**
     * Validates and saves the currently tracked field configuration
     */
    const saveTrackedFieldConfiguration = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!fieldConfiguration)
            throw new Error('Field configuration not defined');
        const badFields = [];
        for (let i = 0; i < fieldConfiguration.fieldConfigurationMembers.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const hasError = yield validateFieldConfigurationMember(fieldConfiguration.fieldConfigurationMembers[i]);
            if (hasError) {
                badFields.push(fieldConfiguration.fieldConfigurationMembers[i].viewFieldName);
            }
        }
        const deletionExpressionValid = validateDeleteExpression(fieldConfiguration, evaluate);
        if (!deletionExpressionValid)
            badFields.push('Deletable Expression');
        if (badFields.length !== 0) {
            const options = {
                title: 'Following fields are invalid',
                detail: badFields.join(','),
                confirmText: 'Ok',
                cancelText: 'Cancel',
            };
            yield confirm(options);
        }
        else {
            yield unvalidatedSaveFieldConfiguration(fieldConfiguration);
        }
    }), [evaluate, fieldConfiguration, unvalidatedSaveFieldConfiguration, validateFieldConfigurationMember]);
    /**
     * User clicked the Save button in the ConfigureField Panel.
     * Apply the FieldConfigurationMember from the form and Submit the POST request to save the full FieldConfiguration object.
     *
     * Returns true when save is successful.
     */
    const saveFieldConfigurationMember = (fieldConfigMember) => __awaiter(void 0, void 0, void 0, function* () {
        if (!fieldConfiguration)
            return false;
        const fieldConfigurationCopy = Object.assign({}, (fieldConfiguration || {}));
        const index = fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers.findIndex((fcm) => {
            return fcm.viewFieldName === fieldConfigMember.viewFieldName;
        });
        fieldConfigurationCopy.fieldConfigurationMembers[index] = Object.assign({}, fieldConfigMember);
        const isError = yield validateFieldConfigurationMember(fieldConfigMember);
        if (!isError) {
            yield unvalidatedSaveFieldConfiguration(fieldConfigurationCopy);
            return true;
        }
        return false;
    });
    /**
     * User edited the FieldConfiguration data in the grid.
     * Copy the current FieldConfiguration into a new object and update state.
     * @param data The updated grid data.
     */
    const setFieldConfigurationMembersFromGridData = ({ data, }) => {
        // Remove after Henry's change.
        if (data.length === 0)
            return;
        const fieldConfigurationCopy = Object.assign({}, (fieldConfiguration || {}));
        fieldConfigurationCopy.fieldConfigurationMembers = data.filter((x) => x && !x.__DELETED__);
        _setFieldConfiguration(fieldConfigurationCopy);
    };
    /**
     * User clicked the AddRow button.
     * Append a new FieldConfigurationMember and update state.
     */
    const addFieldConfigurationMember = () => {
        const fieldConfigurationCopy = Object.assign(Object.assign({}, (fieldConfiguration || {})), { fieldConfigurationMembers: [
                ...((fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers) || []),
                {
                    viewFieldName: '',
                    isLocked: false,
                    requiredExpression: undefined,
                    displayNameExpression: undefined,
                    editableExpression: undefined,
                    visibleExpression: undefined,
                    defaultValueExpression: undefined,
                    hexFillRule: undefined,
                    isKeyField: false,
                    formatId: undefined,
                    inputType: 0,
                    lookupProperties: undefined,
                    measurementProperties: undefined,
                    unitOfMeasureProperties: undefined,
                    currencyProperties: undefined,
                    unitOfCurrencyProperties: undefined,
                    confirmOptions: undefined,
                },
            ] });
        _setFieldConfiguration(fieldConfigurationCopy);
    };
    const setFieldConfiguration = React.useCallback((newFieldConfiguration) => {
        _setFieldConfiguration(Object.assign({}, newFieldConfiguration));
    }, []);
    const { setValue: setHeaderValue, tabs, selectedTabId } = useHeaderContext();
    React.useEffect(() => {
        if (tabs !== ConfigureDomainTabHeaders) {
            setHeaderValue('tabs', ConfigureDomainTabHeaders);
        }
    }, [setHeaderValue, tabs]);
    // select an initial tab using provided id (if valid) or first id
    React.useEffect(() => {
        let targetId = initialSelectedTabId;
        if (!targetId || !ConfigureDomainTabHeaders.some((h) => h.id === targetId)) {
            targetId = ConfigureDomainTab.Fields;
        }
        if (targetId && targetId !== selectedTabId) {
            setHeaderValue('selectedTabId', targetId);
            if (onTabChanged)
                onTabChanged(targetId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSelectedTabId]);
    // clean up
    React.useEffect(() => {
        return () => {
            setHeaderValue('controlBarProps', undefined);
            setHeaderValue('tabs', undefined);
            setHeaderValue('selectedTabId', undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // when field config loads, set it internally
    React.useEffect(() => {
        if (!fieldConfigResponse.isLoading && !fieldConfigResponse.isError && fieldConfigResponse.data) {
            _setFieldConfiguration(Object.assign({}, fieldConfigResponse.data));
        }
    }, [fieldConfigResponse.data, fieldConfigResponse.isError, fieldConfigResponse.isLoading]);
    return (React.createElement("div", { className: "configure-domain", style: { display: 'flex', flexDirection: 'column', width: '100%' } },
        React.createElement("div", { style: { width: '100%', flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' } }, children({
            selectedTabId: selectedTabId,
            fieldConfiguration,
            setFieldConfiguration,
            addFieldConfigurationMember,
            saveFieldConfigurationMember,
            setFieldConfigurationMembersFromGridData,
            saveFieldConfiguration: saveTrackedFieldConfiguration,
            resetFieldConfiguration,
        }))));
};
export default ConfigureDomain;
