import React from 'react';
import { useStyletron } from 'styletron-react';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import { Layout, SectionHeader, renderFormField, InnerFormContextProvider, } from '@samc/react-ui-form';
import { useFormView, ViewType, ApiContext, } from '@samc/screen-config-api';
import { FormScreenWrapper } from '../FormScreenWrapper/FormScreenWrapper';
import { GridScreenWrapper } from '../GridScreenWrapper/GridScreenWrapper';
import { useLinkClicked } from '../../hooks/useLinkClicked/useLinkClicked';
import { ViewModal } from '../modals/ViewModal/ViewModal';
export const GridWithHeaderLinks = (props) => {
    var _a, _b, _c;
    const { listViewId, setListViewId, selectedFilterIds, setSelectedFilterIds, defaultData, onSelectionChanges, ServiceDrivenViewSet, filters, gridDisplayOptions, selectedTabViewId, sectionTitle, baselineFilterExpression, sectionLinks, onModalSaved, } = props;
    const [formViewId] = React.useState(listViewId.split('List')[0]);
    const [css] = useStyletron();
    const formView = (_a = useFormView(formViewId)) === null || _a === void 0 ? void 0 : _a.data;
    const [links, setLinks] = React.useState(sectionLinks);
    const [isUninitialized, setIsUninitialized] = React.useState(true);
    const dirtinessScope = React.useRef(null);
    const api = React.useContext(ApiContext);
    const { promptAndContinue, forceReset } = useDirtinessPrompt({ scope: dirtinessScope }); // synced at bottom
    const [onLinkClicked, modalFormViewId, modalPk, closeFormViewModal, , , modalDefaultData, modalText, setModalPk] = useLinkClicked({}, formView === null || formView === void 0 ? void 0 : formView.domainId, promptAndContinue);
    React.useEffect(() => {
        var _a, _b, _c;
        if (formView) {
            setLinks((_c = (_b = (_a = formView.formLayout) === null || _a === void 0 ? void 0 : _a.tabs[0]) === null || _b === void 0 ? void 0 : _b.sections[0]) === null || _c === void 0 ? void 0 : _c.links);
            setIsUninitialized(false);
        }
    }, [formView]);
    /**
     * When a modal (or submodal) is saved, we should reset
     */
    const onModalSavedCb = React.useCallback(() => {
        if (onModalSaved)
            onModalSaved();
        forceReset({ includeClean: true });
    }, [onModalSaved]);
    return (React.createElement(InnerFormContextProvider, { value: {
            isUninitialized,
            data: {},
            initialData: {},
            onLinkClicked,
        } },
        React.createElement("div", { ref: dirtinessScope, className: css({
                display: 'flex',
                overflowY: 'hidden',
                flexDirection: 'column',
                width: '100%',
                margin: '20px',
            }) },
            React.createElement("div", { className: css({
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '17px 0 12px',
                    borderBottom: '1px solid rgb(209, 209, 209)',
                }) },
                sectionTitle && React.createElement(SectionHeader, { title: sectionTitle }),
                links && (React.createElement("div", { className: css({
                        flex: '1',
                        marginLeft: '0px',
                        marginTop: '3px',
                    }) },
                    React.createElement(Layout, { config: links, items: (_c = (_b = formView === null || formView === void 0 ? void 0 : formView.formLayout) === null || _b === void 0 ? void 0 : _b.tabs[0]) === null || _c === void 0 ? void 0 : _c.sections, renderItem: renderFormField, outermost: true, baselineFilterExpression: baselineFilterExpression })))),
            React.createElement(GridScreenWrapper, { key: listViewId, listViewId: listViewId, setListViewId: setListViewId, filters: filters, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, defaultData: defaultData, onSelectionChanges: onSelectionChanges, gridDisplayOptions: gridDisplayOptions, onListViewLoadFailed: (failedParams) => {
                    // if the load fails, revert to the default for the tab
                    if (failedParams.listViewId !== selectedTabViewId && setListViewId)
                        setListViewId(selectedTabViewId);
                }, ServiceDrivenViewSet: ServiceDrivenViewSet })),
        modalFormViewId && (React.createElement(ViewModal, { viewId: modalFormViewId, viewType: ViewType.Form, isOpen: true, onDismiss: () => {
                closeFormViewModal();
                forceReset({ includeClean: true });
            }, primaryKeyValue: modalPk, parentPk: api === null || api === void 0 ? void 0 : api.tenantId, setPrimaryKeyValue: setModalPk, onSaved: onModalSavedCb, onSubmodalSaved: onModalSavedCb, filters: [baselineFilterExpression || ''], defaultData: modalDefaultData, headerText: modalText, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }))));
};
export default GridWithHeaderLinks;
