var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useFormView, useApplicationContext } from '@samc/screen-config-api';
import { ClassNameBuilder, MessageBar, MessageBarType } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { useContactAssignmentValidations, } from '../../hooks/useContactAssignmentValidations/useContactAssignmentValidations';
import { FormScreenWrapper } from '../FormScreenWrapper/FormScreenWrapper';
import { GridScreenWrapper } from '../GridScreenWrapper/GridScreenWrapper';
export const ContactAssignmentFormScreenWrapper = (props) => {
    const { formViewId, filters, sidePanelForm, controlVisibilities, defaultData, primaryKeyValue, parentScope: scope, submitAllAttributes, initialTabIndexOrName, className, onTabChanged, ServiceDrivenViewSet, } = props;
    const [css] = useStyletron();
    const formView = useFormView(formViewId);
    const [listMessage, setListMessage] = React.useState();
    const { currentUser, Entitlements } = useApplicationContext();
    const validateContactAssignments = useContactAssignmentValidations();
    React.useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            if (((_a = formView === null || formView === void 0 ? void 0 : formView.data) === null || _a === void 0 ? void 0 : _a.gridFields) && ((_b = formView === null || formView === void 0 ? void 0 : formView.data) === null || _b === void 0 ? void 0 : _b.gridFields.length) > 0) {
                const domainId = (_c = formView.data) === null || _c === void 0 ? void 0 : _c.gridFields[0].domainId;
                const warnings = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.hasEntitlement(Entitlements.AccessLimitedCompanyData))
                    ? yield validateContactAssignments(domainId, primaryKeyValue !== null && primaryKeyValue !== void 0 ? primaryKeyValue : '')
                    : undefined;
                setListMessage(warnings);
            }
        }))();
    }, [
        validateContactAssignments,
        primaryKeyValue,
        formView.data,
        currentUser,
        Entitlements.AccessLimitedCompanyData,
    ]);
    return (React.createElement("div", { className: ClassNameBuilder('contact-assignment-form-screen-wrapper', className, css({ display: 'flex', overflowY: 'hidden', flexDirection: 'column', width: '100%' })) },
        listMessage && listMessage.length > 0 && (React.createElement("div", { className: `${css({ maxHeight: '130px', overflow: 'auto', margin: '10px 20px' })}` }, listMessage === null || listMessage === void 0 ? void 0 : listMessage.map((mes) => (React.createElement(MessageBar, { key: mes.validationMessage, messageBarType: MessageBarType.warning, text: mes.validationMessage }))))),
        React.createElement(FormScreenWrapper, { ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper, className: css({ flex: 1 }), parentScope: scope, key: primaryKeyValue, formViewId: formViewId, primaryKeyValue: primaryKeyValue, filters: filters, defaultData: defaultData, sidePanelForm: sidePanelForm, controlVisibilities: controlVisibilities, submitAllAttributes: submitAllAttributes, initialTabIndexOrName: initialTabIndexOrName, onTabChanged: onTabChanged })));
};
export default ContactAssignmentFormScreenWrapper;
