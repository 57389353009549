import React from 'react';
import { InputType } from '@samc/screen-config-api';
import { DomainExpressionEditor } from '../../molecules/controls/ExpressionEditor/ExpressionEditor';
export const useExpressionGridFields = (gridFieldConfigs, fieldConfigurationMembers) => {
    const wrappedFields = React.useMemo(() => {
        if (!gridFieldConfigs)
            return gridFieldConfigs;
        return gridFieldConfigs.map((gfc) => {
            const { field: fieldName } = gfc;
            const fieldConfigurationMember = fieldConfigurationMembers[fieldName];
            if (!fieldConfigurationMember)
                return gfc;
            const { inputType, domainId } = fieldConfigurationMember;
            if (inputType !== InputType.Expression || !domainId)
                return gfc;
            return Object.assign(Object.assign({}, gfc), { editor: (params) => React.createElement(DomainExpressionEditor, { params: params, domainId: domainId }) });
        });
    }, [fieldConfigurationMembers, gridFieldConfigs]);
    return wrappedFields;
};
export default useExpressionGridFields;
