var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { runFieldCalculations } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const RECALCULATE_KEY = `${SCREEN_CONFIG_API_KEY}RECALCULATE`;
/**
 * Recalculates the provided fields using the calculation rules and conditions
 * provided. Just queues up a job, does not return anything.
 */
// eslint-disable-next-line import/prefer-default-export
export const useFieldCalculator = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (params) => __awaiter(void 0, void 0, void 0, function* () {
        const [primaryKeyField, fields] = params;
        api.ShowMessage(`Calculation queued.`, 'info');
        return runFieldCalculations(api.AggregatorApi, domainId, primaryKeyField, fields, api.requestInit).catch((err) => {
            api.ShowMessage(err instanceof Error && err.message ? err.message : `Failed to queue a calculation.`, 'error');
            throw err;
        });
    });
    const [_sendRequest, mutationResult] = useMutation(query, [RECALCULATE_KEY]);
    const sendRequest = React.useCallback((primaryKeyField, fields) => _sendRequest([primaryKeyField, fields]), [_sendRequest]);
    return [sendRequest, mutationResult];
};
