import React from 'react';
import { InputType } from '@samc/react-ui-form';
import { InputTypeSelect } from '../../../molecules/controls/InputTypeSelect/InputTypeSelect';
export const InputTypeFieldConfig = (openPicklistConfiguration, domainFields) => {
    const onRenderField = (params) => {
        const { displayProperty, valueProperty, onChange, value, onFetchMenuItems, disabled, onBlur, name, data } = params;
        return (React.createElement(InputTypeSelect, { name: name, openPicklistConfiguration: openPicklistConfiguration, displayProperty: displayProperty, valueProperty: valueProperty, onChange: onChange, onFetchMenuItems: onFetchMenuItems, disabled: disabled, domainField: domainFields === null || domainFields === void 0 ? void 0 : domainFields.find((f) => f.fieldName === data.viewFieldName), value: value, onBlur: onBlur }));
    };
    return {
        displayNameRule: "'Type'",
        field: 'inputType',
        editableRule: '[isLocked] = false',
        valueProperty: 'id',
        inputType: InputType.Select,
        onRenderField,
    };
};
export default InputTypeFieldConfig;
