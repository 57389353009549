var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/ban-types */
import { MapToListViewMember, useListView, useQBQuery, } from '@samc/screen-config-api';
import React from 'react';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { Grid } from '@samc/react-ui-grid';
import { ErrorDisplay, useRefWrapper } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { styled, useStyletron } from 'styletron-react';
import { MessageBody } from '../MessageBody/MessageBody';
import { useGridFieldsFromListview } from '../../../hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import { useMissingViewDependencies } from '../../../hooks/useMissingViewDependencies/useMissingViewDependencies';
import { useGridInjector } from '../../../hooks/useGridInjector/useGridInjector';
const StyledDiv = styled('div', () => ({
    whiteSpace: 'pre-wrap',
}));
const noop = () => undefined;
/**
 * Merges two sets of data
 * @param d1 the base data
 * @param d2 the record to merge in
 * @returns A record containing all of the elements in both sets with attributes in d2 overriding those in d1
 */
const mergeDataSets = (d1, d2) => {
    const result = Object.assign({}, d1);
    Object.keys(d2).forEach((key) => {
        result[key] = Object.assign(Object.assign({}, result[key]), d2[key]);
    });
    return result;
};
/**
 * Outputs an array of data values based on inputted ids
 * @param data the data indexed by id
 * @param ids the ids
 * @returns an array mapping ids to data
 */
const extractIds = (data, ids) => {
    return ids.map((id) => data[id]);
};
const SectionHeader = (props) => {
    const { title, description } = props;
    const [css] = useStyletron();
    return (React.createElement("div", { className: css({ marginBottom: '10px' }) },
        React.createElement("div", null,
            React.createElement("span", { className: css({
                    fontWeight: 'bold',
                    verticalAlign: 'top',
                }) }, title)),
        description && React.createElement(StyledDiv, null, description)));
};
const PromptGridInner = (props) => {
    const { prompt, dataByDomain, submit, onChangeData, listView } = props;
    const { domainId, listViewMembers } = listView;
    const numIds = prompt.ids.length;
    const { missingViewMembers: missingListViewMembers, isLoading: areMissingViewMembersLoading } = useMissingViewDependencies(listView, listView.listViewMembers, MapToListViewMember);
    const { wrappedFields, frameworkComponents, domain: domainRequest, } = useGridFieldsFromListview({
        listView,
        additionalMembers: missingListViewMembers,
        useGridInjector,
    });
    const [failed, setFailed] = React.useState(false);
    const [dbData, setDbData] = React.useState();
    const query = useQBQuery();
    const reload = React.useCallback(() => {
        setDbData(undefined);
        setFailed(false);
        const { data: domainData } = domainRequest;
        if (!domainData)
            return;
        const { primaryKey } = domainData;
        const adhocListViewMembers = listViewMembers.concat(missingListViewMembers).map((lvm) => ({
            scalarExpression: `[${lvm.viewFieldName}]`,
            scalarDisplayName: `${lvm.viewFieldName}`,
        }));
        query({
            adhocFilter: {
                advancedExpression: `DoesContain([${primaryKey}], '${prompt.ids.join(',')}')`,
                advancedInd: true,
                expressionLang: 'Centric',
                filterName: 'Adhoc',
            },
            adhocListViewMembers,
            domainId,
            sorting: { order: 'asc', orderBy: { scalarExpression: `[${primaryKey}]` } },
            paging: { start: 0, stop: numIds },
        })
            .then((result) => {
            const { Data } = result;
            const merged = Data.reduce((all, cur) => {
                const id = String(cur.primaryKey);
                const val = cur;
                return Object.assign(Object.assign({}, all), { [id]: val });
            }, {});
            setDbData(merged);
        })
            .catch(() => setFailed(true));
    }, [domainId, domainRequest, listViewMembers, missingListViewMembers, numIds, prompt.ids, query]);
    React.useEffect(() => {
        reload();
    }, [reload]);
    const mergedData = React.useMemo(() => {
        if (dbData) {
            const dataSet = mergeDataSets(dbData, dataByDomain[domainId]);
            Object.keys(dataSet).forEach((key) => {
                var _a;
                if (dataSet[key]) {
                    const ds = Object.assign({}, dataSet[key]);
                    ds.__STAGED__DELETED__ = (_a = ds.__DELETED__) !== null && _a !== void 0 ? _a : false;
                    delete ds.__DELETED__;
                    dataSet[key] = ds;
                }
            });
            return extractIds(dataSet, prompt.ids);
        }
        return undefined;
    }, [dataByDomain, domainId, dbData, prompt.ids]);
    const onChangeDataInner = React.useCallback((args) => {
        const updatedData = args.data
            ? args.data.map((row) => {
                const rowData = row;
                if (rowData.__STAGED__DELETED__)
                    rowData.__DELETED__ = rowData.__STAGED__DELETED__;
                delete rowData.__STAGED__DELETED__;
                return rowData;
            })
            : undefined;
        onChangeData === null || onChangeData === void 0 ? void 0 : onChangeData(domainId, Object.assign(Object.assign({}, args), { data: updatedData }));
    }, [domainId, onChangeData]);
    if (failed)
        return React.createElement(ErrorDisplay, { error: "Failed", onReload: reload });
    if (!mergedData || !wrappedFields || domainRequest.isLoading || areMissingViewMembersLoading)
        return React.createElement(Spinner, null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pkField = domainRequest.data.primaryKey;
    return (React.createElement(Grid, { onSubmit: submit, fields: wrappedFields, idField: pkField, frameworkComponents: frameworkComponents, data: mergedData, suppressColumnFilters: false, suppressRowSelector: true, rowSelection: "none", suppressEditToggle: true, suppressAddRowButton: true, suppressAggregateToggle: true, suppressClearFilter: true, suppressDeleteButton: true, suppressDeleteConfirmationModal: true, suppressRefresh: true, suppressRowSpacing: true, suppressExcelExport: true, isEditingDefault: true, stopEditingWhenCellsLoseFocus: true, onChangeData: onChangeDataInner, style: { width: '100%', height: '250px' } }));
};
export const PromptGrid = (props) => {
    const { prompt } = props;
    const { listViewId, title, description } = prompt;
    const [css] = useStyletron();
    const { data: listView, isError, refresh, error: errorMessage } = useListView(listViewId);
    const error = React.useMemo(() => (isError ? new Error(errorMessage) : undefined), [isError, errorMessage]);
    const loading = !listView && !error;
    return (React.createElement("div", null,
        React.createElement(SectionHeader, { title: title, description: description }),
        React.createElement("div", { className: css({ minHeight: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }) },
            listView && React.createElement(PromptGridInner, Object.assign({}, props, { listView: listView })),
            error && React.createElement(ErrorDisplay, { onReload: refresh, error: error }),
            loading && React.createElement(Spinner, null))));
};
/**
 * Renders the body for the TaskResponsePopup where the response contains prompts
 * and ONLY info messages. It is expected that the consumer only render this when it contains info messages only.
 */
export const PromptBody = (props) => {
    const { infoMessages, reassembleableDataByDomain, domainNameMap, prompts, submitWithUpdates, setAdditionalButtons, } = props;
    const [css] = useStyletron();
    const [dirty, setDirty] = React.useState(false);
    const [changedData, setChangedData] = React.useState(reassembleableDataByDomain);
    const changedDataRef = useRefWrapper(changedData);
    const [isSaving, setIsSaving] = React.useState(false);
    // resetters on change data
    React.useEffect(() => {
        setDirty(false);
        setChangedData(reassembleableDataByDomain);
    }, [reassembleableDataByDomain]);
    const onChangeData = React.useCallback((domain, d) => {
        setChangedData((curCd) => {
            const result = Object.assign({}, curCd);
            if (!result[domain])
                result[domain] = {};
            Object.keys(d.changedData).forEach((key) => {
                result[domain][key] = Object.assign(Object.assign({}, result[domain][key]), d.changedData[key]);
            });
            setDirty(true);
            return result;
        });
    }, []);
    const onSubmit = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!submitWithUpdates)
            return;
        let taskRequests;
        Object.values(changedDataRef.current).forEach((domainData) => {
            Object.values(domainData).forEach((data) => {
                taskRequests = data.reassemble(data);
            });
        });
        try {
            setIsSaving(true);
            if (taskRequests)
                yield submitWithUpdates(taskRequests);
            // expect consumer to change visibility if needed
        }
        finally {
            setIsSaving(false);
        }
    }), [changedDataRef, submitWithUpdates]);
    React.useEffect(() => {
        if (submitWithUpdates && setAdditionalButtons) {
            setAdditionalButtons([
                {
                    text: isSaving ? 'Submitting...' : 'Continue with Save',
                    onClick: onSubmit,
                    disabled: !dirty || isSaving,
                },
            ]);
        }
    }, [changedDataRef, dirty, isSaving, onSubmit, setAdditionalButtons, submitWithUpdates]);
    // clear out buttons on de-render
    React.useEffect(() => {
        return () => setAdditionalButtons === null || setAdditionalButtons === void 0 ? void 0 : setAdditionalButtons([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: css({}) },
        prompts.map((prompt, i) => (React.createElement(HeaderContextProvider, { key: `${prompt.title}_${prompt.listViewId}` },
            i !== 0 && React.createElement("hr", { className: css({ margin: '10px 0px' }) }),
            React.createElement(PromptGrid, { submit: onSubmit, onChangeData: onChangeData, prompt: prompt, dataByDomain: changedData })))),
        infoMessages.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement("hr", { className: css({ margin: '10px 0px' }) }),
            React.createElement(SectionHeader, { title: "Informational Messages", description: "No action needs to be taken" }),
            React.createElement(MessageBody, { domainNameMap: domainNameMap, messages: infoMessages, dataByDomain: reassembleableDataByDomain })))));
};
export default PromptBody;
