/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { DocCountLink } from '../../../atoms/DocCountLink/DocCountLink';
export const DocCountLinkCell = (props) => {
    const { restProps, onClickDocCount, value } = props;
    const handleClick = () => {
        if (onClickDocCount && typeof onClickDocCount === 'function') {
            onClickDocCount(restProps);
        }
    };
    return React.createElement(DocCountLink, { onClick: handleClick, styles: { root: { paddingLeft: '10px' } }, value: value });
};
export const isValidEditableCallbackParams = (params) => !isNullOrUndefined(params.column);
export const DocCountLinkCellRenderer = React.forwardRef((props, ref) => {
    const { valueFormatted, onClickDocCount } = props;
    const [editable, setEditable] = React.useState(false);
    React.useEffect(() => {
        if (!isNullOrUndefined(props.colDef) &&
            typeof props.colDef.editable === 'function' &&
            isValidEditableCallbackParams(props) &&
            props.colDef.editable(props) !== editable) {
            setEditable(!editable);
        }
    }, [editable, props]);
    React.useImperativeHandle(ref, () => ({
        refresh: () => true,
    }));
    if (props.node && props.node.rowPinned === 'bottom') {
        return React.createElement("span", null, valueFormatted);
    }
    return (React.createElement(DocCountLinkCell, { restProps: props, onClickDocCount: onClickDocCount, value: valueFormatted ? Number(valueFormatted) : null }));
});
export const DocCountLinkCellRendererFramework = (onClickDocCount) => (props) => (React.createElement(DocCountLinkCellRenderer, Object.assign({}, props, { onClickDocCount: onClickDocCount })));
export const DocCountLinkValueGetter = (params) => {
    const { data, colDef } = params;
    const { field: column } = colDef;
    const value = column ? data === null || data === void 0 ? void 0 : data[column] : undefined;
    if (!value)
        return '';
    return value;
};
export default DocCountLinkCellRenderer;
