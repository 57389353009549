var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Modal } from '@fluentui/react';
import { HeaderContextProvider } from '@samc/react-ui-history';
import React from 'react';
import { ViewType, useDomains, useTaskSubmit, } from '@samc/screen-config-api';
import { useStyletron } from 'styletron-react';
import { ErrorDisplay, Loader } from '@samc/react-ui-core';
import { ConfirmationModalWrapper, } from '../../../molecules/ConfirmationModalWrapper/ConfirmationModalWrapper';
import { useSelectedRowsByGridContext, } from '../../../contexts/GridSelectionContext/GridSelectionContext';
import { getDomainNameMap, isSuccessfulResponse } from '../../FormScreen/utilities';
import { TaskResponsePopup } from '../../../molecules/TaskResponsePopup/TaskResponsePopup';
/**
 * The confirmation view modal. Dismisses on save by default.
 */
export const MultiSelectConfirmationViewModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { isOpen, headerText, onDismiss, referencedFieldId, viewType, onSaved, dismissOnSave, ServiceDrivenViewSet, style, children, showContent, viewId, filters, defaultData, sidePanelFormViewId, onSubmodalSaved, FormScreenWrapper, GridScreenWrapper, } = props;
    const gridContext = useSelectedRowsByGridContext(referencedFieldId);
    const domains = useDomains();
    const [css] = useStyletron();
    const firstRowPk = (_c = (_b = (_a = gridContext.selectedRowsByGrids) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.selectedIds) === null || _c === void 0 ? void 0 : _c.values().next().value;
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [lastResponses, setLastResponses] = React.useState([]);
    const [lastRequests, setLastRequests] = React.useState([]);
    const [domainMap, setDomainMap] = React.useState({});
    const closeoutModal = React.useCallback(() => {
        gridContext.setSelectedRowsByGrids([]);
        setIsError(false);
        onSaved === null || onSaved === void 0 ? void 0 : onSaved();
        if (dismissOnSave && onDismiss)
            onDismiss();
    }, [gridContext, onDismiss, onSaved]);
    const closeTaskResponsePopup = React.useCallback((openModal) => {
        if (openModal) {
            return;
        }
        closeoutModal();
    }, [onSaved, onDismiss]);
    const domainDict = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = domains.data) === null || _a === void 0 ? void 0 : _a.reduce((all, cur) => {
            return Object.assign(Object.assign({}, all), { [cur.id]: cur });
        }, {})) !== null && _b !== void 0 ? _b : {};
    }, [domains.data]);
    const [taskSubmit] = useTaskSubmit();
    const processResults = React.useCallback((results, requests) => {
        const { isSuccessful } = isSuccessfulResponse(results);
        if (isSuccessful) {
            closeoutModal();
            return [];
        }
        const map = getDomainNameMap(domainDict, requests);
        setDomainMap(map);
        setLastResponses(results);
        setLastRequests(requests);
        setIsError(true);
        setIsLoading(false);
        return results;
    }, [setDomainMap, setLastResponses, setLastRequests, setIsError]);
    const submitTask = React.useCallback((taskRequest, overrideCodes) => __awaiter(void 0, void 0, void 0, function* () {
        setIsError(false);
        setIsLoading(true);
        const updatedRequest = taskRequest.map((request) => (Object.assign(Object.assign({}, request), { overrideCodes })));
        const result = yield taskSubmit(...updatedRequest);
        return processResults(result, updatedRequest);
    }), [setIsError, setIsLoading]);
    const processAdditionalRows = React.useCallback((originalRequest, originalResponse, context, firstRowPrimaryKey) => __awaiter(void 0, void 0, void 0, function* () {
        const requiredIdsToSubmit = [...context.selectedIds];
        const { selectedRows } = context;
        // if the original response was successful, we can remove the first row from the list of required ids
        if (isSuccessfulResponse([originalResponse]).isSuccessful) {
            requiredIdsToSubmit.shift();
            selectedRows.shift();
        }
        const submitObject = [
            Object.assign(Object.assign({}, originalRequest), { payload: requiredIdsToSubmit.reduce((previous, current, index) => (Object.assign(Object.assign({}, previous), { [current]: Object.assign(Object.assign(Object.assign({}, originalRequest.payload[firstRowPrimaryKey]), { [context.primaryKeyField]: current, primaryKey: current }), context.selectedRows[index]) })), {}) }),
        ];
        yield submitTask(submitObject);
    }), []);
    const handleFormSubmit = React.useCallback((_, taskRequests, taskResponses) => __awaiter(void 0, void 0, void 0, function* () {
        const originalResponse = taskResponses[0];
        const originalRequest = taskRequests[0];
        const allResponses = [...taskResponses];
        const submittedRequests = [...taskRequests];
        if (!originalResponse || !originalRequest) {
            return;
        }
        if (gridContext.selectedRowsByGrids[0].selectedIds.size === 1) {
            processResults(allResponses, submittedRequests);
            return;
        }
        yield processAdditionalRows(originalRequest, originalResponse, gridContext.selectedRowsByGrids[0], firstRowPk);
    }), []);
    if (((_d = gridContext === null || gridContext === void 0 ? void 0 : gridContext.selectedRowsByGrids) === null || _d === void 0 ? void 0 : _d.length) === 0 ||
        ((_g = (_f = (_e = gridContext === null || gridContext === void 0 ? void 0 : gridContext.selectedRowsByGrids) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.selectedIds) === null || _g === void 0 ? void 0 : _g.size) === 0) {
        return (React.createElement(HeaderContextProvider, null,
            React.createElement(Modal, { isOpen: isOpen, onDismiss: onDismiss, layerProps: { eventBubblingEnabled: true } },
                React.createElement(ErrorDisplay, { title: "No rows selected", error: new Error('No rows selected, you must select at least one row to continue') }))));
    }
    if (viewType === ViewType.Grid) {
        return (React.createElement(HeaderContextProvider, null,
            React.createElement(Modal, { isOpen: isOpen, onDismiss: onDismiss, layerProps: { eventBubblingEnabled: true } },
                React.createElement(ErrorDisplay, { error: new Error('Sorry, Grids are not supported in this context') }))));
    }
    if (isError && lastRequests && lastResponses) {
        return (React.createElement(TaskResponsePopup, { responses: lastResponses, requests: lastRequests, visible: isError, setVisible: closeTaskResponsePopup, domainNameMap: domainMap, submitWithOverrides: (overrideCodes) => __awaiter(void 0, void 0, void 0, function* () {
                submitTask(lastRequests, overrideCodes);
            }) }));
    }
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(ConfirmationModalWrapper, { isOpen: isOpen, headerText: headerText, onDismiss: onDismiss },
            React.createElement(Loader, { isLoading: isLoading, isError: isError, errorText: "There was a problem submitting your request" },
                React.createElement(FormScreenWrapper, { showContent: showContent, className: css({ flex: 1 }), style: style, key: viewId, formViewId: viewId, onSubmit: handleFormSubmit, filters: filters, defaultData: defaultData, primaryKeyValue: firstRowPk, sidePanelForm: sidePanelFormViewId, onModalSaved: onSubmodalSaved, submitAllAttributes // modals, for now, should submit entire payload
                    : true, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper }, children)))));
};
export default MultiSelectConfirmationViewModal;
