import React from 'react';
import { GridScreenWrapperInner } from './GridScreenWrapperInner';
export * from './GridScreenWrapperInner'; // re-export for backwards compatibility
export class GridScreenWrapper extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.gridScreenRef = React.createRef();
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.reset = () => {
            var _a;
            (_a = this.gridScreenRef.current) === null || _a === void 0 ? void 0 : _a.reset();
        };
    }
    render() {
        return (React.createElement(GridScreenWrapperInner, Object.assign({ GridScreenWrapper: GridScreenWrapper, ref: this.gridScreenRef }, this.props)));
    }
}
export default GridScreenWrapper;
