import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getFieldConfigurationsByDomain } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const FIELD_CONFIGURATIONS_BY_DOMAIN_KEY = `${SCREEN_CONFIG_API_KEY}FIELD_CONFIGURATIONS_BY_DOMAIN`;
export const useFieldConfigurationByDomain = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (domId) => domId ? getFieldConfigurationsByDomain(api.AggregatorApi, domId, api.requestInit) : Promise.resolve(undefined);
    return useRequestBase({
        key: FIELD_CONFIGURATIONS_BY_DOMAIN_KEY,
        query,
        requestContext: ApiContext,
        params: [domainId],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
