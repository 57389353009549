import { InputType } from '@samc/screen-config-api';
/**
 * Processes grid field configurations for consumption by folder UI for evaluating folder dropdown fields.
 * @param gfcs the unprepared grid field configurations
 * @param fieldConfigurations the field configuration members indexed by field name
 * @returns processed grid field configurations
 */
export const prepareFolderFields = (gfcs, fieldConfigurationMembers) => gfcs.map((gfc) => {
    const { field } = gfc;
    const fieldConfiguration = fieldConfigurationMembers[field];
    if (!fieldConfiguration)
        return gfc;
    const { domainId, inputType } = fieldConfiguration;
    if (!domainId)
        return gfc;
    let itemType;
    switch (inputType) {
        case InputType.DomainFilter:
            itemType = 'DomainFilter';
            break;
        case InputType.ListView:
            itemType = 'DomainView';
            break;
        case InputType.FormView:
            itemType = 'FormView';
            break;
        default:
            return gfc;
    }
    return Object.assign(Object.assign({}, gfc), { folderQuery: {
            spaceName: domainId,
            itemType,
        } });
});
export default {};
