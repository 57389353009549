import React from 'react';
const GridRowsSelectionContext = React.createContext({
    selectedRowsByGrids: [],
    setSelectedRowsByGrids: () => 0,
});
/**
 * A context for getting the selected rows of a grid.
 */
export const GridSelectionProvider = ({ children }) => {
    const [selectedRowsByGrids, setSelectedRowsByGrids] = React.useState([]);
    const selectedRowsByGridsMemo = React.useMemo(() => ({
        selectedRowsByGrids: selectedRowsByGrids,
        setSelectedRowsByGrids: setSelectedRowsByGrids,
    }), [selectedRowsByGrids]);
    return (React.createElement(GridRowsSelectionContext.Provider, { value: selectedRowsByGridsMemo }, children));
};
export const cleanGridRowsSelected = (prevSelectedRowsByGrids, listViewId) => {
    const tmpState = [...prevSelectedRowsByGrids];
    const existingGridIndex = tmpState.findIndex((row) => row.gridId === listViewId);
    if (existingGridIndex !== -1) {
        tmpState.splice(existingGridIndex, 1);
    }
    return tmpState;
};
export const useSelectedRowsByGridContext = (listViewId) => {
    const { setSelectedRowsByGrids } = React.useContext(GridRowsSelectionContext);
    React.useEffect(() => {
        return () => {
            setSelectedRowsByGrids((prevState) => cleanGridRowsSelected(prevState, listViewId));
        };
    }, [listViewId, setSelectedRowsByGrids]);
    return React.useContext(GridRowsSelectionContext);
};
export const updateRowsSelected = (prevSelectedRowsByGrids, newRows, listViewId, newSelectedIds, primaryKeyField) => {
    const tmpState = [...prevSelectedRowsByGrids];
    const existingGridIndex = tmpState.findIndex((row) => row.gridId === listViewId);
    if (existingGridIndex !== -1) {
        tmpState[existingGridIndex].selectedRows = newRows;
        tmpState[existingGridIndex].selectedIds = newSelectedIds;
    }
    else {
        tmpState.push({
            selectedRows: newRows,
            gridId: listViewId,
            selectedIds: newSelectedIds,
            primaryKeyField,
        });
    }
    return tmpState;
};
