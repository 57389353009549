var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDomain, useQBQuery } from '@samc/screen-config-api';
import { useMissingViewSetDependencies } from '../useMissingViewSetDependencies/useMissingViewSetDependencies';
export const useViewSetDataContext = (viewSet, primaryKeyValue) => {
    var _a, _b;
    const [data, setData] = React.useState();
    const domainId = (_a = viewSet === null || viewSet === void 0 ? void 0 : viewSet.domainId) !== null && _a !== void 0 ? _a : '';
    const domain = useDomain(domainId);
    const qbQuery = useQBQuery();
    const primaryKey = (_b = domain.data) === null || _b === void 0 ? void 0 : _b.primaryKey;
    const viewMembers = useMissingViewSetDependencies(viewSet);
    React.useEffect(() => {
        function fetchQbData() {
            return __awaiter(this, void 0, void 0, function* () {
                const loadViewSetData = () => __awaiter(this, void 0, void 0, function* () {
                    return qbQuery({
                        domainId,
                        primaryViewId: '-1',
                        filterIds: [],
                        adhocListViewMembers: viewMembers.map((m) => ({
                            scalarExpression: `[${m.viewFieldName}]`,
                            scalarDisplayName: m.viewFieldName,
                        })) || [],
                        paging: { start: 0, stop: 1 },
                        sorting: {},
                        summaryMode: false,
                        adhocFilter: {
                            filterName: 'useViewSetDataContext',
                            advancedInd: true,
                            expressionLang: 'Centric',
                            advancedExpression: `[${primaryKey}] = '${primaryKeyValue}'`,
                        },
                    });
                });
                if (domainId && primaryKeyValue && primaryKey && viewMembers) {
                    const viewSetDataResult = yield loadViewSetData();
                    if (viewSetDataResult && viewSetDataResult.Data && viewSetDataResult.Data.length === 1) {
                        const viewSetData = viewSetDataResult.Data[0];
                        setData(viewSetData);
                    }
                }
            });
        }
        // only request data if needed.
        if (viewMembers.length > 0) {
            fetchQbData();
        }
    }, [primaryKeyValue, primaryKey, domainId, qbQuery, viewMembers]);
    return data;
};
export default useViewSetDataContext;
