/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useStyletron } from 'styletron-react';
import { ConfigureFields } from '../../ConfigureDomain/ConfigureFields/ConfigureFields';
import { ConfigureForms } from '../../ConfigureDomain/ConfigureForms/ConfigureForms';
import { ConfigureViewSet } from '../../ConfigureDomain/ConfigureViewSet/ConfigureViewSet';
import { ValidationViewEditor } from '../../editors/ValidationViewEditor/ValidationViewEditor';
import { ListViewFolderEditor } from '../../editors/ListViewFolderEditor/ListViewFolderEditor';
import { GridFilterFolderEditor } from '../../editors/GridFilterFolderEditor/GridFilterFolderEditor';
import { ConfigureDomainTab } from '../../ConfigureDomain/ConfigureDomain';
export const DomainDetailBody = (props) => {
    const { domain, fieldConfigurationId, selectedTabId, fieldConfiguration, setFieldConfiguration, addFieldConfigurationMember, saveFieldConfigurationMember, setFieldConfigurationMembersFromGridData, saveFieldConfiguration, resetFieldConfiguration, } = props;
    const [css] = useStyletron();
    if (selectedTabId === ConfigureDomainTab.Fields && fieldConfiguration && fieldConfigurationId) {
        return (React.createElement(ConfigureFields, { className: css({ height: '100%' }), key: `${domain.id}:${fieldConfigurationId}`, fieldConfiguration: fieldConfiguration, domain: domain, setFieldConfiguration: setFieldConfiguration, addFieldConfigurationMember: addFieldConfigurationMember, saveFieldConfiguration: saveFieldConfiguration, saveFieldConfigurationMember: saveFieldConfigurationMember, setFieldConfigurationMembersFromGridData: setFieldConfigurationMembersFromGridData, resetFieldConfiguration: resetFieldConfiguration }));
    }
    if (selectedTabId === ConfigureDomainTab.GridViews) {
        return React.createElement(ListViewFolderEditor, { domainId: domain.id, multiselect: true });
    }
    if (selectedTabId === ConfigureDomainTab.FormsAndLayouts) {
        return React.createElement(ConfigureForms, { key: domain.id, domainId: domain.id, domainFields: domain.fields });
    }
    if (selectedTabId === ConfigureDomainTab.Validations) {
        return React.createElement(ValidationViewEditor, { domainId: domain.id });
    }
    if (selectedTabId === ConfigureDomainTab.Screens) {
        return (React.createElement(ConfigureViewSet, { key: fieldConfigurationId, domainId: domain.id, domainFields: domain.fields, fieldConfigurationId: fieldConfigurationId }));
    }
    if (selectedTabId === ConfigureDomainTab.GridFilters) {
        return React.createElement(GridFilterFolderEditor, { domainId: domain.id, multiselect: true });
    }
    return null;
};
export const DomainDetailBodyWrapper = (props) => {
    const [css] = useStyletron();
    return (React.createElement("div", { className: css({
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }) },
        React.createElement(DomainDetailBody, Object.assign({}, props))));
};
export default DomainDetailBodyWrapper;
