import { ManagedTabBar, useDirtinessPrompt } from '@samc/react-ui-core';
import React from 'react';
export const ViewSetTabBar = (props) => {
    const { tabHeaders, selectedTab, setSelectedTab, dirtinessScope, className } = props;
    const { promptAndContinue } = useDirtinessPrompt({ scope: dirtinessScope });
    const handleSelect = React.useCallback((newIndex) => {
        // If form is dirty, confirm user wants to discard changes before switching the tab.
        promptAndContinue(() => {
            setSelectedTab(newIndex);
        });
    }, [promptAndContinue, setSelectedTab]);
    return (React.createElement(ManagedTabBar, { headers: tabHeaders, selectedIndex: selectedTab, onSelect: handleSelect, className: className }));
};
export default ViewSetTabBar;
