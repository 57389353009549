import React from 'react';
import { useChildViews, useDomains, DomainRelationshipType, } from '@samc/screen-config-api';
import { LSThemeWrapper } from '@samc/vmsnext-querybuilder';
import { FormViewEditor } from './FormViewEditor/FormViewEditor';
import { FormSkeletonMappers } from '../../../mappers/FormSkeletonMappers/FormSkeletonMappers';
export const ConfigureForms = (props) => {
    const { domainId, domainFields, saveFormToTab } = props;
    const childViewsResult = useChildViews(domainId !== null && domainId !== void 0 ? domainId : '');
    const domainsResult = useDomains();
    const childGrids = React.useMemo(() => {
        if (!childViewsResult.data || !domainsResult.data)
            return [];
        // get first view for each domain
        const viewsByDomain = childViewsResult.data.reduce((out, cur) => {
            if (out[cur.domainId])
                return out;
            const newOut = Object.assign({}, out);
            newOut[cur.domainId] = cur;
            return newOut;
        }, {});
        return domainsResult.data
            .map((domain) => {
            var _a, _b;
            const { domainName, id: dId } = domain;
            const listView = viewsByDomain[dId];
            if (!listView)
                return undefined;
            return FormSkeletonMappers.GridConfiguration.fromFormViewGridField({
                field: domainName,
                viewId: (_a = listView.id) !== null && _a !== void 0 ? _a : '',
                domainId: (_b = listView.domainId) !== null && _b !== void 0 ? _b : '',
                sourceField: '', // Only needed when loading data, irrelevant here
                targetField: '', // Only needed when loading data, irrelevant here
                relationshipType: DomainRelationshipType.Single, // irrelevant here
            }, {
                viewName: listView.viewName,
            });
        })
            .filter((v) => !!v);
    }, [childViewsResult, domainsResult]);
    return (React.createElement(LSThemeWrapper, null,
        React.createElement(FormViewEditor, { domainId: domainId, domainFields: domainFields, childGrids: childGrids, saveFormToTab: saveFormToTab })));
};
export default ConfigureForms;
