import React from 'react';
import { ServersideFolderViewer, isFolder, } from '@samc/folder-ui-core';
import { ViewTypeContextProvider, ViewType } from '@samc/vmsnext-querybuilder';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { ValidationViewEditorModal } from '../../../molecules/modals/ValidationViewEditorModal/ValidationViewEditorModal';
export const ValidationViewEditor = (props) => {
    const { domainId } = props;
    const viewContext = React.useContext(ViewTypeContextProvider);
    const [{ defaultFolderId, selectedTemplateId }, setSelectionState] = React.useState({});
    const folderViewerRef = React.useRef(null);
    const defaultValues = React.useMemo(() => (Object.assign({}, (defaultFolderId && {
        folder: { folderGuid: defaultFolderId },
    }))), [defaultFolderId]);
    const handleAddValidationView = (folderId) => {
        setSelectionState({
            defaultFolderId: folderId,
        });
    };
    const onClose = () => {
        var _a;
        setSelectionState({});
        (_a = folderViewerRef.current) === null || _a === void 0 ? void 0 : _a.reload();
    };
    const getContextMenuItems = (params) => {
        const { node } = params;
        const { data } = node;
        if (!data)
            return [];
        if (isFolder(data))
            return [{ action: () => handleAddValidationView(data.id), name: 'Add Validation Template' }];
        return [
            {
                action: () => {
                    setSelectionState({
                        selectedTemplateId: data.sequentialItemId,
                    });
                },
                name: 'Edit Validation Template',
            },
        ];
    };
    React.useEffect(() => {
        viewContext.setViewType(ViewType.Validation);
        viewContext.fetchViewList();
    });
    const isModalOpen = !isNullOrUndefined(selectedTemplateId) || !!defaultFolderId;
    return (React.createElement("div", { style: { width: '100%', height: '100%' } },
        React.createElement(ServersideFolderViewer, { ref: folderViewerRef, spaceName: domainId, type: "ValidationTemplate", typeDisplayName: "Validation", typeDisplayNamePlural: "Validations", getContextMenuItems: getContextMenuItems, multiselect: true }),
        isModalOpen && (React.createElement(ValidationViewEditorModal, { defaultValues: defaultValues, onClose: onClose, validationViewId: selectedTemplateId }))));
};
export default ValidationViewEditor;
