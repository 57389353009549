import React from 'react';
import { Route, Routes } from 'react-router';
import DomainDetailPage from '../../pages/DomainDetailPage/DomainDetailPage';
import { ViewSetPage } from '../../pages/ViewSetPage/ViewSetPage';

export const Router = (): React.ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<ViewSetPage />} />
            <Route path="screens/" element={<ViewSetPage />} />
            <Route path="screens/:id" element={<ViewSetPage />} />
            <Route path="screens/:id/:tabId" element={<ViewSetPage />} />
            <Route path="screens/:id/:tabId/:subtabId" element={<ViewSetPage />} />
            <Route path="document-types/:id" element={<ViewSetPage />} />
            <Route path="domain-detail/" element={<DomainDetailPage />} />
            <Route path="domain-detail/:id" element={<DomainDetailPage />} />
            <Route path="domain-detail/:id/:tabId" element={<DomainDetailPage />} />
        </Routes>
    );
};

export default Router;
