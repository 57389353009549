import React from 'react';
import { InputType } from '@samc/react-ui-form';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { FormSkeletonMappers } from '../../mappers/FormSkeletonMappers/FormSkeletonMappers';
export const transformMeasurementCurrencyGridFieldconfigurations = (gridFields, fieldConfigurationMembers) => {
    if (!gridFields)
        return gridFields;
    const gridFieldConfigs = gridFields.map((f) => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!Object.keys(fieldConfigurationMembers).some((k) => k === f.field))
            return f;
        const fieldConfig = fieldConfigurationMembers[f.field];
        const isPicklistField = !!fieldConfig.picklistField;
        if (isPicklistField)
            return f;
        const field = Object.assign({}, f);
        const apiInputType = isNullOrUndefined(fieldConfig.inputType)
            ? undefined
            : FormSkeletonMappers.InputType.toFrontendInputType(fieldConfig.inputType);
        switch (apiInputType) {
            case InputType.CurrencyField:
                field.type = 'number';
                field.displayFormat = (_a = f.displayFormat) !== null && _a !== void 0 ? _a : 'money';
                field.currencyProperties = {
                    unitOfCurrencyFieldName: (_b = fieldConfig.currencyProperties) === null || _b === void 0 ? void 0 : _b.unitOfCurrencyFieldName,
                };
                break;
            case InputType.MeasurementField:
                field.type = 'number';
                field.displayFormat = (_c = f.displayFormat) !== null && _c !== void 0 ? _c : 'number';
                field.measurementProperties = {
                    unitOfMeasureFieldName: (_d = fieldConfig.measurementProperties) === null || _d === void 0 ? void 0 : _d.unitOfMeasureFieldName,
                };
                break;
            case InputType.CurrencyPerArea:
                field.type = 'number';
                field.displayFormat = (_e = f.displayFormat) !== null && _e !== void 0 ? _e : 'number';
                field.currencyProperties = {
                    unitOfCurrencyFieldName: (_f = fieldConfig.currencyProperties) === null || _f === void 0 ? void 0 : _f.unitOfCurrencyFieldName,
                };
                field.measurementProperties = {
                    unitOfMeasureFieldName: (_g = fieldConfig.measurementProperties) === null || _g === void 0 ? void 0 : _g.unitOfMeasureFieldName,
                };
                break;
            default:
                break;
        }
        return field;
    });
    return gridFieldConfigs;
};
export const useMeasurementCurrencyGridFields = (fieldConfigurationMembers, gridFieldConfigs) => {
    const newGFConfigs = React.useMemo(() => transformMeasurementCurrencyGridFieldconfigurations(gridFieldConfigs, fieldConfigurationMembers), [fieldConfigurationMembers, gridFieldConfigs]);
    return newGFConfigs;
};
export default useMeasurementCurrencyGridFields;
