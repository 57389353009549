export var TaskStatus;
(function (TaskStatus) {
    /**
     * Indicates a task has completed
     */
    TaskStatus[TaskStatus["Completed"] = 0] = "Completed";
    /**
     * Indicates a task has failed
     */
    TaskStatus[TaskStatus["Failed"] = 1] = "Failed";
    /**
     * Indicates that execution of a task was deferred pending a change
     */
    TaskStatus[TaskStatus["Deferred"] = 2] = "Deferred";
})(TaskStatus || (TaskStatus = {}));
export default TaskStatus;
