import React from 'react';
import { Button } from '@samc/react-ui-core';
import { useExpressionResult } from '@samc/expressions-react';
import { useFormTabExporter } from '@samc/screen-config-api';
export const TabExportButton = (props) => {
    const { formTab, className, formData, primaryKey, formViewId } = props;
    const { exportEnabledExpression, includeClientName, includeFooterText, includePageNumber, includeTimestamp, isHorizontal, titleExpression, footerText, tabIndex, } = formTab;
    const [isExporting, setIsExporting] = React.useState(false);
    const _export = useFormTabExporter();
    const onClick = () => {
        setIsExporting(true);
        _export({
            tabOptions: {
                includeClientName: !!includeClientName,
                includeFooterText: !!includeFooterText,
                includePageNumber: !!includePageNumber,
                includeTimestamp: !!includeTimestamp,
                isHorizontal: !!isHorizontal,
                titleExpression,
                footerText,
            },
            formViewId,
            primaryKey,
            tabIndex,
        }).finally(() => setIsExporting(false));
    };
    const canGenerate = useExpressionResult(exportEnabledExpression !== null && exportEnabledExpression !== void 0 ? exportEnabledExpression : 'false', formData, undefined, true) === true;
    if (!canGenerate)
        return null;
    return (React.createElement(Button, { className: className, buttonType: "secondary", disabled: isExporting, text: isExporting ? 'Exporting...' : 'PDF Screen', onClick: onClick }));
};
export default TabExportButton;
