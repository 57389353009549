import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getViewSetsByDomain } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const VIEW_SETS_BY_DOMAIN_KEY = `${SCREEN_CONFIG_API_KEY}VIEW_SETS_BY_DOMAIN`;
// eslint-disable-next-line import/prefer-default-export
export const useViewSetsByDomain = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (dId) => getViewSetsByDomain(api.AggregatorApi, dId, api.requestInit);
    return useRequestBase({
        key: VIEW_SETS_BY_DOMAIN_KEY,
        query,
        requestContext: ApiContext,
        params: [domainId],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
