import SitusHeader from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import React from 'react';
import { SelectEntryField } from '@samc/react-ui-form';
import { ConfigureForms } from '../../../ConfigureForms/ConfigureForms';
import { ListViewFolderEditor } from '../../../../editors/ListViewFolderEditor/ListViewFolderEditor';
export const ViewSetLayoutSettings = (props) => {
    const { domainId, domainFields, onFormSubmit, onFormCancel } = props;
    const [displayFormViews, setDisplayFormViews] = React.useState(false);
    const [displayListViews, setDisplayListViews] = React.useState(false);
    const [selectedListView, setSelectedListView] = React.useState();
    const [selectedFormView, setSelectedFormView] = React.useState();
    const handleLayoutTypeChange = (menuItem) => {
        if ((menuItem === null || menuItem === void 0 ? void 0 : menuItem.key) === 'formview') {
            setDisplayListViews(false);
            setDisplayFormViews(true);
        }
        else {
            setDisplayFormViews(false);
            setDisplayListViews(true);
        }
    };
    const handleSaveListToTab = (listView) => {
        var _a;
        setSelectedListView({
            viewId: (_a = listView.guid) !== null && _a !== void 0 ? _a : listView.id,
            type: 0,
            title: listView.viewName,
        });
        setSelectedFormView(undefined);
    };
    const handleSaveFormToTab = (formView) => {
        setSelectedFormView({ viewId: formView.id, type: 1, title: formView.name });
        setSelectedListView(undefined);
    };
    const onSubmit = () => {
        if (selectedListView)
            onFormSubmit(selectedListView);
        else if (selectedFormView)
            onFormSubmit(selectedFormView);
    };
    const menuItems = [
        { key: 'formview', text: 'Form View' },
        { key: 'listview', text: 'List View' },
    ];
    return (React.createElement("div", { style: {
            height: '70vh',
            width: '70vw',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement(SitusHeader, { title: "Layout Settings", confirmationButtonText: "Save", onConfirmationButtonClicked: onSubmit, rejectionButtonText: "Cancel", onRejectionButtonClicked: onFormCancel }),
        React.createElement("div", { style: { width: '300px', marginLeft: '11px', marginTop: '11px' } },
            "Select Layout Type:",
            React.createElement("div", { style: { marginTop: '11px' } },
                React.createElement(SelectEntryField, { displayProperty: "text", valueProperty: "key", onChange: handleLayoutTypeChange, menuItems: menuItems }))),
        React.createElement("div", { style: { marginTop: '11px', marginLeft: '11px', overflowY: 'auto', flex: 1 } },
            displayFormViews && (React.createElement(ConfigureForms, { domainId: domainId, domainFields: domainFields, saveFormToTab: handleSaveFormToTab })),
            displayListViews && domainId && (React.createElement(ListViewFolderEditor, { domainId: domainId, onSelectionChanged: handleSaveListToTab })))));
};
export default ViewSetLayoutSettings;
