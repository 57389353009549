import { Icon, Modal } from '@fluentui/react';
import { Button } from '@samc/react-ui-core';
import React from 'react';
import { useStyletron } from 'styletron-react';
export const BaseTaskResponsePopup = (props) => {
    const { accentColor, buttons, children, title, description, visible, setVisible, showDivider } = props;
    const [css] = useStyletron();
    /* The modal may contain a grid with autoHeight, which does not play nicely with IModalProps.isOpen.
     * We return null here when !visible instead of leveraging isOpen so that when this Modal becomes
     * visible, the grid's parent containers will already be rendered by the DOM, and it can then
     * calculate autoHeight correctly.
     */
    if (!visible)
        return null;
    return (React.createElement(Modal, { layerProps: { eventBubblingEnabled: true }, forceFocusInsideTrap: false, isOpen: true, styles: {
            main: {
                display: 'flex',
                overflow: 'hidden',
            },
            scrollableContent: { display: 'flex', overflow: 'hidden' },
        }, onDismiss: () => setVisible(false) },
        React.createElement("div", { className: css({ width: '58vw', display: 'flex' }) },
            React.createElement("div", { className: css({
                    padding: '1rem 1rem 0 1rem',
                    borderBottom: `0.5rem solid ${accentColor}`,
                    flexGrow: 1,
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                }) },
                React.createElement("div", { className: css({ marginBottom: '10px', flexShrink: 0 }) },
                    React.createElement("div", { className: css({
                            lineHeight: '1.5rem',
                        }) },
                        React.createElement(Icon, { className: css({
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                color: accentColor,
                            }), iconName: "Error" }),
                        React.createElement("span", { className: css({
                                fontWeight: 'bold',
                                fontSize: '1.5em',
                                verticalAlign: 'top',
                                marginLeft: '1em',
                            }) }, title)),
                    description && React.createElement("div", null, description)),
                showDivider && React.createElement("hr", { className: css({ margin: '10px 0px' }) }),
                React.createElement("div", { className: css({ flex: '1', overflow: 'auto' }) }, children),
                React.createElement("div", { className: css({
                        textAlign: 'right',
                        margin: '1.5rem 0 1rem 0',
                        flexShrink: 0,
                    }) }, buttons.map((button, i) => (React.createElement(Button, { key: button.text, buttonType: "secondary", text: button.text, disabled: button.disabled, onClick: button.onClick, className: css({ marginLeft: i === 0 ? undefined : '5px' }) }))))))));
};
export default BaseTaskResponsePopup;
