import { useMutation as useReactMutation, useQueryClient } from 'react-query';
/**
 * Wrapper around the react-query useMutation hook to handle invalidating cache
 * elements on create/update. This hook maps the request information returned by
 * react-query to the limited subset we care about. This is intended to be used
 * only within this package to compose domain-specific query hooks.
 * @param request Function to execute the mutation (create/update)
 * @param dependentKeys The keys to the corresponding query that will be
 * invalidated by this mutation.
 */
const useMutation = (request, dependentKeys) => {
    const queryClient = useQueryClient();
    const { mutateAsync, data, isLoading, isError, error } = useReactMutation(request, {
        onSuccess: () => {
            if (dependentKeys) {
                queryClient.invalidateQueries(dependentKeys);
            }
        },
        onError: (e) => {
            throw e;
        },
    });
    const mappedResult = {
        isLoading,
        isError,
        data,
        error: error === null || error === void 0 ? void 0 : error.message,
    };
    return [mutateAsync, mappedResult];
};
export default useMutation;
