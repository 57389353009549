import React from 'react';
import { CustomModal, ValidationEditor } from '@samc/vmsnext-querybuilder';
import { useLookupsForLiftAndSift } from '../../../hooks/useLookupsForExpressions/useLookupsForExpressions';
const severityOptions = [
    { key: '3', text: 'Error' },
    { key: '2', text: 'Warning' },
    { key: '0', text: 'Off' },
];
export const ValidationViewEditorModal = (props) => {
    const { onClose, validationViewId, defaultValues } = props;
    useLookupsForLiftAndSift();
    return (React.createElement(CustomModal, { onDismiss: onClose },
        React.createElement(ValidationEditor, { templateId: validationViewId, onClose: onClose, suppressOverrideableColumn: true, suppressManagedIndColumn: true, overrideSeverityOptions: severityOptions, suppressSetTypeColumn: false, defaultValues: defaultValues })));
};
export default ValidationViewEditorModal;
