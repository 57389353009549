var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/display-name */
import React from 'react';
import { Panel, PanelType, Spinner } from '@fluentui/react';
import { DataEntryForm, useFormApi } from '@samc/react-ui-form';
import { CalculationFrequency } from '@samc/screen-config-api';
import { MessageBar, MessageBarType, useDirtinessPrompt } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { useLinkClicked } from '../../../hooks/useLinkClicked/useLinkClicked';
import { ModalControlBar } from '../../modals/ModalControlBar/ModalControlBar';
export const FieldConfigurationCrudEditorPanel = (props) => {
    const { formConfig, formData, onSubmit, isOpen, onCancel: _onCancelProp, onFormLoaded: externalOnFormLoaded, onClose: onDismiss, dirtyOverride, } = props;
    const dirtinessScope = React.useRef(null);
    const { promptAndContinue, save, isDirty } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const [panelIsSaving, setPanelIsSaving] = React.useState(false);
    const [formApi, onFormLoaded] = useFormApi(externalOnFormLoaded);
    const [css] = useStyletron();
    const [formState, setFormState] = React.useState();
    const [currentData, setCurrentData] = React.useState(formData !== null && formData !== void 0 ? formData : {});
    const { calculationFrequency, calculationCondition } = currentData;
    const { isValid } = formState !== null && formState !== void 0 ? formState : {};
    const [showBanner, setShowBanner] = React.useState(false);
    const onCancel = () => promptAndContinue(() => {
        _onCancelProp();
    });
    React.useEffect(() => {
        const calculationConditionField = 'calculationCondition';
        if (calculationFrequency !== CalculationFrequency.Conditionally && calculationCondition && formApi) {
            // TS is unhappy if we don't cast, due to circular properties
            formApi.onChange(calculationConditionField, undefined, false);
        }
    }, [calculationFrequency, calculationCondition, formApi]);
    const handleFormSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isValid) {
            setShowBanner(true);
            throw new Error('Invalid data');
        }
        setPanelIsSaving(true);
        yield onSubmit(Object.assign(Object.assign({}, formData), values), true);
        setPanelIsSaving(false);
    });
    const [onLinkClick] = useLinkClicked();
    React.useEffect(() => {
        if (isValid)
            setShowBanner(false);
    }, [isValid]);
    return (React.createElement(Panel, { isOpen: isOpen, layerProps: { eventBubblingEnabled: true }, type: PanelType.medium, onRenderNavigation: () => null, hasCloseButton: false, styles: {
            scrollableContent: {
                overflow: 'hidden',
            },
            content: {
                padding: 'unset',
                height: '100%',
                overflow: 'hidden',
            },
        } },
        React.createElement("div", { ref: dirtinessScope, className: css({ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }) },
            formState && (React.createElement(ModalControlBar, { headerText: "Edit Field", submit: () => save().then(), onDismiss: onDismiss, cancel: onCancel, cancelDisabled: false, submitDisabled: !dirtyOverride && !isDirty })),
            React.createElement("div", { className: css({
                    flex: 1,
                    overflow: 'auto',
                }) },
                showBanner && (React.createElement(MessageBar, { text: "Cannot submit due to invalid form data", messageBarType: MessageBarType.error })),
                React.createElement(DataEntryForm, { onFormLoaded: onFormLoaded, onLinkClicked: onLinkClick, formConfig: formConfig, initialData: formData, onSubmit: handleFormSubmit, onChange: setCurrentData, onFormStateChange: setFormState }, (formRenderer) => {
                    return panelIsSaving ? (React.createElement("div", { style: { height: '80vh', display: 'flex' } },
                        React.createElement(Spinner, { label: "Saving...", style: { width: '100%' } }))) : (React.createElement("div", { style: { display: 'flex' } }, formRenderer));
                })))));
};
export default FieldConfigurationCrudEditorPanel;
