var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { HeaderContextProvider, useHeaderContext } from '@samc/react-ui-history';
import { Modal } from '@fluentui/react';
import { useStyletron } from 'styletron-react';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import { ModalControlBar } from '../ModalControlBar/ModalControlBar';
import { ViewSetTabBar } from '../../../molecules/ViewSetTabBar/ViewSetTabBar';
const NO_TABS = [];
/**
 * The contents of a ViewSet modal, does not provide the HeaderContext
 */
export const ViewSetModalContents = (props) => {
    const { ServiceDrivenViewSet } = props, forwardedProps = __rest(props, ["ServiceDrivenViewSet"]);
    const { tabs: _tabs, setValue, selectedTab } = useHeaderContext();
    const tabs = _tabs !== null && _tabs !== void 0 ? _tabs : NO_TABS;
    const [css] = useStyletron();
    const dirtinessScope = React.useRef(null);
    const tabHeaders = React.useMemo(() => tabs.map((t) => t.header), [tabs]);
    return (React.createElement("div", { ref: dirtinessScope, style: { display: 'flex', flex: '1 1 auto', height: 'calc(85vh - 41px)', flexDirection: 'column' } },
        tabHeaders && selectedTab && tabHeaders.length > 1 && (React.createElement(ViewSetTabBar, { dirtinessScope: dirtinessScope, tabHeaders: tabHeaders, selectedTab: selectedTab.index, setSelectedTab: (v) => {
                const newTab = tabs[v];
                if (!newTab)
                    return;
                setValue('selectedTabId', newTab.id);
            }, className: css({ marginTop: '10px', flexShrink: 0 }) })),
        React.createElement(ServiceDrivenViewSet, Object.assign({ submitAllAttributes: true }, forwardedProps))));
};
/**
 * The contents of a ViewSet modal plus a header, does not provide the HeaderContext
 */
function InnerViewSetModal(props) {
    const { onDismiss: onDismissProp, headerText, onSaved } = props;
    const { controlBarProps } = useHeaderContext();
    const { submitDisabled } = controlBarProps !== null && controlBarProps !== void 0 ? controlBarProps : {};
    const dirtinessScope = React.useRef(null);
    const wasSaved = React.useRef(false);
    const { save, promptAndContinue, isDirty } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    /**
     * Prompts if necessary
     */
    const onCancel = React.useCallback(() => promptAndContinue(() => onDismissProp(wasSaved.current)), [onDismissProp, promptAndContinue]);
    /**
     * Dismisses, does not prompt
     */
    const onDismiss = React.useCallback(() => onDismissProp(wasSaved.current), [onDismissProp]);
    const afterDelete = React.useCallback(() => {
        wasSaved.current = true; // forces a reset
        onSaved === null || onSaved === void 0 ? void 0 : onSaved(); // inform the parent that a record has changed
        onDismiss();
    }, [onDismiss, onSaved]);
    const internalOnSave = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        yield save();
        wasSaved.current = true;
        onSaved === null || onSaved === void 0 ? void 0 : onSaved();
    }), [save, onSaved]);
    return (React.createElement(Modal, { forceFocusInsideTrap: false, layerProps: { eventBubblingEnabled: true }, popupProps: { ref: dirtinessScope }, isOpen: true, styles: { main: { height: '85vh', width: '85vw', overflowY: 'hidden' } } },
        React.createElement(ModalControlBar, Object.assign({}, controlBarProps, { headerText: headerText, onDismiss: onDismiss, cancel: onCancel, submit: internalOnSave, submitDisabled: submitDisabled || !isDirty })),
        React.createElement(ViewSetModalContents, Object.assign({}, props, { afterDelete: afterDelete }))));
}
/**
 * Luanches a full ViewSet within a Modal
 * @param props
 * @returns
 */
export const ViewSetModal = Object.assign((props) => {
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(InnerViewSetModal, Object.assign({}, props))));
}, { Contents: ViewSetModalContents });
export default ViewSetModal;
