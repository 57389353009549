import React from 'react';
import { ErrorMessageType } from '@samc/react-ui-core';
import { Icon } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
export const ErrorTypeValueGetter = (params) => {
    const { data } = params;
    switch (data.type) {
        case ErrorMessageType.Error:
            return 'Error';
        case ErrorMessageType.Info:
            return 'Info';
        default:
            return 'Warning Only';
    }
};
export const ErrorTypeCellRenderer = (props) => {
    const { data } = props;
    const theme = useThemeContext();
    const errorCodeParams = {
        [ErrorMessageType.Error]: {
            iconName: 'Error',
            hexCode: theme.TextColors.error.toString(),
            message: 'Error',
        },
        [ErrorMessageType.Warning]: {
            iconName: 'Warning',
            hexCode: theme.TextColors.warning.toString(),
            message: 'Warning Only',
        },
        [ErrorMessageType.Info]: {
            iconName: 'Info',
            hexCode: theme.TextColors.primary.toString(),
            message: 'Info',
        },
    };
    const ec = errorCodeParams[data.type] || errorCodeParams[ErrorMessageType.Error];
    return (React.createElement(React.Fragment, null,
        React.createElement(Icon, { iconName: ec.iconName, style: { fontSize: 10, color: ec.hexCode } }),
        React.createElement("span", { style: { color: ec.hexCode, padding: '2%' } }, ec.message)));
};
/**
 * Generates reassembleable data for a request. Recursive.
 *
 * NOTE: this function has side effects on outReq. Be aware.
 * @param outReq the request to be mutated when the callback is called
 */
export const generateReassembleableRequest = (outReqs) => {
    const output = {};
    outReqs.forEach((outReq) => {
        const copiedPayload = Object.assign({}, outReq.payload);
        const { domainId } = outReq;
        const actualPayload = outReq.payload;
        Object.keys(copiedPayload).forEach((id) => {
            const copiedRecord = Object.assign({}, copiedPayload[id]);
            const actualRecord = actualPayload[id];
            const { __CHILD_REQUESTS__: actualChildRequests } = actualRecord;
            if (actualChildRequests) {
                const childrenOutput = generateReassembleableRequest(actualChildRequests);
                Object.keys(childrenOutput).forEach((childDomain) => {
                    output[childDomain] = childrenOutput[childDomain];
                });
            }
            delete copiedRecord.__CHILD_REQUESTS__;
            if (!output[domainId])
                output[domainId] = {};
            output[domainId][id] = Object.assign(Object.assign({}, copiedRecord), { reassemble: (d) => {
                    // eslint-disable-next-line no-param-reassign
                    actualPayload[id] = Object.assign(Object.assign({}, actualPayload[id]), d);
                    return outReqs;
                } });
        });
    });
    return output;
};
