import { HeaderContextProvider } from '@samc/react-ui-history';
import React from 'react';
import { ConfirmationModalWrapper, } from '../../../molecules/ConfirmationModalWrapper/ConfirmationModalWrapper';
export const ConfirmationViewSetModal = (props) => {
    const { isOpen, headerText, onDismiss, dismissOnSave, ViewSetModal } = props;
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(ConfirmationModalWrapper, { isOpen: isOpen, onSaved: dismissOnSave ? onDismiss : undefined, headerText: headerText, onDismiss: onDismiss },
            React.createElement(ViewSetModal.Contents, Object.assign({}, props)))));
};
export default ConfirmationViewSetModal;
