import { FunctionList, ValidateExpression } from '@samc/expressions-core';
/**
 * Ensures an expression can be run on a domain.
 * @param expression the expression to validate
 * @param domain the domain on which the expression will be run
 * @returns if that expression is valid on that domain
 */
export const expressionIsValidForDomain = (expression, domain) => {
    const { errors } = ValidateExpression(expression, FunctionList, domain.fields.map((x) => x.fieldName));
    return errors.length === 0;
};
/**
 * Returns subset of given expressions which are valid for the provided domain
 * @param expressions expressions to test for domain validity
 * @param domain the domain against which expressions will be evaluated
 * @returns array of expressions valid for the domain
 */
export const getValidExpressions = (expressions, domain) => expressions === undefined || domain === undefined
    ? []
    : expressions.filter((exp) => expressionIsValidForDomain(exp, domain));
/**
 * Returns an expression string with expressions valid for the given domain, joined by " AND "
 * @param expressions expressions to test for validity and combine
 * @param domain the domain against which expressions will be evaluated
 * @returns unified expression containing valid expressions
 */
export const getUnifiedValidExpression = (expressions, domain) => {
    const validExpressions = getValidExpressions(expressions, domain);
    return validExpressions.length > 0 ? validExpressions.join(' AND ') : undefined;
};
