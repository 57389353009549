var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Icon, Modal, Spinner, SpinnerSize } from '@fluentui/react';
import { Button, ClassNameBuilder, useDirtinessPrompt } from '@samc/react-ui-core';
import { useThemeContext } from '@samc/react-ui-theme';
import React from 'react';
import { useStyletron } from 'styletron-react';
/**
 * A wrapped designed to wrap a Validation View or ViewSet.
 * Does NOT provide the HeaderContext
 */
export const ConfirmationModalWrapper = (props) => {
    const { isOpen, headerText, onDismiss, children, onSaved, bodyClass } = props;
    const [css] = useStyletron();
    const theme = useThemeContext();
    const [isSaving, setIsSaving] = React.useState(false);
    const dirtinessScope = React.useRef(null);
    const { save, isDirty } = useDirtinessPrompt({ scope: dirtinessScope });
    const handleSave = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsSaving(true);
        try {
            yield save();
            if (onSaved)
                onSaved();
        }
        finally {
            setIsSaving(false);
        }
    }), [onSaved, save]);
    return (React.createElement(Modal, { forceFocusInsideTrap: false, layerProps: { eventBubblingEnabled: true }, isOpen: isOpen, onDismiss: onDismiss, isBlocking: false },
        React.createElement("div", { ref: dirtinessScope, className: ClassNameBuilder(bodyClass, css({
                boxSizing: 'border-box',
                padding: '1rem 1rem 0 1rem',
                borderBottom: `0.5rem solid ${theme.TextColors.error.toString()}`,
                maxHeight: '80vh',
                maxWidth: '56vw',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            })) },
            React.createElement("div", { className: css({
                    lineHeight: '1.5rem',
                }) },
                React.createElement(Icon, { className: css({
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: theme.TextColors.error.toString(),
                    }), iconName: "Error" }),
                React.createElement("span", { className: css({
                        fontWeight: 'bold',
                        fontSize: '1.5em',
                        verticalAlign: 'top',
                        marginLeft: '1em',
                    }) }, headerText !== null && headerText !== void 0 ? headerText : 'Confirmation Required')),
            React.createElement("div", { className: css({ flex: '1', overflow: 'auto' }) }, children),
            React.createElement("div", { className: css({
                    margin: '1.5rem 0 1rem 0',
                    display: 'flex',
                    gap: '3px',
                    alignItems: 'center',
                }) },
                React.createElement(Button, { className: css({
                        marginLeft: 'auto',
                    }), buttonType: "secondary", disabled: isSaving, text: "Cancel", onClick: onDismiss }),
                React.createElement(Button, { buttonType: "secondary", text: "Continue", onClick: handleSave, disabled: !isDirty || isSaving }),
                isSaving && React.createElement(Spinner, { size: SpinnerSize.medium })))));
};
