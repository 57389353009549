var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/display-name */
import React from 'react';
import { SelectEntryField as CoreSelectEntryField } from '@samc/react-ui-form';
import QueryExecuteManager from '../QueryExecuteManager/QueryExecuteManager';
export const SelectEntryField = (props) => {
    const { displayProperty, valueProperty, domainId, viewId, onChange, defaultSelectedKey, adhocListViewMembers } = props;
    return (React.createElement(QueryExecuteManager, { domainId: domainId, listViewId: viewId, adhocListViewMembers: adhocListViewMembers }, (queryRenderProps) => (React.createElement(CoreSelectEntryField, { onFetchMenuItems: () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield queryRenderProps.doFetch(false, { start: 0, stop: 100 });
            return result[0].Data || [];
        }), displayProperty: displayProperty, valueProperty: valueProperty, onChange: (menuItem) => {
            if (onChange)
                onChange(menuItem);
        }, defaultSelectedKey: defaultSelectedKey }))));
};
export const GetSelectEntryField = (domainId, viewId) => {
    return (props) => {
        const { displayProperty, valueProperty, value, onChange } = props;
        return (React.createElement(SelectEntryField, { domainId: domainId, viewId: viewId, displayProperty: displayProperty, valueProperty: valueProperty, onChange: (menuItem) => {
                onChange(menuItem === null || menuItem === void 0 ? void 0 : menuItem.id);
            }, defaultSelectedKey: value }));
    };
};
export const GetCoreSelectEntryField = () => {
    return (props) => {
        const { displayProperty, valueProperty, value, onChange, menuItems } = props;
        return (React.createElement(CoreSelectEntryField, { displayProperty: displayProperty, valueProperty: valueProperty, onChange: (menuItem) => {
                onChange(menuItem === null || menuItem === void 0 ? void 0 : menuItem.id);
            }, defaultSelectedKey: value, menuItems: menuItems }));
    };
};
export default SelectEntryField;
