import React from 'react';
import { useFieldConfiguration } from '@samc/screen-config-api';
import { usePreparedEvaluatorConfiguration, } from '../usePreparedEvaluatorConfiguration/usePreparedEvaluatorConfiguration';
export const usePreparedEvaluatorConfigurationByFieldConfiguration = (fieldConfigurationId) => {
    const { data } = useFieldConfiguration(fieldConfigurationId || '');
    const members = React.useMemo(() => {
        var _a;
        if (data) {
            return (_a = data.fieldConfigurationMembers) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => {
                const accumlated = Object.assign({}, prev);
                accumlated[curr.viewFieldName] = curr;
                return accumlated;
            }, {});
        }
        return {};
    }, [data]);
    return usePreparedEvaluatorConfiguration(members);
};
export default usePreparedEvaluatorConfigurationByFieldConfiguration;
