import React from 'react';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { useStyletron } from 'styletron-react';
import { ClassNameBuilder, PRINT, isNullOrUndefined } from '@samc/react-ui-core';
import { useFormView } from '@samc/screen-config-api';
import { usePreparedEvaluatorConfiguration } from '../../hooks/usePreparedEvaluatorConfiguration/usePreparedEvaluatorConfiguration';
export const InnerFormField = (props) => {
    const { primaryKey, formViewId, onFormStateChange, editable, parentRequestIdentifier, parentScope: scope, exportMode, submitAllAttributes, taskResponses, beforeReset, className, onModalSaved, FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const [css] = useStyletron();
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(FormScreenWrapper, { className: ClassNameBuilder('inner-form-field', className), formViewId: formViewId, primaryKeyValue: primaryKey, onFormStateChange: onFormStateChange, disabled: !editable, parentRequestIdentifier: parentRequestIdentifier, formWrapperClassName: css({ transform: 'translateX(-7px)' }), parentScope: scope, exportMode: exportMode, submitAllAttributes: submitAllAttributes, taskResponses: taskResponses, beforeReset: beforeReset, onModalSaved: onModalSaved, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper })));
};
export const SelectAndShowComponent = (props) => {
    var _a;
    const { selectAndShow, selectedId, onFormStateChange: onFormStateChangeProp, dropdownRenderer, parentRequestIdentifier, parentScope: scope, exportMode, submitAllAttributes, taskResponses, beforeReset: _beforeResetProp, onModalSaved, FormScreenWrapper, ServiceDrivenViewSet, GridScreenWrapper, } = props;
    const { subLayoutForm: formViewId, editable } = selectAndShow;
    const [state, _onFormStateChange] = React.useState({});
    const { data } = state;
    const formView = useFormView(formViewId);
    const evaluatorConfiguration = usePreparedEvaluatorConfiguration((_a = formView.data) === null || _a === void 0 ? void 0 : _a.formViewMembers);
    const onFormStateChange = React.useCallback((v) => {
        if (onFormStateChangeProp)
            onFormStateChangeProp(v);
        _onFormStateChange(v);
    }, [onFormStateChangeProp]);
    const beforeReset = React.useCallback((params) => _beforeResetProp === null || _beforeResetProp === void 0 ? void 0 : _beforeResetProp(Object.assign(Object.assign({}, params), { selectAndShow })), [_beforeResetProp, selectAndShow]);
    const formData = React.useMemo(() => data !== null && data !== void 0 ? data : {}, [data]);
    const [css] = useStyletron();
    const dropdownClass = css({
        paddingBottom: '6px',
        [PRINT]: {
            fontWeight: 800,
            fontSize: '12px',
        },
    });
    if (isNullOrUndefined(formViewId))
        return React.createElement(React.Fragment, null, "No Form View Specified...");
    return (React.createElement(React.Fragment, null,
        dropdownRenderer(formData, dropdownClass, evaluatorConfiguration),
        !isNullOrUndefined(selectedId) && (React.createElement(InnerFormField, { FormScreenWrapper: FormScreenWrapper, exportMode: exportMode, editable: editable, parentScope: scope, primaryKey: String(selectedId), formViewId: formViewId, beforeReset: beforeReset, onFormStateChange: onFormStateChange, parentRequestIdentifier: parentRequestIdentifier, submitAllAttributes: submitAllAttributes, taskResponses: taskResponses, onModalSaved: onModalSaved, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }))));
};
export const renderSelectAndShow = (parentRequestIdentifier, selectedId, selectAndShow, onFormStateChange, dropdownRenderer, exportMode, options) => {
    const { submitAllAttributes, FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } = options !== null && options !== void 0 ? options : {};
    return (React.createElement(SelectAndShowComponent, { selectAndShow: selectAndShow, selectedId: selectedId, onFormStateChange: onFormStateChange, dropdownRenderer: dropdownRenderer, parentRequestIdentifier: parentRequestIdentifier, exportMode: exportMode, submitAllAttributes: submitAllAttributes, FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }));
};
