/* eslint-disable no-case-declarations */
import React from 'react';
import { ApiContext } from '@samc/screen-config-api';
import { useHistoryContext } from '@samc/react-ui-history';
import { handleFileDownload } from '@samc/screen-config-docs';
import { useEvaluator } from '@samc/expressions-react';
import { useContextToken } from '../useContextToken/useContextToken';
import { linkType } from '../../enums/enumsTypes';
import { DocCountLinkCellRendererFramework, DocCountLinkValueGetter, } from '../../molecules/cell-renderers/DocCountLinkCellRenderer/DocCountLinkCellRenderer';
import { mapListViewMembersToGridFields } from '../../organisms/GridScreen/GridScreen.utilities';
export const getFormViewLink = (link, promptAndContinue, setCurrentLink, primaryKeyField) => (params) => {
    var _a;
    const { node } = params !== null && params !== void 0 ? params : {};
    const { data } = node !== null && node !== void 0 ? node : {};
    if (!setCurrentLink)
        return undefined;
    // Current use case is that only one LinkCriteria would be provided specifying foreign key for the target form
    // TODO This may need more buildout as use cases develop
    const linkCriteria = ((_a = link.linkCriteria) === null || _a === void 0 ? void 0 : _a.length) > 0 ? link.linkCriteria[0] : undefined;
    const targetPrimaryKeyField = linkCriteria && data && Object.keys(data).includes(linkCriteria.sourceFieldName)
        ? linkCriteria.sourceFieldName
        : primaryKeyField;
    const execute = () => setCurrentLink(Object.assign(Object.assign({}, link), { primaryKeyValue: targetPrimaryKeyField && data ? data[targetPrimaryKeyField] : '', data }));
    if (!promptAndContinue)
        return execute();
    return promptAndContinue(execute);
};
export const getHrefLink = (link, history, promptAndContinue, evaluate) => (params) => {
    const execute = () => {
        const href = evaluate(params.node.data, link.targetId, true);
        history.push(href);
    };
    if (promptAndContinue)
        promptAndContinue(execute);
    else
        execute();
};
export const getHandleFileDownload = (token, strataTenantId, strataApi) => (params) => {
    handleFileDownload([params.node.data], token, strataTenantId, strataApi);
};
export const useGridCellLinks = (links, primaryKey, listViewMembers, setCurrentEditLink, 
// promptAndContinue checks for unsaved changes in the grid and prompts the user to stop or continue if there are any unsaved changes.
// If the user continues (or there are no unsaved changes in the first place), it continues executing the function that it was passed.
// it is a function that retunrs void and takes *a different function that returns void* as an argument.
promptAndContinue, domainFields, wrapText) => {
    const history = useHistoryContext();
    const { token } = useContextToken();
    const { evaluate } = useEvaluator();
    const api = React.useContext(ApiContext);
    return React.useMemo(() => {
        const tempFields = mapListViewMembersToGridFields(listViewMembers, domainFields, wrapText);
        links === null || links === void 0 ? void 0 : links
        // if isDefault is true, then the link should show up in the actual cell in the table (rather than in the context menu?).
        .filter((l) => l.isDefault === true).forEach((link) => {
            const field = tempFields.find((f) => f.field === link.fieldName);
            if (field) {
                switch (link.targetType) {
                    case linkType.Edit:
                        field.edit = getFormViewLink(link, promptAndContinue, setCurrentEditLink, primaryKey);
                        field.sortable = false;
                        field.suppressHeaderMenuButton = true;
                        break;
                    case linkType.FormView:
                    case linkType.Modal:
                    case linkType.Screen:
                    case linkType.ViewSetModal:
                        field.link = getFormViewLink(link, promptAndContinue, setCurrentEditLink, primaryKey);
                        break;
                    case linkType.DocCountLink:
                        const onClickDocCount = getFormViewLink(link, promptAndContinue, setCurrentEditLink, primaryKey);
                        field.cellRenderer = DocCountLinkCellRendererFramework(onClickDocCount);
                        field.valueGetter = DocCountLinkValueGetter;
                        break;
                    case linkType.Href:
                        field.link = getHrefLink(link, history.browserHistory, promptAndContinue, evaluate);
                        break;
                    case linkType.HrefDocs:
                        field.link = getHandleFileDownload(token, (api === null || api === void 0 ? void 0 : api.tenantName) || '', (api === null || api === void 0 ? void 0 : api.StrataApi) || '');
                        break;
                    default:
                }
            }
        });
        return tempFields;
    }, [
        api === null || api === void 0 ? void 0 : api.StrataApi,
        domainFields,
        evaluate,
        history.browserHistory,
        links,
        listViewMembers,
        primaryKey,
        promptAndContinue,
        setCurrentEditLink,
        api === null || api === void 0 ? void 0 : api.tenantName,
        token,
        wrapText,
    ]);
};
export default useGridCellLinks;
