var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { getFieldConfiguration } from '@samc/screen-config-api';
import { toastWarning, toastError, ToastMessage } from '@samc/react-ui-core';
const getAddDocumentLinksPayload = (selectedDocumentLinks, currentEntityId) => {
    let documentLinksPayload = {};
    selectedDocumentLinks.forEach((row, index) => {
        const idx = `-${index + 1}`;
        documentLinksPayload = Object.assign(Object.assign({}, documentLinksPayload), { [idx]: {
                Id: idx,
                DocumentId: row.DocumentId,
                EntityId: currentEntityId,
                __CHILD_REQUESTS__: [],
                __ADDED__: true,
            } });
    });
    return {
        domainId: 'DocumentLinks',
        fieldConfigurationId: 'DocumentLinks',
        viewId: 'AddDocumentLinks',
        requestIdentifier: 'AddDocumentLinks Grid',
        payload: documentLinksPayload,
    };
};
const clearStateAndDismiss = (clearDirtyState, onSaved, onDismiss) => {
    clearDirtyState();
    if (onDismiss)
        onDismiss();
    if (onSaved)
        onSaved();
};
export const getAssociatedEntityPicklistConfig = (setPicklistConfig, serviceName, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    getFieldConfiguration(serviceName, 'DocumentLinks', requestInit).then((data) => {
        if (!data) {
            toastError(React.createElement(ToastMessage, { title: "Not Found", message: "Field Configuration for DocumentLinks was not found" }));
            return;
        }
        const associatedEntityConfig = data.fieldConfigurationMembers.filter((member) => member.viewFieldName === 'EntityId') || [];
        const picklistConfig = associatedEntityConfig[0].picklistField;
        setPicklistConfig(picklistConfig);
    });
});
export const validateAndSetFundEntities = (fundId, setEntity) => {
    if (!fundId) {
        toastWarning(React.createElement(ToastMessage, { title: "No entitites found", message: "No other entities were found to link documents" }));
    }
    else {
        setEntity(fundId);
    }
};
export const getDocLinkEntityFilters = (filters, entityTypeId) => {
    const tmpFilters = [];
    if (window.location.hash.toLocaleLowerCase().indexOf('debtequityanalysis') !== -1 &&
        Array.isArray(filters) &&
        Array.length) {
        const baselineFilter = filters.filter((blFilter) => blFilter.toLowerCase().indexOf('categoryids') !== -1);
        if (baselineFilter)
            tmpFilters.push(baselineFilter[0].replace('EntityTypeIds', 'DocumentType_EntityTypeIds'));
    }
    else {
        tmpFilters.push(`DoesContain('${entityTypeId}', [DocumentType_EntityTypeIds])`);
    }
    return tmpFilters;
};
export default {
    getAddDocumentLinksPayload,
    clearStateAndDismiss,
    getAssociatedEntityPicklistConfig,
    getDocLinkEntityFilters,
};
