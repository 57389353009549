import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getParentFormViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
const PARENT_FORMVIEWS_KEY = `${SCREEN_CONFIG_API_KEY}parent_formviews_key`;
export const useParentFormViews = (domainId) => {
    const api = React.useContext(ApiContext);
    const getter = (domId) => domId ? getParentFormViews(api.AggregatorApi, domId, api.requestInit) : Promise.resolve([]);
    return useRequest(PARENT_FORMVIEWS_KEY, getter, ApiContext, domainId);
};
export default useParentFormViews;
