import React from 'react';
import { useRequestGetter } from '@samc/react-ui-request';
import { executeQuery } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const QUERY_KEY = `${SCREEN_CONFIG_API_KEY}query`;
/**
 * Hook-style function for accessing the Query Builder execute function.
 *
 * TODO: Implement with the useQuery hook to take advantage of caching
 * abilities. useQuery needs to accept more complex params than just strings.
 */
export const useQBQuery = () => {
    const api = React.useContext(ApiContext);
    const query = executeQuery(api.LiftSiftApi, api.requestInit, undefined);
    const getter = useRequestGetter({
        key: QUERY_KEY,
        query,
        onError: (error) => api.ShowMessage(error.message, 'error'),
    });
    return React.useCallback((params, includelimitedAccessRecords) => getter(false, params, includelimitedAccessRecords), [getter]);
};
