import React from 'react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { FormViewEditor as BaseFormViewEditor } from './FormViewEditor.ContextMenu';
/**
 * Component to add/edit a FormView.
 * Displays a list of FormViews and launches the FormBuilderWorkspace in a modal.
 * @param props The component Props.
 */
export const FormViewEditor = ({ childGrids, domainFields, domainId, saveFormToTab }) => {
    const [{ initialForm: initialEditingForm, currentForm: currentEditingForm, formViewId: currentEditingFormId, moveToFolderId, }, setEditingFormSkeleton,] = React.useState({});
    if (!domainId)
        return React.createElement(MessageBar, { text: "No domain id provided", messageBarType: MessageBarType.error });
    return (React.createElement(BaseFormViewEditor, { childGrids: childGrids, currentEditingForm: currentEditingForm, currentEditingFormId: currentEditingFormId, domainFields: domainFields, domainId: domainId, initialEditingForm: initialEditingForm, moveToFolderId: moveToFolderId, saveFormToTab: saveFormToTab, setEditingFormSkeleton: setEditingFormSkeleton }));
};
export default FormViewEditor;
