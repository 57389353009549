import React from 'react';
import { useEvaluator } from '@samc/expressions-react';
import useCustomColumns from '@samc/react-ui-grid/lib/hooks/useCustomColumns/useCustomColumns';
import { getFormViewLink } from '../../hooks/useGridCellLinks/useGridCellLinks';
export const useContextMenuLinks = (links, domain, setLink, 
// promptAndContinue checks for unsaved changes in the grid and prompts the user to stop or continue if there are any unsaved changes.
// If the user continues (or there are no unsaved changes in the first place), it continues executing the function that it was passed.
// it is a function that retunrs void and takes *a different function that returns void* as an argument.
promptAndContinue) => {
    const { evaluate } = useEvaluator();
    const domainName = domain.data && domain.data.domainName;
    const customMenuList = useCustomColumns(domainName);
    const getContextMenuItems = React.useCallback((params) => {
        const finalList = [];
        if (links.data && params.node && params.node.data && domain && domain.data) {
            const { primaryKey } = domain.data;
            links.data
                .filter((l) => l.isDefault !== true)
                .map((l) => {
                var _a;
                return finalList.push({
                    name: String(evaluate((_a = params.node) === null || _a === void 0 ? void 0 : _a.data, l.displayNameExpression, true)),
                    // subMenuName: l.folder,
                    // href: l.targetType === 'href' ? l.targetId : '',
                    action: l.targetType === 'FormView' || l.targetType === 'screen'
                        ? () => getFormViewLink(l, promptAndContinue, setLink, primaryKey)(params)
                        : // eslint-disable-next-line @typescript-eslint/no-empty-function
                            () => { },
                });
            });
        }
        if (customMenuList) {
            // eslint-disable-next-line array-callback-return
            customMenuList.map((l) => {
                if (l.getContextMenuItem) {
                    const customContextMenuList = l.getContextMenuItem(params);
                    customContextMenuList.map((item) => finalList.push(item));
                }
            });
        }
        return finalList;
    }, [links.data, domain, customMenuList, evaluate, promptAndContinue, setLink]);
    return getContextMenuItems;
};
export default useContextMenuLinks;
