var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useQBQuery } from '@samc/screen-config-api';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
/**
 * Hook for converting AutoCompleteActions into FormAutoFillHandler AutoFillActions
 * that will use Lift and Sift queries to dynamically calculate the autofill results
 * @param autoCompleteActions
 */
export const useLSAutoFillActions = (autoCompleteActions) => {
    const executeQuery = useQBQuery();
    const { evaluate } = useEvaluator();
    return React.useMemo(() => autoCompleteActions.map((action) => {
        const { filterMap, isActiveExpression, resultMap, targetDomainId, triggerFields, id } = action;
        return {
            key: id !== null && id !== void 0 ? id : targetDomainId + triggerFields.join('_'),
            triggerFields,
            onGetTargetFieldValues: (fieldValues) => __awaiter(void 0, void 0, void 0, function* () {
                const triggerFieldsHaveValue = triggerFields.every((field) => !isNullOrUndefined(fieldValues[field]));
                const shouldAbortAutoFill = isActiveExpression && evaluate(fieldValues, isActiveExpression, true) === false;
                if (shouldAbortAutoFill || !triggerFieldsHaveValue)
                    return {};
                const queryResult = yield executeQuery({
                    domainId: targetDomainId,
                    sorting: {},
                    paging: { start: 0, stop: 10 },
                    adhocListViewMembers: resultMap.map((item) => ({
                        scalarExpression: item.targetExpression,
                        scalarDisplayName: item.sourceField,
                    })),
                    adhocFilter: {
                        advancedExpression: filterMap
                            .map((item) => `${item.targetExpression}='${evaluate(fieldValues, item.sourceExpression, true)}'`)
                            .join(' AND '),
                        advancedInd: true,
                        expressionLang: 'Centric',
                    },
                });
                const uniqueResultRecordCount = queryResult.Data
                    // Stringify the result records for easy comparison since indexOf doesn't play well with raw objects
                    .map((item) => JSON.stringify(item, (itemField, itemFieldValue) => 
                // Fields tacked on by L&S can be ignored
                ['OrderBy', 'primaryKey'].some((field) => field === itemField)
                    ? undefined
                    : itemFieldValue))
                    .filter((value, index, self) => self.indexOf(value) === index).length;
                return resultMap.reduce((prev, resultMapItem) => {
                    return Object.assign(Object.assign({}, prev), { [resultMapItem.sourceField]: uniqueResultRecordCount === 1
                            ? queryResult.Data[0][resultMapItem.sourceField]
                            : // TODO We may eventually want to use some other value as the default here when L&S doesn't return a single record
                                '' });
                }, {});
            }),
        };
    }), [autoCompleteActions, evaluate, executeQuery]);
};
export default useLSAutoFillActions;
