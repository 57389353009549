/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useQueryClient } from 'react-query';
import { LISTVIEWS_BY_DOMAIN_KEY } from '@samc/screen-config-api/lib/useListViewsByDomain/useListViewsByDomain';
import { ViewTypeContextProvider, getItemsFromLiftSiftFolder, ViewDrawerV2, Boxes, ViewType, } from '@samc/vmsnext-querybuilder';
import { ListViewEditorModal } from '../../modals/ListViewEditorModal/ListViewEditorModal';
import { useQueryContext } from '../../../organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
const { ViewBox } = Boxes;
export const ListViewControlBarItem = ({ enableDrawer, promptAndContinue, selectedViewId, setSelectedViewId, refreshView, }) => {
    const [drawerOpen, _setDrawerOpen] = React.useState(false);
    const [{ defaultParentFolderId, editorPk, editorOpen }, setEditorState] = React.useState({
        editorOpen: false,
    });
    const queryClient = useQueryClient();
    const queryContext = useQueryContext();
    const viewContext = React.useContext(ViewTypeContextProvider);
    const { viewId, viewList } = viewContext;
    // Pre-fetch the view list
    React.useEffect(() => {
        viewContext.fetchViewList(true);
        // Only run this effect when the component first loads.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // if viewId (int) changes, tell the parent component about it
    React.useEffect(() => {
        if (viewId && setSelectedViewId) {
            const items = getItemsFromLiftSiftFolder(viewList ? [viewList] : undefined);
            const selectedView = items.filter((x) => x.id === viewId)[0];
            if (selectedView && selectedView.guid !== selectedViewId) {
                setSelectedViewId(selectedView.guid);
            }
        }
        // Only run this effect if the selected view has changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewId, viewList]);
    // if selectedViewId (string) changes, tell QueryContext about it
    React.useEffect(() => {
        if (selectedViewId) {
            const items = getItemsFromLiftSiftFolder(viewList ? [viewList] : undefined);
            const selectedView = items.filter((x) => x.guid === selectedViewId)[0];
            if (selectedView && selectedView.id !== viewId) {
                // Bypass the view context here so we can tell the queryContext not to refresh
                queryContext.mergeQueryProps({
                    viewId: selectedView.id,
                }, undefined, undefined, true);
            }
        }
        // Only run effect if the selectedViewId has changed OR once the viewList has loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedViewId, viewList]);
    const openDrawer = React.useCallback((open) => {
        if (open && promptAndContinue)
            promptAndContinue(() => _setDrawerOpen(open));
        else
            _setDrawerOpen(open);
    }, [promptAndContinue]);
    const onAddClicked = (_, parentFolderId, as) => {
        setEditorState({
            editorOpen: true,
            editorPk: undefined,
            afterSave: as,
            defaultParentFolderId: parentFolderId,
        });
    };
    const onEditClicked = (item, _, __, as) => {
        setEditorState({
            editorOpen: true,
            editorPk: item.id,
            afterSave: as,
            defaultParentFolderId: undefined,
        });
    };
    const onClose = () => {
        if (editorOpen && refreshView)
            refreshView();
        setEditorState((cur) => {
            var _a;
            (_a = cur.afterSave) === null || _a === void 0 ? void 0 : _a.call(cur);
            return { editorOpen: false };
        });
        _setDrawerOpen(false);
    };
    const onItemSaved = (_, _action) => {
        queryClient.invalidateQueries(LISTVIEWS_BY_DOMAIN_KEY);
        viewContext.fetchViewList(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ViewBox, { onClick: () => {
                if (enableDrawer)
                    openDrawer(true);
            } }),
        drawerOpen && (React.createElement(ViewDrawerV2, { onLinkEdit: onEditClicked, drawerOpen: drawerOpen, editorOpen: editorOpen, setDrawerOpen: openDrawer, onAddClicked: onAddClicked, suppressSelectionBox: true, isValidViewType: (v) => v === ViewType.List, folderViewerProps: {
                onItemSaved,
            } })),
        editorOpen && (React.createElement(ListViewEditorModal, { defaultFolderId: defaultParentFolderId, onClose: onClose, listViewId: editorPk }))));
};
