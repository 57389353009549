import React from 'react';
import { DataEntryForm } from '@samc/react-ui-form';
import { getFormConfiguration } from './Utils';
export const FormGridFieldConfigurationForm = (props) => {
    const { formDomainId, params } = props;
    const { initialGridField, onChange } = params;
    const configuration = React.useMemo(() => getFormConfiguration(formDomainId, initialGridField.domainId), [formDomainId, initialGridField.domainId]);
    return React.createElement(DataEntryForm, { formConfig: configuration, initialData: initialGridField, onChange: onChange });
};
export const getGridDataFormRenderer = (formDomainId) => 
// eslint-disable-next-line react/display-name
(params) => React.createElement(FormGridFieldConfigurationForm, { params: params, formDomainId: formDomainId });
