import React from 'react';
import { Modal, TextField } from '@fluentui/react';
import { Button, SearchableDropdown, SitusHeader, Checkbox } from '@samc/react-ui-core';
import { styled, useStyletron } from 'styletron-react';
export const Field = styled('div', () => ({
    display: 'flex',
    margin: '10px 0px',
    alignItems: 'center',
}));
export const Label = styled('p', () => ({
    fontWeight: 'bold',
    color: '#1e242a',
    fontSize: '12px',
    width: '40%',
}));
export function FileDownloadModal(props) {
    const { onDismiss, onFileDownload } = props;
    const [css] = useStyletron();
    const [fileNameSelected, setFileNameSelected] = React.useState([0]);
    const [zipFileName, setZipFileName] = React.useState('');
    const [isPrefixed, setPrefixed] = React.useState(true);
    const onSubmitDownload = React.useCallback(() => {
        onFileDownload(zipFileName, fileNameSelected[0], isPrefixed);
        onDismiss();
    }, [fileNameSelected, isPrefixed, onFileDownload, zipFileName, onDismiss]);
    const onFileNameChange = (option) => {
        setFileNameSelected([option.id]);
    };
    const onZipFileNameChange = (_event, newValue) => {
        setZipFileName(newValue || '');
    };
    return (React.createElement(Modal, { forceFocusInsideTrap: false, isOpen: true, layerProps: { eventBubblingEnabled: true }, styles: { main: { height: '20vh', width: '40vw', overflowY: 'hidden' } } },
        React.createElement(SitusHeader, { title: "Set Document Download Settings" },
            React.createElement(Button, { className: "download-cancel-button", buttonType: "secondary", text: "Cancel", onClick: onDismiss }),
            React.createElement(Button, { className: "download-button", buttonType: "primary", text: "Download", onClick: onSubmitDownload })),
        React.createElement("div", { className: css({ margin: '20px 40px' }) },
            React.createElement(Field, null,
                React.createElement(Label, null, "Download File Names Using"),
                React.createElement(SearchableDropdown, { selectedKeys: fileNameSelected, options: [
                        { id: 0, displayText: 'Document Name (System Calculated)' },
                        { id: 1, displayText: 'File Name (As Originally Uploaded)' },
                    ], onChange: onFileNameChange, className: css({ width: '60%' }) })),
            React.createElement(Field, null,
                React.createElement(Label, null, "Enter Zip File Name (Optional)"),
                React.createElement(TextField, { className: css({ width: '60%', borderRadius: '4px' }), value: zipFileName, onChange: onZipFileNameChange, placeholder: "File Name" })),
            React.createElement(Field, null,
                React.createElement(Checkbox, { checked: isPrefixed, onChange: (checked) => setPrefixed(checked) }),
                "Preface File Names with Document Entity Name (e.g. Asset Name, Valuation Name, etc.)"))));
}
export default FileDownloadModal;
