import React from 'react';
import { SearchBox, OverflowSet, Label, VerticalDivider, CommandButton, DetailsList, SelectionMode, DetailsListLayoutMode, DetailsRow, Link, } from '@fluentui/react';
import { useFormViews, useListViews, ViewType } from '@samc/screen-config-api';
export const renderDetailsRow = (props) => 
// eslint-disable-next-line react/jsx-props-no-spreading
props ? React.createElement(DetailsRow, Object.assign({}, props)) : null;
export const ViewPicker = React.memo((props) => {
    const { onClickAdd, onSelectScreen, onClickEdit } = props;
    const [searchText, setSearchText] = React.useState();
    const handleSearch = (_event, v) => setSearchText(v);
    const headerItems = [
        {
            key: 'viewLabel',
            render: React.createElement(Label, { style: { marginRight: 12, fontSize: 20 } }, "Views"),
        },
    ];
    if (onClickAdd) {
        headerItems.push({ key: 'divider', render: React.createElement(VerticalDivider, null) });
        headerItems.push({
            key: 'addButton',
            render: (React.createElement(CommandButton, { onClick: onClickAdd, iconProps: { iconName: 'Add' }, style: { marginRight: 6, marginLeft: 4, height: 30, marginTop: 8 } }, "Add")),
        });
    }
    const columns = [
        { key: 'title', name: 'Title', fieldName: 'title', minWidth: 100, isResizable: true },
        { key: 'editbutton', name: '', fieldName: 'editbutton', minWidth: 100, width: 100, isResizable: false },
    ];
    const renderItemColumn = (item, _index, column) => {
        if (!column)
            return null;
        const fieldContent = item[column.fieldName];
        if (onSelectScreen && column.key === 'title') {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return React.createElement(Link, { onClick: () => onSelectScreen(item) }, item.title);
        }
        if (onClickEdit && column.key === 'editbutton') {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return React.createElement(Link, { onClick: () => onClickEdit(item) }, "Edit");
        }
        return React.createElement("span", { "data-key": column.key }, fieldContent);
    };
    const formResult = useFormViews();
    const listResult = useListViews();
    const filteredItems = React.useMemo(() => {
        const newItems = [];
        if (formResult.data) {
            newItems.push(...formResult.data.map((f) => ({
                title: f.name,
                viewType: ViewType.Form,
                id: f.id,
                domainId: f.domainId,
                fieldConfigurationId: f.fieldConfigurationId,
            })));
        }
        if (listResult.data) {
            newItems.push(...listResult.data.map((l) => ({
                title: l.viewName,
                viewType: ViewType.Grid,
                id: l.id,
                domainId: l.domainId,
                fieldConfigurationId: l.fieldConfigurationId,
            })));
        }
        return newItems.filter((x) => !searchText || (x.title ? x.title : '').toLowerCase().indexOf((searchText || '').toLowerCase()) >= 0);
    }, [formResult.data, listResult.data, searchText]);
    return (React.createElement(React.Fragment, null,
        React.createElement(OverflowSet, { styles: {
                root: {
                    marginBottom: 20,
                    marginTop: 20,
                },
            }, onRenderOverflowButton: () => null, items: headerItems, onRenderItem: (item) => item.render }),
        React.createElement(SearchBox, { onChange: handleSearch }),
        React.createElement(DetailsList, { compact: true, items: filteredItems, columns: columns, setKey: "set", selectionMode: SelectionMode.none, layoutMode: DetailsListLayoutMode.fixedColumns, onRenderRow: renderDetailsRow, onRenderItemColumn: renderItemColumn })));
});
ViewPicker.displayName = 'ViewPicker';
export default ViewPicker;
