export var ViewType;
(function (ViewType) {
    ViewType[ViewType["Grid"] = 0] = "Grid";
    ViewType[ViewType["Form"] = 1] = "Form";
    ViewType[ViewType["ViewSet"] = 2] = "ViewSet";
    ViewType[ViewType["ViewDocument"] = 3] = "ViewDocument";
    ViewType[ViewType["ContactAssignmentGrid"] = 4] = "ContactAssignmentGrid";
    ViewType[ViewType["DocumentLinks"] = 5] = "DocumentLinks";
})(ViewType || (ViewType = {}));
export var ViewTypeName;
(function (ViewTypeName) {
    ViewTypeName["ViewDocument"] = "AddDocuments";
})(ViewTypeName || (ViewTypeName = {}));
export var ViewSetActionType;
(function (ViewSetActionType) {
    ViewSetActionType[ViewSetActionType["AddModal"] = 0] = "AddModal";
    ViewSetActionType[ViewSetActionType["Delete"] = 1] = "Delete";
    ViewSetActionType[ViewSetActionType["Link"] = 2] = "Link";
    ViewSetActionType[ViewSetActionType["ConfirmationModal"] = 3] = "ConfirmationModal";
})(ViewSetActionType || (ViewSetActionType = {}));
