var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { ErrorBoundary, isNullOrUndefined, MessageBar, MessageBarType, toastError, ToastMessage, toastSuccess, useDirtinessPrompt, useDirtinessSync, useRefWrapper, ClassNameBuilder, } from '@samc/react-ui-core';
import { DataEntryForm, ContextFormAutoFillHandler, useFormState, useFormApi, useAutoFillLoading, FormFieldLocationType, } from '@samc/react-ui-form';
import { TaskStatus, useDomain, useDomains, useFieldConfiguration, useLinks, ViewType, } from '@samc/screen-config-api';
import { useStyletron } from 'styletron-react';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { useEvaluator } from '@samc/expressions-react';
import { ProgressIndicator } from '../../atoms/ProgressIndicator/ProgressIndicator';
import { ViewModal } from '../modals/ViewModal/ViewModal';
import { useLSAutoFillActions } from '../../hooks/useLSAutoFillActions/useLSAutoFillActions';
import { useFormData } from './useFormData';
import { buildFormScreenRequests, getDomainNameMap, getFormRequestIdentifier, getFormUsingRequestIdentifier, getSubformRequestIdentifier, isSuccessfulResponse, processMessagesFromTaskResponses, } from './utilities';
import useFormLinks from './useFormLinks';
import { FormGridField } from './FormGridField';
import { useLinkClicked } from '../../hooks/useLinkClicked/useLinkClicked';
import { useFormGridFieldHelpers } from './useFormGridFieldHelpers';
import { useSelectAndShowFormConfiguration } from '../../hooks/useSelectAndShowFormConfiguration/useSelectAndShowFormConfiguration';
import { useFieldMatrixOverrider } from './useFieldMatrixOverrider';
import { ConfirmationViewModal } from '../modals/ConfirmationViewModal/ConfirmationViewModal';
import { useMissingFormViewDependencies } from '../../hooks/useMissingFormViewDependencies/useMissingFormViewDependencies';
import { TaskResponsePopup } from '../../molecules/TaskResponsePopup/TaskResponsePopup';
import { createSubmissionPromise } from '../GridScreen/GridScreen.utilities';
import { TabExportButton } from './TabExportButton/TabExportButton';
import { useFormSkeletonFromFormView } from '../../hooks/useFormSkeletonFromFormView/useFormSkeletonFromFormView';
import { mapFormViewMember } from '../../hooks/useFormSkeletonFromFormView/Utils';
import { MultiSelectConfirmationViewModal } from '../modals/MultiSelectConfirmationViewModal/MultiSelectConfirmationViewModal';
import { ModalType } from '../../enums/ModalType';
import { useSelectAndShowURLSearchParams } from '../../hooks/useSelectAndShowURLSearchParams/useSelectAndShowURLSearchParams';
const EmptyAutofill = []; // to avoid re-renders
const EmptyFormData = {};
const InnerFormScreen = (props) => {
    var _a;
    const { formView: _formViewProp, onSubmit, primaryKeyValue, className, formWrapperClassName, filters, defaultData, style, childrenStyle, children, showContent, onChange, disabled, onFormStateChange, sidePanelForm: sidePanelFormView, controlVisibilities, onSelectAndShowSelectionChange: _onSelectAndShowSelectionChange, parentRequestIdentifier, dataEntryFormProps, parentScope, fieldConfiguration, domain, links, initialTabIndexOrName, exportMode, submitAllAttributes, onTabChanged, beforeReset, onModalSaved: _onModalSavedProp, taskResponses: parentTaskResponses, selectAndShowOptionOverrides, selectAndShowInitialValue, GridScreenWrapper, FormScreenWrapper, ServiceDrivenViewSet, } = props;
    const dirtinessScope = React.useRef(null);
    const missingFormViewMembers = useMissingFormViewDependencies(_formViewProp, fieldConfiguration, sidePanelFormView);
    const fieldConfigurationMembers = React.useMemo(() => fieldConfiguration.fieldConfigurationMembers.reduce((all, fcm) => (Object.assign(Object.assign({}, all), { [fcm.viewFieldName]: fcm })), {}), [fieldConfiguration]);
    // splice in missing members at the top and we never have to worry about them again...
    const formView = React.useMemo(() => (Object.assign(Object.assign({}, _formViewProp), { formViewMembers: Object.assign(Object.assign({}, _formViewProp.formViewMembers), missingFormViewMembers.reduce((a, fvm) => (Object.assign(Object.assign({}, a), { [fvm.viewFieldName]: fvm })), {})) })), [_formViewProp, missingFormViewMembers]);
    const completeSubmission = React.useRef();
    const { domainId, id: formViewId, gridFields } = formView;
    /**
     * Scopes requests down by the form view id and parent scope
     */
    const scope = parentScope ? `${parentScope}_${formViewId}` : undefined;
    const rootRequestIdentifier = React.useMemo(() => parentRequestIdentifier
        ? getSubformRequestIdentifier(parentRequestIdentifier, domainId, formViewId)
        : getFormRequestIdentifier(), [domainId, formViewId, parentRequestIdentifier]);
    const [formApi, onFormLoaded] = useFormApi();
    const [formState, onFormStateChanged] = useFormState();
    const { isDirty: isRootFormDirty, data: rootFormData } = formState;
    const { evaluate } = useEvaluator();
    const { evaluate: evaluateAsync } = useEvaluator(undefined, true);
    const { promptAndContinue } = useDirtinessPrompt({ scope: dirtinessScope }); // synced at bottom
    const { formSkeleton: form } = useFormSkeletonFromFormView({
        fieldConfigurationMembers,
        filters,
        formView,
    });
    const { formSkeleton: sidePanelForm } = useFormSkeletonFromFormView({
        fieldConfigurationMembers,
        filters,
        formView: sidePanelFormView,
    });
    const [lastTaskResponses, setLastTaskResponses] = React.useState();
    const [taskResponsePopupIsVisible, setTaskResponsePopupIsVisible] = React.useState(false);
    const closeTaskResponsePopup = React.useCallback(() => {
        if (completeSubmission.current) {
            completeSubmission.current();
            completeSubmission.current = undefined;
        }
        setTaskResponsePopupIsVisible(false);
        // clear out task responses on close if the task was completed successfully.
        if (lastTaskResponses === null || lastTaskResponses === void 0 ? void 0 : lastTaskResponses.every((x) => x.statusCode === TaskStatus.Completed)) {
            setLastTaskResponses([]);
        }
    }, [lastTaskResponses]);
    /**
     * The last task response specifically with this form at the root
     */
    const lastTaskResponseForThisForm = React.useMemo(() => {
        const taskResponses = lastTaskResponses !== null && lastTaskResponses !== void 0 ? lastTaskResponses : parentTaskResponses;
        if (!taskResponses)
            return undefined;
        const [potential] = taskResponses;
        if (!potential)
            return undefined;
        const { requestIdentifier } = potential;
        if (requestIdentifier === rootRequestIdentifier)
            return potential;
        return undefined;
    }, [lastTaskResponses, parentTaskResponses, rootRequestIdentifier]);
    const [data, isFormDataLoading, refreshFormData, primaryKeyField, isFormDataError] = useFormData(formView, primaryKeyValue, filters, defaultData);
    const [fieldsWithPendingAutoFill, onAutoFillStarting, onAutoFillFinished] = useAutoFillLoading();
    const [currentEditLinkSelected, setCurrentEditLinkSelected] = React.useState(null);
    const { gridFieldApis, gridFieldRefs, gridFieldRequests, onDomainLoaded, onGridApiChanged, onGridRequestChange, onRequestIdentifierChanged, onUnmounted, } = useFormGridFieldHelpers();
    // a mapping between select and show IDs and task requests
    const [innerFormStates, setInnerFormStates] = React.useState({});
    const onSelectAndShowChange = React.useCallback((state) => {
        const { requestIdentifier: subformRequestIdentifier } = state;
        setInnerFormStates((v) => {
            const newVal = Object.assign(Object.assign({}, v), { [subformRequestIdentifier]: state });
            if (!state.isDirty)
                delete newVal[subformRequestIdentifier];
            return newVal;
        });
    }, []);
    /**
     * Resets form AND data. Returns request identifiers that were not matched
     */
    const resetForm = React.useCallback((requestIdentifiers, clearTaskResponses) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield (beforeReset === null || beforeReset === void 0 ? void 0 : beforeReset({ requestIdentifiers }));
            if (requestIdentifiers) {
                const unusedIdentifiers = new Set();
                yield Promise.all(requestIdentifiers.map((requestIdentifier) => __awaiter(void 0, void 0, void 0, function* () {
                    var _b;
                    const subForm = innerFormStates[requestIdentifier];
                    if (subForm) {
                        yield subForm.formApi.resetForm([requestIdentifier]); // only this form
                        return;
                    }
                    const subGrid = gridFieldRefs.current[requestIdentifier];
                    if (subGrid) {
                        yield ((_b = subGrid.current) === null || _b === void 0 ? void 0 : _b.reset());
                        return;
                    }
                    if (requestIdentifier === rootRequestIdentifier) {
                        refreshFormData(); // this will trigger a form reset when it returns
                        return;
                    }
                    unusedIdentifiers.add(requestIdentifier);
                })));
                let unmatchedIdentifiers = Array.from(unusedIdentifiers);
                yield Promise.all(Object.values(innerFormStates).map((innerFormState) => __awaiter(void 0, void 0, void 0, function* () {
                    if (unmatchedIdentifiers.length)
                        unmatchedIdentifiers = yield innerFormState.formApi.resetForm(unmatchedIdentifiers);
                })));
                return unmatchedIdentifiers;
            }
            yield Promise.all(Object.keys(innerFormStates).map((rqId) => innerFormStates[rqId].formApi.resetForm()));
            Object.keys(gridFieldRefs.current).forEach((requestIdentifier) => {
                var _a;
                const api = (_a = gridFieldRefs.current[requestIdentifier]) === null || _a === void 0 ? void 0 : _a.current;
                if (!api)
                    return;
                api.reset();
            });
            refreshFormData(); // this will trigger a form reset when it returns
            // clear task response if not disabled
            if (clearTaskResponses !== false) {
                setLastTaskResponses([]);
            }
            return [];
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Failed to reset form', e);
            return [];
        }
    }), [beforeReset, gridFieldRefs, innerFormStates, refreshFormData, rootRequestIdentifier]);
    /**
     * Resets form AND data
     */
    const resetFormRef = useRefWrapper(resetForm);
    const [getSelectAndShowURLSearchParam, setSelectAndShowURLSearchParam] = useSelectAndShowURLSearchParams();
    const onSelectAndShowSelectionChange = (newId, selectAndShow, option) => __awaiter(void 0, void 0, void 0, function* () {
        setSelectAndShowURLSearchParam(selectAndShow.id, String(newId));
        if (!_onSelectAndShowSelectionChange)
            return true;
        return _onSelectAndShowSelectionChange(newId, selectAndShow, option);
    });
    const selectAndShowConfiguration = useSelectAndShowFormConfiguration({
        parentRequestIdentifier: rootRequestIdentifier,
        domainId,
        primaryKey: primaryKeyValue,
        onChange: onSelectAndShowChange,
        onSelectionChange: onSelectAndShowSelectionChange,
        data,
        parentScope: scope,
        filters,
        exportMode,
        options: Object.assign({ submitAllAttributes, onModalSaved: () => resetForm(undefined, true).then() }, selectAndShowOptionOverrides),
        initialSelectedItemValue: selectAndShowInitialValue,
        taskResponses: lastTaskResponses,
        FormScreenWrapper,
        ServiceDrivenViewSet,
        GridScreenWrapper,
    });
    selectAndShowConfiguration.getInitialSelectedItemValue = (selectAndShow) => { var _a; return (_a = getSelectAndShowURLSearchParam(selectAndShow.id)) !== null && _a !== void 0 ? _a : undefined; };
    const currentApi = React.useMemo(() => (Object.assign({ resetForm: (requestIdentifiers) => resetFormRef.current(requestIdentifiers, true), getGridFieldApis: () => gridFieldApis.current, submitForm: () => undefined, locateFields: (fields) => Promise.resolve({ notFound: new Set(fields), locations: {} }), locateGrids: (grids) => Promise.resolve({ notFound: new Set(grids), locations: {} }), getAllFields: () => ({}) }, (formApi && {
        submitForm: formApi.submitForm,
        locateFields: formApi.locateFields,
        locateGrids: formApi.locateGrids,
        getAllFields: () => formApi.allFields,
    }))), [gridFieldApis, resetFormRef, formApi]);
    /**
     * Dirtiness as it pertains to the root form, inner forms, or grids
     */
    const globalIsDirty = React.useMemo(() => isRootFormDirty ||
        !!Object.keys(gridFieldRequests).length ||
        Object.keys(innerFormStates).some((k) => innerFormStates[k].isDirty), [isRootFormDirty, gridFieldRequests, innerFormStates]);
    const currentState = React.useMemo(() => (Object.assign(Object.assign({}, formState), { requestIdentifier: rootRequestIdentifier, rootFormState: formState, primaryKey: primaryKeyValue, primaryKeyField, isDirty: globalIsDirty, gridFieldRequests,
        formView,
        innerFormStates, formApi: currentApi, initialData: data, defaultData })), [
        currentApi,
        formState,
        formView,
        globalIsDirty,
        gridFieldRequests,
        innerFormStates,
        primaryKeyField,
        primaryKeyValue,
        rootRequestIdentifier,
        data,
        defaultData,
    ]);
    React.useEffect(() => {
        onFormStateChange === null || onFormStateChange === void 0 ? void 0 : onFormStateChange(currentState);
    }, [currentState, onFormStateChange]);
    /**
     * Submits a task request using form state and resets the form if successful
     */
    const submitTaskRequests = React.useCallback((rqs) => __awaiter(void 0, void 0, void 0, function* () {
        yield onSubmit(rqs, currentState).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            setLastTaskResponses(res); // a useEffect later will propogate updates
            setTaskResponsePopupIsVisible(true);
            const { isSuccessful, successfulRequests, messages } = isSuccessfulResponse(res);
            if (isSuccessful && messages.length === 0) {
                toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Saved successfully" }));
                yield resetFormRef.current(undefined, messages.length === 0); // reset all, only clear last tasks if no messages
            }
            else {
                // reset anything successful
                yield resetFormRef.current(successfulRequests);
                throw new Error('Save not successful');
            }
            if (messages.length > 0 && isSuccessful) {
                yield createSubmissionPromise(completeSubmission);
            }
        }));
    }), [currentState, onSubmit, resetFormRef]);
    const handleFinalSubmit = React.useCallback((overrideCodes) => __awaiter(void 0, void 0, void 0, function* () {
        const requests = buildFormScreenRequests(currentState, overrideCodes, {
            submitAllAttributes,
        });
        return submitTaskRequests(requests);
    }), [currentState, submitAllAttributes, submitTaskRequests]);
    /**
     * The function to pass to the header and any external entities calling save.
     * This will route around the form in the scenario in which the form fields have not been
     * touched (when using a grid or S&S).
     */
    const submitForm = React.useCallback(() => {
        // if the form isn't dirty (grids dirty) don't route submit through the form
        if (isRootFormDirty && formApi)
            return formApi.submitForm();
        return handleFinalSubmit();
    }, [formApi, isRootFormDirty, handleFinalSubmit]);
    const beforeChangeTab = React.useCallback(() => {
        return new Promise((res) => {
            promptAndContinue(() => res(true));
        });
    }, [promptAndContinue]);
    const onDismissViewModal = () => setCurrentEditLinkSelected(null);
    const _formData = useFormLinks(links, form, primaryKeyValue, setCurrentEditLinkSelected, promptAndContinue);
    const formData = React.useMemo(() => {
        if (!disabled)
            return _formData;
        const newFields = {};
        Object.keys(_formData.fields).forEach((field) => {
            newFields[field] = Object.assign(Object.assign({}, _formData.fields[field]), { editableRule: 'false' });
        });
        return Object.assign(Object.assign({}, _formData), { fields: newFields });
    }, [disabled, _formData]);
    const autoFillActions = useLSAutoFillActions(formView.autoCompleteActions || EmptyAutofill);
    React.useMemo(() => {
        if (primaryKeyValue && !isFormDataLoading && !data) {
            toastError(React.createElement(ToastMessage, { title: "Not Found", message: "The item could not be found" }));
        }
    }, [data, isFormDataLoading, primaryKeyValue]);
    const [css] = useStyletron();
    const renderGridField = React.useCallback(({ gridFieldIndex }) => {
        if (gridFieldIndex === undefined || gridFields === undefined || gridFields.length <= gridFieldIndex)
            return React.createElement(React.Fragment, null);
        if (domainId === undefined)
            return React.createElement("div", null, "Missing parent form domain ID.");
        const gridField = gridFields[gridFieldIndex];
        return (React.createElement(FormGridField, { parentScope: scope, parentRequestIdentifier: rootRequestIdentifier, index: gridFieldIndex, parentFormDomainId: domainId, gridField: gridField, formData: formState.data, onGridRequestChange: onGridRequestChange, filters: filters, defaultData: defaultData, formResponses: lastTaskResponses !== null && lastTaskResponses !== void 0 ? lastTaskResponses : parentTaskResponses, controlVisibilities: controlVisibilities, onGridApiChanged: onGridApiChanged, onDomainLoaded: onDomainLoaded, onUnmounted: onUnmounted, onRequestIdentifierChanged: onRequestIdentifierChanged, exportMode: exportMode, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }));
    }, [
        gridFields,
        domainId,
        scope,
        rootRequestIdentifier,
        formState.data,
        onGridRequestChange,
        filters,
        defaultData,
        lastTaskResponses,
        parentTaskResponses,
        controlVisibilities,
        onGridApiChanged,
        onDomainLoaded,
        onUnmounted,
        onRequestIdentifierChanged,
        exportMode,
    ]);
    const { setValue } = useHeaderContext();
    const [onLinkClicked, modalFormViewId, modalPk, closeFormViewModal, modalType, modalFilter, modalDefaultData, modalText, setModalPk, referencedFieldId,] = useLinkClicked((_a = formState === null || formState === void 0 ? void 0 : formState.data) !== null && _a !== void 0 ? _a : EmptyFormData, domainId, promptAndContinue);
    const modalFilters = React.useMemo(() => [...modalFilter, ...(filters || [])], [modalFilter, filters]);
    const { fieldMatrixFieldOverrider, fieldMatrixFrameworkComponents, isReady } = useFieldMatrixOverrider(formView, setCurrentEditLinkSelected, promptAndContinue, filters, domain, links);
    /**
     * Wrap handleFinalSubmit to keep params from getting into it
     */
    const _onSubmit = React.useCallback(() => handleFinalSubmit(), [handleFinalSubmit]);
    const domains = useDomains();
    const domainDict = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = domains.data) === null || _a === void 0 ? void 0 : _a.reduce((all, cur) => {
            return Object.assign(Object.assign({}, all), { [cur.id]: cur });
        }, {})) !== null && _b !== void 0 ? _b : {};
    }, [domains.data]);
    /**
     * An up-to-date set of requests/maps for the popup to handle.
     * Only populated when the prompt is visible.
     *
     * If we just store the last request and a prompt comes in in a request
     * with a successful addition, that addition may be resubmitted.
     */
    const { responsePopupRequests, responsePopupDomainMap } = React.useMemo(() => {
        if (!taskResponsePopupIsVisible)
            return { responsePopupRequests: undefined, responsePopupDomainMap: undefined };
        const reqs = buildFormScreenRequests(currentState);
        const map = getDomainNameMap(domainDict, reqs);
        return { responsePopupRequests: reqs, responsePopupDomainMap: map };
        // Intentionally only updating this when taskResponsePopupIsVisible changes. The other dependencies currentState and domainDict
        // are already up-to-date when this toggles, but then quickly get reset if the task was successful
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskResponsePopupIsVisible]);
    /**
     * A processed set of responses for the popup to handle.
     */
    const processedTaskResponses = React.useMemo(() => lastTaskResponses ? processMessagesFromTaskResponses(lastTaskResponses, gridFieldApis.current) : undefined, [gridFieldApis, lastTaskResponses]);
    const onChangeInner = React.useCallback((d) => {
        if (onChange)
            onChange(d);
    }, [onChange]);
    const initialTabIndex = React.useMemo(() => {
        if (isNullOrUndefined(initialTabIndexOrName))
            return undefined;
        if (typeof initialTabIndexOrName === 'number')
            return initialTabIndexOrName;
        return Math.max(0, formData.tabs.findIndex((t) => t.title.toLowerCase() === initialTabIndexOrName.toLowerCase()));
    }, [formData.tabs, initialTabIndexOrName]);
    /**
     * Gets the fields in in the error response from the server that do not render in the form
     */
    const [relatedFields, _setRelatedFields] = React.useState();
    React.useEffect(() => {
        if (!formApi || !lastTaskResponseForThisForm)
            return;
        const { messages } = lastTaskResponseForThisForm;
        if (!messages.length)
            return;
        formApi
            .getRenderedFields()
            .then((usedFields) => {
            const missingButRequiredFields = messages
                .filter((x) => { var _a; return ((_a = x.code) === null || _a === void 0 ? void 0 : _a.indexOf('require')) > -1; }) // only pull in required fields
                .flatMap((e) => e.fields)
                .filter((field) => !usedFields.has(field));
            return missingButRequiredFields.length > 0
                ? missingButRequiredFields.reduce((prev, current) => {
                    let target = formData.fields[current];
                    if (!target) {
                        // reach out to field configs to see if field exists
                        const fcm = fieldConfigurationMembers[current];
                        if (!fcm)
                            return prev;
                        target = mapFormViewMember(fcm);
                    }
                    // ensure the field is visible depending on the required rule
                    return Object.assign(Object.assign({}, prev), { [current]: Object.assign(Object.assign({}, target), { visibleRule: target.requiredFieldRule }) });
                }, {})
                : undefined;
        })
            .then(_setRelatedFields);
    }, [fieldConfigurationMembers, formApi, formData.fields, lastTaskResponseForThisForm]);
    const renderTab = (defaultBody, params) => {
        const { tabConfig, data: _fd } = params;
        if (!primaryKeyValue || !formViewId || exportMode)
            return React.createElement(React.Fragment, null, defaultBody);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css({ width: '100%', display: 'flex' }) },
                React.createElement(TabExportButton, { formTab: tabConfig, primaryKey: primaryKeyValue, formData: _fd, formViewId: formViewId, className: css({ marginLeft: 'auto' }) })),
            defaultBody));
    };
    /**
     * Gets the textual human-readable location of the given error message, used by the task response popup
     */
    const getMessageLocation = (_c) => __awaiter(void 0, [_c], void 0, function* ({ message, data: _d, }) {
        const { response, fields } = message;
        const { requestIdentifier: messageReqId } = response;
        const _res = getFormUsingRequestIdentifier(messageReqId, currentState);
        if (!_res)
            return 'Unknown';
        const { formScreenState: targetFormState, isGrid } = _res;
        const { formApi: targetFormApi } = targetFormState;
        let locations;
        if (isGrid) {
            const { gridFieldIndex } = isGrid;
            ({ locations } = yield targetFormApi.locateGrids([gridFieldIndex]));
        }
        else
            ({ locations } = yield targetFormApi.locateFields(fields));
        const allLocations = Object.values(locations);
        if (allLocations.length > 0) {
            return Promise.all(allLocations.map((l) => __awaiter(void 0, void 0, void 0, function* () {
                switch (l.type) {
                    case FormFieldLocationType.SidePanel:
                        return `Sidepanel: ${yield evaluateAsync(_d, l.section.titleExpression, true)}`;
                    case FormFieldLocationType.RelatedFields:
                        return 'Related Fields';
                    default:
                        return String(yield evaluateAsync(_d, l.section.titleExpression, true));
                }
            }))).then((res) => res.join(', '));
        }
        return 'Unknown';
    });
    /**
     * When a modal (or submodal) is saved, we should reset
     */
    const onModalSaved = React.useCallback(() => {
        resetFormRef.current();
        if (_onModalSavedProp)
            _onModalSavedProp();
    }, [_onModalSavedProp, resetFormRef]);
    // selects initial tab
    React.useEffect(() => {
        if (!onTabChanged || !isNullOrUndefined(initialTabIndex))
            return;
        const tab = formData.tabs.at(0);
        if (!tab)
            return;
        onTabChanged(tab.title, {
            tab,
            userRequested: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useDirtinessSync({
        blockChildren: true,
        isDirty: globalIsDirty,
        onReset: () => resetForm().then(),
        onSave: submitForm,
        scope: dirtinessScope,
        data: rootFormData, // this is as robust as we can get
    });
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { ref: dirtinessScope, className: ClassNameBuilder('form-screen', className, css({ overflowY: 'hidden' })) },
            isFormDataError && (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Failed to load form data." })),
            !isFormDataError && (isFormDataLoading || !isReady) && React.createElement(ProgressIndicator, { label: "Loading Form..." }),
            !isFormDataError && !isFormDataLoading && isReady && (React.createElement(React.Fragment, null,
                React.createElement(DataEntryForm, Object.assign({ renderTab: renderTab, initialTabIndex: initialTabIndex, fieldMatrixFrameworkComponents: fieldMatrixFrameworkComponents, fieldMatrixFieldOverrider: fieldMatrixFieldOverrider, initialData: data, formConfig: formData, onSubmit: _onSubmit, fieldsWithPendingAutoFill: fieldsWithPendingAutoFill, relatedFields: relatedFields, suppressPadding: !!parentRequestIdentifier, renderGridField: renderGridField, onFormStateChange: onFormStateChanged, onChange: onChangeInner, onLinkClicked: onLinkClicked, onTabChanged: onTabChanged, onFormLoaded: onFormLoaded, selectAndShowConfiguration: selectAndShowConfiguration, sidePanelForm: sidePanelForm, suppressTabBar: exportMode, beforeChangeTab: beforeChangeTab, suppressInitialCalculations: !!primaryKeyValue }, dataEntryFormProps), (formRenderer, _) => {
                    return (React.createElement("div", { className: formWrapperClassName, style: Object.assign({ display: 'flex', flex: '1', flexDirection: 'column', overflow: 'auto' }, style) },
                        children && (React.createElement("div", { style: Object.assign({ marginBottom: '10px' }, childrenStyle) }, children)),
                        showContent !== false && (React.createElement(ContextFormAutoFillHandler, { autoFillActions: autoFillActions, debounceMilliSeconds: 250, onAutoFillStarting: onAutoFillStarting, onAutoFillFinished: onAutoFillFinished })),
                        showContent !== false && formRenderer));
                }),
                currentEditLinkSelected && (React.createElement(ViewModal, { viewId: currentEditLinkSelected.targetId, viewType: ViewType.ViewSet, headerText: String(evaluate(currentEditLinkSelected.data, currentEditLinkSelected.displayNameExpression, true)), isOpen: currentEditLinkSelected !== null, onDismiss: onDismissViewModal, primaryKeyValue: currentEditLinkSelected.primaryKeyValue, onSaved: onModalSaved, onSubmodalSaved: onModalSaved, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet })),
                responsePopupRequests && responsePopupDomainMap && processedTaskResponses && (React.createElement(TaskResponsePopup, { responses: processedTaskResponses, requests: responsePopupRequests, domainNameMap: responsePopupDomainMap, visible: taskResponsePopupIsVisible, setVisible: closeTaskResponsePopup, submitWithOverrides: handleFinalSubmit, submitWithUpdates: submitTaskRequests, getMessageLocation: getMessageLocation })))),
            modalFormViewId && modalType === ModalType.Modal && (React.createElement(ViewModal, { viewId: modalFormViewId, viewType: ViewType.Form, isOpen: true, onDismiss: closeFormViewModal, primaryKeyValue: modalPk, parentPk: primaryKeyValue, setPrimaryKeyValue: setModalPk, onSaved: onModalSaved, onSubmodalSaved: onModalSaved, filters: modalFilters, defaultData: modalDefaultData, headerText: modalText, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet })),
            modalFormViewId && modalType === ModalType.ConfirmationModal && (React.createElement(ConfirmationViewModal, { viewId: modalFormViewId, viewType: ViewType.Form, isOpen: true, dismissOnSave: true, onDismiss: closeFormViewModal, primaryKeyValue: modalPk, onSaved: onModalSaved, onSubmodalSaved: onModalSaved, filters: modalFilters, defaultData: modalDefaultData, ViewModal: ViewModal, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet })),
            modalFormViewId && modalType === ModalType.MultiSelectConfirmationModal && referencedFieldId && (React.createElement(MultiSelectConfirmationViewModal, { viewId: modalFormViewId, viewType: ViewType.Form, isOpen: true, dismissOnSave: true, onDismiss: closeFormViewModal, onSaved: onModalSaved, onSubmodalSaved: onModalSaved, filters: modalFilters, defaultData: modalDefaultData, ViewModal: ViewModal, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet, referencedFieldId: referencedFieldId })))));
};
/**
 * Responsible for taking FormView metadata and translating to React-UI-Library Form to render form
 */
export const FormScreen = (props) => {
    const { formView } = props;
    const { fieldConfigurationId, domainId } = formView;
    const { data: domain, isLoading: isDomainLoading } = useDomain(domainId);
    const { data: fieldConfiguration, isLoading: isFieldConfigurationLoading } = useFieldConfiguration(fieldConfigurationId);
    const { data: links, isLoading: areLinksLoading } = useLinks(domainId);
    if (isDomainLoading)
        return React.createElement(ProgressIndicator, { label: "Loading Domain..." });
    if (isFieldConfigurationLoading)
        return React.createElement(ProgressIndicator, { label: "Loading Field Configuration..." });
    if (areLinksLoading)
        return React.createElement(ProgressIndicator, { label: "Loading Links..." });
    if (!fieldConfiguration)
        return React.createElement(MessageBar, { text: `Failed to load field configuration ${fieldConfigurationId}` });
    if (!domain)
        return React.createElement(MessageBar, { text: `Failed to load domain ${domainId}` });
    if (!links)
        return React.createElement(MessageBar, { text: `Failed to load links for domain ${domainId}` });
    return React.createElement(InnerFormScreen, Object.assign(Object.assign({}, props), { fieldConfiguration, domain, links }));
};
export default FormScreen;
