export function MapToListViewMember(domainField, fieldConfiguration) {
    var _a;
    const fcMember = fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers.find((member) => member.viewFieldName === domainField.fieldName);
    return {
        defaultValueExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.defaultValueExpression,
        displayFormat: (_a = fcMember === null || fcMember === void 0 ? void 0 : fcMember.formatId) !== null && _a !== void 0 ? _a : domainField.displayFormat,
        editableExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.editableExpression,
        scalarDisplayName: domainField.displayName,
        sequence: 1000,
        viewFieldName: domainField.fieldName,
        requiredExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.requiredExpression,
        visibleExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.visibleExpression,
        hexFillRule: fcMember === null || fcMember === void 0 ? void 0 : fcMember.hexFillRule,
        formatId: fcMember === null || fcMember === void 0 ? void 0 : fcMember.formatId,
        inputType: fcMember === null || fcMember === void 0 ? void 0 : fcMember.inputType,
        lookupProperties: fcMember === null || fcMember === void 0 ? void 0 : fcMember.lookupProperties,
        tooltip: fcMember === null || fcMember === void 0 ? void 0 : fcMember.tooltip,
        horizontalAlignment: fcMember === null || fcMember === void 0 ? void 0 : fcMember.horizontalAlignment,
        calculationCondition: fcMember === null || fcMember === void 0 ? void 0 : fcMember.calculationCondition,
        calculationFrequency: fcMember === null || fcMember === void 0 ? void 0 : fcMember.calculationFrequency,
        calculationRule: fcMember === null || fcMember === void 0 ? void 0 : fcMember.calculationRule,
        displayNameExpression: fcMember === null || fcMember === void 0 ? void 0 : fcMember.displayNameExpression,
    };
}
export default MapToListViewMember;
