import React from 'react';
import { useApiContext } from '@samc/screen-config-api';
import { QueryContextProvider, globals, ConfigurationContext, QueryContext, DomainContext, ViewContext, DomainFilterContext, FolderGroupTreeContext, FileUploadContext, PendingChangesContext, MultiSortContext, } from '@samc/vmsnext-querybuilder';
export const useQueryContext = () => React.useContext(QueryContextProvider);
// eslint-disable-next-line react/display-name
export const QueryBuilderContextProvider = (props) => {
    var _a;
    const { children, domainId } = props;
    const queryProps = React.useMemo(() => ({ domainId }), [domainId]);
    const api = useApiContext();
    globals.__WEBAPI_URL = api.LiftSiftApi;
    globals.__FOLDERAPI_URL = api.FolderApi;
    globals.__USE_FOLDER_TREE_VIEWER = true;
    globals.__DOMAINVIEW_SORT_CONFIGURABLE = true;
    return (React.createElement(ConfigurationContext, { autoLoad: true, headers: (_a = api.requestInit) === null || _a === void 0 ? void 0 : _a.headers },
        React.createElement(QueryContext, { autoloadOverride: false, queryProps: queryProps },
            React.createElement(DomainContext, null,
                React.createElement(ViewContext, null,
                    React.createElement(DomainFilterContext, null,
                        React.createElement(FolderGroupTreeContext, null,
                            React.createElement(FileUploadContext, null,
                                React.createElement(PendingChangesContext, null,
                                    React.createElement(MultiSortContext, null, children))))))))));
};
export default QueryBuilderContextProvider;
