export var InputType;
(function (InputType) {
    InputType[InputType["Text"] = 0] = "Text";
    InputType[InputType["Number"] = 1] = "Number";
    InputType[InputType["Date"] = 2] = "Date";
    InputType[InputType["Select"] = 3] = "Select";
    InputType[InputType["Checkbox"] = 4] = "Checkbox";
    InputType[InputType["MultilineText"] = 5] = "MultilineText";
    InputType[InputType["MeasurementField"] = 6] = "MeasurementField";
    InputType[InputType["MultiSelect"] = 7] = "MultiSelect";
    InputType[InputType["CurrencyField"] = 8] = "CurrencyField";
    InputType[InputType["Picklist"] = 9] = "Picklist";
    InputType[InputType["UnitOfMeasure"] = 10] = "UnitOfMeasure";
    InputType[InputType["UnitOfCurrency"] = 11] = "UnitOfCurrency";
    InputType[InputType["DateTime"] = 12] = "DateTime";
    InputType[InputType["Time"] = 13] = "Time";
    InputType[InputType["YesNo"] = 14] = "YesNo";
    InputType[InputType["StrataFile"] = 15] = "StrataFile";
    InputType[InputType["ListView"] = 16] = "ListView";
    InputType[InputType["FormView"] = 17] = "FormView";
    InputType[InputType["Wysiwyg"] = 18] = "Wysiwyg";
    InputType[InputType["Percent"] = 19] = "Percent";
    InputType[InputType["DocLinkCount"] = 20] = "DocLinkCount";
    InputType[InputType["Expression"] = 21] = "Expression";
    InputType[InputType["CurrencyPerArea"] = 22] = "CurrencyPerArea";
    InputType[InputType["DomainFilter"] = 23] = "DomainFilter";
})(InputType || (InputType = {}));
export const BooleanInputTypes = [InputType.YesNo, InputType.Checkbox];
export const NumberInputTypes = [
    InputType.Number,
    InputType.Percent,
    InputType.MeasurementField,
    InputType.CurrencyField,
    InputType.DocLinkCount,
    InputType.CurrencyPerArea,
];
export const DateInputTypes = [InputType.Date, InputType.DateTime];
export default InputType;
