import { v4 as uuid } from 'uuid';
export const GetHeaders = () => {
    const headers = new Headers();
    if (typeof Ext !== 'undefined') {
        headers.set('X-Csrf-Token', Ext.util.Cookies.get('__AF'));
    }
    headers.set('X-CorrelationId', uuid());
    return headers;
};
export default GetHeaders;
