var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { v4 as uuid } from 'uuid';
import { mergeHeaders } from '@samc/react-ui-request';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { GetHeaders } from './GetHeaders';
import { downloadAsFile } from '../helpers/downloadHelpers';
const fetchInternal = (input_1, init_1, ...args_1) => __awaiter(void 0, [input_1, init_1, ...args_1], void 0, function* (input, init, ignoreStatus = []) {
    var _a, _b;
    const response = yield fetch(input, init);
    if (response.status >= 400 && response.status < 600 && !ignoreStatus.includes(response.status)) {
        if (response.status === 401) {
            throw new Error(`Unauthorized. Please log out and back in again.`);
        }
        if (response.status === 403) {
            throw new Error(`Request Forbidden. Please contact your administrator if you believe this is an error.`);
        }
        if (response.status === 502) {
            throw new Error(`Request failed due to temporary outage. Please try again later.`);
        }
        if (response.status === 504) {
            throw new Error(`Request timed out. Please refresh the page and try again.`);
        }
        let body;
        try {
            body = yield response.json();
        }
        catch (e) {
            throw new Error(`Request failed to ${input}`);
        }
        const statusText = response.statusText ? `${response.statusText}: ` : '';
        if (body.developerMessage) {
            if (body.developerMessage.message)
                throw new Error(`${statusText}${body.developerMessage.message}`);
        }
        if (body.DeveloperMessage) {
            if (body.DeveloperMessage.Message)
                throw new Error(`${statusText}${body.DeveloperMessage.Message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${body.messages[0]}`);
        }
        if (((_b = (_a = body === null || body === void 0 ? void 0 : body.Errors) === null || _a === void 0 ? void 0 : _a.ExpressionValidations) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            throw new Error(`${body.Errors.ExpressionValidations[0]}`);
        }
        throw new Error(`Request failed to ${input}`);
    }
    return response;
});
export const getLinks = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/link/domain/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getFormViews = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    let endpoint = '/api/v2/formview';
    if (domainId) {
        endpoint += `/domain/${domainId}`;
    }
    const response = yield fetchInternal(`${url}${endpoint}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getFormViewsRequest = (url, domainId, requestInit) => {
    return () => {
        return getFormViews(url, domainId, requestInit);
    };
};
export const getFormView = (url, formViewId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!formViewId) {
        return Promise.reject(new Error('Invalid formViewId'));
    }
    const response = yield fetchInternal(`${url}/api/v2/formview/${formViewId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [403, 404]);
    if (response.status === 403)
        return Promise.reject(new Error(`You are not authorized to view the Form View on this tab. Please contact your administrator if you believe this is an error.`));
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getListViews = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    let endpoint = '/api/v1/listview';
    if (domainId) {
        endpoint += `/domain/${domainId}`;
    }
    const response = yield fetchInternal(`${url}${endpoint}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getListViewsRequest = (url, requestInit) => {
    return (domainId) => {
        return getListViews(url, domainId, requestInit);
    };
};
export const getListView = (url, listViewId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!listViewId) {
        return Promise.reject(new Error('Invalid listViewId'));
    }
    const response = yield fetchInternal(`${url}/api/v1/listview/${listViewId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [403, 404]);
    if (response.status === 403)
        return Promise.reject(new Error(`You are not authorized to view the List View on this tab. Please contact your administrator if you believe this is an error.`));
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getChildViews = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domainId'));
    }
    const response = yield fetchInternal(`${url}/api/v1/listview/childdomains/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getParentFormViews = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domainId'));
    }
    const response = yield fetchInternal(`${url}/api/v2/formview/parentdomains/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getChildFormViews = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domainId'));
    }
    const response = yield fetchInternal(`${url}/api/v2/formview/childdomains/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getListViewRequest = (url, requestInit) => {
    return (listViewId) => {
        return getListView(url, listViewId, requestInit);
    };
};
export const getViewSet = (url, viewSetId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!viewSetId) {
        return Promise.reject(new Error('Invalid viewSetId'));
    }
    const response = yield fetchInternal(`${url}/api/v1/viewset/${viewSetId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [403, 404]);
    if (response.status === 403)
        return Promise.reject(new Error(`You are not authorized to view this page. Please contact your administrator if you believe this is an error.`));
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getViewSetsByDomain = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domainId'));
    }
    const response = yield fetch(`${url}/api/v1/viewset/domain/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const saveListView = (url, requestInit) => (listview) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/listview`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(listview),
    });
    return response.json();
});
export const saveDomainField = (url, requestInit) => (field) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/calculatedfield`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(field),
    });
    return response.json();
});
export const saveFormView = (url, requestInit) => (formView) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v2/formview`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(formView),
    });
    return response.json();
});
export const saveViewSet = (url, requestInit) => (viewSet) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/viewset`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(viewSet),
    });
    return response.json();
});
export const executeQuery = (url, requestInit, showError) => (params, includeLimitedAccessRecords) => __awaiter(void 0, void 0, void 0, function* () {
    const req = params;
    // Add a cacheId for list view so that when downloading excel, we can reference exactly what the user sees in grid.
    if (!req.cacheId && params.summaryMode !== true)
        req.cacheId = uuid();
    try {
        const response = yield fetchInternal(`${url}/api/v2/Query/Execute${includeLimitedAccessRecords ? '?includeLimitedAccessRecords=true' : ''}`, {
            method: 'POST',
            headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
            body: JSON.stringify(req),
        });
        const queryResponse = (yield response.json());
        queryResponse.CacheId = req.cacheId;
        return queryResponse;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }
    catch (e) {
        if (e instanceof Error) {
            showError === null || showError === void 0 ? void 0 : showError(e.message);
        }
        throw e;
    }
});
export const getExportGenerator = (apiUrl, requestInit) => (_c) => __awaiter(void 0, [_c], void 0, function* ({ request, sortOrder, sortOrderBy }) {
    let targetUrl = `${apiUrl}/api/v1/Generate`;
    if (!isNullOrUndefined(sortOrderBy)) {
        // Default to ascending order if no order was specified
        const orderChar = sortOrder === 'desc' ? '-' : '+';
        targetUrl = `${targetUrl}?sort=${orderChar}${sortOrderBy}`;
    }
    const response = yield fetchInternal(targetUrl, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, {
            'Content-Type': 'application/json; charset=utf-8',
        }),
        body: JSON.stringify(request),
    });
    yield downloadAsFile(response, 'Export.xlsx');
});
export const getDomain = (url, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!id) {
        return Promise.resolve(undefined);
    }
    const response = yield fetchInternal(`${url}/api/v1/domain/${id}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [404]);
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getDomains = (url, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/domain`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getDomainRelationships = (url, targetDomainId, sourceDomainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const apiUrl = sourceDomainId
        ? `${url}/api/v1/domainrelationship/source/${sourceDomainId}`
        : `${url}/api/v1/domainrelationship/target/${targetDomainId}`;
    const response = yield fetchInternal(apiUrl, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    let ret = yield response.json();
    if (sourceDomainId && targetDomainId) {
        ret = ret.filter((d) => d.targetDomainId === targetDomainId);
    }
    return ret;
});
/**
 * Submits a task to the task gateway service
 * @param url the task gateway service location
 * @param requestInit request header data
 * @returns the task response
 */
export const submitTask = (url, requestInit) => (request) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v2/task`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(request),
    }, [400]);
    return response.json();
});
/**
 * Submits multiple tasks in a flat list (not related) together
 * @param url the location of the task gateway service
 * @param requestInit request data
 * @returns the task responses in the same order as requested
 */
export const submitMultipleTasks = (url, requestInit) => (request) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v2/task/bulk`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(request),
    }, [400]);
    return response.json();
});
export const getFieldConfiguration = (url, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!id) {
        return Promise.resolve(undefined);
    }
    const response = yield fetchInternal(`${url}/api/v1/FieldConfiguration/${id}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [404]);
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getFieldConfigurationsByDomain = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domain id'));
    }
    const response = yield fetchInternal(`${url}/api/v1/FieldConfiguration/domain/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getFieldConfigurationsByParentDomains = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domain id'));
    }
    const response = yield fetchInternal(`${url}/api/v1/FieldConfiguration/parentdomains/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const saveFieldConfiguration = (url, requestInit) => (fieldConfiguration) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/fieldconfiguration`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(fieldConfiguration),
    });
    return response.json();
});
/*
 * Validation Views
 */
export const getValidationViews = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    let newUrl = `${url}/api/v1/validationview`;
    if (domainId)
        newUrl = `${newUrl}/domain/${domainId}`;
    const response = yield fetch(newUrl, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getValidationViewsRequest = (url, requestInit) => {
    return (domainId) => {
        return getValidationViews(url, domainId, requestInit);
    };
};
export const getValidationView = (url, validationViewId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!validationViewId) {
        return Promise.reject(new Error('Invalid validationViewId'));
    }
    const response = yield fetchInternal(`${url}/api/v1/validationview/${validationViewId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [404]);
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getValidationViewRequest = (url, requestInit) => {
    return (validationViewId) => {
        return getValidationView(url, validationViewId, requestInit);
    };
};
export const saveValidationView = (url, requestInit) => (validationView) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(`${url}/api/v1/validationview`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(validationView),
    });
    return response.json();
});
export const deleteValidationView = (url, requestInit) => (templateId) => __awaiter(void 0, void 0, void 0, function* () {
    if (!templateId) {
        return Promise.reject(new Error('Invalid templateId'));
    }
    const response = yield fetch(`${url}/api/v1/ValidationView/${templateId}`, {
        method: 'DELETE',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.ok;
});
export const getFiltersByDomain = (url, domainId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId) {
        return Promise.reject(new Error('Invalid domainId'));
    }
    const response = yield fetch(`${url}/api/v1/Filter/domain/${domainId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getFiltersByDomainRequest = (url, requestInit) => {
    return (domainId) => {
        return getFiltersByDomain(url, domainId, requestInit);
    };
};
export const getFilterById = (url, filterId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!filterId) {
        return Promise.reject(new Error('Invalid filterId'));
    }
    const response = yield fetchInternal(`${url}/api/v1/Filter/${filterId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    }, [404]);
    if (response.status === 404)
        return undefined;
    return response.json();
});
export const getFilterByIdRequest = (url, requestInit) => {
    return (filterId) => {
        return getFilterById(url, filterId, requestInit);
    };
};
export const saveFilter = (url, requestInit) => (filter) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/Filter`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(filter),
    });
    return response.json();
});
export const deleteFilter = (url, requestInit) => (filterId) => __awaiter(void 0, void 0, void 0, function* () {
    if (!filterId) {
        return Promise.reject(new Error('Invalid filterId'));
    }
    const response = yield fetch(`${url}/api/v1/Filter/${filterId}`, {
        method: 'DELETE',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const runFieldCalculations = (url, domainId, primaryKeyField, fields, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!domainId)
        throw new Error('Invalid domainId');
    if (!primaryKeyField)
        throw new Error('Invalid primaryKeyField');
    if (fields.length === 0)
        return;
    const body = {
        domainId,
        primaryKeyField,
        fields,
    };
    yield fetchInternal(`${url}/api/v1/calculatedfields/calculate`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
        body: JSON.stringify(body),
    });
});
export const validateContactAssignments = (url, domain, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const _url = `${url}/api/Validate/${domain}/${id}`;
    const response = yield fetchInternal(_url, {
        method: 'GET',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getFormTabPdf = (url, exportParams, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const { tabOptions, formViewId, tabIndex, primaryKey } = exportParams;
    const { footerText, includeClientName, includeFooterText, includePageNumber, includeTimestamp, isHorizontal, titleExpression, } = tabOptions;
    const queryStringBuilder = new URLSearchParams({ formViewId, primaryKey });
    if (!isNullOrUndefined(tabIndex))
        queryStringBuilder.append('tabIndex', String(tabIndex));
    if (titleExpression)
        queryStringBuilder.append('titleExpression', titleExpression);
    if (isHorizontal)
        queryStringBuilder.append('horizontal', String(isHorizontal));
    if (includeClientName)
        queryStringBuilder.append('includeClientName', String(includeClientName));
    if (includePageNumber)
        queryStringBuilder.append('includePageNumbers', String(includePageNumber));
    if (includeTimestamp)
        queryStringBuilder.append('includeTimestamp', String(includeTimestamp));
    if (includeFooterText && footerText) {
        queryStringBuilder.append('footerText', footerText);
    }
    const _url = `${url}/pdfexporter/export?${queryStringBuilder.toString()}`;
    const response = yield fetch(_url, {
        method: 'GET',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    if (response.status >= 400 && response.status < 600)
        throw new Error('Failed to export PDF');
    yield downloadAsFile(response, 'Export.pdf');
});
export const getEntityEntitlements = (url_1, _d, requestInit_1) => __awaiter(void 0, [url_1, _d, requestInit_1], void 0, function* (url, { domainId, targetIds, entitlementNames, entitlementTypes }, requestInit) {
    if (!domainId)
        return Promise.reject(new Error('Invalid domainId'));
    if (targetIds.length === 0)
        return Promise.reject(new Error('Must provide at least one target id'));
    if (!(entitlementNames === null || entitlementNames === void 0 ? void 0 : entitlementNames.length) && !(entitlementTypes === null || entitlementTypes === void 0 ? void 0 : entitlementTypes.length))
        return Promise.reject(new Error('Must provide at least one entitlement name or type to target'));
    const response = yield fetchInternal(`${url}/api/v1/EntityEntitlements/Check`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify({
            domainId,
            targetIds,
            entitlements: entitlementNames,
            entitlementTypes,
        }),
    });
    return response.json();
});
