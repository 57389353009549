import React from 'react';
import { useListView } from '@samc/screen-config-api';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { ConfigureListView } from './ConfigureListView';
import { ProgressIndicator } from '../../atoms/ProgressIndicator/ProgressIndicator';
const _GetListViewManager = (props, ref) => {
    var _a;
    const { listViewId, children, onLoadFailed, setSelectedFilterIds } = props;
    const result = useListView(listViewId);
    const listView = React.useMemo(() => {
        if (result.data)
            return ConfigureListView(result.data);
        return result.data;
    }, [result.data]);
    React.useImperativeHandle(ref, () => ({
        reload: () => result.refresh(),
    }), [result]);
    React.useEffect(() => {
        if (result.data && setSelectedFilterIds && result.data.defaultFilter) {
            const newFilterIds = [result.data.defaultFilter];
            setSelectedFilterIds(newFilterIds);
        }
    }, [(_a = result.data) === null || _a === void 0 ? void 0 : _a.defaultFilter]);
    const listViewLoadFailed = result.isError || (!result.isLoading && !listView);
    React.useLayoutEffect(() => {
        if (listViewLoadFailed)
            onLoadFailed === null || onLoadFailed === void 0 ? void 0 : onLoadFailed({ listViewId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listViewLoadFailed]);
    // Check network call results
    if (result.isLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading Grid..." });
    }
    if (result.isError) {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, text: `Error loading List View: ${listViewId}! ${result.error}` }));
    }
    if (!listView) {
        return React.createElement(MessageBar, { messageBarType: MessageBarType.error, text: `List View ${listViewId} not found!` });
    }
    return children({ listView });
};
/**
 * This component is meant to handle the call to GetScreen, and will use ConfigureScreen to apply any additional configuration to the resulting Screen that is
 * needed to properly render the grid or form.
 * @param props The component props.
 */
export const GetListViewManager = React.forwardRef(_GetListViewManager);
export default GetListViewManager;
