var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useStyletron } from 'styletron-react';
import { Modal, Spinner } from '@fluentui/react';
import { ApiContext, TaskStatus, useFormView, useQBQuery, useTaskSubmit, } from '@samc/screen-config-api';
import { Picklist } from '@samc/picklist-core';
import { toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { HeaderContextProvider, useHeaderContext, } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { usePicklistItemGetter } from '@samc/picklist-api';
import { ModalControlBar } from '../../ModalControlBar/ModalControlBar';
import { getAssociatedEntityPicklistItemGetter, getDocumentLinksSelectedRows, getEntityIdFromQueryParams, getFundIdFromCurrentEntity, } from '../DocumentsModal.Utilities';
import { useSelectedRowsByGridContext } from '../../../../contexts/GridSelectionContext/GridSelectionContext';
import * as Utilities from './AddDocumentLinks.Utilities';
import { useDocumentAssociatedEntity } from '../../../../hooks/useDocumentAssociatedEntity/useDocumentAssociatedEntity';
import { usePicklistGridFieldConfigurations } from '../../../../hooks/usePicklistGridFieldConfigurations/usePicklistGridFieldConfigurations';
import { useGridInjector } from '../../../../hooks/useGridInjector/useGridInjector';
import { useGridFieldsFromListview } from '../../../../hooks/useGridFieldsFromListview/useGridFieldsFromListview';
const useGridFieldConfigurations = usePicklistGridFieldConfigurations.withHooks({
    useGridFieldsFromListview,
    useGridInjector,
});
export const InnerViewModal = (props) => {
    var _a, _b;
    const { viewId, isOpen, onDismiss, onSaved, showContent, entityId, style, filters, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    // Get the List View from the Form View
    const { data: formView } = useFormView(viewId);
    const listViewId = ((_b = (_a = formView === null || formView === void 0 ? void 0 : formView.gridFields) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.viewId) || viewId;
    // Why do we not use entityId passed?
    //       It's because it's always PortfolioId for Fund/Investment/Asset/Valuation etc.
    //       The Domain Relationships should be updated, then we would get the correct id always.
    const currentEntity = React.useMemo(() => entityId || getEntityIdFromQueryParams(), []);
    const { data: entity, isLoading } = useDocumentAssociatedEntity(currentEntity);
    const [picklistConfig, setPicklistConfig] = React.useState();
    const [filterEntityIds, setFilterEntityIds] = React.useState(undefined);
    const [selectedEntity, setSelectedEntity] = React.useState('');
    const [gridFilter, setGridFilter] = React.useState('');
    const gridScreenInternalRef = React.useRef(null);
    const apiContext = React.useContext(ApiContext);
    const [css] = useStyletron();
    const picklistItemGetter = usePicklistItemGetter();
    const qbQuery = useQBQuery();
    const [taskSubmit] = useTaskSubmit();
    const { controlBarProps } = useHeaderContext();
    const { selectedRowsByGrids } = useSelectedRowsByGridContext();
    /* Gets the associated entities of the picklist given the entities filters */
    const associatedPicklistItemGetter = React.useMemo(() => {
        if (!picklistConfig || !filterEntityIds)
            return undefined;
        const { picklistId } = picklistConfig;
        return getAssociatedEntityPicklistItemGetter(filterEntityIds, currentEntity, picklistId, picklistItemGetter);
    }, [currentEntity, filterEntityIds, viewId, picklistConfig, picklistItemGetter]);
    const selectedDocumentLinks = React.useMemo(() => getDocumentLinksSelectedRows(selectedRowsByGrids), [selectedRowsByGrids]);
    const handleModalDismiss = React.useCallback(() => {
        if (!onDismiss)
            return;
        onDismiss();
    }, [onDismiss]);
    const handleReset = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        (_c = gridScreenInternalRef.current) === null || _c === void 0 ? void 0 : _c.reset();
    }), []);
    /* Looks for the fieldConfiguration of the Associated Entity picklist */
    React.useEffect(() => {
        Utilities.default.getAssociatedEntityPicklistConfig(setPicklistConfig, apiContext === null || apiContext === void 0 ? void 0 : apiContext.AggregatorApi, apiContext === null || apiContext === void 0 ? void 0 : apiContext.requestInit);
    }, [apiContext === null || apiContext === void 0 ? void 0 : apiContext.AggregatorApi, apiContext === null || apiContext === void 0 ? void 0 : apiContext.requestInit]);
    /* Looks for FundId when the current page is in POMA or Valuations */
    React.useEffect(() => {
        const isNotFundPage = window.location.hash.toLocaleLowerCase().indexOf('fund') === -1;
        if (isNotFundPage) {
            getFundIdFromCurrentEntity(currentEntity, qbQuery, viewId).then((fundId) => Utilities.validateAndSetFundEntities(fundId, setFilterEntityIds));
        }
        else {
            setFilterEntityIds(currentEntity);
        }
    }, [currentEntity, qbQuery]);
    const onSelectionChange = (data) => {
        const selectedEntityId = data[0];
        handleReset();
        setSelectedEntity(`${selectedEntityId}`);
        // main filter to filter out the documents for the currently selected entity.
        let customFilters = [`DoesContain('${selectedEntityId}', [EntityId])`];
        // filter to filter by the DocumentType's EntityTypeIds
        // this is based on currentEntity, not selectedEntity
        if ((entity === null || entity === void 0 ? void 0 : entity.Data) && entity.Data.length > 0) {
            const entityFilters = Utilities.getDocLinkEntityFilters(filters, entity.Data[0].entityTypeId);
            customFilters = customFilters.concat(entityFilters);
        }
        /* Gets the baseline filter from the Documents gridField within a specific formView according to the selected entity. */
        setGridFilter(customFilters.join(' AND '));
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const payload = Utilities.default.getAddDocumentLinksPayload(selectedDocumentLinks, currentEntity);
        return taskSubmit(payload).then(([response]) => {
            const { statusCode, messages, messageCount } = response;
            if (statusCode !== TaskStatus.Completed || messageCount > 0) {
                toastError(React.createElement(ToastMessage, { title: "Save failed", message: messages
                        ? messages[0].title || messages[0].description
                        : 'An error ocurred while saving the documents' }));
                return;
            }
            toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "The documents have been linked" }));
            if (onSaved)
                onSaved();
        });
    });
    return (React.createElement(Modal, { forceFocusInsideTrap: false, layerProps: { eventBubblingEnabled: true }, isOpen: isOpen, onDismiss: handleModalDismiss, isBlocking: false },
        React.createElement("div", { className: "view-modal", style: { height: '85vh', width: '85vw', display: 'flex', flexDirection: 'column' } },
            React.createElement(ModalControlBar, Object.assign({ headerText: "Link Documents from Another Entity", onDismiss: handleModalDismiss }, controlBarProps, { submitDisabled: selectedDocumentLinks.length === 0, submit: handleSubmit })),
            React.createElement("div", { className: css({
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    alignSelf: 'stretch',
                    maxWidth: '100%',
                    marginLeft: '10px',
                    marginRight: '10px',
                }) },
                (!picklistConfig || !associatedPicklistItemGetter || isLoading) && React.createElement(Spinner, null),
                picklistConfig && associatedPicklistItemGetter && !isLoading && (React.createElement("div", { className: css({
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '100%',
                        margin: '12px',
                    }) },
                    React.createElement("p", { className: css({
                            width: '90px',
                            fontWeight: 'bold',
                            color: '#1e242a',
                            fontSize: '12px',
                        }) }, "Select Entity"),
                    React.createElement("div", { className: css({ width: '200px' }) },
                        React.createElement(Picklist, { selectedKey: selectedEntity ? [selectedEntity] : undefined, displayProperty: "customLongName", fetchItems: associatedPicklistItemGetter, picklist: picklistConfig, onChange: onSelectionChange, valueProperty: "id", useGridFieldConfigurations: useGridFieldConfigurations })))),
                selectedEntity && gridFilter && (React.createElement(GridScreenWrapper, { ref: gridScreenInternalRef, style: style, showContent: showContent, key: listViewId, listViewId: listViewId, filters: [gridFilter], ServiceDrivenViewSet: ServiceDrivenViewSet }))))));
};
/**
 * Launches a modal to render Remove Document Links listing custom component
 * @param props
 * @returns
 */
export const AddDocumentLinks = (props) => {
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(InnerViewModal, Object.assign({}, props))));
};
export default AddDocumentLinks;
