import { InputType } from '@samc/screen-config-api';
import { GetSelectEntryField } from '../molecules/SelectEntryField/SelectEntryField';
import { FormSkeletonMappers } from '../mappers/FormSkeletonMappers/FormSkeletonMappers';
/**
 * @deprecated use {@link FormSkeletonMappers}
 */
export const convertFormFieldConfigurationToFormViewMember = (fieldConfiguration) => FormSkeletonMappers.Field.toFormViewMember(fieldConfiguration);
/**
 * This is an implementation of the FormFieldConfiguration interface to help in converting from FormViewMember
 * to FormFieldConfiguration and back.
 * @deprecated use {@link FormSkeletonMappers} for mapping
 */
export class FormFieldConfigurationViewModel {
    constructor(formViewMember) {
        var _a, _b, _c, _d;
        /**
         * Map to FormViewMember for saving, most likely.
         */
        this.toFormViewMember = () => convertFormFieldConfigurationToFormViewMember(this);
        this.field = formViewMember.viewFieldName;
        this.displayNameRule = formViewMember.displayNameExpression || `'${formViewMember.viewFieldName}'`;
        this.editableRule = formViewMember.editableExpression;
        this.visibleRule = formViewMember.visibleExpression;
        this.requiredFieldRule = formViewMember.requiredExpression;
        this.hexFillRule = formViewMember.hexFillRule;
        this.isKeyField = formViewMember.isKeyField;
        this.defaultValueRule = formViewMember.defaultValueExpression;
        this.displayProperty = (_a = formViewMember.lookupProperties) === null || _a === void 0 ? void 0 : _a.displayProperty;
        this.valueProperty = (_b = formViewMember.lookupProperties) === null || _b === void 0 ? void 0 : _b.valueProperty;
        this.menuItems = (_c = formViewMember.lookupProperties) === null || _c === void 0 ? void 0 : _c.lookupValues;
        this.inputType = FormSkeletonMappers.InputType.toFrontendInputType(formViewMember.inputType || InputType.Text);
        this.unitOfMeasureProperties = formViewMember === null || formViewMember === void 0 ? void 0 : formViewMember.unitOfMeasureProperties;
        this.unitOfCurrencyProperties = formViewMember === null || formViewMember === void 0 ? void 0 : formViewMember.unitOfCurrencyProperties;
        this.confirmOptions = formViewMember.confirmOptions;
        this.onRenderField = ((_d = formViewMember.lookupProperties) === null || _d === void 0 ? void 0 : _d.domainId)
            ? GetSelectEntryField(formViewMember.lookupProperties.domainId, formViewMember.lookupProperties.viewId)
            : undefined;
        this.tooltip = formViewMember.tooltip;
        this.defaultValueRuleOverride = formViewMember.defaultValueRuleOverride;
        this.group = formViewMember.domainId;
        this.source = formViewMember.domainId;
    }
}
/**
 * Makes a view model from a form field configuration
 */
FormFieldConfigurationViewModel.fromFormFieldConfiguration = (ffc) => {
    const objectInstance = Object.create(FormFieldConfigurationViewModel);
    Object.assign(objectInstance, ffc);
    return objectInstance;
};
export default FormFieldConfigurationViewModel;
