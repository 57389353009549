import React from 'react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { EntityEntitlementContextProvider, useViewSet, useDomains } from '@samc/screen-config-api';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { useHeaderContext } from '@samc/react-ui-history';
import { useViewSetDataContext } from '../../../hooks/useViewSetDataContext/useViewSetDataContext';
import { TabOverrideContextProvider, } from '../../../contexts/TabOverrideContext/TabOverrideContext';
import { usePreparedEvaluatorConfigurationByDomain } from '../../../hooks/usePreparedEvaluatorConfigurationByDomain/usePreparedEvaluatorConfigurationByDomain';
import { ViewSetWithHeader } from '../ViewSetWithHeader/ViewSetWithHeader';
import { ProgressIndicator } from '../../../atoms/ProgressIndicator/ProgressIndicator';
import { useSelectAndShowURLSearchParams } from '../../../hooks/useSelectAndShowURLSearchParams/useSelectAndShowURLSearchParams';
/**
 * Takes a ViewSetId and pulls ViewSet metadata from API to then render a ViewSet, the RecordPicker and any actions for the ViewSet
 * - this component can be either controlled (by the parent) or uncontrolled (it controls itself)
 * - if using it controlled, be sure to pass in both the primaryKeyValue and setPrimaryKeyValue props
 * @param props
 */
export const ServiceDrivenViewSet = (props) => {
    const { viewSetId, filters, primaryKeyValue, setPrimaryKeyValue, defaultData, tabRenderer, baselineFilterExpression, onSelectionChanges, precedingControlRenderer, initialSelectedTabId, onTabChanged, initialSelectedSubtabId, onSubtabChanged, parentScope: scope, useFormView, submitAllAttributes, afterDelete, } = props;
    const [currentPrimaryKeyValue, setCurrentPrimaryKeyValue] = React.useState(primaryKeyValue);
    const [_, __, deleteAllSelectAndShowURLSearchParams] = useSelectAndShowURLSearchParams();
    // get all domains cached
    useDomains();
    // Update current primary key value if the one passed in has changed
    React.useEffect(() => {
        if (primaryKeyValue !== currentPrimaryKeyValue) {
            setCurrentPrimaryKeyValue(primaryKeyValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryKeyValue]);
    const handleSetPrimaryKey = React.useCallback((value) => {
        deleteAllSelectAndShowURLSearchParams();
        if (setPrimaryKeyValue)
            setPrimaryKeyValue(value);
        else
            setCurrentPrimaryKeyValue(value);
    }, [deleteAllSelectAndShowURLSearchParams, setPrimaryKeyValue]);
    const { data: viewSet, isLoading, isError, error } = useViewSet(viewSetId);
    const dataContext = useViewSetDataContext(viewSet, currentPrimaryKeyValue);
    const evaluatorConfig = usePreparedEvaluatorConfigurationByDomain(viewSet === null || viewSet === void 0 ? void 0 : viewSet.domainId);
    const { setValue: setHeaderValue, breadcrumbProps, reset: resetHeaderContext } = useHeaderContext();
    React.useEffect(() => {
        // If the viewset couldn't load, make sure the header doesn't reflect old data
        if (isError) {
            resetHeaderContext();
            // Hang onto the breadcrump props though to orient the user
            setHeaderValue('breadcrumbProps', breadcrumbProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);
    if (isLoading && !viewSet)
        return React.createElement(ProgressIndicator, { label: "Loading Screen..." });
    if (isError) {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: `Error loading the ${viewSetId} page`, text: error }));
    }
    if (!viewSet)
        return React.createElement(MessageBar, { messageBarType: MessageBarType.error, text: `ViewSet not found: ${viewSetId}.` });
    return (React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
        React.createElement(EntityEntitlementContextProvider, { domainId: viewSet.domainId, entityId: primaryKeyValue, targetEntitlementTypes: ['ViewSetAction', 'ViewSetTab', 'FormViewLink', 'DocumentType', 'DataAccess'] },
            React.createElement(TabOverrideContextProvider, { tabRenderer: tabRenderer, renderingViewSetId: viewSetId },
                React.createElement(ViewSetWithHeader, { afterDelete: afterDelete, submitAllAttributes: submitAllAttributes, initialSelectedTabId: initialSelectedTabId, initialSelectedSubtabId: initialSelectedSubtabId, viewSet: viewSet, data: dataContext, filters: filters, onSelectionChanges: onSelectionChanges, primaryKeyValue: currentPrimaryKeyValue, setPrimaryKeyValue: handleSetPrimaryKey, defaultData: defaultData, baselineFilterExpression: baselineFilterExpression, precedingControlRenderer: precedingControlRenderer, onTabChanged: onTabChanged, onSubtabChanged: onSubtabChanged, parentScope: scope, useFormView: useFormView, ServiceDrivenViewSet: ServiceDrivenViewSet })))));
};
export default ServiceDrivenViewSet;
