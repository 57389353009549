import React from 'react';
import { useDomain } from '@samc/screen-config-api';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { ProgressIndicator } from '../../atoms/ProgressIndicator/ProgressIndicator';
/**
 * This component is meant to handle the call to GetScreen, and will use ConfigureScreen to apply any additional configuration to the resulting Screen that is
 * needed to properly render the grid or form.
 * @param props The component props.
 */
export const GetDomainManager = (props) => {
    const { domainId, children } = props;
    const result = useDomain(domainId);
    // Check network call results
    if (result.isLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading Domain..." });
    }
    if (result.isError) {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, text: `Error loading Domain: ${domainId}! ${result.error}` }));
    }
    if (!result.data) {
        return React.createElement(MessageBar, { messageBarType: MessageBarType.error, text: `Domain ${domainId} not found!` });
    }
    return children({ domain: result.data });
};
export default GetDomainManager;
