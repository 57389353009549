import { InputType, LayoutType } from '@samc/react-ui-form';
export const FORM_TAB_PDF_GENERATION_SECTION = {
    titleExpression: "'Enable Generation of PDF'",
    fieldLayout: {
        children: [
            {
                customText: '<p><strong>Allow user to generate a PDF of this tab as a report</strong></p>',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                field: 'exportEnabledExpression',
                type: LayoutType.HorizontalStack,
                children: [],
                suppressLabel: true,
            },
            {
                field: 'titleExpression',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                field: 'includeClientName',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                field: 'includePageNumber',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                field: 'includeTimestamp',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                field: 'includeFooterText',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                field: 'footerText',
                type: LayoutType.HorizontalStack,
                children: [],
                suppressLabel: true,
            },
            {
                field: 'isHorizontal',
                type: LayoutType.HorizontalStack,
                children: [],
            },
            {
                customText: '<p><i>If there are grids or any controls that are too wide for the pdf page, they will get cut off upon generation. Therefore, forms enabled for PDF generation should not be too wide horizontally or they will be cut off.</i></p>',
                type: LayoutType.HorizontalStack,
                children: [],
            },
        ],
        type: LayoutType.VerticalStack,
    },
};
export const FORM_TAB_PDF_GENERATION_FIELDS = {
    exportEnabledExpression: {
        defaultValueRule: 'false',
        visibleRule: 'true',
        editableRule: 'true',
        field: 'exportEnabledExpression',
        displayNameRule: "'Export Enabled Expression'",
        inputType: InputType.Expression,
    },
    titleExpression: {
        field: 'titleExpression',
        displayNameRule: "'Report Title'",
        editableRule: 'true',
        visibleRule: 'true',
        inputType: InputType.Expression,
    },
    includeClientName: {
        field: 'includeClientName',
        displayNameRule: "'Include Client Name'",
        defaultValueRule: 'false',
        visibleRule: 'true',
        editableRule: 'true',
        inputType: InputType.Checkbox,
    },
    includePageNumber: {
        field: 'includePageNumber',
        displayNameRule: "'Include Page #s'",
        defaultValueRule: 'false',
        visibleRule: 'true',
        editableRule: 'true',
        inputType: InputType.Checkbox,
    },
    includeTimestamp: {
        field: 'includeTimestamp',
        displayNameRule: "'Include Timestamp'",
        defaultValueRule: 'false',
        visibleRule: 'true',
        editableRule: 'true',
        inputType: InputType.Checkbox,
    },
    includeFooterText: {
        field: 'includeFooterText',
        displayNameRule: "'Include Footer Text'",
        defaultValueRule: 'false',
        editableRule: 'true',
        visibleRule: 'true',
        inputType: InputType.Checkbox,
    },
    footerText: {
        field: 'footerText',
        displayNameRule: "'Footer Text'",
        inputType: InputType.MultilineText,
        visibleRule: 'true',
        editableRule: '[includeFooterText] = true',
    },
    isHorizontal: {
        field: 'isHorizontal',
        displayNameRule: "'Page Orientation'",
        visibleRule: 'true',
        defaultValueRule: 'false',
        inputType: InputType.Select,
        valueProperty: 'id',
        displayProperty: 'text',
        editableRule: 'true',
        menuItems: [
            {
                id: true,
                text: 'Landscape',
            },
            {
                id: false,
                text: 'Portrait',
            },
        ],
    },
};
export default {};
