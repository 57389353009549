import React from 'react';
import { useRequestGetter } from '@samc/react-ui-request';
import { getValidationViewsRequest } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { ValidationViewViewModel } from '../viewModel/ValidationViewViewModel';
import { VALIDATIONS_KEY } from '../useValidationViews/useValidationViews';
export const useValidationViewsRequest = () => {
    const api = React.useContext(ApiContext);
    const query = (dId) => getValidationViewsRequest(api.AggregatorApi, api.requestInit)(dId).catch((err) => {
        api.ShowMessage(`Failed to fetch Validations`, 'error');
        throw err;
    });
    const getter = useRequestGetter({
        query,
        key: VALIDATIONS_KEY,
    });
    return React.useCallback((dId) => getter(false, dId), [getter]);
};
export const useValidationViewViewModelsRequest = () => {
    const getValidationViews = useValidationViewsRequest();
    return React.useCallback((domainId) => getValidationViews(domainId).then((res) => res.map((validationView) => new ValidationViewViewModel(validationView))), [getValidationViews]);
};
export default useValidationViewsRequest;
