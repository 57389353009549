/* eslint-disable @typescript-eslint/no-explicit-any */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useQBQuery, useDomain, useDomainGetter, } from '@samc/screen-config-api';
import { cloneDatum } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { MergeFilters } from './MergeAdHocFilter';
import { getValidExpressions } from '../../utils/ExpressionValidation/ExpressionValidationUtilities';
import { HIDDEN_PREFIX } from '../../utils/AuthUtils/AuthUtils';
import { useQueryContext } from '../../organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
export * from './Types'; // backwards compat
const isMultiSort = (p) => !!p.multiSort;
export const createQueryRequest = (domain, summaryMode, pagingData, filters, adHocFilter, filterIds, listViewId, adhocListViewMembers, defaultSortParameters, // from ListView
getOrderByExpression) => {
    const allSorts = [];
    if (isMultiSort(pagingData)) {
        const { multiSort } = pagingData;
        multiSort.forEach((sort) => {
            const { order, orderBy: field } = sort;
            const scalarExpression = getOrderByExpression ? getOrderByExpression(field) : `[${field}]`;
            allSorts.push({ order: order !== null && order !== void 0 ? order : undefined, orderBy: { scalarExpression }, sequence: allSorts.length });
        });
    }
    else {
        const { order, orderBy: field } = pagingData;
        if (field) {
            const scalarExpression = getOrderByExpression ? getOrderByExpression(field) : `[${field}]`;
            allSorts.push({
                order: order !== null && order !== void 0 ? order : undefined,
                orderBy: {
                    scalarExpression,
                },
            });
        }
    }
    let sorting = {};
    if (allSorts.length > 0) {
        const [firstSort, ...additionalSorting] = allSorts;
        sorting = Object.assign(Object.assign({}, firstSort), { additionalSorting });
    }
    else if (defaultSortParameters) {
        const { order, orderBy } = defaultSortParameters;
        const { scalarExpression } = orderBy;
        if (scalarExpression) {
            sorting = {
                order,
                orderBy: {
                    scalarExpression,
                },
            };
        }
    }
    const validFilters = getValidExpressions(filters, domain);
    return {
        domainId: domain.id,
        filterIds: filterIds || [],
        primaryViewId: listViewId,
        adhocListViewMembers: adhocListViewMembers || [],
        sorting,
        paging: { start: pagingData.start, stop: pagingData.stop },
        summaryMode,
        adhocFilter: MergeFilters([adHocFilter, ...validFilters], 'QueryExecution Filter'),
    };
};
export function getGroupHeaders(groupByGridResultProps) {
    return __awaiter(this, void 0, void 0, function* () {
        const { buildExecuteQueryRequest, groupByFieldName, qbQuery, resultRef } = groupByGridResultProps;
        let { result } = groupByGridResultProps;
        result = yield qbQuery(buildExecuteQueryRequest);
        resultRef.current = result; // resultRef.current will be used when getGroupRows.
        const headerResult = cloneDatum(result);
        headerResult.Data = headerResult.Data.reduce((acc, obj) => {
            const isDuplicate = acc.some((item) => item[groupByFieldName] === obj[groupByFieldName]);
            if (!isDuplicate) {
                acc.push(obj);
            }
            return acc;
        }, []);
        headerResult.TotalRecords = headerResult.Data.length;
        // The header is essentially the same exact data as the first row in the group, therefore it will become "duplicate" row.
        // AG-grid will apply css that will basically remove the header row from the grid.
        // To avoid this removal from happening we need to change the ID and the PrimaryKey in the headerResult data.
        headerResult.Data.forEach((res) => {
            if (Object.hasOwn(res, 'primaryKey')) {
                res.primaryKey = `header_${res.primaryKey}`;
            }
            if (Object.hasOwn(res, 'Id')) {
                res.Id = `header_${res.Id}`;
            }
        });
        return headerResult;
    });
}
export function getGroupRows(groupByGridResultProps) {
    return __awaiter(this, void 0, void 0, function* () {
        const { buildExecuteQueryRequest, groupByFieldName, groupKeys, qbQuery, resultRef } = groupByGridResultProps;
        let { result } = groupByGridResultProps;
        if (resultRef.current == null) {
            resultRef.current = yield qbQuery(buildExecuteQueryRequest);
        }
        result = resultRef.current;
        const groupResult = cloneDatum(result);
        const groupResultData = result === null || result === void 0 ? void 0 : result.Data.filter((obj) => obj[groupByFieldName] === groupKeys.toString());
        groupResult.Data = groupResultData;
        groupResult.TotalRecords = groupResult.Data.length;
        return groupResult;
    });
}
export function groupByGridResult(groupByGridResultProps) {
    return __awaiter(this, void 0, void 0, function* () {
        const { buildExecuteQueryRequest, groupKeys, qbQuery } = groupByGridResultProps;
        let { result } = groupByGridResultProps;
        if (groupKeys && groupKeys.length === 0) {
            // If there are no groupKeys than that means this is our first time coming through. We want to get the headers first.
            return getGroupHeaders(groupByGridResultProps);
        }
        if (groupKeys && groupKeys.length > 0) {
            return getGroupRows(groupByGridResultProps);
        }
        result = yield qbQuery(buildExecuteQueryRequest);
        return result;
    });
}
export function mapQueryResult(result, primaryKey) {
    const viewMembers = result.ViewMembers;
    const data = result.Data;
    const mappedData = [];
    data.forEach((record) => {
        const newData = {
            [primaryKey !== null && primaryKey !== void 0 ? primaryKey : 'primaryKey']: record.primaryKey,
        };
        viewMembers
            .filter((x) => !result.IsSummary || x.summaryExpression)
            .forEach((member) => {
            newData[member.viewFieldName] = record[member.scalarDisplayName];
        });
        // Map hidden values from the original result into the new data
        Object.keys(record).forEach((k) => {
            if (k.startsWith(HIDDEN_PREFIX))
                newData[k] = record[k];
        });
        mappedData.push(newData);
    });
    return Object.assign(Object.assign({}, result), { Data: mappedData });
}
/**
 * This component abstracts the setup of the ExecuteQueryRequest for calling the Query/Execute endpoint. It allows the consuming
 * component to simply pass the core Request parameters as individual props then call RenderProps.doFetch() as needed to execute the request.
 * Also will automatically switch to the ExecuteAdHoc endpoint if an adhocFilter is provided.
 * @param props Component properties.
 */
const QueryExecuteManager = (props) => {
    const { children, domainId, listViewId, filters, adhocListViewMembers: adhocListViewMembersProp, defaultSortParameters, } = props;
    const domainGetter = useDomainGetter();
    const domain = useDomain(domainId);
    const qbQuery = useQBQuery();
    const resultRef = React.useRef(null);
    const queryContext = useQueryContext();
    const [validatedAdHocFilter, setValidatedAdHocFilter] = React.useState();
    React.useEffect(() => {
        if ((queryContext === null || queryContext === void 0 ? void 0 : queryContext.mergeQueryProps) === undefined) {
            // Nothing to update
            return;
        }
        if ((validatedAdHocFilter === null || validatedAdHocFilter === void 0 ? void 0 : validatedAdHocFilter.advancedExpression) === undefined) {
            // No filter to add
            return;
        }
        queryContext.mergeQueryProps({ adhocFilter: Object.assign(Object.assign({}, validatedAdHocFilter), { filterName: 'QueryContext Filter' }) }, undefined, undefined, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatedAdHocFilter]);
    /**
     * Build the request body and then call doFetch() to execute the request.
     * @param summaryMode Tells whether to load the ListView or to load aggregated data
     * @param doFetch The function which will actually execute the request.
     * @param pagingData The paging property of the execute request.
     * @param adHocFilter The adhocFilter property of the execute request.
     * @param filterIds Array of pre-defined filter IDs to apply to the fetch request
     */
    const handleFetch = React.useCallback((summaryMode_1, pagingData_1, adHocFilter_1, filterIds_1, primaryKey_1, adhocListViewMembers_1, pure_1, ...args_1) => __awaiter(void 0, [summaryMode_1, pagingData_1, adHocFilter_1, filterIds_1, primaryKey_1, adhocListViewMembers_1, pure_1, ...args_1], void 0, function* (summaryMode, pagingData, adHocFilter, filterIds, primaryKey, adhocListViewMembers, pure, options = {}) {
        var _a, _b;
        const mergedAdhocListViewMembers = [];
        const { getOrderByExpression, groupKeys, enableGroupByGrid, groupByFieldName } = options;
        // add prop list view members in if not a pure request
        if (!pure && adhocListViewMembersProp) {
            mergedAdhocListViewMembers.push(...adhocListViewMembersProp);
            if (adhocListViewMembers && adhocListViewMembers.length > 0) {
                const propLVMExpressions = new Set(mergedAdhocListViewMembers.map((v) => v.scalarExpression));
                adhocListViewMembers.forEach((lvm) => {
                    if (propLVMExpressions.has(lvm.scalarExpression))
                        return;
                    mergedAdhocListViewMembers.push(lvm);
                });
            }
        }
        else if (adhocListViewMembers)
            mergedAdhocListViewMembers.push(...adhocListViewMembers);
        // use cache where available, fetch otherwise. React query should stitch them together
        const _domain = (_a = domain.data) !== null && _a !== void 0 ? _a : (yield domainGetter(false, domainId));
        if (!_domain)
            throw new Error('Domain not found');
        let _listViewId;
        if (!pure)
            _listViewId = listViewId;
        const buildExecuteQueryRequest = createQueryRequest(_domain, summaryMode, pagingData, filters, adHocFilter, filterIds, _listViewId, mergedAdhocListViewMembers, defaultSortParameters, getOrderByExpression);
        setValidatedAdHocFilter(buildExecuteQueryRequest.adhocFilter);
        let result = null;
        if (enableGroupByGrid) {
            buildExecuteQueryRequest.sorting.orderBy = { scalarExpression: `[${groupByFieldName}]` };
            if (!buildExecuteQueryRequest.adhocListViewMembers) {
                buildExecuteQueryRequest.adhocListViewMembers = [];
            }
            (_b = buildExecuteQueryRequest.adhocListViewMembers) === null || _b === void 0 ? void 0 : _b.push({
                scalarExpression: `[${groupByFieldName}]`,
                scalarDisplayName: groupByFieldName,
            });
            const groupByGridResultProps = {
                buildExecuteQueryRequest,
                groupByFieldName,
                groupKeys,
                result,
                qbQuery,
                resultRef,
            };
            result = yield groupByGridResult(groupByGridResultProps);
        }
        else {
            result = yield qbQuery(buildExecuteQueryRequest);
        }
        return [mapQueryResult(result, primaryKey), buildExecuteQueryRequest];
    }), [
        adhocListViewMembersProp,
        defaultSortParameters,
        domain.data,
        domainGetter,
        domainId,
        filters,
        listViewId,
        qbQuery,
        resultRef.current,
    ]);
    return children({ doFetch: handleFetch });
};
export default QueryExecuteManager;
