import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getChildViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
const CHILD_LISTS_KEY = `${SCREEN_CONFIG_API_KEY}child_lists_key`;
// eslint-disable-next-line import/prefer-default-export
export const useChildViews = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (d) => getChildViews(api.AggregatorApi, d, api.requestInit);
    return useRequest(CHILD_LISTS_KEY, query, ApiContext, domainId);
};
