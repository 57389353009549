var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { toastError, ToastMessage } from '@samc/react-ui-core';
export var FileNameSelection;
(function (FileNameSelection) {
    FileNameSelection[FileNameSelection["SystemCalculated"] = 0] = "SystemCalculated";
    FileNameSelection[FileNameSelection["OriginalUploaded"] = 1] = "OriginalUploaded";
})(FileNameSelection || (FileNameSelection = {}));
export const mapFileNameSpecialChars = (fileName) => {
    let documentName = fileName.replaceAll('/', '-');
    documentName = documentName.replaceAll(/[\\:*?"<>|]/g, '');
    return documentName;
};
export const showErrorPopup = (err) => {
    toastError(React.createElement(ToastMessage, { title: "Download failed", message: err }));
};
const getDefaultHeaders = (token, tenantId) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-type', 'application/json');
    headers.append('X-Tenant', tenantId || '');
    return headers;
};
export const getZipFileDownloadUrl = (selectedRows, token, tenantId, strataApi, fileNameSelection, shouldPrefaceWithEntityName) => __awaiter(void 0, void 0, void 0, function* () {
    let body = {};
    const headers = getDefaultHeaders(token, tenantId);
    const customRows = [
        ...selectedRows.map((row) => {
            const entityNames = shouldPrefaceWithEntityName ? `${row.EntityNames}__` : '';
            return Object.assign(Object.assign({}, row), { CalculatedDocumentName: `${entityNames}${row === null || row === void 0 ? void 0 : row.CalculatedDocumentName}.${row === null || row === void 0 ? void 0 : row.FileName.split('.').pop()}`, FileName: `${entityNames}${row === null || row === void 0 ? void 0 : row.FileName}` });
        }),
    ];
    customRows.forEach((row) => {
        const fileName = (fileNameSelection === FileNameSelection.SystemCalculated ? row === null || row === void 0 ? void 0 : row.CalculatedDocumentName : row === null || row === void 0 ? void 0 : row.FileName);
        if (fileName) {
            const fileParts = fileName.split('.');
            const fileExtension = fileParts.pop();
            const fileIndex = customRows
                .filter((fileRow) => fileName ===
                (fileNameSelection === FileNameSelection.SystemCalculated
                    ? fileRow === null || fileRow === void 0 ? void 0 : fileRow.CalculatedDocumentName
                    : fileRow === null || fileRow === void 0 ? void 0 : fileRow.FileName))
                .findIndex((fileRow) => row.DocumentId === fileRow.DocumentId);
            const appendedIndex = fileIndex <= 0 ? '' : ` (${fileIndex})`;
            const fileBaseName = `${fileParts.join('.')}${appendedIndex}`;
            const customFileName = mapFileNameSpecialChars(`${fileBaseName}.${fileExtension}`);
            body = Object.assign(Object.assign({}, body), { [customFileName]: row.DocumentId });
        }
    });
    return fetch(`${strataApi}/api/${tenantId}/zipfileservice/v1/zip`, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    })
        .then((resp) => __awaiter(void 0, void 0, void 0, function* () {
        const respBlob = yield resp.blob();
        return window.URL.createObjectURL(respBlob);
    }))
        .catch((_err) => {
        showErrorPopup('An error ocurred while downloading the document');
        return '';
    });
});
export const getSingleFileDownloadUrl = (token, selectedRow, tenantId, strataApi) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = getDefaultHeaders(token, tenantId);
    return fetch(`${strataApi}/api/${tenantId}/manager/v1/files/${selectedRow.DocumentId}/_download`, {
        method: 'POST',
        headers,
    })
        .then((resp) => resp.json())
        .then((respJson) => {
        if (respJson && respJson.code === 404) {
            showErrorPopup('Document not found');
            return '';
        }
        return respJson.downloadLink;
    })
        .catch((_err) => {
        showErrorPopup('An error ocurred while downloading the document');
        return '';
    });
});
const getDownloadResponse = (selectedRows, token, tenantId, strataApi, fileNameSelection, shouldPrefaceWithEntityName) => __awaiter(void 0, void 0, void 0, function* () {
    if (selectedRows.length > 1) {
        return getZipFileDownloadUrl(selectedRows, token, tenantId, strataApi, fileNameSelection, shouldPrefaceWithEntityName);
    }
    if (selectedRows.length > 0) {
        return getSingleFileDownloadUrl(token, selectedRows[0], tenantId, strataApi);
    }
    return '';
});
const getFileFromStrataId = (id, token, tenantId, strataApi, documentName) => __awaiter(void 0, void 0, void 0, function* () {
    const body = {};
    body.uuid = id;
    body.fileName = documentName || '';
    const headers = getDefaultHeaders(token, tenantId);
    return fetch(`${strataApi}/api/${tenantId}/manager/v1/files/${id}/_download`, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .catch((err) => JSON.stringify(err));
});
export const getStrataFileInfo = (documentId, token, tenantId, strataApi) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = getDefaultHeaders(token, tenantId);
    return fetch(`${strataApi}/api/${tenantId}/manager/v1/files/${documentId}`, {
        method: 'GET',
        headers,
    })
        .then((resp) => resp.json())
        .then((respJson) => {
        if (respJson && respJson.code === 404) {
            showErrorPopup('Document not found');
            return undefined;
        }
        return respJson;
    })
        .catch((_err) => {
        showErrorPopup('An error ocurred while downloading the document');
        return undefined;
    });
});
const handleFileDownload = (selectedRows_1, token_1, tenantName_1, strataApi_1, zipName_1, ...args_1) => __awaiter(void 0, [selectedRows_1, token_1, tenantName_1, strataApi_1, zipName_1, ...args_1], void 0, function* (selectedRows, token, tenantName, strataApi, zipName, fileNameSelection = FileNameSelection.OriginalUploaded, shouldPrefaceWithEntityName = false) {
    const responseUrl = yield getDownloadResponse(selectedRows, token, tenantName, strataApi, fileNameSelection, shouldPrefaceWithEntityName);
    if (responseUrl) {
        const customZipFileName = zipName ? `${zipName}.zip` : `${selectedRows[0].EntityNames}__Documents.zip`;
        const link = document.createElement('a');
        link.className = 'download-helper';
        link.href = responseUrl;
        if (selectedRows.length > 1) {
            link.setAttribute('download', customZipFileName);
        }
        else if (selectedRows.length > 0) {
            link.setAttribute('download', `${selectedRows[0].FileName}`);
        }
        link.click();
        link.remove();
    }
});
export { getDownloadResponse, handleFileDownload, getFileFromStrataId };
