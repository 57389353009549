import React from 'react';
import { EditingProvider } from '@samc/react-ui-grid';
import {
    ConfigureDomainTab,
    ConfigureDomainTabHeaders,
    DomainDetailPage as InnerDomainDetail,
} from '@samc/screen-config-core';
import { createPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { useRouterBlockerAndPromptForUnsavedChanges } from '@samc/react-ui-history';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { RoutingWrapper } from '@samc/react-ui-history/lib/molecules/RoutingWrapper/RoutingWrapper';
import { DomainPicker } from '@samc/screen-config-core/lib/molecules/controls/DomainPicker/DomainPicker';
import { Entitlements, useCurrentUser } from '@samc/single-spa-authentication';

interface InnerDomainDetailPageProps {
    domainId: string | undefined;
    initialSelectedTabId: ConfigureDomainTab | undefined;
    /**
     * Called when ConfigureDomain changes the tab (not the user)
     */
    onTabChanged: (newId: ConfigureDomainTab) => void;
    onDomainChange: (newId?: string) => void;
}

const InnerDomainDetailPage = (props: InnerDomainDetailPageProps): React.ReactElement => {
    const { initialSelectedTabId, onDomainChange, onTabChanged, domainId } = props;

    const { reset: resetHeader, setValue: setHeaderValue } = useHeaderContext();

    // run before other useEffects
    React.useLayoutEffect(() => {
        resetHeader(); // reset on load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useRouterBlockerAndPromptForUnsavedChanges();

    const PagePicker = React.useCallback(
        () => (
            <DomainPicker
                id={domainId}
                onDomainChange={(domain): unknown => onDomainChange(domain?.id as string | undefined)}
            />
        ),
        [domainId, onDomainChange],
    );

    React.useEffect(() => {
        setHeaderValue('pageTitle', PagePicker);
    }, [PagePicker, setHeaderValue]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {domainId && (
                <InnerDomainDetail
                    onTabChanged={onTabChanged}
                    initialSelectedTabId={initialSelectedTabId}
                    domainId={domainId}
                />
            )}
        </div>
    );
};

const DomainDetailPage = (): React.ReactElement => {
    const navigate = useNavigate();
    const { pathname, search, hash } = useLocation();
    const { tabId: urlTabId, id: urlDomainId } = useParams();

    const onTabIdChanged = React.useCallback(
        (newId: string, userRequested?: boolean) => {
            const oldId = urlTabId;
            if (newId === oldId) return;

            let newPath: string;
            if (oldId) {
                newPath = pathname.replace(new RegExp(`/${oldId}(/|$)`), (_, end) => `/${newId}${end}`);
            } else {
                newPath = pathname.replace(/\/{0,1}$/, `/${newId}`);
            }

            const newUrl = createPath({ pathname: newPath, search, hash });
            navigate(newUrl, { replace: !userRequested });
        },
        [hash, navigate, pathname, search, urlTabId],
    );

    const onTabIndexChanged = React.useCallback(
        (newIndex: number, userRequested: boolean) => {
            const tab = ConfigureDomainTabHeaders[newIndex];
            if (!tab) return;

            onTabIdChanged(tab.id, userRequested);
        },
        [onTabIdChanged],
    );

    const onDomainChange = React.useCallback(
        (domainId?: string) => {
            if (urlDomainId) {
                navigate(
                    pathname.replace(new RegExp(`/${urlDomainId}(/|$)`), (_, end) =>
                        domainId ? `/${domainId}${end}` : '',
                    ),
                );
            } else {
                navigate(pathname.replace(/\/{0,1}$/, `/${domainId ?? ''}`));
            }
        },
        [navigate, pathname, urlDomainId],
    );

    const user = useCurrentUser();

    if (!user.isLoaded) return <div />;

    if (!user.hasEntitlement(Entitlements.ConfigurationManagement.ManageDomainConfigurations))
        return (
            <div>
                <MessageBar
                    messageBarType={MessageBarType.error}
                    title="You are not authorized to view this page"
                    text="If you believe this is an error, please contact your administrator"
                />
            </div>
        );

    return (
        <EditingProvider>
            <RoutingWrapper onTabChanged={onTabIndexChanged}>
                <InnerDomainDetailPage
                    onDomainChange={onDomainChange}
                    onTabChanged={onTabIdChanged}
                    initialSelectedTabId={urlTabId as ConfigureDomainTab | undefined}
                    domainId={urlDomainId}
                />
            </RoutingWrapper>
        </EditingProvider>
    );
};

export default DomainDetailPage;
