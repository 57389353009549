import React from 'react';
import { getFormTabPdf } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { ApiContext } from '../ApiContext';
/**
 * @returns a callback for generating and downloading a form tab pdf export using the specified parameters
 */
export const useFormTabExporter = () => {
    const api = React.useContext(ApiContext);
    const fn = (p) => getFormTabPdf(api.AggregatorApi, p, api.requestInit);
    const [generateExport] = useMutation(fn);
    return generateExport;
};
export default useFormTabExporter;
