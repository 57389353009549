// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormSkeletonMappers } from '../mappers/FormSkeletonMappers/FormSkeletonMappers';
/**
 * This is an implementation of the FormSkeleton interface to help in mapping from FormView
 * to FormSkeleton and back.
 *
 * @deprecated - use {@link FormSkeletonMappers}
 */
export class FormGridConfigurationViewModel {
    constructor(gridField, viewName) {
        /**
         * Convert back to FormViewGridField for saving, most likely.
         * @deprecated use {@link FormSkeletonMappers}
         */
        this.toFormViewGridField = (gridSkeleton) => {
            return FormSkeletonMappers.GridConfiguration.toFormViewGridField(gridSkeleton);
        };
        this.field = gridField.field;
        this.idField = 'id';
        this.viewId = gridField.viewId;
        this.domainId = gridField.domainId;
        this.sourceField = gridField.sourceField;
        this.targetField = gridField.targetField;
        this.viewName = viewName;
        this.rowsPerPage = gridField.rowsPerPage;
        this.height = gridField.height;
        this.isEditingDefault = gridField.isEditingDefault;
        this.defaultRowSpacing = gridField.defaultRowSpacing;
        this.suppressExcelExport = gridField.suppressExcelExport;
        this.suppressAddRowButton = gridField.suppressAddRowButton;
        this.suppressEditToggle = gridField.suppressEditToggle;
        this.suppressColumnFilters = gridField.suppressColumnFilters;
        this.suppressFullScreen = gridField.suppressFullScreen;
        this.suppressRowSpacing = gridField.suppressRowSpacing;
        this.baselineFilterExpression = gridField.baselineFilterExpression;
        this.relationshipType = gridField.relationshipType;
        this.wrapText = gridField.wrapText;
        this.addRowButtonEnabledExpression = gridField.addRowButtonEnabledExpression;
    }
}
FormGridConfigurationViewModel.fromFormGridConfiguration = (fgc, viewName) => {
    const objectInstance = Object.create(FormGridConfigurationViewModel);
    Object.assign(objectInstance, fgc); // since these models are 1-1 this is easy
    objectInstance.viewName = viewName;
    return objectInstance;
};
export default FormGridConfigurationViewModel;
