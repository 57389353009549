import React from 'react';
import { ProgressIndicator } from '@fluentui/react';
import { FileUploader } from '../FileUploader/FileUploader';
export const FileUploadModal = (props) => {
    const { isOpen, isReady = true, token, tenantId, strataUrl, model, columns, fileManagerUrl, fileMetadataService, onStrataDataChanged, isSavingData = false, customGridOptions, uploadColumnAtLast, disableWorker, files, dropzoneGridDefs, strataActions, panelTitle, children, className, onHandleGridReadyEvent, customProps, } = props;
    return (React.createElement("div", null, isOpen && (React.createElement("div", null,
        isSavingData && React.createElement(ProgressIndicator, null),
        children && React.createElement("div", { style: { marginBottom: '10px' } }, children),
        React.createElement(FileUploader, { token: token, tenantId: tenantId, strataUrl: strataUrl, isReady: isReady, fileManagerUrl: fileManagerUrl, fileMetadataService: fileMetadataService, columns: columns, model: model, onStrataDataChanged: onStrataDataChanged, customGridOptions: customGridOptions, uploadColumnAtLast: uploadColumnAtLast, disableWorker: disableWorker, files: files, dropzoneGridDefs: dropzoneGridDefs, strataActions: strataActions, className: className, onHandleGridReadyEvent: onHandleGridReadyEvent, panelTitle: panelTitle, customProps: customProps }, children && React.createElement("div", null, children))))));
};
export default FileUploadModal;
