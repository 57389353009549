import React from 'react';
import { getExportGenerator } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { ApiContext } from '../ApiContext';
/**
 * @returns a callback for generating and downloading a report using the specified parameters
 */
export const useExportGenerator = () => {
    const api = React.useContext(ApiContext);
    const generator = getExportGenerator(api.ReportingApi, api.requestInit);
    const [generateExport] = useMutation(generator);
    return generateExport;
};
export default useExportGenerator;
