/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Modal } from '@fluentui/react';
import { useFormViewSaver, useFieldConfigurationByDomain, useFieldConfigurationByParentDomains, FieldConfigurationMemberLookup, } from '@samc/screen-config-api';
import { InputType, LayoutType } from '@samc/react-ui-form';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { ErrorBoundary, toastError, ToastMessage } from '@samc/react-ui-core';
import { FormBuilderWorkspace, FormType, } from '@samc/react-ui-form-builder';
import { ServersideFolderViewer, isFolder, } from '@samc/folder-ui-core';
import { cloneDatum } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { mapDomainFieldsToFormViewMembers } from '../../../../viewModel/FormSkeletonViewModel';
import './FormViewEditor.css';
import { useLinkConfiguration } from '../../../../hooks/useLinkConfiguration/useLinkConfiguration';
import { useSelectAndShowConfiguration } from '../../../../hooks/useSelectAndShowConfiguration/useSelectAndShowConfiguration';
import { getExpressionFormField } from '../../../../atoms/ExpressionField/ExpressionField';
import { getGridDataFormRenderer } from '../../../../atoms/FormGridFieldConfigurationForm/FormGridFieldConfigurationForm';
import { FormSkeletonMappers } from '../../../../mappers/FormSkeletonMappers/FormSkeletonMappers';
import { FORM_TAB_PDF_GENERATION_FIELDS, FORM_TAB_PDF_GENERATION_SECTION } from './PDFGeneration/Utilities';
import { mapFormViewMember } from '../../../../hooks/useFormSkeletonFromFormView/Utils';
export const FormViewEditor = ({ childGrids, currentEditingForm, currentEditingFormId, domainFields, domainId, getContextMenuItems, initialEditingForm, moveToFolderId, saveFormToTab, setEditingFormSkeleton, }) => {
    const isSelectMode = !!saveFormToTab;
    const [saveFormView] = useFormViewSaver();
    const { data: fieldConfigurations } = useFieldConfigurationByDomain(domainId);
    const { data: parentFieldConfigurations } = useFieldConfigurationByParentDomains(domainId);
    const folderViewerRef = React.useRef(null);
    const selectAndShowConfiguration = useSelectAndShowConfiguration(domainId);
    const targetFieldConfigurationId = React.useMemo(() => {
        const [firstFieldConfig] = fieldConfigurations !== null && fieldConfigurations !== void 0 ? fieldConfigurations : [];
        return firstFieldConfig === null || firstFieldConfig === void 0 ? void 0 : firstFieldConfig.id;
    }, [fieldConfigurations]);
    /**
     * User selected a FormView from the list.
     * @param formView The selected FormView
     */
    const onRowSelected = (item) => {
        if (!saveFormToTab)
            return;
        // if saveFormToTab provided, user cannot select folders
        const { body: formViewJson } = item;
        const formView = Object.assign(Object.assign({}, item), JSON.parse(formViewJson));
        saveFormToTab(formView);
    };
    /**
     * Callback when the user edits the FormView.
     * @param newForm The updated FormSkeleton.
     */
    const onFormChanged = React.useCallback((newForm) => {
        setEditingFormSkeleton((cur) => (Object.assign(Object.assign({}, cur), { currentForm: newForm })));
    }, [setEditingFormSkeleton]);
    /**
     * User selected the save button.
     */
    const onSaveFormModal = React.useCallback(() => {
        if (!currentEditingForm || !targetFieldConfigurationId)
            return undefined;
        if (currentEditingForm && !currentEditingForm.title) {
            toastError(React.createElement(ToastMessage, { title: "Missing Form Title", message: "Form Title is required" }));
            return undefined;
        }
        return saveFormView(FormSkeletonMappers.FormSkeleton.toFormView(currentEditingForm, {
            domainId,
            fieldConfigurationId: targetFieldConfigurationId,
            formViewId: currentEditingFormId,
            folder: moveToFolderId ? { folderGuid: moveToFolderId } : undefined,
        })).then((res) => {
            var _a;
            (_a = folderViewerRef.current) === null || _a === void 0 ? void 0 : _a.reload();
            setEditingFormSkeleton({});
            return res;
        });
    }, [
        currentEditingForm,
        currentEditingFormId,
        domainId,
        moveToFolderId,
        saveFormView,
        setEditingFormSkeleton,
        targetFieldConfigurationId,
    ]);
    const linkConfiguration = useLinkConfiguration(domainId, initialEditingForm === null || initialEditingForm === void 0 ? void 0 : initialEditingForm.fields);
    const gridGroup = React.useMemo(() => {
        const options = childGrids.map((c) => {
            var _a;
            return ({
                displayText: `${c.domainId}`,
                value: c.domainId,
                data: c,
                hoverText: `Domain: ${c.domainId}, Default View: ${(_a = c.viewName) !== null && _a !== void 0 ? _a : 'Default'}`,
            });
        });
        return {
            id: 'grids',
            title: 'Grids',
            options,
        };
    }, [childGrids]);
    const availableFields = React.useMemo(() => {
        if (!domainFields)
            return {};
        const lookup = new FieldConfigurationMemberLookup(fieldConfigurations, parentFieldConfigurations);
        const formViewMembers = mapDomainFieldsToFormViewMembers(domainFields, lookup);
        const innerFields = Object.entries(formViewMembers).reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur[0]]: mapFormViewMember(cur[1]) })), {});
        return Object.assign({}, innerFields);
    }, [domainFields, fieldConfigurations, parentFieldConfigurations]);
    const fieldsTransformer = React.useCallback((_fields, type) => {
        let fields = _fields;
        if (type === FormType.Tab) {
            fields = cloneDatum(fields);
            fields = Object.assign(Object.assign({}, fields), FORM_TAB_PDF_GENERATION_FIELDS);
        }
        return fields;
    }, []);
    const fieldTransformer = React.useCallback((_field) => {
        let field = _field;
        if (field.inputType === InputType.MultilineExpression || field.inputType === InputType.Expression) {
            field = cloneDatum(field);
            const { extraProps, field: fieldName } = field;
            const { option, domainId: fieldDomainId } = (extraProps || {});
            let expressionFieldDomainId = domainId;
            if (fieldDomainId)
                expressionFieldDomainId = fieldDomainId;
            // S&S hyperlinks/tooltips go on the S&S domain, but not enabled rule.
            else if (fieldName !== 'ssEnabledRule' && (option === null || option === void 0 ? void 0 : option.id))
                expressionFieldDomainId = option.id;
            return Object.assign(Object.assign({}, field), { onRenderField: getExpressionFormField(String(expressionFieldDomainId), field.field) });
        }
        return field;
    }, [domainId]);
    const tabTransformer = React.useCallback((tab, type) => {
        let _tab = tab;
        if (type === FormType.Tab) {
            _tab = cloneDatum(tab);
            // add tab export form
            const sectionIndex = _tab.sections.push(FORM_TAB_PDF_GENERATION_SECTION) - 1;
            _tab.layout.children.push({ index: sectionIndex, children: [], type: LayoutType.VerticalStack });
        }
        return _tab;
    }, []);
    return (React.createElement("div", { style: { width: '100%', height: '100%' } },
        React.createElement(ServersideFolderViewer, { ref: folderViewerRef, spaceName: domainId, type: "FormView", typeDisplayName: "Form View", typeDisplayNamePlural: "Form Views", getContextMenuItems: getContextMenuItems, onRowSelected: onRowSelected, multiselect: !isSelectMode, isRowSelectable: (r) => !isSelectMode || !isFolder(r) }),
        initialEditingForm && (React.createElement(Modal, { forceFocusInsideTrap: false, onDismiss: () => setEditingFormSkeleton({}), isOpen: true, isBlocking: false, layerProps: { eventBubblingEnabled: true } },
            React.createElement("div", { style: {
                    height: 'calc(100vh - 32px)',
                    width: 'calc(100vw - 32px)',
                    overflowY: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                } },
                React.createElement(SitusHeader, { title: "Edit Form", confirmationButtonText: "Save", onConfirmationButtonClicked: onSaveFormModal, rejectionButtonText: "Cancel", onRejectionButtonClicked: () => setEditingFormSkeleton({}) }),
                React.createElement(ErrorBoundary, null,
                    React.createElement(FormBuilderWorkspace, { initialForm: initialEditingForm, availableFields: availableFields, onFormChanged: onFormChanged, gridGroup: gridGroup, linkConfiguration: linkConfiguration, selectAndShowConfiguration: selectAndShowConfiguration, fieldTransformer: fieldTransformer, renderGridDataForm: getGridDataFormRenderer(domainId), tabTransformer: tabTransformer, fieldsTransformer: fieldsTransformer })))))));
};
export default FormViewEditor;
