var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiContext } from '@samc/screen-config-api';
import { validateContactAssignments } from '@samc/screen-config-api/lib/api/Requests';
import React from 'react';
const domainName = 'VALU_ValuationContact';
export const useContactAssignmentValidations = () => {
    const api = React.useContext(ApiContext);
    const callback = React.useCallback((domain, id) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const url = domain === domainName ? `${api.ValuationApi}` : `${api.CompanyCatalogApi}`;
        try {
            const responseJson = yield validateContactAssignments(url, domain, id, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {});
            const messages = responseJson === null || responseJson === void 0 ? void 0 : responseJson.map((element) => ({
                validationMessage: element.validationMessage,
            }));
            return messages;
        }
        catch (e) {
            api.ShowMessage(`Failed to fetch ${domain === domainName ? 'Valuation' : ''} Contact ${domain === domainName ? '' : 'Assignment'} Validations`);
            return [];
        }
    }), [api]);
    return callback;
};
export default useContactAssignmentValidations;
