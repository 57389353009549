import { InputType, CalculationFrequency, } from '@samc/screen-config-api';
import { getRowNodeAndApi, withGridApi } from '@samc/react-ui-grid';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { PicklistDisplaySetting } from '@samc/picklist-api';
import { GridType } from '@samc/react-ui-grid/lib/organisms/BaseGrid/AddedRowGrid/Types';
import { getDataDrivenRuleOverridesForField } from '../../utils/AuthUtils/AuthUtils';
export const mapDataSourceTypeToGridType = (lvm, domainFields) => {
    var _a;
    if (!lvm)
        return 'string';
    const { inputType, viewFieldName } = lvm;
    switch (inputType) {
        case InputType.Checkbox:
            return 'boolean';
        case InputType.Date:
            return 'date';
        case InputType.DateTime:
            return 'datetime';
        case InputType.Number:
            return 'number';
        case InputType.Time:
            return 'time';
        case InputType.YesNo:
            return 'yes/no';
        case InputType.Wysiwyg:
            return 'wysiwyg';
        case InputType.Percent:
            return 'percent';
        case InputType.MultilineText:
        case InputType.Text:
        case InputType.Expression:
        case InputType.FormView:
        case InputType.ListView:
            return 'string';
        default:
            break;
    }
    const dsType = (_a = domainFields === null || domainFields === void 0 ? void 0 : domainFields.find((df) => df.fieldName === viewFieldName)) === null || _a === void 0 ? void 0 : _a.datasourceDataType;
    if (!dsType)
        return undefined;
    const sqlType = dsType.toLowerCase();
    if (sqlType.includes('date') || sqlType.includes('time'))
        return 'date';
    if (sqlType.includes('bit') ||
        sqlType.includes('int') ||
        sqlType.includes('bool') ||
        sqlType.includes('float') ||
        sqlType.includes('double') ||
        sqlType.includes('dec'))
        return 'number';
    return 'string';
};
export const getDisplayFormat = (displayFormat, formatId, _) => {
    if (displayFormat && displayFormat !== 'default')
        return displayFormat;
    if (formatId)
        return formatId;
    return displayFormat;
};
const getWrapText = (member, wrapText) => {
    return member.inputType === InputType.MultilineText || member.inputType === InputType.Wysiwyg
        ? wrapText
        : undefined;
};
export const mapListViewMemberToGridField = (member, wrapText) => {
    var _a;
    return (Object.assign(Object.assign({ field: member.viewFieldName, headerName: member.scalarDisplayName, displayNameRule: member.displayNameExpression, displayFormat: getDisplayFormat(member.displayFormat, member.formatId, member.inputType), width: member.width === 0 ? undefined : member.width, editableRule: member.editableExpression, visibleRule: member.visibleExpression, requiredFieldRule: member.requiredExpression, isKeyField: member.isKeyField, defaultValueRule: member.defaultValueExpression, hexFillRule: member.hexFillRule, tooltip: member.tooltip, expression: member.scalarExpression, autoHeight: getWrapText(member, wrapText), wrapText: getWrapText(member, wrapText), editor: member.editor, horizontalAlignment: member.horizontalAlignment, filter: !member.suppressFilter && member.filter }, (member.calculationFrequency !== CalculationFrequency.Never &&
        member.calculationRule && {
        calculationCondition: member.calculationFrequency === CalculationFrequency.Conditionally
            ? member.calculationCondition
            : undefined,
        calculationRule: member.calculationRule,
    })), { getDataDrivenRuleOverrides: getDataDrivenRuleOverridesForField((_a = member.scalarDisplayName) !== null && _a !== void 0 ? _a : member.viewFieldName) }));
};
/**
 * Maps list view members to grid fields, using domainfields to inject the field types.
 */
export const mapListViewMembersToGridFields = (members, domainFields, wrapText) => {
    return members.map((lvm, index, self) => {
        let gf;
        if (index !== self.findIndex((t) => t.viewFieldName === lvm.viewFieldName)) {
            gf = mapListViewMemberToGridField(Object.assign(Object.assign({}, lvm), { viewFieldName: '' }), wrapText);
        }
        else {
            gf = mapListViewMemberToGridField(lvm, wrapText);
        }
        gf.type = mapDataSourceTypeToGridType(lvm, domainFields);
        return gf;
    });
};
/**
 * Returns true if both error messages have the same fields
 * @param serverErrorFields
 * @param clientErrorFields
 */
export const haveSameFields = (serverErrorFields, clientErrorFields) => {
    return (Array.isArray(serverErrorFields) &&
        Array.isArray(clientErrorFields) &&
        serverErrorFields.length === clientErrorFields.length &&
        serverErrorFields.every((val, index) => val === clientErrorFields[index]));
};
/**
 * Converts task gateway errors to clientside errors
 */
export const convertServerErrors = (errors) => errors.map((e) => {
    var _a;
    return {
        code: e.code,
        title: e.title,
        detail: e.description,
        fields: e.fields,
        type: e.type,
        id: (_a = e.recordIdentifier) === null || _a === void 0 ? void 0 : _a.toString(),
    };
});
/**
 * Condenses a bunch of batches of errors (client, server, parent, etc.) into one array
 * without duplicates
 * @returns
 */
export const mergeErrors = (...errorBatches) => {
    const [initial, ...rest] = errorBatches;
    if (!initial)
        return [];
    const mapping = initial.reduce((all, cur) => {
        var _a;
        const index = `${cur.rowIndex}${cur.code}${(_a = cur.fields) === null || _a === void 0 ? void 0 : _a.join('')}`;
        return Object.assign(Object.assign({}, all), { [index]: cur });
    }, {});
    rest === null || rest === void 0 ? void 0 : rest.forEach((batch) => {
        batch.forEach((x) => {
            var _a;
            const index = `${x.rowIndex}${x.code}${(_a = x.fields) === null || _a === void 0 ? void 0 : _a.join('')}`;
            mapping[index] = x;
        });
    });
    return Object.values(mapping);
};
/**
 * Helper function to get the RowNode with a given id from the grid.
 * Will attempt to convert `id` to a Number if no matching row is found using the default string.
 * @param gridApi
 * @param id
 * @param idField this is the index of the data object where the ID can be found. Omitting this will
 *                cause the pinned rows not to be searched.
 */
export const getGridRowNode = (gridApi, id, idField) => {
    let rowNodeData = gridApi === null || gridApi === void 0 ? void 0 : gridApi.getRowNode(String(id));
    // If no row was found and the id can be parsed as a number
    if (!rowNodeData && Number.isFinite(Number(id))) {
        // getRowNode expects a string, but grid data may be using a numeric id.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rowNodeData = gridApi === null || gridApi === void 0 ? void 0 : gridApi.getRowNode(Number(id));
    }
    // manually search the pinned to top rows, we do this b/c the id is
    // not properly set by AgGrid on these new pinned rows and .getRowNode does not
    // work.
    if (!rowNodeData && idField) {
        const topRowCount = gridApi.getPinnedTopRowCount();
        let i = 0;
        for (i; i < topRowCount; i++) {
            const rowNode = gridApi.getPinnedTopRow(i);
            if (rowNode && String(rowNode.data[idField]) === id) {
                rowNodeData = rowNode;
                break;
            }
        }
    }
    // manually search rows pinned to the bottom
    if (!rowNodeData && idField) {
        const bottomRowCount = gridApi.getPinnedBottomRowCount();
        let i = 0;
        for (i; i < bottomRowCount; i++) {
            const rowNode = gridApi.getPinnedBottomRow(i);
            if (rowNode && String(rowNode.data[idField]) === id) {
                rowNodeData = rowNode;
                break;
            }
        }
    }
    return rowNodeData !== null && rowNodeData !== void 0 ? rowNodeData : undefined;
};
/**
 * @param message the message for which to get info
 * @param apis the grid api(s)
 * @param primaryKey the primary key of the related record
 * @param rowIndex the row index of the message record, if it relates to one
 * @returns updated message info
 */
export const getUpdatedMessageInfo = (message, apis, primaryKey, 
/**
 * @deprecated primary key field no longer needed
 */
_, rowIndex) => {
    var _a;
    let newTitle = message.title;
    let apparentRowIndex;
    const fieldNames = message.fields || [];
    let row;
    withGridApi(apis, GridType.Body, (api, { adds: addApis, body: bodyApis }) => {
        const headerName = fieldNames
            .map((fn) => {
            try {
                const columnDef = api.getColumnDef(fn);
                if (!columnDef)
                    return fn;
                const { headerName: colHeader } = columnDef;
                return colHeader;
            }
            catch (__) {
                let res = '';
                newTitle.replace(/^'.+'/, (v) => {
                    res = v.slice(1, v.length - 1);
                    return v;
                });
                return res;
            }
        })
            .join(',');
        // find with pk
        if (!isNullOrUndefined(primaryKey)) {
            // find record
            const res = getRowNodeAndApi(String(primaryKey), apis);
            if (res) {
                const { gridApi, node: rowNode } = res;
                row = {
                    api: gridApi,
                    node: rowNode,
                };
            }
        }
        else if (!isNullOrUndefined(rowIndex)) {
            // find with index
            if (newTitle.startsWith('Top') && addApis) {
                // use adds
                const node = addApis.gridApi.getDisplayedRowAtIndex(rowIndex);
                if (node) {
                    row = {
                        node,
                        api: addApis.gridApi,
                    };
                }
            }
            else if (bodyApis) {
                let node;
                if (newTitle.startsWith('Bottom'))
                    node = bodyApis.gridApi.getPinnedBottomRow(rowIndex);
                else
                    node = bodyApis.gridApi.getDisplayedRowAtIndex(rowIndex);
                if (node) {
                    row = {
                        node,
                        api: bodyApis.gridApi,
                    };
                }
            }
        }
        // unquote the header name if quoted
        if (headerName)
            newTitle = newTitle.replace(/^'.+'/, headerName);
        const titlePieces = [];
        if (row)
            titlePieces.push(newTitle);
        else
            titlePieces.push(newTitle.replace(/^.*[0-9]\s+-?\s+/, ''));
        if (row && !isNullOrUndefined(row.node.rowIndex)) {
            let numAddedRows = 0;
            if (addApis && row.api !== addApis.gridApi) {
                // only add offset if not in added rows
                numAddedRows = addApis.gridApi.getDisplayedRowCount();
            }
            apparentRowIndex = numAddedRows + row.node.rowIndex;
        }
        const userFriendlyRowIndex = isNullOrUndefined(apparentRowIndex) ? '-' : apparentRowIndex + 1;
        // strip 'Top Row 1 - ' in favor of clientside prefix
        newTitle = `Row ${userFriendlyRowIndex} - ${newTitle.replace(/^.*[0-9]\s+-?\s+/, '')}`;
    });
    return {
        apparentRowIndex,
        title: newTitle,
        rowIndex: (_a = row === null || row === void 0 ? void 0 : row.node.rowIndex) !== null && _a !== void 0 ? _a : undefined,
        rowPinned: row === null || row === void 0 ? void 0 : row.node.rowPinned,
        row,
    };
};
/**
 * Iterates through the given errors and adds Screen-Config specific
 * styling and properties. Can process clientside errors from BaseGrid.
 */
export const mapErrors = (errors, primaryKeyField, gridApis) => {
    return errors.map((error) => {
        const { id, fields: fieldNames } = error;
        const { title, row } = getUpdatedMessageInfo(error, gridApis, id, primaryKeyField, error.rowIndex);
        const errorOut = Object.assign(Object.assign({}, error), { title });
        if (row) {
            if (!isNullOrUndefined(row.node.rowIndex))
                errorOut.rowIndex = row.node.rowIndex;
            const nodeId = row.node.id;
            if (fieldNames && nodeId) {
                errorOut.onClick = () => {
                    // we fetch the node in here to ensure we don't have stale data (ex: add grid gets re-mounted often)
                    const _res = getRowNodeAndApi(nodeId, gridApis);
                    if (!_res)
                        return;
                    const { gridApi: _api, node: _node } = _res;
                    _api.ensureColumnVisible(fieldNames[0]);
                    if (!isNullOrUndefined(_node.rowIndex)) {
                        _api.startEditingCell({
                            rowIndex: _node.rowIndex,
                            colKey: fieldNames[0],
                            rowPinned: _node.rowPinned,
                        });
                    }
                };
            }
        }
        return errorOut;
    });
};
/**
 * Iterates through the given Task Message and adds Screen-Config specific
 * styling and properties.
 */
export const mapTaskMessages = (errors, primaryKeyField, gridApis) => {
    return errors.map((error) => {
        const { recordIdentifier, fields: fieldNames } = error;
        const { title, row } = getUpdatedMessageInfo(error, gridApis, recordIdentifier, primaryKeyField);
        const errorOut = Object.assign(Object.assign({}, error), { id: String(recordIdentifier), title });
        if (row) {
            const { api, node } = row;
            if (!isNullOrUndefined(node.rowIndex))
                errorOut.rowIndex = node.rowIndex;
            if (fieldNames) {
                errorOut.onMouseUp = () => {
                    api.ensureColumnVisible(fieldNames[0]);
                    if (!isNullOrUndefined(node.rowIndex)) {
                        api.startEditingCell({
                            rowIndex: node.rowIndex,
                            colKey: fieldNames[0],
                            rowPinned: node.rowPinned,
                        });
                    }
                };
            }
        }
        return errorOut;
    });
};
/**
 * Combine GridDisplayOptions with ListViewControlBarVisibilities and return a unified object with
 * properly prioritized settings values.
 * @param gridDisplayOptions
 * @param controlVisibilities
 * @returns An object with the combined keys of the gridDisplayOptions and the controlVisibilities
 */
export const mergeDisplayOptions = (gridDisplayOptions, controlVisibilities) => {
    /**
     * Helper function to combine conflicting boolean settings for GridDisplayOptions and ListViewControlVisibilities
     *
     * @param key Object key to evaluate against gridDisplayOptions and controlVisibilities
     * @returnsthe boolean sum of the two settings, or undefined if both are undefined.
     */
    const coalesce = (key) => {
        const gridOption = Reflect.get(gridDisplayOptions, key);
        const controlVisibilitiesOption = Reflect.get(controlVisibilities || {}, key);
        return gridOption === undefined && controlVisibilitiesOption === undefined
            ? undefined
            : !!(gridOption || controlVisibilitiesOption);
    };
    return {
        defaultRowSpacing: gridDisplayOptions.defaultRowSpacing,
        isEditingDefault: gridDisplayOptions.isEditingDefault,
        rowSelection: gridDisplayOptions.rowSelection,
        rowsPerPage: gridDisplayOptions.rowsPerPage,
        suppressAddRowButton: coalesce('suppressAddRowButton'),
        suppressAggregateToggle: coalesce('suppressAggregateToggle'),
        suppressColumnFilters: coalesce('suppressColumnFilters'),
        suppressDeleteButton: coalesce('suppressDeleteButton'),
        suppressDeleteConfirmationModal: coalesce('suppressDeleteConfirmationModal'),
        suppressEditToggle: coalesce('suppressEditToggle'),
        suppressExcelExport: coalesce('suppressExcelExport'),
        suppressFullScreen: coalesce('suppressFullScreen'),
        suppressRefresh: coalesce('suppressRefresh'),
        suppressRowSpacing: coalesce('suppressRowSpacing'),
        suppressSelectionCheckbox: coalesce('suppressSelectionCheckbox'),
        suppressViewSelector: coalesce('suppressViewSelector'),
        wrapText: coalesce('wrapText'),
    };
};
export const createSubmissionPromise = (submission) => new Promise((res) => {
    // eslint-disable-next-line no-param-reassign
    submission.current = res;
});
/**
 * Saves the current selected list view to local storage
 * @param params the parameters necessary to save the value
 */
export const saveSelectedListView = (params) => {
    const { scope, selectedListViewId, defaultListViewId } = params;
    const storageKey = `LISTVIEW_${scope}`;
    if (selectedListViewId === defaultListViewId)
        localStorage.removeItem(storageKey);
    else
        localStorage.setItem(storageKey, selectedListViewId);
};
/**
 * Gets the appropriate selected list view from local storage
 * @param params the parameters needed to retrieve the value from storage
 * @returns the list view id selected
 */
export const getSelectedListViewId = (params) => {
    const { scope, defaultListViewId } = params;
    const storageKey = `LISTVIEW_${scope}`;
    const savedSelection = localStorage.getItem(storageKey);
    if (isNullOrUndefined(savedSelection))
        return defaultListViewId;
    return savedSelection;
};
/**
 * Saves the current selected filter ids to local storage
 * @param params the parameters necessary to save the value
 */
export const saveSelectedFilter = (params) => {
    const { scope, selectedFilterIds, defaultFilterIds } = params;
    const storageKey = `FILTER_${scope}`;
    if (defaultFilterIds &&
        selectedFilterIds.length === defaultFilterIds.length &&
        defaultFilterIds.every((fId) => selectedFilterIds.includes(fId)))
        localStorage.removeItem(storageKey);
    else
        localStorage.setItem(storageKey, JSON.stringify(selectedFilterIds));
};
/**
 * Gets the appropriate selected filter ids from local storage
 * @param params the parameters needed to retrieve the value from storage
 * @returns the filter ids selected
 */
export const getSelectedFilterIds = (params) => {
    const { scope, defaultFilterIds } = params;
    const storageKey = `FILTER_${scope}`;
    const savedSelection = localStorage.getItem(storageKey);
    if (isNullOrUndefined(savedSelection))
        return defaultFilterIds !== null && defaultFilterIds !== void 0 ? defaultFilterIds : [];
    return JSON.parse(savedSelection);
};
export const getOrderByExpressionGetter = (fieldConfigurationMembers) => (field) => {
    const targetMember = fieldConfigurationMembers[field];
    if (!targetMember)
        return `[${field}]`;
    const { picklistField, inputType } = targetMember;
    if (inputType !== InputType.Picklist || !picklistField)
        return `[${field}]`;
    const { displaySetting } = picklistField;
    switch (displaySetting) {
        case PicklistDisplaySetting.LongName:
        case PicklistDisplaySetting.CustomLongName:
            return `IIF(ISNULL([${field}]), NULL, GetPicklistLongName([${field}]))`;
        case PicklistDisplaySetting.ShortName:
        case PicklistDisplaySetting.CustomShortName:
            return `IIF(ISNULL([${field}]), NULL, GetPicklistShortName([${field}]))`;
        default:
            return `[${field}]`;
    }
};
export const getSortParameters = (_, __, sortModel) => {
    const sortedFields = new Set();
    const allSorts = [];
    if (sortModel) {
        sortModel.forEach((smi) => {
            const { sort: order, colId: orderBy } = smi;
            sortedFields.add(orderBy);
            allSorts.push({
                order,
                orderBy,
            });
        });
    }
    return allSorts;
};
