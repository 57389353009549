var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Grid, ConvertGridFilterToFilterExpression, GetFilterModelForTextFilter, useGridApis, ADDED_FLAG, } from '@samc/react-ui-grid';
import { handleFileDownload, FileDownloadModal } from '@samc/screen-config-docs';
import { ErrorMessageType, GetValueFromData, ToastMessage, isNullOrUndefined, toastSuccess, useDirtinessPrompt, } from '@samc/react-ui-core';
import { useDomain, ViewType, TaskStatus, ApiContext, } from '@samc/screen-config-api';
import { useQueryExporter } from '@samc/screen-config-api/lib/useQueryExporter/useQueryExporter';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { useEvaluator } from '@samc/expressions-react';
import { GridType } from '@samc/react-ui-grid/lib/organisms/BaseGrid/AddedRowGrid/Types';
import { ProgressIndicator } from '@fluentui/react';
import { useContextToken } from '../../hooks/useContextToken/useContextToken';
import { FormPanel } from '../panels/FormPanel/FormPanel';
import { useContextMenuLinks } from './useContextMenuLinks';
import { useNewRecordPanel } from './useNewRecordPanel';
import QueryBuilderControlBarItems from '../../molecules/QueryBuilderControlBarItems/QueryBuilderControlBarItems';
import { useLSAutoFillActions } from '../../hooks/useLSAutoFillActions/useLSAutoFillActions';
import { ViewModal } from '../modals/ViewModal/ViewModal';
import { mergeErrors, mapErrors, mapTaskMessages, createSubmissionPromise, getOrderByExpressionGetter, getSortParameters, } from './GridScreen.utilities';
import { domainType, linkType } from '../../enums/enumsTypes';
import { getGridRequestIdentifier } from '../FormScreen/utilities';
import { updateRowsSelected, useSelectedRowsByGridContext, } from '../../contexts/GridSelectionContext/GridSelectionContext';
import { useRelatedFields } from '../../hooks/useRelatedFields/useRelatedFields';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareMemo/useDeepCompareMemo';
import { ViewSetModal } from '../modals/ViewSetModal/ViewSetModal';
import { TaskResponsePopup } from '../../molecules/TaskResponsePopup/TaskResponsePopup';
import { useGridFieldsFromListview } from '../../hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import useEntitlementsForDomain from '../../hooks/useEntitlementsForDomain/useEntitlementsForDomain';
import { useGridInjector } from '../../hooks/useGridInjector/useGridInjector';
import { FormScreenWrapper } from '../FormScreenWrapper/FormScreenWrapper';
const EMPTY_ERRORS = {
    errors: [],
    data: {},
};
export const useForceUpdate = () => {
    const [, setValue] = React.useState(0); // integer state
    return () => setValue((value) => {
        let _value = value;
        return ++_value;
    });
};
/**
 * Responsible for translating ListView metadata to a React-UI-Library Grid to render grid.
 * @param props
 * @returns
 */
const _GridScreenInternal = (props, ref) => {
    var _a, _b, _c, _d, _e, _f;
    const { listView, refreshListView, setListViewId, selectedFilterIds, setSelectedFilterIds, onSubmit, doFetch, onReset: onResetProp, onExcelExport, extraListViewMembers, defaultData, children, showContent, style, gridDisplayOptions, errors: parentErrors, setErrors: setParentErrors, filters, onSelectionChanges, onChangeData: _onChangeDataProp, onAddGridReady, onBodyGridReady: _onBodyGridReadyProp, onGridApiChanged: _onGridApiChangedProp, sortable = true, disableAddRowButton, suppressDataLoad, onIdSelectionChanged: onIdSelectionChangedProp, initialAddedRecords, preserveInitialRecordsOnReset, autoHeight, hideControlBar, suppressPaginationPanel, gridClassName, sizeColumnsToFit, suppressLinks, onToggleEdit, enableGroupByGrid, groupByFieldName, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const completeSubmission = React.useRef();
    const apisContext = React.useContext(ApiContext);
    const totalRecordCount = React.useRef();
    const dirtinessScope = React.useRef(null);
    const [data, setData] = React.useState();
    const [isDownloadModalOpen, setDownloadModalOpen] = React.useState(false);
    const [errorToastIsVisible, setErrorToastIsVisible] = React.useState(false);
    const [lastRequests, setLastRequests] = React.useState();
    const [lastResponses, setLastResponses] = React.useState();
    // Errors from grid processing required fields in UI
    // these get cleared as the errors are fixed
    const [clientErrors, setClientErrors] = React.useState([]);
    // Errors from server from most recent submission
    // these need to stick around until user clicks save again
    const [serverErrors, setServerErrors] = React.useState(EMPTY_ERRORS);
    // Errors from server from most recent submission
    // these however get cleared as the user makes changes so that they know what they have left to fix.
    const [validationErrors, setValidationErrors] = React.useState(EMPTY_ERRORS);
    const setSubmissionErrors = React.useCallback((errors) => {
        setServerErrors(errors);
        setValidationErrors(errors);
    }, []);
    const forceUpdate = useForceUpdate();
    const { evaluate } = useEvaluator();
    const { promptAndContinue, forceReset } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const closeTaskResponsePopup = React.useCallback((value) => {
        if (completeSubmission.current) {
            completeSubmission.current();
            completeSubmission.current = undefined;
        }
        setErrorToastIsVisible(value);
    }, [setErrorToastIsVisible, completeSubmission]);
    const pagnationParams = React.useRef();
    // Aggregate data is stored here
    const [aggregateData, setAggregateData] = React.useState([]);
    const domain = useDomain(listView.domainId);
    const { canAdd, canUpdate, canDelete } = useEntitlementsForDomain(listView.domainId);
    const { token } = useContextToken();
    const { setSelectedRowsByGrids } = useSelectedRowsByGridContext(listView.id);
    const primaryKey = (_a = domain.data) === null || _a === void 0 ? void 0 : _a.primaryKey;
    const currentQuery = React.useRef({});
    const queryExporter = useQueryExporter();
    const exportCurrentQuery = () => __awaiter(void 0, void 0, void 0, function* () { return queryExporter(currentQuery.current); });
    const shortNameField = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = domain.data) === null || _a === void 0 ? void 0 : _a.fields.find((e) => e.fieldName === 'ShortName')) === null || _b === void 0 ? void 0 : _b.physicalName;
    }, [domain.data]);
    const [onBodyGridReady, newRecordPanelOpen, handleAddRecord, handleRecordAdded, handleDismissed] = useNewRecordPanel({ onBodyGridReady: _onBodyGridReadyProp });
    const { apis: gridApis, onGridApiChanged } = useGridApis(_onGridApiChangedProp);
    const filtersStr = filters === null || filters === void 0 ? void 0 : filters.join('');
    React.useEffect(() => {
        return () => {
            gridApis.ifDefined(GridType.Body, ({ gridApi }) => {
                // Trigger grid refresh whenever filter selection changes or filters passed in change
                // Returned as an effect clean up because we do not need this to get called upon initial render
                gridApi.refreshServerSide({ purge: true });
            });
        };
    }, [selectedFilterIds, filtersStr, gridApis]);
    const allFields = useDeepCompareMemo(() => {
        return [...listView.listViewMembers, ...extraListViewMembers];
    }, [listView.listViewMembers, extraListViewMembers]);
    // Only pass the open dialog callback when addUsingForm is defined
    const handleAddRecordClick = listView.formViewId ? handleAddRecord : undefined;
    const autofillActions = useLSAutoFillActions(listView.autoCompleteActions || []);
    const processErrors = React.useCallback((errors) => mapErrors(errors, primaryKey, gridApis), [gridApis, primaryKey]);
    const errors = useDeepCompareMemo(() => {
        return mergeErrors(clientErrors, serverErrors.errors, processErrors(parentErrors || []));
    }, [clientErrors, serverErrors.errors, processErrors, parentErrors]);
    const errorsThatClear = useDeepCompareMemo(() => {
        return mergeErrors(clientErrors, validationErrors.errors, processErrors(parentErrors || []));
    }, [clientErrors, validationErrors.errors, processErrors, parentErrors]);
    const [relatedFields, listViewMembers] = useRelatedFields(listView.domainId, listView.fieldConfigurationId, errors, allFields);
    const missingAdhocLVM = useDeepCompareMemo(() => {
        return relatedFields.map((field) => {
            return { scalarExpression: `[${field.viewFieldName}]`, scalarDisplayName: field.viewFieldName };
        });
    }, [relatedFields]);
    // pass errors back up to parent
    React.useEffect(() => {
        if (setParentErrors)
            setParentErrors(errors);
    }, [errors, setParentErrors]);
    const onChangeData = React.useCallback((args) => {
        setData(args.changedData);
        setClientErrors(processErrors(args.errors));
        if (_onChangeDataProp)
            _onChangeDataProp(args);
    }, [_onChangeDataProp, processErrors]);
    const gridRef = React.useRef(null);
    const beforeGridReset = React.useCallback(() => {
        setData(undefined);
        setClientErrors([]);
        setSubmissionErrors(EMPTY_ERRORS);
        if (onResetProp)
            onResetProp();
    }, [onResetProp, setSubmissionErrors]);
    const handleClearFilters = () => {
        // eslint-disable-next-line no-unused-expressions
        setSelectedFilterIds === null || setSelectedFilterIds === void 0 ? void 0 : setSelectedFilterIds([]);
    };
    const onSubmitRef = React.useRef(onSubmit);
    React.useEffect(() => {
        onSubmitRef.current = onSubmit;
    }, [onSubmit]);
    const submitRequest = React.useCallback((requests) => __awaiter(void 0, void 0, void 0, function* () {
        setLastRequests(requests);
        const [request] = requests;
        const { payload } = request;
        const response = yield onSubmitRef.current(listView, request);
        const { messages: originalMessages } = response;
        const processedErrors = mapTaskMessages(originalMessages, primaryKey, gridApis);
        const mappedMessages = originalMessages.map((msg, i) => {
            return Object.assign(Object.assign(Object.assign({}, msg), processedErrors[i]), { index: processedErrors[i].rowIndex });
        });
        const mappedResponse = Object.assign(Object.assign({}, response), { messages: mappedMessages });
        setLastResponses([mappedResponse]);
        const isSuccessful = mappedResponse.statusCode === TaskStatus.Completed;
        const hasMessages = mappedResponse.messages.length;
        const hasPrompts = mappedResponse.prompts.length;
        if (hasMessages) {
            setErrorToastIsVisible(true);
            setSubmissionErrors({ data: payload, errors: processedErrors });
        }
        else if (hasPrompts)
            setErrorToastIsVisible(true);
        else {
            setSubmissionErrors(EMPTY_ERRORS);
        }
        if (isSuccessful) {
            setData(undefined);
            setClientErrors([]);
            // do a full refresh on the grid to get updated data
            forceReset();
            toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Saved successfully" }));
        }
        if (hasMessages > 0) {
            yield createSubmissionPromise(completeSubmission);
        }
    }), [gridApis, listView, primaryKey, forceReset, setSubmissionErrors]);
    const handleSubmit = React.useCallback((overrideCodes) => __awaiter(void 0, void 0, void 0, function* () {
        if (!data)
            return;
        const request = {
            domainId: listView.domainId,
            viewId: listView.id,
            fieldConfigurationId: listView.fieldConfigurationId,
            payload: data,
            requestIdentifier: getGridRequestIdentifier(listView),
            overrideCodes,
        };
        yield submitRequest([request]);
    }), [data, listView, submitRequest]);
    const { setValue: setHeaderValue } = useHeaderContext();
    React.useEffect(() => {
        setHeaderValue('controlBarProps', (c) => (Object.assign(Object.assign({}, c), { errors: errorsThatClear.filter((e) => e.type !== ErrorMessageType.Info) })));
    }, [errorsThatClear, setHeaderValue]);
    /**
     * @param value true = enter edit mode, false = exit edit mode
     */
    const handleToggleEditMode = React.useCallback((value) => {
        // user already confirmed to discard
        if (value === false) {
            // clear out data state
            setData(undefined);
            setClientErrors([]);
            setSubmissionErrors(EMPTY_ERRORS);
            // Grid already will clear dirty state if needed
        }
        else {
            forceUpdate();
        }
        onToggleEdit === null || onToggleEdit === void 0 ? void 0 : onToggleEdit(value);
    }, [forceUpdate, onToggleEdit, setSubmissionErrors]);
    // "currentEditLink" isn't "current" here (we're not looping over anything), but it is the "currentEditLink" for each row being rendered in the return statement below.
    const [currentEditLink, setCurrentEditLink] = React.useState(null);
    // the onDismiss function is passed down to the form slide out view, and it called when it wants to be closed
    const onDismissViewModal = React.useCallback((isDirty) => {
        setCurrentEditLink(null);
        // reload after saved modal
        if (isDirty)
            forceReset();
    }, [forceReset]);
    const onDismissDownloadModal = React.useCallback(() => {
        setDownloadModalOpen(false);
    }, []);
    const _listViewWithNewMembers = React.useMemo(() => (Object.assign(Object.assign({}, listView), { listViewMembers })), [listView, listViewMembers]);
    const { frameworkComponents, wrappedFields, links, displayOptions, fieldConfigurationMembers, isLoading } = useGridFieldsFromListview({
        listView: _listViewWithNewMembers,
        additionalMembers: relatedFields,
        setCurrentEditLink,
        promptAndContinue,
        filters,
        gridDisplayOptions,
        suppressLinks,
        useGridInjector,
    });
    const getContextMenuItems = useContextMenuLinks(links, domain, setCurrentEditLink, promptAndContinue);
    const initialLoadComplete = React.useRef(false);
    React.useEffect(() => {
        initialLoadComplete.current = false;
    }, [wrappedFields]);
    const fetchAllIds = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h;
        const totalCount = totalRecordCount.current;
        if (isNullOrUndefined(totalCount))
            throw new Error('Grid data not yet loaded');
        if (isNullOrUndefined(wrappedFields))
            throw new Error('No fields loaded');
        const filterModel = (_h = (_g = gridApis.body) === null || _g === void 0 ? void 0 : _g.gridApi.getFilterModel()) !== null && _h !== void 0 ? _h : {};
        const adHocFilterExpression = Object.keys(filterModel).length > 0
            ? ConvertGridFilterToFilterExpression(filterModel, wrappedFields)
            : undefined;
        // must be pure to avoid unnecessary fields being fetched
        const [response] = yield doFetch(false, { start: 0, stop: totalCount }, adHocFilterExpression
            ? {
                filterName: 'Select all filter',
                advancedInd: true,
                expressionLang: 'Centric',
                advancedExpression: adHocFilterExpression,
            }
            : undefined, selectedFilterIds, undefined, [], true);
        return response.Data.map((r) => String(r.primaryKey));
    }), [doFetch, (_b = gridApis.body) === null || _b === void 0 ? void 0 : _b.gridApi, selectedFilterIds, wrappedFields]);
    /**
     * Calls doFetch to execute the Query/Execute request.
     * parseFilter is used to parse the AgGrid filter into an AdHocFilter.
     * doFetchWrapper calls doFetch with the required summaryMode
     * @param summaryMode Populates the list view if false, else returns aggregated data.
     * @param doFetch The function to execute the query request.
     * @param parseFilter Function, which given the AgGrid column filter can generate an AdHocFilter.
     * @param filterIds Array of pre-defined filter IDs to apply to the fetch request
     */
    const handleGridStateChanged = React.useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        var _j;
        if (!wrappedFields)
            return;
        const { groupKeys } = params;
        const filterIds = [...(selectedFilterIds || [])];
        const filterModel = GetFilterModelForTextFilter((_j = params.filterModel) !== null && _j !== void 0 ? _j : undefined, wrappedFields || []);
        const doFetchWrapper = (summaryMode) => __awaiter(void 0, void 0, void 0, function* () {
            var _k;
            if (suppressDataLoad) {
                return {
                    Data: [],
                    TotalRecords: 0,
                    ViewMembers: [],
                    IsSummary: true,
                };
            }
            const getOrderByExpression = getOrderByExpressionGetter(fieldConfigurationMembers);
            const pageInfo = {
                start: params.startRow || 0,
                stop: params.endRow || 100,
                multiSort: getSortParameters(listViewMembers, fieldConfigurationMembers, params.sortModel),
            };
            const adHocFilterExpression = Object.keys((_k = params.filterModel) !== null && _k !== void 0 ? _k : {}).length > 0
                ? ConvertGridFilterToFilterExpression(filterModel, wrappedFields)
                : undefined;
            const adHocFilter = adHocFilterExpression
                ? {
                    expressionLang: 'Centric',
                    advancedInd: true,
                    advancedExpression: adHocFilterExpression,
                    filterName: 'AdHoc Filter',
                }
                : undefined;
            const [response, request] = yield doFetch(summaryMode, pageInfo, adHocFilter, filterIds, primaryKey, missingAdhocLVM, false, {
                getOrderByExpression,
                groupKeys,
                enableGroupByGrid,
                groupByFieldName,
            });
            pagnationParams.current = {
                pagingData: pageInfo,
                adHocFilter,
                filterIds,
            };
            if (!summaryMode) {
                // save the current query without paging
                currentQuery.current = Object.assign(Object.assign({}, request), { paging: {
                        start: 0,
                        stop: response.TotalRecords - 1,
                    } });
            }
            return response;
        });
        // Calling doFetchWrapper with summaryMode == False to load list view data
        const listViewResponse = yield doFetchWrapper(false);
        params.success({
            rowData: listViewResponse.Data || [],
            rowCount: listViewResponse.TotalRecords || 0,
        });
        totalRecordCount.current = listViewResponse.TotalRecords; // for selecting all
        // Calling doFetchWrapper with summaryMode == True to load the aggregates.
        // The aggregates row will update whenever the grid is modified.
        const aggregateResponse = yield doFetchWrapper(true);
        const [_aggregateData] = aggregateResponse.Data;
        if (_aggregateData)
            setAggregateData([_aggregateData]);
        // refresh headers on initial load, for picklist filters
        if (!initialLoadComplete.current) {
            gridApis.ifDefined(GridType.Body, ({ gridApi: api }) => {
                api.refreshHeader();
            });
            initialLoadComplete.current = true;
        }
    }), [
        wrappedFields,
        selectedFilterIds,
        suppressDataLoad,
        fieldConfigurationMembers,
        listViewMembers,
        doFetch,
        primaryKey,
        missingAdhocLVM,
        gridApis,
        enableGroupByGrid,
        groupByFieldName,
    ]);
    React.useEffect(() => {
        if (suppressDataLoad === false && initialLoadComplete.current === true) {
            forceReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suppressDataLoad]);
    const createItem = React.useCallback(() => {
        return Object.assign({}, defaultData);
    }, [defaultData]);
    const onFileDownload = React.useCallback((zipName, fileNameSelection, shouldPrefaceWithEntityName) => {
        var _a, _b;
        const selectedRows = ((_b = (_a = gridApis.body) === null || _a === void 0 ? void 0 : _a.gridApi.getSelectedRows()) !== null && _b !== void 0 ? _b : []);
        return handleFileDownload(selectedRows, token, (apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName) || '', (apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi) || '', zipName, fileNameSelection, shouldPrefaceWithEntityName);
    }, [gridApis, token, apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName, apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi]);
    const onClickDownload = React.useMemo(() => {
        if (`${listView.domainId}`.indexOf(domainType.Document) >= 0) {
            return () => {
                var _a, _b;
                const selectedRows = ((_b = (_a = gridApis.body) === null || _a === void 0 ? void 0 : _a.gridApi.getSelectedRows()) !== null && _b !== void 0 ? _b : []);
                if (!selectedRows.length) {
                    return;
                }
                if (selectedRows.length >= 2) {
                    setDownloadModalOpen(true);
                }
                else {
                    handleFileDownload(selectedRows, token, (apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName) || '', (apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi) || '');
                }
            };
        }
        return undefined;
    }, [listView.domainId, gridApis, token, apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName, apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi]);
    const onIdSelectionChanged = (e) => {
        if (!primaryKey)
            return;
        gridApis.ifDefined(GridType.Body, ({ gridApi: bodyGridApi }) => {
            const selectedRows = bodyGridApi.getSelectedRows();
            if (onIdSelectionChangedProp)
                onIdSelectionChangedProp(e);
            if (onSelectionChanges)
                onSelectionChanges(selectedRows);
            setSelectedRowsByGrids((prevState) => updateRowsSelected(prevState, selectedRows, listView.id, e.selectedIds, primaryKey));
        });
    };
    const sortableWrappedFields = React.useMemo(() => wrappedFields === null || wrappedFields === void 0 ? void 0 : wrappedFields.map((w) => {
        if (!enableGroupByGrid)
            return Object.assign(Object.assign(Object.assign({}, w), { sortable }), (sizeColumnsToFit && { width: undefined }));
        if (w.field === groupByFieldName) {
            return Object.assign(Object.assign(Object.assign({}, w), { rowGroup: true, sortable }), (sizeColumnsToFit && { width: undefined }));
        }
        return Object.assign(Object.assign(Object.assign({}, w), { sortable }), (sizeColumnsToFit && { width: undefined }));
    }), [enableGroupByGrid, groupByFieldName, sizeColumnsToFit, sortable, wrappedFields]);
    const domainNameMap = React.useMemo(() => {
        if (!shortNameField)
            return {};
        return {
            [listView.domainId]: shortNameField,
        };
    }, [listView.domainId, shortNameField]);
    const onModalSaved = React.useCallback(() => {
        forceReset({ includeClean: true });
    }, [forceReset]);
    React.useImperativeHandle(ref, () => ({
        reset: forceReset,
    }), [forceReset]);
    const refreshDataAndListView = React.useCallback(() => {
        // need to reset data and view
        forceReset();
        refreshListView === null || refreshListView === void 0 ? void 0 : refreshListView();
    }, [refreshListView, forceReset]);
    // intentionally not memoized due to ag-grid modifing the prop and it needing to be a new object each render
    const gridOptions = {
        isRowSelectable: (node) => GetValueFromData(node.data, ADDED_FLAG) !== true,
        groupDisplayType: enableGroupByGrid ? 'groupRows' : undefined,
        isServerSideGroupOpenByDefault: enableGroupByGrid ? () => true : undefined,
    };
    if (!sortableWrappedFields)
        return null;
    if (isLoading)
        return React.createElement(ProgressIndicator, { label: "Loading Grid Components..." });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: dirtinessScope, style: { display: 'flex', flex: '1 0 auto', flexDirection: 'column', margin: '12px' } },
            children && React.createElement("div", { style: { marginBottom: '10px' } }, children),
            showContent !== false && (React.createElement(Grid
            // onSubmit callback allow triggering saves from within grid/dirtiness tracker
            , { 
                // onSubmit callback allow triggering saves from within grid/dirtiness tracker
                onSubmit: () => handleSubmit(), onBeforeReset: beforeGridReset, autofillActions: autofillActions, aggregateData: aggregateData, autoHeight: autoHeight, disableAddRowButton: disableAddRowButton, fetchAllIds: fetchAllIds, fields: sortableWrappedFields, frameworkComponents: frameworkComponents, getContextMenuItems: getContextMenuItems, gridClassName: gridClassName, gridRef: gridRef, hideControlBar: hideControlBar, idField: primaryKey, initialAddedRecords: initialAddedRecords, isEditingDefault: canUpdate && ((_c = displayOptions.isEditingDefault) !== null && _c !== void 0 ? _c : listView.isEditingDefault), newRowFactory: createItem, onChangeData: onChangeData, onClickAddRow: handleAddRecordClick, onClickClearFilter: handleClearFilters, onClickDownload: onClickDownload, onClickExcelExport: onExcelExport !== null && onExcelExport !== void 0 ? onExcelExport : exportCurrentQuery, onAddGridReady: onAddGridReady, onBodyGridReady: onBodyGridReady, onGridApiChanged: onGridApiChanged, onGridStateChange: handleGridStateChanged, onIdSelectionChanged: onIdSelectionChanged, onToggleEdit: handleToggleEditMode, preserveInitialRecordsOnReset: preserveInitialRecordsOnReset, renderControlBarItems: () => {
                    return displayOptions.suppressViewSelector ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    React.createElement(React.Fragment, null)) : (React.createElement(QueryBuilderControlBarItems, { refreshFilter: forceReset, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, refreshView: refreshDataAndListView, selectedViewId: listView.id, setSelectedViewId: setListViewId, promptAndContinue: promptAndContinue }));
                }, rowSelection: (_d = displayOptions.rowSelection) !== null && _d !== void 0 ? _d : listView.rowSelection, rowSpacingValue: (_e = displayOptions.defaultRowSpacing) !== null && _e !== void 0 ? _e : listView.defaultRowSpacing, rowsPerPage: (_f = displayOptions.rowsPerPage) !== null && _f !== void 0 ? _f : 100, sizeColumnsToFit: sizeColumnsToFit, stopEditingWhenCellsLoseFocus: true, style: style, suppressAddRowButton: displayOptions.suppressAddRowButton || !canAdd, suppressAggregateToggle: displayOptions.suppressAggregateToggle, suppressColumnFilters: displayOptions.suppressColumnFilters, suppressDeleteButton: displayOptions.suppressDeleteButton || !canDelete, suppressDeleteConfirmationModal: displayOptions.suppressDeleteConfirmationModal, suppressEditToggle: displayOptions.suppressEditToggle || !canUpdate, suppressExcelExport: displayOptions.suppressExcelExport, suppressFullScreen: displayOptions.suppressFullScreen, suppressPaginationPanel: suppressPaginationPanel, suppressRefresh: displayOptions.suppressRefresh, suppressRowSelector: displayOptions.suppressSelectionCheckbox, suppressRowSpacing: displayOptions.suppressRowSpacing, updateValidationErrors: setValidationErrors, validationErrors: validationErrors, gridOptions: gridOptions }))),
        currentEditLink &&
            (currentEditLink.targetType === linkType.Edit ||
                currentEditLink.targetType === linkType.Modal ||
                currentEditLink.targetType === linkType.DocCountLink) && (React.createElement(ViewModal, { viewId: currentEditLink.targetId, viewType: ViewType.ViewSet, headerText: String(evaluate(currentEditLink.data || {}, currentEditLink.displayNameExpression, true)), isOpen: currentEditLink !== null, onDismiss: onDismissViewModal, filters: filters, primaryKeyValue: currentEditLink.primaryKeyValue, defaultFilterExpression: currentEditLink.defaultFilterExpression, onSaved: onModalSaved, FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet })),
        isDownloadModalOpen && (React.createElement(FileDownloadModal, { onDismiss: onDismissDownloadModal, onFileDownload: onFileDownload })),
        currentEditLink && currentEditLink.targetType === linkType.ViewSetModal && (React.createElement(ViewSetModal, { viewSetId: currentEditLink.targetId, headerText: String(evaluate(currentEditLink.data || {}, currentEditLink.displayNameExpression, true)), onDismiss: onDismissViewModal, onSaved: onModalSaved, filters: filters, primaryKeyValue: currentEditLink.primaryKeyValue, ServiceDrivenViewSet: ServiceDrivenViewSet })),
        React.createElement(FormPanel, { title: "Add New Record", formViewId: listView.formViewId, isOpen: newRecordPanelOpen, onSubmit: handleRecordAdded, onDismiss: handleDismissed, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }),
        lastRequests && lastResponses && (React.createElement(TaskResponsePopup, { responses: lastResponses, requests: lastRequests, visible: errorToastIsVisible, setVisible: closeTaskResponsePopup, submitWithOverrides: handleSubmit, submitWithUpdates: submitRequest, domainNameMap: domainNameMap }))));
};
export const GridScreenInternal = React.forwardRef(_GridScreenInternal);
export default GridScreenInternal;
