import React from 'react';
import { useAllPicklists } from '@samc/picklist-core';
import { DomainContextProvider } from '@samc/vmsnext-querybuilder';
export const mapPicklistsToLookups = (picklists) => {
    return Object.values(picklists).map((picklist) => {
        const items = picklist.picklistItems || [];
        return {
            name: picklist.id,
            values: items.map((item) => ({ value: String(item.id), description: item.shortName })),
        };
    });
};
/**
 * Hook that will load all picklists and then set the lookups on
 * the domain context so that Lift and Sift components will have lookups in the expression editor
 */
export const useLookupsForLiftAndSift = () => {
    const allPicklists = useAllPicklists();
    const { lookups, setLookups } = React.useContext(DomainContextProvider);
    React.useEffect(() => {
        if (allPicklists) {
            setLookups(mapPicklistsToLookups(allPicklists));
        }
    }, [allPicklists, setLookups]);
    return { lookups };
};
/**
 * Hook to get just the lookups based on all picklists
 */
export const useLookupsForExpressions = () => {
    const allPicklists = useAllPicklists();
    // return the lookup
    const lookups = React.useMemo(() => mapPicklistsToLookups(allPicklists), [allPicklists]);
    return { lookups };
};
export default useLookupsForExpressions;
