export const MergeExpressionData = (...data) => {
    if (data.length === 1)
        return data[0];
    const result = data.reduce((compiledData, currentData) => {
        const record = Object.assign({}, compiledData);
        Object.keys(currentData).forEach((key) => {
            const value = currentData[key];
            if (value === null || value === undefined || typeof value === 'object')
                return;
            const strValue = String(value);
            const curStrValue = record[key];
            if (!curStrValue)
                record[key] = strValue;
            else {
                record[key] = `${record[key]},${strValue}`;
            }
        });
        return record;
    }, {});
    return result;
};
export default MergeExpressionData;
