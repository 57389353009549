import { FormFieldConfigurationViewModel } from './FormFieldConfigurationViewModel';
import { FormTabViewModel } from './FormTabViewModel';
import { FormGridConfigurationViewModel } from './FormGridConfigurationViewModel';
import { FormSkeletonMappers } from '../mappers/FormSkeletonMappers/FormSkeletonMappers';
import { removeMarkdownFormViewMembers } from '../mappers/FormSkeletonMappers/Utilities';
/**
 * @deprecated use {@link FormSkeletonMappers}
 */
export function mapFormViewMembersToViewModel(formViewMembers) {
    return Object.keys(formViewMembers).reduce((acc, field) => {
        acc[field] = new FormFieldConfigurationViewModel(formViewMembers[field]);
        return acc;
    }, {});
}
/**
 * This is an implementation of the FormSkeleton interface to help in mapping from FormView
 * to FormSkeleton and back.
 * @deprecated use {@link FormSkeletonMappers} for mapping
 */
export class FormSkeletonViewModel {
    constructor(fv) {
        var _a, _b, _c;
        /**
         * Convert back to FormView for saving, most likely.
         *
         * Note: The awkward implementation here is because of the js closure around the toFormView function.
         * Since we are using object spread the class properties will change as the Form changes but the closure around
         * this function will become stale! If I were smarter perhaps this would be solved more gracefully.
         * @param formSkeleton The FormSkeleton object to convert.
         *
         * @deprecated use {@link FormSkeletonMappers}
         */
        this.toFormView = (domainId, fieldConfigId, formSkeleton, formViewId, folder) => FormSkeletonMappers.FormSkeleton.toFormView(formSkeleton, {
            fieldConfigurationId: fieldConfigId,
            domainId,
            formViewId,
            folder,
        });
        const formView = removeMarkdownFormViewMembers(fv);
        this.title = formView.name;
        this.fields = mapFormViewMembersToViewModel(formView.formViewMembers);
        this.gridFields =
            (_b = (_a = formView.gridFields) === null || _a === void 0 ? void 0 : _a.map((gridField) => new FormGridConfigurationViewModel(gridField))) !== null && _b !== void 0 ? _b : [];
        this.tabs = formView.formLayout
            ? formView.formLayout.tabs.map((tab) => {
                var _a;
                return new FormTabViewModel(((_a = formView.gridFields) === null || _a === void 0 ? void 0 : _a.length) || 0, Object.keys(formView.formViewMembers).length, formView.gridFields || [], tab);
            })
            : [
                new FormTabViewModel(((_c = formView.gridFields) === null || _c === void 0 ? void 0 : _c.length) || 0, Object.keys(formView.formViewMembers).length, formView.gridFields || []),
            ];
        this.fieldMatrices = formView.fieldMatrices;
    }
}
/**
 * Creates a form skeleton view model from a form skeleton
 */
FormSkeletonViewModel.fromFormSkeleton = (fs) => {
    const objectInstance = Object.create(FormSkeletonViewModel);
    Object.assign(objectInstance, fs);
    objectInstance.fields = Object.entries(fs.fields).reduce((all, [field, config]) => (Object.assign(Object.assign({}, all), { [field]: FormFieldConfigurationViewModel.fromFormFieldConfiguration(config) })), {});
    objectInstance.gridFields = fs.gridFields.map((gf) => {
        let viewName;
        if (gf instanceof FormGridConfigurationViewModel)
            viewName = gf.viewName;
        return FormGridConfigurationViewModel.fromFormGridConfiguration(gf, viewName);
    });
    objectInstance.tabs = fs.tabs.map((t) => FormTabViewModel.fromFormTab(t));
    return objectInstance;
};
export default FormSkeletonViewModel;
export function mapDomainFieldsToFormViewMembers(domainFields, fieldConfigMemberLookup) {
    return domainFields.reduce((acc, domainField) => {
        const fieldConfigMember = fieldConfigMemberLookup.get(domainField.domainId, domainField.fieldName);
        acc[domainField.fieldName] = {
            viewFieldName: domainField.fieldName,
            displayNameExpression: domainField.displayName,
            domainId: domainField.domainId,
            inputType: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.inputType,
            allowedFileTypes: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.allowedFileTypes,
            calculationCondition: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.calculationCondition,
            calculationFrequency: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.calculationFrequency,
            calculationRule: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.calculationRule,
            defaultValueExpression: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.defaultValueExpression,
            editableExpression: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.editableExpression,
            formatId: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.formatId,
            isKeyField: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.isKeyField,
            lookupProperties: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.lookupProperties,
            requiredExpression: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.requiredExpression,
            tooltip: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.tooltip,
            visibleExpression: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.visibleExpression,
            hexFillRule: fieldConfigMember === null || fieldConfigMember === void 0 ? void 0 : fieldConfigMember.hexFillRule,
        };
        return acc;
    }, {});
}
