import React from 'react';
import { useStyletron } from 'styletron-react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
import { toastError, toastSuccess } from '@samc/react-ui-core';
import { Upload, useStrata } from '@samc/filestorage-react';
import { mapColumnsForStrata, validateMandatoryFieldsToRenderFilters } from './FileUploader.Utilities';
import DropzoneGrid from '../../molecules/DropzoneGrid/DropzoneGrid';
import { useDropzoneGridIds } from '../../hooks/useDropzoneGridIds/useDropzoneGridIds';
import './FileUploader.css';
const EMPTY_FILES = [];
export const FileUploader = (props) => {
    const { token, tenantId, strataUrl, columns, fileManagerUrl, fileMetadataService, onStrataDataChanged, customGridOptions, uploadColumnAtLast = false, disableWorker = false, dropzoneGridDefs, strataActions, isReady, children, className, onHandleGridReadyEvent, panelTitle, customProps, } = props;
    const [css] = useStyletron();
    const theme = useThemeContext();
    const [isFilterCompleted, setIsFilterComponent] = React.useState(false);
    const { onDZGridFileDrop, onStrataFileAdded } = useDropzoneGridIds(dropzoneGridDefs, strataActions === null || strataActions === void 0 ? void 0 : strataActions.onFileAdded);
    const { request: { files: StrataFiles = EMPTY_FILES } = {}, saveActions: { saveConfiguration }, } = useStrata();
    React.useEffect(() => {
        saveConfiguration({
            token,
            tenantId,
            strataUrl,
            fileManagerUrl,
            fileMetadataService,
            toastMessages: {
                toastMessageSuccess: toastSuccess,
                toastMessageError: toastError,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileManagerUrl, fileMetadataService, saveConfiguration, tenantId, token]);
    React.useEffect(() => {
        setIsFilterComponent(validateMandatoryFieldsToRenderFilters(columns));
    }, [columns]);
    return (React.createElement("div", { className: css({
            display: 'flex',
            justifyContent: 'space-around',
            height: `${children ? '50vh' : '68vh'}`,
        }) },
        dropzoneGridDefs && (React.createElement("div", { className: css({
                width: '30%',
                backgroundColor: theme.TableColors.cellColors.disabled.toString(),
            }) },
            React.createElement(DropzoneGrid, { colDefs: dropzoneGridDefs.colDefs, rowDefs: dropzoneGridDefs.rowDefs, isLoading: dropzoneGridDefs.isLoading, onFileDrop: onDZGridFileDrop, idField: dropzoneGridDefs.idField, fileTypesField: dropzoneGridDefs.fileTypesField, gridTitle: dropzoneGridDefs.title, gridHeight: "calc(68vh - 60px)", onStateChange: dropzoneGridDefs.onStateChange }))),
        React.createElement("div", { className: `${className} ${css(dropzoneGridDefs ? { width: '70%' } : { width: '100%' })}` },
            panelTitle && (React.createElement("div", { className: css({
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '30px',
                    alignItems: 'center',
                }) },
                React.createElement("span", { className: css({
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: theme.TextColors.secondary.toString(),
                        borderBottom: '1px solid #d1d1d1',
                        width: '100%',
                    }) }, panelTitle))),
            isReady && isFilterCompleted && (React.createElement(Upload, { columns: mapColumnsForStrata(columns), displaySaveButton: false, entityName: "VMSNextDocument", onDataChanged: onStrataDataChanged, disableDropzone: false, customGridOptions: customGridOptions, condensedUploadStatus: true, allowedFileTypes: "", uploadColumnAtLast: uploadColumnAtLast, disableWorker: disableWorker, files: StrataFiles, isDropzoneSeperate: false, isWorkroomMode: false, onFileAdded: onStrataFileAdded, onHandleGridReadyEvent: onHandleGridReadyEvent, disableToast: true, customProps: customProps },
                React.createElement("div", { className: "quick-dropzone-root" },
                    React.createElement("div", { className: css({
                            margin: '12px',
                        }) },
                        React.createElement("p", null,
                            React.createElement("strong", null, "Quick Drop Zone")),
                        React.createElement("p", null,
                            React.createElement("span", null, "Drag and drop documents here or "),
                            React.createElement("span", { className: css({
                                    color: '#133d91',
                                    textDecoration: 'underline solid rgb(19, 61, 145)',
                                }) }, "Select Files")))))),
            !isReady && !isFilterCompleted && (React.createElement(Spinner, { label: "Loading Grid", ariaLive: "assertive", labelPosition: "right", size: SpinnerSize.large, className: css({
                    marginTop: '15px',
                }) })))));
};
export default FileUploader;
