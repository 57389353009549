import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getFormViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { FORM_VIEW_BY_DOMAIN_KEY } from '../useFormViewsByDomainRequest/useFormViewsByDomainRequest';
export const useFormViews = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (dId) => getFormViews(api.AggregatorApi, dId, api.requestInit);
    return useRequestBase({
        key: FORM_VIEW_BY_DOMAIN_KEY,
        query,
        requestContext: ApiContext,
        params: [domainId],
    });
};
export default useFormViews;
