import React from 'react';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { useThemeContext } from '@samc/react-ui-theme';
import { useStyletron } from 'styletron-react';
import { toastSuccess, ToastMessage } from '@samc/react-ui-core';
import { TaskStatus, useTaskSubmit } from '@samc/screen-config-api';
import { useDocuments } from '@samc/screen-config-docs';
import { AddDocumentsModal } from '../AddDocumentsModal/AddDocumentsModal';
import { submitAttachedDocuments, attachDocumentCreatePayload, getAddDocumentsView, getEntityIdFromQueryParams, getLinkedValuationFilter, getEntitiesValuationFilter, getDocumentListView, } from '../DocumentsModal.Utilities';
import { TaskResponsePopup } from '../../../../molecules/TaskResponsePopup/TaskResponsePopup';
import { ProgressIndicator } from '../../../../atoms/ProgressIndicator/ProgressIndicator';
import './AttachDocumentsGrid.css';
export const InnerViewModal = (props) => {
    const { viewId, primaryKeyValue, onSaved, onDismiss, isOpen, showContent, filters, subtitle, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const shortNameField = 'FileName';
    const [viewDocumentsGrid, setViewDocumentsGrid] = React.useState(viewId);
    const [lastResponses, setLastResponses] = React.useState();
    const [errors, setErrors] = React.useState([]);
    const [domainId, setDomainMap] = React.useState('');
    const [errorsVisible, setErrorsVisible] = React.useState(false);
    const [taskSubmit, taskLoading, lastRequests] = useTaskSubmit();
    const [css] = useStyletron();
    const theme = useThemeContext();
    const parentEntityPk = React.useMemo(() => getEntityIdFromQueryParams(), []);
    const dirtinessScope = React.useRef(null);
    const [changedData, setChangedData] = React.useState();
    const { saveActions: { disableSaveButton }, } = useDocuments();
    const domainMap = React.useMemo(() => ({ [domainId]: shortNameField }), [shortNameField, domainId]);
    React.useEffect(() => {
        if (!changedData || Object.keys(changedData).length === 0) {
            disableSaveButton(true);
        }
        else {
            disableSaveButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changedData]);
    const handleSaveModal = (listView, documentsSaved = false) => {
        setDomainMap(listView.domainId);
        if (changedData) {
            let requestPayloadNotes = {};
            Object.keys(changedData).forEach((e) => {
                const row = changedData[e];
                requestPayloadNotes = Object.assign(Object.assign({}, requestPayloadNotes), { [row.Id]: attachDocumentCreatePayload(row, primaryKeyValue) });
            });
            submitAttachedDocuments(listView, requestPayloadNotes, taskSubmit, setErrors, setErrorsVisible).then((response) => {
                const { statusCode } = response;
                setLastResponses([response]);
                if (statusCode === TaskStatus.Completed) {
                    toastSuccess(React.createElement(ToastMessage, { title: "Successful saved", message: "Document(s) saved" }));
                    if (onDismiss)
                        onDismiss();
                    if (onSaved)
                        onSaved();
                }
            });
        }
        else {
            if (documentsSaved) {
                toastSuccess(React.createElement(ToastMessage, { title: "Successful saved", message: "Document(s) saved" }));
                if (onSaved)
                    onSaved();
            }
            onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss();
        }
    };
    return (React.createElement(AddDocumentsModal, { onDismiss: onDismiss, viewId: getAddDocumentsView(viewDocumentsGrid), isOpen: isOpen, entityId: primaryKeyValue, onSaved: onSaved, customSave: handleSaveModal, showDropzone: false, filters: filters, className: "document-management-attach-file", panelTitle: "Upload Documents" },
        React.createElement("div", { ref: dirtinessScope, className: css({
                maxheight: '300px',
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                alignSelf: 'stretch',
                maxWidth: '100%',
                marginLeft: '10px',
                marginRight: '10px',
            }) },
            React.createElement("div", { className: css({
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '30px',
                    alignItems: 'center',
                }) },
                React.createElement("span", { className: css({
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: theme.TextColors.secondary.toString(),
                    }) }, subtitle)),
            React.createElement("div", { className: css({
                    marginTop: '5px',
                    paddingTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    flex: '1',
                    borderTop: '1px solid #d1d1d1',
                }) },
                taskLoading.isLoading && React.createElement(ProgressIndicator, { label: "Loading Document Grid..." }),
                React.createElement(GridScreenWrapper, { ServiceDrivenViewSet: ServiceDrivenViewSet, showContent: showContent, setListViewId: setViewDocumentsGrid, key: viewDocumentsGrid, listViewId: getDocumentListView(viewDocumentsGrid), onChangeData: ({ changedData: _cd }) => setChangedData(_cd), filters: [
                        `DoesContain('${primaryKeyValue}', [EntityId])`,
                        getLinkedValuationFilter(viewDocumentsGrid, parentEntityPk),
                        getEntitiesValuationFilter(viewDocumentsGrid, parentEntityPk),
                    ] }),
                errors && lastRequests && lastResponses && (React.createElement(TaskResponsePopup, { responses: lastResponses, requests: lastRequests, visible: errorsVisible, setVisible: setErrorsVisible, domainNameMap: domainMap }))))));
};
const AttachDocumentsGrid = (props) => {
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(InnerViewModal, Object.assign({}, props))));
};
export default AttachDocumentsGrid;
