import { styled } from 'styletron-react';
export const Row = styled('div', (props) => {
    return {
        display: 'flex',
        width: '100%',
        fontSize: '10px',
        fontWeight: props.$isHeader ? 600 : 400,
    };
});
export const normalizeFileTypes = (fileTypes) => {
    if (fileTypes) {
        return `.${fileTypes.replaceAll('.', '').split(',').join(',.')}`;
    }
    return '';
};
const getCellBorder = (props) => {
    if (props.$index === 0) {
        return props.$isHeader ? '1px 1px 1px 1px' : '0px 1px 1px 1px';
    }
    return props.$isHeader ? '1px 1px 1px 0px' : '0px 1px 1px 0px';
};
/**
 * Assigns styling for different cell types: even, odd, header and selected (isDragActive) cells
 */
const getCellBackground = (props) => {
    const { $theme: { TableColors }, $isDragActive, $index, $isHeader, } = props;
    if (($index + 1) % 2 === 0) {
        return $isDragActive ? TableColors.rowColors.selected.toString() : TableColors.rowColors.even.toString();
    }
    if ($isHeader) {
        return TableColors.rowColors.even.toString();
    }
    return $isDragActive ? TableColors.rowColors.disabledSelected.toString() : TableColors.rowColors.odd.toString();
};
export const Cell = styled('span', (props) => {
    return {
        flex: props.$flexWidth ? props.$flexWidth : 1,
        borderWidth: getCellBorder(props),
        borderColor: props.$theme.ButtonColors.light.default.border.toString(),
        borderStyle: 'solid',
        padding: '8px',
        minWidth: '60px',
        backgroundColor: getCellBackground(props),
        cursor: props.$isLoading ? 'default' : 'pointer',
        display: 'flex',
    };
});
