/**
 * A class to help with referencing FieldConfigurationMembers.
 * Given a collection of FieldConfiguration objects, FieldConfigurationMemberLookup will
 * maintain a dictionary like object and allow finding a FieldConfigurationMember by domain
 * and field name.
 */
export class FieldConfigurationMemberLookup {
    constructor(fieldConfigurations, morefieldConfigurations) {
        /**
         * Get a FieldConfigurationMember by domain and field name.
         * @param domain The string identifier of the Domain.
         * @param field The string name of the field.
         * @returns The FieldConfigurationMember if the collection contains it, otherwise undefined.
         */
        this.get = (domain, field) => {
            var _a;
            return (_a = this._lookup[domain]) === null || _a === void 0 ? void 0 : _a[field];
        };
        this._lookup = [...(fieldConfigurations || []), ...(morefieldConfigurations || [])].reduce((domainAcc, config) => {
            domainAcc[config.domainId] = config.fieldConfigurationMembers.reduce((acc, member) => {
                acc[member.viewFieldName] = Object.assign({}, member);
                return acc;
            }, {});
            return domainAcc;
        }, {});
    }
}
export default FieldConfigurationMemberLookup;
