import { isFolder } from '@samc/folder-ui-core';
// do this b/c key not exported
const FolderTreeQueryKey = 'FOLDER_TREE';
export const getContextMenuItems = (iProps, getFilterAndCopyToClipboard) => ({ node }) => {
    const { setTargetGridFilterId, setNewGridFilterFolderId } = iProps;
    if (!node)
        return [];
    const { data } = node;
    if (!data)
        return [];
    if (isFolder(data)) {
        return [{ action: () => setNewGridFilterFolderId(data.id), name: 'Add Filter' }];
    }
    const isDevToolsEnabled = localStorage.getItem('devtools') === 'true';
    if (isDevToolsEnabled && getFilterAndCopyToClipboard) {
        return [
            { action: () => setTargetGridFilterId(data.sequentialItemId), name: 'Edit Filter' },
            { action: () => getFilterAndCopyToClipboard(data.id), name: 'Dev: Copy to Clipboard' },
        ];
    }
    return [{ action: () => setTargetGridFilterId(data.sequentialItemId), name: 'Edit Filter' }];
};
export const onRowClicked = (props, iProps) => (v) => {
    const { editOnClick, onSelectionChanged } = props;
    const { setTargetGridFilterId } = iProps;
    if (isFolder(v))
        return;
    const { sequentialItemId, id, body } = v;
    const view = Object.assign(Object.assign({}, JSON.parse(body)), { id });
    if (editOnClick)
        setTargetGridFilterId(sequentialItemId);
    if (onSelectionChanged)
        onSelectionChanged(view);
};
export const onClose = (iProps) => () => {
    const { setTargetGridFilterId, queryClient, setNewGridFilterFolderId } = iProps;
    setTargetGridFilterId(undefined);
    setNewGridFilterFolderId(undefined);
    // reload tree
    queryClient.invalidateQueries({ queryKey: [FolderTreeQueryKey], exact: false });
};
export default {};
