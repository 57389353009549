export const HIDDEN_PREFIX = '__hidden__';
export const HIDDEN_PREFIX_VIEWABLE = `${HIDDEN_PREFIX}viewable_`;
export const HIDDEN_PREFIX_EDITABLE = `${HIDDEN_PREFIX}editable_`;
export const getHiddenViewableField = (fieldName) => `${HIDDEN_PREFIX_VIEWABLE}${fieldName}`;
export const getHiddenEditableField = (fieldName) => `${HIDDEN_PREFIX_EDITABLE}${fieldName}`;
const getBooleanOrUndefined = (value) => (typeof value === 'boolean' ? value : undefined);
export const getFieldPermissionsForRecord = (fieldName, recordData) => {
    const hiddenViewableState = Reflect.get(recordData, getHiddenViewableField(fieldName));
    const hiddenEditableState = Reflect.get(recordData, getHiddenEditableField(fieldName));
    // Accept boolean values for hidden properties, interpret anythinge else as undefined
    return {
        viewable: getBooleanOrUndefined(hiddenViewableState),
        editable: getBooleanOrUndefined(hiddenEditableState),
    };
};
/**
 * Get a callback which will return the data-driven field rule overrides for the given field
 * @param fieldName
 * @returns
 */
export const getDataDrivenRuleOverridesForField = (fieldName) => (recordData) => {
    const { viewable: hiddenViewableState, editable: hiddenEditableState } = getFieldPermissionsForRecord(fieldName, recordData);
    const suppressView = hiddenViewableState === false;
    const suppressEdit = suppressView || hiddenEditableState === false;
    return {
        visibleRule: suppressView ? 'false' : undefined,
        editableRule: suppressEdit ? 'false' : undefined,
    };
};
