import React from 'react';
import { usePicklistGridFields } from '@samc/picklist-core';
import { CustomTooltip } from '@samc/react-ui-grid/lib/organisms/BaseGrid/CustomTooltip';
import { useFieldConfiguration } from '@samc/screen-config-api';
import { useFolderDropdownGridFieldConfigurations } from '@samc/folder-ui-core';
import { useGridFieldsFromListview } from '../useGridFieldsFromListview/useGridFieldsFromListview';
import { useSelectGridFields } from '../useSelectGridFields/useSelectGridFields';
import { useMeasurementCurrencyGridFields } from '../useMeasurementCurrencyGridFields/useMeasurementCurrencyGridFields';
import { useExpressionGridFields } from '../useExpressionGridFields/useExpressionGridFields';
import { usePicklistGridFieldConfigurations as _usePicklistGridFieldConfigurations } from '../usePicklistGridFieldConfigurations/usePicklistGridFieldConfigurations';
import { prepareFolderFields } from './Utils';
import { useGridFieldsWithCustomClipboard } from '../useGridFieldsWithCustomClipboard/useGridFieldsWithCustomClipboard';
let _useGridInjector;
const useGridFieldConfigurations = _usePicklistGridFieldConfigurations.withHooks({
    useGridFieldsFromListview,
    get useGridInjector() {
        return _useGridInjector;
    },
});
_useGridInjector = (fieldConfigurationId, gridFields = [], filterExpressions = undefined) => {
    /**
     * Adds custom-tooltip to the fields not having any
     * @param childProps
     * @returns instance of GridInjectorChildProps
     */
    const getChildPropsWithTooltips = (childProps) => {
        var _a;
        if (!(childProps === null || childProps === void 0 ? void 0 : childProps.wrappedFields) || !(childProps === null || childProps === void 0 ? void 0 : childProps.frameworkComponents)) {
            return childProps;
        }
        const cp = Object.assign({}, childProps);
        cp.wrappedFields = (_a = cp.wrappedFields) === null || _a === void 0 ? void 0 : _a.map((f) => {
            var _a;
            return (Object.assign(Object.assign({}, f), { tooltipComponent: (_a = f.tooltipComponent) !== null && _a !== void 0 ? _a : 'custom-tooltip' }));
        });
        cp.frameworkComponents['custom-tooltip'] = CustomTooltip;
        return cp;
    };
    const fieldConfigurationResponse = useFieldConfiguration(fieldConfigurationId);
    const { isLoading: isLoading1, data: fieldConfiguration } = fieldConfigurationResponse;
    const fieldConfigurationMemberDictionary = React.useMemo(() => {
        var _a;
        return ((_a = fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers) !== null && _a !== void 0 ? _a : []).reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur.viewFieldName]: cur })), {});
    }, [fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers]);
    /**
     * Fields that have been prepared for consumption by folder ui
     */
    const folderPreparedFields = React.useMemo(() => prepareFolderFields(gridFields, fieldConfigurationMemberDictionary), [fieldConfigurationMemberDictionary, gridFields]);
    const { wrappedFields: wrappedFields1, frameworkComponents: frameworkComponents1, isReady: isReady1, } = useFolderDropdownGridFieldConfigurations({ gridFieldConfigurations: folderPreparedFields });
    const { wrappedFields: wrappedFields2, frameworkComponents: frameworkComponents2 } = usePicklistGridFields(fieldConfigurationMemberDictionary, wrappedFields1, filterExpressions, frameworkComponents1, undefined, undefined, useGridFieldConfigurations);
    const wrappedFields3 = useSelectGridFields(fieldConfigurationMemberDictionary, wrappedFields2);
    const wrappedFields4 = useMeasurementCurrencyGridFields(fieldConfigurationMemberDictionary, wrappedFields3);
    const wrappedFields5 = useExpressionGridFields(wrappedFields4, fieldConfigurationMemberDictionary);
    const wrappedFields6 = useGridFieldsWithCustomClipboard(wrappedFields5, fieldConfigurationMemberDictionary);
    const childPropsWithTooltips = React.useMemo(() => getChildPropsWithTooltips({
        wrappedFields: wrappedFields6,
        frameworkComponents: frameworkComponents2,
    }), [wrappedFields6, frameworkComponents2]);
    return Object.assign(Object.assign({}, childPropsWithTooltips), { fieldConfigurationMembers: fieldConfigurationMemberDictionary, isLoading: isLoading1 || !isReady1 });
};
export const useGridInjector = _useGridInjector;
export default useGridInjector;
