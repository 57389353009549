var InputType;
(function (InputType) {
    InputType[InputType["Text"] = 0] = "Text";
    InputType[InputType["Number"] = 1] = "Number";
    InputType[InputType["Date"] = 2] = "Date";
    InputType[InputType["Select"] = 3] = "Select";
    InputType[InputType["Checkbox"] = 4] = "Checkbox";
    InputType[InputType["MultilineText"] = 5] = "MultilineText";
    InputType[InputType["MeasurementField"] = 6] = "MeasurementField";
    InputType[InputType["MultiSelect"] = 7] = "MultiSelect";
    InputType[InputType["CurrencyField"] = 8] = "CurrencyField";
    InputType[InputType["Picklist"] = 9] = "Picklist";
    InputType[InputType["UnitOfMeasure"] = 10] = "UnitOfMeasure";
    InputType[InputType["UnitOfCurrency"] = 11] = "UnitOfCurrency";
})(InputType || (InputType = {}));
const FILE_SIZE_FIELD = 'fileSize';
// We round the number just for UI format. We're saving the float number
export const bytesToKilobytes = (bytes) => {
    if (!bytes)
        return 0;
    const KILOBYTE = 1024;
    const resultNumber = bytes / KILOBYTE;
    return Math.round(resultNumber * 100) / 100;
};
export const transformSizetoKB = (data) => {
    if (!(data === null || data === void 0 ? void 0 : data.value))
        return '';
    const valueFileSize = data.value;
    return `${bytesToKilobytes(valueFileSize)} KB`;
};
export const mapColumnsForStrata = (columns) => {
    return columns.map((column) => {
        let mappedColumn = Object.assign({}, column);
        if (mappedColumn.inputType === InputType.Date) {
            mappedColumn = Object.assign({}, mappedColumn);
        }
        if (mappedColumn.field === FILE_SIZE_FIELD) {
            mappedColumn = Object.assign(Object.assign({}, mappedColumn), { valueFormatter: transformSizetoKB });
        }
        return mappedColumn;
    });
};
export const validateMandatoryFieldsToRenderFilters = (columns) => {
    let isCompleted = true;
    columns.forEach((column) => {
        if (column.inputType === InputType.Picklist) {
            if (!column.filter || !column.floatingFilterComponent) {
                isCompleted = false;
            }
        }
    });
    return isCompleted;
};
