import React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import { HeaderContextProvider } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import RoutingHeader from '@samc/react-ui-history/lib/atoms/RoutingHeader/RoutingHeader';
import { useStyletron } from 'styletron-react';
import { FormScreenWrapper } from '../../FormScreenWrapper/FormScreenWrapper';
const InnerFormPanel = (props) => {
    const { title, formViewId, primaryKeyValue, isOpen, onDismiss, onSubmit, ServiceDrivenViewSet, GridScreenWrapper } = props;
    const [css] = useStyletron();
    return (React.createElement(Panel, { className: "form-panel", layerProps: { eventBubblingEnabled: true }, headerText: title, isOpen: isOpen, onDismiss: onDismiss, type: PanelType.large, 
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel: "Close" },
        React.createElement("div", { className: css({ display: 'flex', flexDirection: 'column', overflow: 'hidden' }) },
            React.createElement(RoutingHeader, null),
            React.createElement(FormScreenWrapper, { className: css({ flex: 1 }), key: `${formViewId}${primaryKeyValue}`, formViewId: formViewId, primaryKeyValue: primaryKeyValue, onSubmit: onSubmit, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper }))));
};
export const FormPanel = (props) => {
    const { title } = props;
    return (React.createElement(HeaderContextProvider, { initialValue: { pageTitle: title } },
        React.createElement(InnerFormPanel, Object.assign({}, props))));
};
export default FormPanel;
