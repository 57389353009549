/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { ViewType } from '@samc/screen-config-api';
import { ToastMessage, toastError } from '@samc/react-ui-core';
import { DocumentModalContainer, DocumentsProvider } from '@samc/screen-config-docs';
import { AddDocumentsModal } from '../../modals/DocumentsModal/AddDocumentsModal/AddDocumentsModal';
import { RemoveDocumentLinksModal } from '../../modals/DocumentsModal/RemoveDocumentLinksModal/RemoveDocumentLinksModal';
/**
 * Displays the correct modal based on the type that the current action is for.
 * - ViewModal
 * - ViewSetModal
 * - DocumentModal
 * @param props
 * @returns
 */
export const AddModalAction = (props) => {
    const { action, onDismiss: onDismissProp, filters, defaultData, primaryKeyValue: parentPrimaryKeyValue, onSaved, GridScreenWrapper, FormScreenWrapper, ViewModal, ViewSetModal, ServiceDrivenViewSet, } = props;
    const { defaultValues, defaultFilterExpression } = action;
    const mergedFilters = React.useMemo(() => [...(filters !== null && filters !== void 0 ? filters : []), defaultFilterExpression].filter((e) => typeof e === 'string'), [filters, defaultFilterExpression]);
    const mergedDefaultData = React.useMemo(() => (Object.assign(Object.assign({}, defaultData), defaultValues)), [defaultData, defaultValues]);
    const [primaryKeyValue, setPrimaryKeyValue] = React.useState();
    const onDismiss = React.useCallback(() => {
        onDismissProp();
        setPrimaryKeyValue(undefined);
    }, [onDismissProp]);
    if (!action.viewId || action.viewType === undefined || action.viewType === null) {
        toastError(React.createElement(ToastMessage, { title: "Missing View Id", message: "View Id is missing from this Action but is required for an Add" }));
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return React.createElement(React.Fragment, null);
    }
    if (action.viewType === ViewType.Form) {
        return (React.createElement(ViewModal, { viewId: action.viewId, viewType: action.viewType, isOpen: true, headerText: action.displayText, onDismiss: onDismiss, onSaved: onSaved, filters: mergedFilters, defaultData: mergedDefaultData, primaryKeyValue: primaryKeyValue, setPrimaryKeyValue: setPrimaryKeyValue, FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }));
    }
    if (action.viewType === ViewType.ViewSet) {
        return (React.createElement(ViewSetModal, { viewSetId: action.viewId, onSaved: onSaved, defaultData: mergedDefaultData, headerText: action.displayText, onDismiss: onDismiss, filters: mergedFilters, primaryKeyValue: primaryKeyValue, setPrimaryKeyValue: setPrimaryKeyValue, ServiceDrivenViewSet: ServiceDrivenViewSet }));
    }
    if (action.viewType === ViewType.ViewDocument) {
        return (React.createElement(DocumentsProvider, null,
            React.createElement(DocumentModalContainer, { title: "Add Documents", className: "document-management-file-uploader" },
                React.createElement(AddDocumentsModal, { viewId: action.viewId, isOpen: true, onDismiss: onDismiss, entityId: parentPrimaryKeyValue, onSaved: onSaved, filters: mergedFilters }))));
    }
    if (action.viewType === ViewType.DocumentLinks) {
        return (React.createElement(RemoveDocumentLinksModal, { viewId: action.viewId, isOpen: true, onDismiss: onDismiss, onSaved: onSaved, filters: mergedFilters, headerText: action.displayText, entityId: parentPrimaryKeyValue, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }));
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null);
};
export default AddModalAction;
