import React from 'react';
import { MessageType } from '@samc/screen-config-api';
import { useThemeContext } from '@samc/react-ui-theme';
import { cloneData } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { BaseTaskResponsePopup } from './BaseTaskResponsePopup/BaseTaskResponsePopup';
import { MessageBody } from './MessageBody/MessageBody';
import { PromptBody } from './PromptBody/PromptBody';
import { generateReassembleableRequest } from './TaskResponsePopupUtils';
import { isSuccessfulResponse } from '../../organisms/FormScreen/utilities';
const hasPromptsGuard = (prompts) => !!(prompts === null || prompts === void 0 ? void 0 : prompts.length);
const extractMessagesWithResponses = (responses) => {
    return responses.reduce((all, curResponse) => {
        const { messages: responseMessages, childResponses } = curResponse;
        const outputs = [...all];
        responseMessages.forEach((message) => {
            outputs.push(Object.assign(Object.assign({}, message), { response: curResponse }));
        });
        // Make sure messages are sorted by row index when applicable
        outputs.sort((a, z) => { var _a, _b; return ((_a = a.index) !== null && _a !== void 0 ? _a : 0) - ((_b = z.index) !== null && _b !== void 0 ? _b : 0); });
        const childMessages = extractMessagesWithResponses(Object.values(childResponses));
        outputs.push(...childMessages);
        return outputs;
    }, []);
};
const extractPrompts = (responses) => {
    return responses.reduce((all, curResponse) => {
        const { prompts, childResponses } = curResponse;
        const outputs = [...all, ...prompts];
        const childPrompts = extractPrompts(Object.values(childResponses));
        outputs.push(...childPrompts);
        return outputs;
    }, []);
};
export function TaskResponsePopup(props) {
    const { responses, requests, domainNameMap, getMessageLocation, submitWithUpdates, submitWithOverrides, visible: isVisibleProp, setVisible, } = props;
    /**
     * Prompts across all responses
     */
    const prompts = React.useMemo(() => extractPrompts(responses), [responses]);
    /**
     * Messages with their associated responses spliced in
     */
    const messages = React.useMemo(() => extractMessagesWithResponses(responses), [responses]);
    const dataByDomain = React.useMemo(() => {
        const clonedRequests = cloneData(requests);
        return generateReassembleableRequest(clonedRequests);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requests, isVisibleProp]);
    const { successfulRequests } = React.useMemo(() => isSuccessfulResponse(responses), [responses]);
    const hasErrors = React.useMemo(() => messages.some((x) => x.type === MessageType.Error), [messages]);
    const hasWarnings = React.useMemo(() => messages.some((x) => x.type === MessageType.Warning), [messages]);
    const infoOnly = !hasErrors && !hasWarnings;
    const hasPrompts = hasPromptsGuard(prompts);
    const showPromptBody = infoOnly && hasPrompts;
    /**
     * Indicates a need to indicate continuing
     */
    const hasAnotherStage = !hasErrors && (hasPrompts || hasWarnings);
    const [additionalButtons, setAdditionalButtons] = React.useState([]);
    const handleSubmit = React.useCallback(() => {
        // eslint-disable-next-line no-unused-expressions
        submitWithOverrides === null || submitWithOverrides === void 0 ? void 0 : submitWithOverrides(messages.map((x) => x.code));
        setVisible(false);
    }, [setVisible, messages, submitWithOverrides]);
    const theme = useThemeContext();
    const accentColor = React.useMemo(() => {
        if (hasErrors || showPromptBody)
            return theme.TextColors.error.toString();
        if (hasWarnings)
            return theme.TextColors.warning.toString();
        return theme.TextColors.primary.toString();
    }, [hasErrors, showPromptBody, theme, hasWarnings]);
    const buttons = React.useMemo(() => {
        const response = [];
        if (!showPromptBody && hasAnotherStage)
            response.push({
                text: 'Continue with Save',
                onClick: handleSubmit,
            });
        return [
            ...response,
            ...additionalButtons,
            { text: hasAnotherStage ? 'Cancel Save' : 'Close', onClick: () => setVisible(false) },
        ];
    }, [showPromptBody, hasAnotherStage, handleSubmit, additionalButtons, setVisible]);
    const description = React.useMemo(() => {
        if (hasErrors)
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, "Save cannot be performed until Errors are cleared"),
                React.createElement("div", null, "Warning violations do not need to be cleared to save.")));
        if (hasWarnings)
            return 'Warning violations do not need to be cleared to save.';
        if (showPromptBody)
            return 'Save cannot be performed until you finish the following tasks';
        return 'The save completed but the following actions could not be performed. No action is needed. This is informational only.';
    }, [hasErrors, hasWarnings, showPromptBody]);
    const title = React.useMemo(() => {
        if (infoOnly && !showPromptBody)
            return 'Save Completed';
        if (successfulRequests.length)
            return 'Some changes have not been saved';
        return 'No changes have been saved';
    }, [infoOnly, showPromptBody, successfulRequests.length]);
    const isVisible = isVisibleProp && (hasPrompts || !!messages.length);
    React.useEffect(() => {
        if (!isVisible && isVisibleProp)
            setVisible(false);
    }, [isVisible, setVisible, isVisibleProp]);
    return (React.createElement(BaseTaskResponsePopup, { title: title, accentColor: accentColor, buttons: buttons, visible: isVisible, setVisible: setVisible, description: description, showDivider: showPromptBody },
        !showPromptBody && (React.createElement(MessageBody, { getMessageLocation: getMessageLocation, domainNameMap: domainNameMap, dataByDomain: dataByDomain, messages: messages })),
        showPromptBody && (React.createElement(PromptBody, { submitWithUpdates: submitWithUpdates, reassembleableDataByDomain: dataByDomain, infoMessages: messages, prompts: prompts, domainNameMap: domainNameMap, setAdditionalButtons: setAdditionalButtons }))));
}
export default TaskResponsePopup;
