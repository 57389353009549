/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useRequestGetter } from '@samc/react-ui-request';
import { getFormView } from '../api/Requests';
import { FORM_KEY } from '../useFormView/useFormView';
import { CacheTime } from '../CacheTime';
import { useApiContext } from '../ApiContext';
export const useFormViewGetter = () => {
    const { AggregatorApi, requestInit } = useApiContext();
    const query = React.useCallback((id) => {
        return getFormView(AggregatorApi, id, requestInit);
    }, [AggregatorApi, requestInit]);
    const formviewGetter = useRequestGetter({ key: FORM_KEY, query, options: { staleTime: CacheTime.default } });
    return formviewGetter;
};
export default useFormViewGetter;
