var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { getDisplayProperty, getOrderBy, usePicklistItemGetter, } from '@samc/picklist-api';
import { toastError } from '@samc/react-ui-core';
/**
 * Retrieves the dropzone data and handles loading state.
 * @param params
 * @returns dropzone data, loading state and callback to execute onStateChange to update sort
 */
export function useDropzoneData(params) {
    const { fieldName, filters, fieldConfiguration, initialState } = params;
    const [dropzoneState, setDropzoneState] = React.useState(initialState);
    const getDBPicklistItems = usePicklistItemGetter();
    const hasAscendantOrder = window.location.pathname.indexOf('reference-documents') < 0;
    /**
     * Retrieves the picklist data.
     * @param forceReload whether to force cache invalidation or not. The cache can still be stale, causing new data.
     * @returns the picklist data.
     */
    const fetch = React.useCallback((forceReload) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const picklistField = (_a = fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers.find((f) => f.viewFieldName === fieldName)) === null || _a === void 0 ? void 0 : _a.picklistField;
        if (!picklistField)
            throw new Error(`Field ${fieldName} does not contain a picklist or it has not yet loaded`);
        const { picklistId } = picklistField;
        const orderBy = getOrderBy(getDisplayProperty(picklistField.displaySetting), picklistField.sortType, picklistField.customSortField ? getDisplayProperty(picklistField.customSortField) : '', picklistField.gridFieldConfiguration);
        const { items } = yield getDBPicklistItems(picklistId, {
            hardReload: !!forceReload,
            additionalFields: [
                {
                    scalarDisplayName: 'categoryIds',
                    scalarExpression: '[CategoryIds]',
                },
                {
                    scalarDisplayName: 'categories',
                    scalarExpression: '[Categories]',
                },
                {
                    scalarDisplayName: 'fileTypeNames',
                    scalarExpression: '[FileTypeNames]',
                },
                {
                    scalarDisplayName: 'documentTypeCode',
                    scalarExpression: '[DocumentTypeCode]',
                },
            ],
            filters,
            limit: 1000, // there should never be that many dropzones
            sortOverride: {
                order: hasAscendantOrder ? 'asc' : null,
                orderBy: {
                    scalarExpression: hasAscendantOrder ? orderBy : '[Sorting]',
                },
            },
        });
        return items;
    }), [fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers, fieldName, filters, getDBPicklistItems]);
    const loadData = React.useCallback((forceReload) => __awaiter(this, void 0, void 0, function* () {
        setDropzoneState((prevState) => (Object.assign(Object.assign({ data: [] }, prevState), { isLoading: true })));
        try {
            const value = yield fetch(forceReload);
            const data = value.filter((item) => item.isActive === true);
            setDropzoneState({ data, isLoading: false });
        }
        catch (e) {
            toastError(String(e));
            setDropzoneState((prevState) => (Object.assign(Object.assign({ data: [] }, prevState), { isLoading: false })));
        }
    }), [fetch]);
    // dropzoneData is only undefined before initial data load
    React.useEffect(() => {
        if (fieldConfiguration && !dropzoneState) {
            loadData(false);
        }
    }, [fieldConfiguration, dropzoneState, loadData]);
    const sortData = React.useCallback(({ orderBy, order }) => {
        setDropzoneState((prevData) => {
            if (prevData) {
                const newData = [...prevData.data];
                newData.sort((a, b) => (String(a[orderBy]) || '').localeCompare(String(b[orderBy]) || ''));
                if (order === 'desc')
                    newData.reverse();
                return { data: newData, isLoading: false };
            }
            return prevData;
        });
    }, []);
    /**
     * Updates the picklist data with new sort
     * @param StateChangedEvent state changed event from dropzone, including updated sort state
     */
    const onStateChange = React.useCallback((event) => __awaiter(this, void 0, void 0, function* () {
        if (event.eventType === 'sort') {
            if (event.sortState.orderBy) {
                sortData(event.sortState);
            }
            else {
                loadData(false);
            }
        }
    }), [loadData, sortData]);
    return {
        data: (dropzoneState === null || dropzoneState === void 0 ? void 0 : dropzoneState.data) || [],
        isLoading: (dropzoneState === null || dropzoneState === void 0 ? void 0 : dropzoneState.isLoading) || false,
        onStateChange,
    };
}
export default useDropzoneData;
