import React from 'react';
import { useApplicationContext, useEntityEntitlementContext } from '@samc/screen-config-api';
import { LinkType } from '../../enums/LinkType';
export const useEntitledFormView = (formView, primaryKeyValue = undefined) => {
    const { Entitlements } = useApplicationContext();
    const { currentEntityEntitlements: currentRecordEntitlements } = useEntityEntitlementContext();
    const { GenerateFormViewLinkEntitlementName } = Entitlements;
    const formViewId = formView === null || formView === void 0 ? void 0 : formView.id;
    const getEntitledSectionLayout = React.useCallback((sectionLayout) => {
        if (!sectionLayout)
            return undefined;
        const { link } = sectionLayout;
        let entitledLink = link;
        if (formViewId !== undefined &&
            (link === null || link === void 0 ? void 0 : link.target) &&
            (link.to === LinkType.Modal || link.to === LinkType.ConfirmationModal)) {
            // This link meets the criteria for an entitlement check
            const requiredEntitlement = GenerateFormViewLinkEntitlementName(formViewId, link.target);
            if (!(currentRecordEntitlements === null || currentRecordEntitlements === void 0 ? void 0 : currentRecordEntitlements[requiredEntitlement])) {
                // User does not have required entitlement for this link - remove it
                entitledLink = undefined;
            }
        }
        return Object.assign(Object.assign({}, sectionLayout), { link: entitledLink, children: sectionLayout.children
                .map((child) => getEntitledSectionLayout(child))
                // Reassure TS that only defined children are included in the array
                .filter((x) => x !== undefined) });
    }, [GenerateFormViewLinkEntitlementName, currentRecordEntitlements, formViewId]);
    const entitledFormView = React.useMemo(() => {
        var _a, _b;
        return formView
            ? Object.assign(Object.assign({}, formView), { formLayout: Object.assign(Object.assign({}, formView === null || formView === void 0 ? void 0 : formView.formLayout), { tabs: (_b = (_a = formView === null || formView === void 0 ? void 0 : formView.formLayout) === null || _a === void 0 ? void 0 : _a.tabs.map((t) => (Object.assign(Object.assign({}, t), { sections: t.sections.map((s) => (Object.assign(Object.assign({}, s), { links: getEntitledSectionLayout(s.links), fieldLayout: getEntitledSectionLayout(s.fieldLayout) }))) })))) !== null && _b !== void 0 ? _b : [] }) }) : undefined;
    }, [getEntitledSectionLayout, formView]);
    return entitledFormView;
};
export default useEntitledFormView;
