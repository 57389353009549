// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ms-DetailsRow-cell {
    width: auto !important;
}`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-screen-config-core-virtual-2101c279b0/1/packages/core/lib/organisms/ConfigureDomain/ConfigureForms/FormViewEditor/FormViewEditor.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B","sourcesContent":[".ms-DetailsRow-cell {\n    width: auto !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
