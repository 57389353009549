import React from 'react';
import { useQueryClient } from 'react-query';
import { getQueryKey } from '@samc/react-ui-request';
import { saveViewSet } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { VIEW_SET_KEY } from '../useViewSet/useViewSet';
import { VIEW_SETS_BY_DOMAIN_KEY } from '../useViewSetsByDomain/useViewSetsByDomain';
import { ApiContext } from '../ApiContext';
/**
 * Returns an array containing a method to save a view set and an object
 * representing the current state of the save request. Calling the save function
 * will invalidate the cache for any requests for a view set with the same ID.
 * @param viewSet View set to be saved.
 */
// eslint-disable-next-line import/prefer-default-export
export const useViewSetSaver = (viewSet) => {
    var _a;
    const api = React.useContext(ApiContext);
    const queryClient = useQueryClient();
    const query = (vs) => saveViewSet(api.AggregatorApi, api.requestInit)(vs)
        .then((res) => {
        api.ShowMessage('Screen saved successfully', 'success');
        // also reset cache if fetched by domain
        queryClient.invalidateQueries(getQueryKey(VIEW_SET_KEY, [vs.id]));
        queryClient.invalidateQueries(getQueryKey(VIEW_SETS_BY_DOMAIN_KEY, [vs.domainId]));
        return res;
    })
        .catch((err) => {
        api.ShowMessage('Failed to save Screen', 'error');
        throw err;
    });
    return useMutation(query, [VIEW_SET_KEY, api.AggregatorApi, (_a = viewSet.id) !== null && _a !== void 0 ? _a : '']);
};
