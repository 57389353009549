/* eslint-disable no-void */
import React from 'react';
import { CustomModal, DomainContextProvider, DomainFilterContextProvider, ExpressionContext, ExpressionEditorWithToolbar, PreviewMode, } from '@samc/vmsnext-querybuilder';
import { ExpressionEntryField, useFieldData } from '@samc/react-ui-form';
import { useStyletron } from 'styletron-react';
import { useDomain } from '@samc/screen-config-api';
import { IconButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
import { ClassNameBuilder, useDebouncedCallback } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
import { QueryBuilderContextProvider } from '../../organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
import './ExpressionField.css';
export const ExpressionFieldInner = (props) => {
    const { value, onChange, multiline, filterMode, summaryMode, showEditor, setShowEditor, editingFieldName, hasError, valueHasChanged, className, disabled, } = props;
    const { evaluate } = useEvaluator();
    const theme = useThemeContext();
    const [css] = useStyletron();
    const { functionList } = React.useContext(DomainFilterContextProvider);
    const { domainFieldList, lookups } = React.useContext(DomainContextProvider);
    const ref = React.useRef(null);
    const [isScrollingV, setScrollingV] = React.useState(false);
    const [isScrollingH, setScrollingH] = React.useState(false);
    const [hasInternalError, setHasInternalError] = React.useState(false);
    React.useEffect(() => {
        if (!multiline)
            return;
        const check = () => {
            var _a, _b;
            if (!ref.current)
                return;
            const hScrollbar = ref.current.querySelector('.ace_scrollbar-h');
            const vScrollbar = ref.current.querySelector('.ace_scrollbar-v');
            setScrollingV(!!vScrollbar && !((_a = vScrollbar.getAttribute('style')) === null || _a === void 0 ? void 0 : _a.includes('display: none;')));
            setScrollingH(!!hScrollbar && !((_b = hScrollbar.getAttribute('style')) === null || _b === void 0 ? void 0 : _b.includes('display: none;')));
        };
        setTimeout(check, 100);
    }, [value, multiline]);
    const fields = React.useMemo(() => (domainFieldList || []).map((f) => f.fieldName), [domainFieldList]);
    const validateField = useDebouncedCallback((newValue) => {
        if (!newValue) {
            setHasInternalError(false);
            return;
        }
        const testData = {};
        fields.forEach((x) => {
            testData[x] = '';
        });
        try {
            evaluate(testData, newValue, false);
            setHasInternalError(false);
        }
        catch (e) {
            setHasInternalError(true);
        }
    }, [fields, evaluate], 100);
    const handleChange = React.useCallback((newValue) => {
        onChange(newValue);
        validateField(newValue);
    }, [onChange, validateField]);
    return (React.createElement("div", { className: ClassNameBuilder(className, 'sc-expression-field', multiline ? 'multiline' : 'inline', css({ position: 'relative', zIndex: 0 })), ref: ref },
        React.createElement(IconButton, { onClick: () => setShowEditor(true), styles: {
                icon: { fontSize: multiline ? '160%' : undefined, color: theme.TextColors.primary.toString() },
            }, className: css(Object.assign(Object.assign({ position: 'absolute', zIndex: 10, backgroundColor: theme.FormColors.backgroundColors.dark.toString() }, (multiline && { bottom: isScrollingH ? '21px' : '5px', right: isScrollingV ? '21px' : '5px' })), (!multiline && {
                top: '0px',
                bottom: '0px',
                right: '2px',
                height: 'calc(100% - 4px)',
                margin: 'auto',
            }))), iconProps: { iconName: 'Edit' }, disabled: disabled }),
        React.createElement(ExpressionEntryField, { extraProps: { fields, functions: (functionList === null || functionList === void 0 ? void 0 : functionList.map(String)) || [], lookups }, value: value || '', onChange: handleChange, multiline: multiline, name: "Expression Field", onBlur: () => void 0, hasError: hasInternalError || hasError, valueHasChanged: valueHasChanged, disabled: disabled }),
        showEditor && (React.createElement(CustomModal, { onDismiss: () => setShowEditor(false) },
            React.createElement(ExpressionContext, { filterMode: filterMode, summaryMode: summaryMode, initialMode: "Centric", initialExpression: value, editingFieldName: editingFieldName },
                React.createElement(ExpressionEditorWithToolbar, { previewMode: PreviewMode.expression, onSave: (expressionState) => {
                        onChange(expressionState.localExpression);
                        setShowEditor(false);
                    }, onClose: () => setShowEditor(false), title: "Expression Editor", subtitle: editingFieldName, isSaveDisabled: false }))))));
};
export const ExpressionField = (props) => {
    const { domainId, onChange, hasError, disabled } = props;
    const [showEditor, setShowEditor] = React.useState(false);
    const { data: domain } = useDomain(domainId);
    if (!domain)
        return (React.createElement("div", null,
            React.createElement(Spinner, { size: SpinnerSize.small })));
    return (React.createElement(QueryBuilderContextProvider, { domainId: domain.sequentialId },
        React.createElement(ExpressionFieldInner, Object.assign({}, props, { hasError: hasError, onChange: onChange, showEditor: showEditor, setShowEditor: setShowEditor, disabled: disabled }))));
};
export const ExpressionFormField = (props) => {
    const { editingFieldName } = props;
    const { displayName } = useFieldData(editingFieldName);
    return React.createElement(ExpressionField, Object.assign({}, props, { editingFieldName: displayName }));
};
export const getExpressionFormField = (domainId, fieldName, multiline) => 
// eslint-disable-next-line react/display-name
(props) => {
    const { value, onChange, hasError, valueHasChanged, disabled } = props;
    return (React.createElement(ExpressionFormField, { value: value !== undefined && value !== null ? String(value) : '', hasError: hasError, valueHasChanged: valueHasChanged, onChange: onChange, domainId: domainId, editingFieldName: fieldName, multiline: multiline, disabled: disabled }));
};
