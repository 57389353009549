import React from 'react';
import { AllGridApis } from '@samc/react-ui-grid';
export const aggregateBooleanValues = (arr) => {
    if (arr.some((v) => v === true))
        return true;
    if (arr.some((v) => v === false))
        return false;
    return undefined;
};
export const useFormGridFieldHelpers = () => {
    const [gridFieldRequests, setGridFieldRequests] = React.useState({});
    const gridFieldApis = React.useRef({});
    const gridFieldApiSetters = React.useRef({});
    const gridFieldRefs = React.useRef({});
    const gridFieldDomains = React.useRef({});
    const onGridApiChanged = React.useCallback((requestIdentifier, { gridApi, gridType, columnApi }, ref) => {
        const currentSetter = gridFieldApiSetters.current[requestIdentifier];
        if (!currentSetter) {
            gridFieldApis.current[requestIdentifier] = new AllGridApis({
                initialApis: {
                    [gridType]: {
                        gridApi,
                        columnApi,
                    },
                },
                privateCallbackGetter: (cbs) => {
                    gridFieldApiSetters.current[requestIdentifier] = cbs.setGridApis;
                },
            });
        }
        else
            currentSetter(gridType, { gridApi, columnApi });
        gridFieldRefs.current = Object.assign(Object.assign({}, gridFieldRefs.current), { [requestIdentifier]: ref });
    }, []);
    const onUnmounted = React.useCallback((requestIdentifier) => {
        setGridFieldRequests((reqs) => {
            const newReqs = Object.assign({}, reqs);
            delete newReqs[requestIdentifier];
            return newReqs;
        });
        delete gridFieldApis.current[requestIdentifier];
        delete gridFieldApiSetters.current[requestIdentifier];
        delete gridFieldRefs.current[requestIdentifier];
        delete gridFieldDomains.current[requestIdentifier];
    }, []);
    const onGridRequestChange = React.useCallback((requestIdentifier, gridFieldRequest) => {
        setGridFieldRequests((cur) => {
            const newVal = Object.assign({}, cur);
            if (gridFieldRequest) {
                newVal[requestIdentifier] = gridFieldRequest;
            }
            else {
                delete newVal[requestIdentifier];
            }
            return newVal;
        });
    }, []);
    const onDomainLoaded = React.useCallback((dom, requestIdentifier) => {
        gridFieldDomains.current[requestIdentifier] = dom;
    }, []);
    const onRequestIdentifierChanged = React.useCallback((oldRequestIdentifier, newRequestIdentifier) => {
        // update requests
        setGridFieldRequests((reqs) => {
            const originalReq = reqs[oldRequestIdentifier];
            if (!originalReq)
                return reqs;
            const newReqs = Object.assign({}, reqs);
            delete newReqs[oldRequestIdentifier];
            newReqs[newRequestIdentifier] = Object.assign(Object.assign({}, originalReq), { requestIdentifier: newRequestIdentifier });
            return newReqs;
        });
        gridFieldApis.current[newRequestIdentifier] = gridFieldApis.current[oldRequestIdentifier];
        delete gridFieldApis.current[oldRequestIdentifier];
        gridFieldRefs.current[newRequestIdentifier] = gridFieldRefs.current[oldRequestIdentifier];
        delete gridFieldRefs.current[oldRequestIdentifier];
        gridFieldDomains.current[newRequestIdentifier] = gridFieldDomains.current[oldRequestIdentifier];
        delete gridFieldDomains.current[oldRequestIdentifier];
    }, []);
    return {
        gridFieldRequests,
        gridFieldApis,
        gridFieldRefs,
        gridFieldDomains,
        onDomainLoaded,
        onRequestIdentifierChanged,
        onUnmounted,
        onGridApiChanged,
        onGridRequestChange,
    };
};
export default useFormGridFieldHelpers;
