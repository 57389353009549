import React from 'react';
import { useStyletron } from 'styletron-react';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { ViewType } from '@samc/screen-config-api';
import { GridScreenWrapper } from '../GridScreenWrapper/GridScreenWrapper';
import { FormScreenWrapper } from '../FormScreenWrapper/FormScreenWrapper';
import { useTabOverrides } from '../../contexts/TabOverrideContext/TabOverrideContext';
import { ContactAssignmentFormScreenWrapper } from '../ContactAssignmentFormScreenWrapper/ContactAssignmentFormScreenWrapper';
import { getSelectedFilterIds, getSelectedListViewId, saveSelectedFilter, saveSelectedListView, } from '../GridScreen/GridScreen.utilities';
import { onSetState } from '../../utils/State/StateUtils';
import { GridWithHeaderLinks } from '../GridWithHeaderLinks/GridWithHeaderLinks';
import { isReferenceDocumentView } from '../modals/DocumentsModal/DocumentsModal.Utilities';
/**
 * Takes ViewSet metadata and renders tabs along with the Grid or Form under each tab.
 * @param props
 * @returns
 */
export const ViewSet = (props) => {
    const { primaryKeyValue, defaultData, onSelectionChanges, baselineFilterExpression, filters, tabs, parentScope, useFormView, submitAllAttributes, onSubtabChanged, initialSelectedSubtabId, ServiceDrivenViewSet, } = props;
    const { selectedTabId } = useHeaderContext();
    const [css] = useStyletron();
    const selectedViewTab = selectedTabId ? tabs[selectedTabId] : undefined;
    const { defaultLayout, title: selectedTabHeader, sidePanelForm: selectedTabViewSidePanelForm, controlVisibilities: selectedTabControlVisibilities, baselineFilterExpression: selectedTabBaselineFilterExpression, } = selectedViewTab !== null && selectedViewTab !== void 0 ? selectedViewTab : {};
    const { viewId: selectedTabViewId, type: selectedTabViewType, title: sectionTitle } = defaultLayout !== null && defaultLayout !== void 0 ? defaultLayout : {};
    const scope = parentScope ? `${parentScope}_${selectedTabId}` : undefined;
    const [listViewId, _setListViewId] = React.useState(scope ? getSelectedListViewId({ scope, defaultListViewId: selectedTabViewId }) : selectedTabViewId);
    const [selectedFilterIds, _setSelectedFilterIds] = React.useState(scope ? getSelectedFilterIds({ scope, defaultFilterIds: [] }) : []);
    const tabOverrides = useTabOverrides();
    const onFormTabChanged = (newId, { userRequested }) => onSubtabChanged && onSubtabChanged({ newId, userRequested });
    React.useLayoutEffect(() => {
        let newListViewId;
        if (scope)
            newListViewId = getSelectedListViewId({
                scope,
                defaultListViewId: selectedTabViewId,
            });
        else
            newListViewId = selectedTabViewId;
        _setListViewId(newListViewId);
    }, [scope, selectedTabId, selectedTabViewId]);
    React.useLayoutEffect(() => {
        let newFilterIds = [];
        if (scope)
            newFilterIds = getSelectedFilterIds({ scope, defaultFilterIds: [] });
        _setSelectedFilterIds(newFilterIds);
    }, [scope, selectedTabId]);
    const setListViewId = React.useCallback((action) => {
        onSetState(_setListViewId, action, (newListViewId) => {
            if (scope && newListViewId)
                saveSelectedListView({
                    scope,
                    defaultListViewId: selectedTabViewId,
                    selectedListViewId: newListViewId,
                });
        });
    }, [scope, selectedTabViewId]);
    const setSelectedFilterIds = React.useCallback((action) => {
        onSetState(_setSelectedFilterIds, action, (newFilterIds) => {
            if (scope)
                saveSelectedFilter({
                    scope,
                    selectedFilterIds: newFilterIds,
                    defaultFilterIds: [],
                });
        });
    }, [scope]);
    const mergedFilters = React.useMemo(() => [...(filters !== null && filters !== void 0 ? filters : []), baselineFilterExpression, selectedTabBaselineFilterExpression].filter((exp) => typeof exp === 'string'), [baselineFilterExpression, filters, selectedTabBaselineFilterExpression]);
    const customTab = React.useMemo(() => {
        if (!(tabOverrides === null || tabOverrides === void 0 ? void 0 : tabOverrides.tabRenderer))
            return undefined;
        const { tabRenderer, renderingViewSetIds } = tabOverrides;
        if (selectedTabId === undefined ||
            selectedTabViewId === undefined ||
            selectedTabViewType === undefined ||
            listViewId === undefined)
            return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Cannot render custom tab with missing parameters." }));
        return tabRenderer({
            parentViewSetIds: renderingViewSetIds,
            tabId: selectedTabId,
            tabHeader: selectedTabHeader,
            viewId: selectedTabViewId,
            viewType: selectedTabViewType,
            listViewId,
            setListViewId: setListViewId,
            filters,
            defaultData,
            selectedFilterIds,
            setSelectedFilterIds,
            primaryKeyValue,
            sidePanelFormViewId: selectedTabViewSidePanelForm,
            parentScope,
            onSelectionChanges,
            baselineFilterExpression,
            tabs,
        });
    }, [
        defaultData,
        filters,
        listViewId,
        primaryKeyValue,
        selectedFilterIds,
        selectedTabHeader,
        selectedTabId,
        selectedTabViewId,
        selectedTabViewSidePanelForm,
        selectedTabViewType,
        setListViewId,
        setSelectedFilterIds,
        tabOverrides,
        parentScope,
        onSelectionChanges,
        baselineFilterExpression,
        tabs,
    ]);
    if (customTab) {
        return customTab;
    }
    switch (selectedTabViewType) {
        case ViewType.Grid:
            return typeof listViewId === 'string' ? (React.createElement(GridScreenWrapper, { key: listViewId, listViewId: listViewId, setListViewId: setListViewId, filters: mergedFilters, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, defaultData: defaultData, onSelectionChanges: onSelectionChanges, gridDisplayOptions: selectedTabControlVisibilities, onListViewLoadFailed: (failedParams) => {
                    // if the load fails, revert to the default for the tab
                    if (failedParams.listViewId !== selectedTabViewId)
                        setListViewId(selectedTabViewId);
                }, ServiceDrivenViewSet: ServiceDrivenViewSet })) : (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Cannot load grid with undefined listView Id." }));
        case ViewType.ContactAssignmentGrid:
            return typeof selectedTabViewId === 'string' ? (React.createElement(ContactAssignmentFormScreenWrapper, { ServiceDrivenViewSet: ServiceDrivenViewSet, parentScope: scope, key: listViewId, formViewId: selectedTabViewId, filters: mergedFilters, defaultData: defaultData, primaryKeyValue: primaryKeyValue, sidePanelForm: selectedTabViewSidePanelForm, controlVisibilities: selectedTabControlVisibilities, submitAllAttributes: submitAllAttributes, onTabChanged: onFormTabChanged, initialTabIndexOrName: initialSelectedSubtabId })) : (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Cannot load Contact Assignment grid with undefined listView Id." }));
        case isReferenceDocumentView(listViewId) && ViewType.ViewDocument:
            return typeof listViewId === 'string' ? (React.createElement(GridWithHeaderLinks, { listViewId: listViewId, setListViewId: setListViewId, filters: mergedFilters, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, defaultData: defaultData, onSelectionChanges: onSelectionChanges, gridDisplayOptions: selectedTabControlVisibilities, ServiceDrivenViewSet: ServiceDrivenViewSet, selectedTabViewId: selectedTabViewId, sectionTitle: sectionTitle, baselineFilterExpression: selectedTabBaselineFilterExpression })) : (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Cannot load grid with undefined listView Id." }));
        case ViewType.Form:
            return typeof selectedTabViewId === 'string' ? (React.createElement(FormScreenWrapper, { parentScope: scope, key: primaryKeyValue, formViewId: selectedTabViewId, primaryKeyValue: primaryKeyValue, filters: mergedFilters, defaultData: defaultData, sidePanelForm: selectedTabViewSidePanelForm, controlVisibilities: selectedTabControlVisibilities, useFormView: useFormView, submitAllAttributes: submitAllAttributes, onTabChanged: onFormTabChanged, initialTabIndexOrName: initialSelectedSubtabId, className: css({ height: '100%', flex: '1' }), ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper })) : (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Cannot load form with undefined formView Id." }));
        default:
            return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error", text: "Cannot load tab with undefined view type." }));
    }
};
export default ViewSet;
