import { HorizontalAlignment, Measurements, Currencies } from '@samc/react-ui-core';
import { InputType, NumberInputTypes, BooleanInputTypes, DateInputTypes, } from '@samc/screen-config-api';
import { CalculationFrequency } from '@samc/screen-config-api/lib/model/CalculationFrequency';
import { mapUIInputType } from '../../organisms/FormScreen/utilities';
export class FetchItems {
}
/**
 * Returns hard coded measurements as a key-value pair[]
 * @param valueProperty value property to use
 * @param displayProperty display property to use
 */
FetchItems.fetchItems = (valueProperty, displayProperty) => {
    const units = Object.keys(Measurements).map((x) => ({
        [valueProperty]: x,
        [displayProperty]: Measurements[x],
    }));
    return units;
};
FetchItems.fetchMonetaryItems = (valueProperty, displayProperty) => {
    // make api call to Oanda
    const currencyUnits = Object.keys(Currencies).map((x) => ({
        [valueProperty]: x,
        [displayProperty]: Currencies[x],
    }));
    return currencyUnits;
};
FetchItems.filterInputTypes = (inputTypes, domainField) => {
    const { datasourceDataType } = domainField;
    if (datasourceDataType.match(/(bit)/i)) {
        return inputTypes.filter((i) => BooleanInputTypes.includes(i));
    }
    if (datasourceDataType.match(/(bit|int|decimal|numeric|money|float|real)/i)) {
        return inputTypes.filter((i) => NumberInputTypes.includes(i));
    }
    if (datasourceDataType.match(/(date)/i)) {
        return inputTypes.filter((i) => DateInputTypes.includes(i));
    }
    return inputTypes;
};
/**
 * Returns inputType items as a key-value pair[]
 * @param valueProperty value property to use
 * @param displayProperty display property to use
 */
FetchItems.fetchInputTypes = (valueProperty, displayProperty, domainField) => {
    const units = [];
    let inputTypes = Object.keys(InputType)
        .map((n) => Number(n))
        .filter((key) => Number.isInteger(key));
    if (domainField)
        inputTypes = FetchItems.filterInputTypes(inputTypes, domainField);
    inputTypes.forEach((item) => {
        units.push({ [valueProperty]: item, [displayProperty]: InputType[item] });
    });
    return units;
};
/**
 * Returns editable, required and visible expressions that are tied to a inputType
 * by default maps @param inputType to UIInputType
 * set @param apiInputType to true to no longer map
 * @param inputType UI or API input types to link expressions to, link is a 'or'
 * @param expressionType what type of expression to return
 * @param expressionValue value to assign to the isLocked property
 * @param apiInputType must be true if the inputType is API InputType
 */
FetchItems.fetchFieldExpression = (expressionType, expressionValue, apiInputType, ...inputType) => {
    let expression = '';
    let count = 0;
    inputType.forEach((type) => {
        const mappedInputType = apiInputType ? type : mapUIInputType(type);
        switch (expressionType) {
            case 'editable':
                expression +=
                    count === 0
                        ? `[isLocked] = ${!expressionValue} and [inputType] = ${mappedInputType}`
                        : ` or [inputType] = ${mappedInputType} `;
                break;
            case 'required':
                expression +=
                    count === 0 ? `[inputType] = ${mappedInputType}` : ` or [inputType] = ${mappedInputType}`;
                break;
            case 'visible':
                expression +=
                    count === 0 ? `[inputType] = ${mappedInputType}` : ` or [inputType] = ${mappedInputType}`;
                break;
            default:
                return undefined;
        }
        count += 1;
        return undefined;
    });
    return expression;
};
/**
 * Returns all fields from field configuration that match the inputType param
 * @param inputType API inputType of the field we are trying to fetch
 * @param valueProperty valueProperty for select field
 * @param displayProperty displayProperty for select field
 * @param fieldconfiguration current field configuration to search in
 */
FetchItems.fetchFields = (inputType, valueProperty, displayProperty, fieldConfiguration) => {
    const fields = [];
    fieldConfiguration.fieldConfigurationMembers.forEach((value) => {
        if (value.inputType === inputType) {
            fields.push({
                [valueProperty]: value.viewFieldName,
                [displayProperty]: value.viewFieldName,
            });
        }
    });
    return fields;
};
/**
 * Returns horizontal alignment items as a key-value pair[]
 * @param valueProperty value property to use
 * @param displayProperty display property to use
 */
FetchItems.fetchHorizontalAlignment = (valueProperty, displayProperty) => {
    const units = [];
    Object.keys(HorizontalAlignment)
        // eslint-disable-next-line no-restricted-globals
        .filter((key) => !isNaN(Number(HorizontalAlignment[key])))
        .forEach((item) => {
        units.push({ [valueProperty]: HorizontalAlignment[item], [displayProperty]: item });
    });
    return units;
};
/**
 * Returns horizontal alignment items as a key-value pair[]
 * @param valueProperty value property to use
 * @param displayProperty display property to use
 */
FetchItems.fetchCalculationFrequency = (valueProperty, displayProperty) => {
    const units = [];
    Object.keys(CalculationFrequency)
        // eslint-disable-next-line no-restricted-globals
        .filter((key) => !isNaN(Number(CalculationFrequency[key])))
        .forEach((item) => {
        units.push({
            [valueProperty]: CalculationFrequency[item],
            [displayProperty]: item,
        });
    });
    return units;
};
export default FetchItems;
