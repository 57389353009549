import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getListViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const LISTVIEWS_BY_DOMAIN_KEY = `${SCREEN_CONFIG_API_KEY}list_views_by_domain`;
export const useListViewsByDomain = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (dId) => getListViews(api.AggregatorApi, dId, api.requestInit);
    return useRequest(LISTVIEWS_BY_DOMAIN_KEY, query, ApiContext, domainId);
};
