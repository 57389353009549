var linkType;
(function (linkType) {
    /**
     * A modal that opens a form view to edit - in the grid this column will show an edit icon
     */
    linkType["Edit"] = "Edit";
    linkType["FormView"] = "FormView";
    linkType["Href"] = "href";
    linkType["Screen"] = "screen";
    /**
     * A modal that opens a form view to edit
     */
    linkType["Modal"] = "Modal";
    /**
     * A modal that opens the first tab of a view set
     */
    linkType["ViewSetModal"] = "ViewSetModal";
    linkType["HrefDocs"] = "hrefDocs";
    linkType["DocCountLink"] = "DocCountLink";
})(linkType || (linkType = {}));
var domainType;
(function (domainType) {
    domainType["Document"] = "Document";
    domainType["Asset"] = "Asset";
})(domainType || (domainType = {}));
export { linkType, domainType };
