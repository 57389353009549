var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useQueryClient } from 'react-query';
import { saveFormView } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { FORM_KEY } from '../useFormView/useFormView';
import { ApiContext } from '../ApiContext';
import { FORM_VIEW_BY_DOMAIN_KEY } from '../useFormViewsByDomainRequest/useFormViewsByDomainRequest';
/**
 * Returns an array containing a method to save a FormView and an object
 * representing the current state of the save request. Calling the save function
 * will invalidate the cache for any requests for a FormView with the same ID.
 * @param formView FormView to be saved.
 */
// eslint-disable-next-line import/prefer-default-export
export const useFormViewSaver = (formViewId) => {
    const api = React.useContext(ApiContext);
    const queryClient = useQueryClient();
    const query = (formView) => __awaiter(void 0, void 0, void 0, function* () {
        const handler = saveFormView(api.AggregatorApi, api.requestInit);
        return handler(formView)
            .then((result) => {
            if (!result || !result.id) {
                throw new Error('No FormView or FormView Id was Provided');
            }
            api.ShowMessage(`FormView saved successfully`, 'success');
            const { domainId, id } = result;
            // also reset cache if fetched by domain
            queryClient.invalidateQueries([FORM_KEY, id]);
            queryClient.invalidateQueries([FORM_VIEW_BY_DOMAIN_KEY, domainId]);
            return result;
        })
            .catch((err) => {
            var _a;
            api.ShowMessage(`Failed to save FormView due to ${(_a = err.message) !== null && _a !== void 0 ? _a : 'unknown error'}`, 'error');
            throw err;
        });
    });
    return useMutation(query, [FORM_KEY, api.AggregatorApi, formViewId !== null && formViewId !== void 0 ? formViewId : '']);
};
