import React from 'react';
import { useFieldConfigurationByDomain } from '@samc/screen-config-api';
import { usePreparedEvaluatorConfiguration, } from '../usePreparedEvaluatorConfiguration/usePreparedEvaluatorConfiguration';
export const usePreparedEvaluatorConfigurationByDomain = (domainId) => {
    const { data } = useFieldConfigurationByDomain(domainId || '');
    const members = React.useMemo(() => {
        var _a;
        if (Array.isArray(data) && data.length > 0) {
            return (_a = data[0].fieldConfigurationMembers) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => {
                const accumlated = Object.assign({}, prev);
                accumlated[curr.viewFieldName] = curr;
                return accumlated;
            }, {});
        }
        return {};
    }, [data]);
    return usePreparedEvaluatorConfiguration(members);
};
export default usePreparedEvaluatorConfigurationByDomain;
