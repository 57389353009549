import React from 'react';
import { ToastMessage, toastWarning, toastError, toastInfo, ErrorMessageType } from '@samc/react-ui-core';
import { toast } from 'react-toastify';
import TaskMessageSummary from '../TaskMessageSummary/TaskMessageSummary';
const ErrorToaster = ({ message, taskMessages, visible, setVisible, overrideCodes, onOverride, }) => {
    const errors = taskMessages.map((m) => (Object.assign(Object.assign({}, m), { type: overrideCodes.some((c) => c === m.code) && m.type === ErrorMessageType.Error
            ? ErrorMessageType.Warning
            : m.type, originalType: m.type })));
    const overridableErrors = errors.filter((e) => e.type === ErrorMessageType.Error && e.overridable);
    const highestErrorLevel = errors.length > 0
        ? errors.map((e) => e.type).reduce((prev, current) => (current < prev ? current : prev))
        : undefined;
    const handleOverride = () => {
        if (typeof onOverride !== 'undefined')
            onOverride(overridableErrors.map((e) => e.code).filter((code) => typeof code !== 'undefined'));
    };
    const toastId = errors
        .map((e) => e.code)
        .sort()
        .join(';');
    const closeToast = () => {
        toast.dismiss(toastId);
    };
    let toastFunction;
    let toastTitle;
    let primaryText;
    const secondaryText = 'Close';
    let onClickPrimary;
    const onClickSecondary = closeToast;
    const autoClose = false;
    switch (highestErrorLevel) {
        case ErrorMessageType.Error: {
            toastFunction = toastError;
            toastTitle = 'Error';
            if (overridableErrors.length > 0) {
                primaryText = `Override ${overridableErrors.length} Error${overridableErrors.length === 1 ? '' : 's'}`;
                onClickPrimary = () => {
                    handleOverride();
                    closeToast();
                };
            }
            break;
        }
        case ErrorMessageType.Warning:
            toastFunction = toastWarning;
            toastTitle = 'Warning';
            break;
        case ErrorMessageType.Info:
            toastFunction = toastInfo;
            toastTitle = 'Info';
            break;
        default:
            toastFunction = toastError;
            toastTitle = 'Error';
    }
    if (visible) {
        toast.dismiss();
        toastFunction(React.createElement(ToastMessage, { title: toastTitle, message: message || 'The following validation rules were triggered:', primaryText: primaryText, onClickPrimary: onClickPrimary, onClickSecondary: onClickSecondary, secondaryText: secondaryText }, errors
            .sort((a, b) => {
            if (a.type !== b.type)
                return a.type > b.type ? 1 : -1;
            if (a.originalType !== b.originalType)
                return a.originalType > b.originalType ? 1 : -1;
            if (typeof a.overridable !== 'undefined' &&
                typeof b.overridable !== 'undefined' &&
                a.overridable !== b.overridable)
                return a.overridable > (b === null || b === void 0 ? void 0 : b.overridable) ? 1 : -1;
            return a.code > b.code ? 1 : -1;
        })
            .map((error) => (React.createElement(TaskMessageSummary, { message: error, isOverridden: overrideCodes.some((c) => c === error.code), key: `${error.index}_${error.code}` })))), {
            position: 'top-center',
            autoClose,
            toastId,
            closeOnClick: false,
            onClose: () => setVisible(false),
        });
    }
    return React.createElement(React.Fragment, null);
};
export default ErrorToaster;
