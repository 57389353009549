import React from 'react';
import { useChildFormViews } from '@samc/screen-config-api';
import { InputType } from '@samc/react-ui-form';
export const useSelectAndShowConfiguration = (domainId) => {
    const childFormViews = useChildFormViews(domainId);
    const options = React.useMemo(() => {
        const ids = new Set();
        if (!childFormViews.data)
            return [];
        childFormViews.data.forEach((f) => {
            ids.add(f.domainId);
        });
        return Array.from(ids).map((id) => ({
            id,
            displayText: id,
        }));
    }, [childFormViews.data]);
    const sublayoutSelector = React.useCallback((option) => ({
        inputType: InputType.Select,
        menuItems: (childFormViews.data || []).filter((o) => o.domainId === option.id),
        valueProperty: 'id',
        displayProperty: 'name',
        displayNameRule: "'Form View'",
        editableRule: 'true',
    }), [childFormViews.data]);
    return React.useMemo(() => ({ sublayoutSelector, options }), [options, sublayoutSelector]);
};
export default useSelectAndShowConfiguration;
