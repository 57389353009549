// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ace_editor.ace_autocomplete {
    z-index: 99999999;
}
.configure-domain [role="grid"] {
    height: 100%,
}

.configure-domain .ms-DetailsList {
    height: 100%;
}

.configure-domain .ms-DetailsList-contentWrapper {
    height: 100%;
    overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-screen-config-core-virtual-2101c279b0/1/packages/core/lib/organisms/ConfigureDomain/ConfigureDomain.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".ace_editor.ace_autocomplete {\n    z-index: 99999999;\n}\n.configure-domain [role=\"grid\"] {\n    height: 100%,\n}\n\n.configure-domain .ms-DetailsList {\n    height: 100%;\n}\n\n.configure-domain .ms-DetailsList-contentWrapper {\n    height: 100%;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
