var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ViewType, LayoutType, ApiContext, useFormViewGetter, } from '@samc/screen-config-api';
import { isFolder } from '@samc/folder-ui-core';
import { LoadingModal } from '@samc/react-ui-core';
import { FormViewEditor as BaseFormViewEditor } from './FormViewEditor.Base';
import { FormSkeletonMappers } from '../../../../mappers/FormSkeletonMappers/FormSkeletonMappers';
/**
 * Used to initialize a new FormView when the user selects to add a new Form.
 * @param domainId Domain the FormView belongs to.
 */
export const createEmptyFormView = (domainId) => {
    return {
        domainId,
        name: 'temp name',
        viewType: ViewType.Form,
        fieldConfigurationId: 'some id',
        formViewMembers: {},
        formLayout: {
            tabs: [
                {
                    title: 'Tab 1',
                    baselineFilterExpression: null,
                    sections: [
                        {
                            titleExpression: "'Section 1'",
                            fieldLayout: {
                                type: LayoutType.VerticalStack,
                                children: [],
                            },
                        },
                    ],
                },
            ],
        },
    };
};
export const FormViewEditor = ({ childGrids, currentEditingForm, currentEditingFormId, domainFields, domainId, initialEditingForm, moveToFolderId, saveFormToTab, setEditingFormSkeleton, }) => {
    const api = React.useContext(ApiContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const formViewGetter = useFormViewGetter();
    const isDevToolsEnabled = React.useRef(localStorage.getItem('devtools') === 'true').current;
    const getFormViewAndCopyToClipboard = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const resp = yield fetch(`/domainmetadata/api/v1/FormView/${id}`, api.requestInit);
            const capitalizedJsonString = JSON.stringify(yield resp.json(), undefined, 2).replace(/"([\w]+)":/g, (_match, group) => `"${group.charAt(0).toUpperCase()}${group.slice(1)}":`);
            navigator.clipboard.writeText(capitalizedJsonString);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    });
    /**
     * User selected the New FormView button.
     * Reset the selection and open the editor.
     */
    const handleAddFormView = React.useCallback((targetFolder) => {
        const form = FormSkeletonMappers.FormSkeleton.fromFormView(createEmptyFormView(domainId), {});
        setEditingFormSkeleton({
            initialForm: form,
            currentForm: form,
            moveToFolderId: targetFolder.id,
            formViewId: undefined, // new form
        });
    }, [domainId, setEditingFormSkeleton]);
    /**
     * User selected the edit button on a FormView.
     * Convert the FormView to a FormSkeleton and open the editor.
     */
    const handleEditFormView = React.useCallback((formViewId) => {
        setIsLoading(true);
        return formViewGetter(false, formViewId)
            .then((formView) => {
            var _a;
            if (!formView)
                return; // nothing to do here...
            const form = FormSkeletonMappers.FormSkeleton.fromFormView(formView, {});
            setEditingFormSkeleton({
                formViewId: formView.id,
                currentForm: form,
                initialForm: form,
                moveToFolderId: (_a = formView.folder) === null || _a === void 0 ? void 0 : _a.folderGuid, // to persist the folder path
            });
        })
            .finally(() => setIsLoading(false));
    }, [formViewGetter, setEditingFormSkeleton]);
    const getContextMenuItems = (params) => {
        const { node } = params;
        const { data } = node;
        if (!data)
            return [];
        if (isFolder(data))
            return [{ action: () => handleAddFormView(data), name: 'Add Form View' }];
        if (isDevToolsEnabled) {
            return [
                { action: () => handleEditFormView(data.id), name: 'Edit Form View' },
                { action: () => getFormViewAndCopyToClipboard(data.id), name: 'Dev: Copy to Clipboard' },
            ];
        }
        return [{ action: () => handleEditFormView(data.id), name: 'Edit Form View' }];
    };
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(LoadingModal, { text: "Loading Form View..." }),
        React.createElement(BaseFormViewEditor, { childGrids: childGrids, currentEditingForm: currentEditingForm, currentEditingFormId: currentEditingFormId, domainFields: domainFields, domainId: domainId, getContextMenuItems: getContextMenuItems, initialEditingForm: initialEditingForm, moveToFolderId: moveToFolderId, saveFormToTab: saveFormToTab, setEditingFormSkeleton: setEditingFormSkeleton })));
};
export default FormViewEditor;
