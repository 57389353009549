import React from 'react';
import { useEntityEntitlementContext, useApplicationContext } from '@samc/screen-config-api';
export const useEntitledViewSet = (viewSet, primaryKeyValue) => {
    const { currentEntityEntitlements: entitlementsForSelectedRecord } = useEntityEntitlementContext();
    const { Entitlements } = useApplicationContext();
    const { GenerateViewSetActionEntitlementName, GenerateViewSetTabEntitlementName } = Entitlements;
    const entitledViewSet = React.useMemo(() => {
        var _a;
        return (Object.assign(Object.assign({}, viewSet), { tabs: viewSet.tabs.filter((tab) => entitlementsForSelectedRecord
                ? entitlementsForSelectedRecord[GenerateViewSetTabEntitlementName(viewSet.domainId, viewSet.id, tab.id)] === true
                : true), actions: (_a = viewSet.actions) === null || _a === void 0 ? void 0 : _a.filter((a) => entitlementsForSelectedRecord
                ? entitlementsForSelectedRecord[GenerateViewSetActionEntitlementName(viewSet.domainId, viewSet.id, a.displayText)] === true
                : true) }));
    }, [
        GenerateViewSetActionEntitlementName,
        GenerateViewSetTabEntitlementName,
        entitlementsForSelectedRecord,
        viewSet,
    ]);
    return entitledViewSet;
};
export default useEntitledViewSet;
