var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Modal } from '@fluentui/react';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { InputType } from '@samc/react-ui-form';
import { FieldConfigurationMemberLookup, useFieldConfigurationByDomain, useFieldConfigurationByParentDomains, useFormView, useFormViewSaver, } from '@samc/screen-config-api';
import { FormBuilderWorkspace } from '@samc/react-ui-form-builder';
import { mapDomainFieldsToFormViewMembers } from '../../../../../viewModel/FormSkeletonViewModel';
import { useLinkConfiguration } from '../../../../../hooks/useLinkConfiguration/useLinkConfiguration';
import { getExpressionFormField } from '../../../../../atoms/ExpressionField/ExpressionField';
import { getGridDataFormRenderer } from '../../../../../atoms/FormGridFieldConfigurationForm/FormGridFieldConfigurationForm';
import { FormSkeletonMappers } from '../../../../../mappers/FormSkeletonMappers/FormSkeletonMappers';
export const FormViewModal = (props) => {
    const { isOpen, onDismiss, formViewId, onSaveForm, domainId, fieldConfigurationId, childGrids, domainFields } = props;
    const [initialFormSkeleton, setInitialFormSkeleton] = React.useState();
    const [formSkeleton, setFormSkeleton] = React.useState();
    const formViewRequest = useFormView(formViewId);
    const formView = formViewRequest.data;
    const [saveFormView] = useFormViewSaver(formViewId);
    const linkConfiguration = useLinkConfiguration(domainId, initialFormSkeleton === null || initialFormSkeleton === void 0 ? void 0 : initialFormSkeleton.fields);
    const { data: fieldConfigurations } = useFieldConfigurationByDomain(domainId);
    const { data: parentFieldConfigurations } = useFieldConfigurationByParentDomains(domainId);
    const handleFormChanged = React.useCallback((newForm) => {
        setFormSkeleton(newForm);
    }, []);
    React.useEffect(() => {
        if (formView) {
            const form = FormSkeletonMappers.FormSkeleton.fromFormView(formView, {});
            setFormSkeleton(form);
            setInitialFormSkeleton(form);
        }
    }, [formView]);
    const handleFormSubmit = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!formSkeleton)
            return;
        const savedFormView = yield saveFormView(FormSkeletonMappers.FormSkeleton.toFormView(formSkeleton, { domainId, fieldConfigurationId, formViewId }));
        onSaveForm(savedFormView);
    }), [domainId, fieldConfigurationId, formSkeleton, formViewId, onSaveForm, saveFormView]);
    const availableFields = React.useMemo(() => {
        if (!domainFields)
            return {};
        const lookup = new FieldConfigurationMemberLookup(fieldConfigurations, parentFieldConfigurations);
        const formViewMembers = mapDomainFieldsToFormViewMembers(domainFields, lookup);
        const innerFields = Object.entries(formViewMembers).reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur[0]]: FormSkeletonMappers.Field.fromFormViewMember(cur[1], {}) })), {});
        return Object.assign({}, innerFields);
    }, [domainFields, fieldConfigurations, parentFieldConfigurations]);
    const gridGroup = React.useMemo(() => {
        const options = childGrids.map((c) => {
            var _a, _b;
            return ({
                displayText: `${c.domainId} ${(_a = c.viewName) !== null && _a !== void 0 ? _a : ''}`,
                value: c.domainId,
                data: c,
                hoverText: `Domain: ${c.domainId}, Default View: ${(_b = c.viewName) !== null && _b !== void 0 ? _b : 'Default'}`,
            });
        });
        return {
            id: 'grids',
            title: 'Grids',
            options,
        };
    }, [childGrids]);
    const fieldTransformer = React.useCallback((field) => {
        if (field.inputType === InputType.MultilineExpression || field.inputType === InputType.Expression) {
            return Object.assign(Object.assign({}, field), { onRenderField: getExpressionFormField(domainId, field.field) });
        }
        return field;
    }, [domainId]);
    return (React.createElement(Modal, { forceFocusInsideTrap: false, onDismiss: onDismiss, isOpen: isOpen, layerProps: { eventBubblingEnabled: true } },
        React.createElement("div", { style: {
                height: '90vh',
                width: '90vw',
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            } },
            React.createElement(SitusHeader, { title: "Edit Form", confirmationButtonText: "Save", onConfirmationButtonClicked: handleFormSubmit, rejectionButtonText: "Cancel", onRejectionButtonClicked: onDismiss }),
            initialFormSkeleton && !formViewRequest.isLoading && (React.createElement(FormBuilderWorkspace, { initialForm: initialFormSkeleton, availableFields: availableFields, onFormChanged: handleFormChanged, gridGroup: gridGroup, linkConfiguration: linkConfiguration, fieldTransformer: fieldTransformer, renderGridDataForm: getGridDataFormRenderer(domainId) })))));
};
export default FormViewModal;
