var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@samc/react-ui-grid/lib/styles/ag-theme-react-ui-library.css';
import { ToolbarButton, isNullOrUndefined } from '@samc/react-ui-core';
import { Modal } from '@fluentui/react';
import { useViewSetSaver, ViewType, } from '@samc/screen-config-api';
import { cloneDatum } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { ViewSetTabSettings } from './ViewSetTabSettings/ViewSetTabSettings';
import { ViewSetLayoutSettings } from './ViewSetLayoutSettings/ViewSetLayoutSettings';
import { generateId, mapViewSetTab, tabIdAlreadyExists, getSelectedViewSet, getSelectedTab, } from '../ConfigureViewSetHelper';
import { FormViewModal } from './FormViewModal/FormViewModal';
import { ConfigureListViewEditor } from './ConfigureListView/ConfigureListViewEditor';
const ROW_HEIGHT = 35;
const DefaultContextMenuItems = ['copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste'];
export const ConfigureViewSetRenderer = (props) => {
    const { viewSets, fieldConfigurationId, forceUpdate, rowData, domainId, domainFields, childGrids } = props;
    const [tabEditorOpen, setTabEditorOpen] = React.useState(false);
    const [layoutEditorOpen, setLayoutEditorOpen] = React.useState(false);
    const [tabData, setTabData] = React.useState({});
    const [listViewEditorOpen, setListViewEditorOpen] = React.useState(false);
    const [listViewId, setListViewId] = React.useState();
    const [screenId, setScreenId] = React.useState('');
    const [formViewId, setFormViewId] = React.useState();
    const [layoutTabId, setLayoutTabId] = React.useState('');
    const [isDragStartATab, setIsDragStartATab] = React.useState(false);
    const [saveViewSet] = useViewSetSaver({});
    const columnDefs = React.useMemo(() => [
        {
            field: 'type',
            width: 100,
            valueGetter: ({ data }) => {
                if (!data)
                    return null;
                const { type, layoutType } = data;
                if (type !== 'Layout' || isNullOrUndefined(layoutType))
                    return type;
                switch (layoutType) {
                    case ViewType.ContactAssignmentGrid:
                    case ViewType.Form:
                        return 'Form Layout';
                    case ViewType.Grid:
                        return 'Grid Layout';
                    default:
                        return type;
                }
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const addRenderer = {
                    component: 'addButtonRenderer',
                };
                if (params.data.type === 'Tab')
                    return addRenderer;
                return {};
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const editRenderer = {
                    component: 'editButtonRenderer',
                };
                if (params.data.type === 'Tab')
                    return editRenderer;
                return {};
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const editRenderer = {
                    component: 'editButtonRenderer',
                };
                const deleteRenderer = {
                    component: 'deleteButtonRenderer',
                };
                if (params.data.type === 'Layout')
                    return editRenderer;
                if (params.data.type === 'Tab')
                    return deleteRenderer;
                return {};
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const cloneRenderer = {
                    component: 'cloneButtonRenderer',
                };
                const addRenderer = {
                    component: 'addButtonRenderer',
                };
                const deleteRenderer = {
                    component: 'deleteButtonRenderer',
                };
                if (params.data.type === 'Screen')
                    return addRenderer;
                if (params.data.type === 'Tab')
                    return cloneRenderer;
                return deleteRenderer;
            },
        },
    ], []);
    const valueFormatter = (params) => {
        return params.value.split('$')[0];
    };
    const [defaultColumnDef] = React.useState({ resizable: true, suppressMovable: true, suppressMenu: true });
    const [autoGroupColumnDef] = React.useState({
        headerName: 'Item',
        flex: 2,
        cellRendererParams: { suppressCount: true },
        rowDrag: true,
        valueFormatter,
    });
    const [groupDefaultExpanded] = React.useState(-1);
    const getDataPath = (data) => {
        const updatedData = JSON.parse(JSON.stringify(data));
        updatedData.item.forEach((_, i) => {
            if (i === 0)
                updatedData.item[i] = `${updatedData.item[i]}$${updatedData.viewSetId}`;
            else if (i === 1)
                updatedData.item[i] = `${updatedData.item[i]}$${updatedData.tabId}`;
            else
                updatedData.item[i] = `${updatedData.item[i]}$${updatedData.viewId}`;
        });
        return updatedData.item;
    };
    const handleAddLayout = React.useCallback(() => {
        if (!domainId || !domainFields)
            return;
        setLayoutEditorOpen(true);
    }, [domainFields, domainId]);
    const AddButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        const { viewSetId, tabId, type } = data;
        const handleAddTabClick = () => {
            setTabData({
                id: generateId(),
                visibleExpression: 'true',
                layouts: [],
                defaultLayout: {},
                baselineFilterExpression: '',
                title: '',
                controlVisibilities: {
                    wrapText: false,
                },
                layoutSelectionExpression: '',
                titleExpression: '',
            });
            setScreenId(viewSetId);
            setTabEditorOpen(true);
        };
        const handleAddLayoutClick = () => {
            setScreenId(viewSetId);
            setLayoutTabId(tabId);
            handleAddLayout();
        };
        if (type === 'Screen')
            return (React.createElement(ToolbarButton, { onClick: handleAddTabClick, iconName: "CircleAddition", tooltip: "Add a new tab", iconStyle: { backgroundColor: 'transparent' } }));
        return (React.createElement(ToolbarButton, { onClick: handleAddLayoutClick, iconName: "CircleAddition", tooltip: "Add a new Layout", iconStyle: { backgroundColor: 'transparent' } }));
    }, [handleAddLayout]);
    const EditButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        const { viewSetId, tabId, type, layoutType, viewId } = data;
        const handleEditTabClick = () => {
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            const selectedTab = selectedViewSet.tabs.filter((x) => x.id === tabId)[0];
            setTabData(selectedTab);
            setScreenId(selectedViewSet.id);
            setTabEditorOpen(true);
        };
        const handleEditLayoutClick = () => {
            setScreenId(viewSetId);
            setLayoutTabId(tabId);
            if (layoutType === ViewType.Form || layoutType === ViewType.ContactAssignmentGrid) {
                setFormViewId(viewId);
            }
            else {
                setListViewId(viewId);
                setListViewEditorOpen(true);
            }
        };
        if (type === 'Tab') {
            return (React.createElement(ToolbarButton, { onClick: handleEditTabClick, iconName: "Edit", tooltip: "Edit Tab", iconStyle: { backgroundColor: 'transparent' } }));
        }
        return (React.createElement(ToolbarButton, { onClick: handleEditLayoutClick, iconName: "Edit", tooltip: "Edit Layout", iconStyle: { backgroundColor: 'transparent' } }));
    }, [viewSets]);
    const DeleteButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        const { viewSetId, tabId, viewId, type } = data;
        const handleDeleteTabClick = () => __awaiter(void 0, void 0, void 0, function* () {
            const tabToBeDeleted = tabId;
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            selectedViewSet.tabs = selectedViewSet.tabs.filter((x) => x.id !== tabToBeDeleted);
            yield saveViewSet(selectedViewSet);
            forceUpdate();
        });
        const handleDeleteLayoutClick = () => __awaiter(void 0, void 0, void 0, function* () {
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            const selectedTab = selectedViewSet.tabs.filter((x) => x.id === tabId)[0];
            selectedTab.layouts = selectedTab.layouts.filter((x) => x.viewId !== viewId);
            yield saveViewSet(selectedViewSet);
            forceUpdate();
        });
        if (type === 'Tab') {
            return (React.createElement(ToolbarButton, { onClick: handleDeleteTabClick, iconName: "Delete", tooltip: "Delete Tab", iconStyle: { backgroundColor: 'transparent' } }));
        }
        return (React.createElement(ToolbarButton, { onClick: handleDeleteLayoutClick, iconName: "Delete", tooltip: "Delete Layout", iconStyle: { backgroundColor: 'transparent' } }));
    }, [forceUpdate, saveViewSet, viewSets]);
    const CloneButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        const { viewSetId, tabId, type } = data;
        const handleCloneTabClick = () => {
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            const selectedTab = selectedViewSet.tabs.filter((x) => x.id === tabId)[0];
            // Only change the Id and the title
            const clonedTab = Object.assign(Object.assign({}, selectedTab), { id: generateId(), title: `${selectedTab.title}_clone` });
            setTabData(clonedTab);
            setScreenId(selectedViewSet.id);
            setTabEditorOpen(true);
        };
        if (type === 'Tab') {
            return (React.createElement(ToolbarButton, { onClick: handleCloneTabClick, iconName: "DuplicateRow", tooltip: "Clone Tab", iconStyle: { backgroundColor: 'transparent' } }));
        }
        return React.createElement(React.Fragment, null);
    }, [viewSets]);
    const [frameworkComponents] = React.useState({
        addButtonRenderer: AddButtonRenderer,
        editButtonRenderer: EditButtonRenderer,
        deleteButtonRenderer: DeleteButtonRenderer,
        cloneButtonRenderer: CloneButtonRenderer,
    });
    const getRowHeight = () => {
        return ROW_HEIGHT;
    };
    const handleTabEditorSubmit = (viewSetTab) => __awaiter(void 0, void 0, void 0, function* () {
        const tabId = viewSetTab.id;
        if (!viewSets)
            return;
        const selectedViewSet = getSelectedViewSet(viewSets, screenId);
        if (!tabIdAlreadyExists(selectedViewSet.tabs, tabId)) {
            selectedViewSet.tabs.push(Object.assign(Object.assign({}, viewSetTab), { layouts: tabData.layouts ? tabData.layouts : [] }));
        }
        else {
            const existingTab = selectedViewSet.tabs.filter((x) => x.id === tabId)[0];
            mapViewSetTab(viewSetTab, existingTab);
        }
        yield saveViewSet(selectedViewSet);
        setTabEditorOpen(false);
        forceUpdate();
    });
    const handleTabEditorCancel = () => {
        setTabEditorOpen(false);
    };
    const handleLayoutEditorSubmit = (layout) => __awaiter(void 0, void 0, void 0, function* () {
        if (!viewSets)
            return;
        const selectedViewSet = getSelectedViewSet(viewSets, screenId);
        const selectedTab = getSelectedTab(selectedViewSet, layoutTabId);
        selectedTab.layouts.push(layout);
        const numLayouts = selectedTab.layouts.length;
        // If this is the only layout in the tab, then set it as default
        if (numLayouts === 1) {
            [selectedTab.defaultLayout] = selectedTab.layouts;
        }
        yield saveViewSet(selectedViewSet);
        setLayoutEditorOpen(false);
        forceUpdate();
    });
    const handleLayoutEditorCancel = () => {
        setLayoutEditorOpen(false);
    };
    const saveEditedLayout = (viewId, viewName) => __awaiter(void 0, void 0, void 0, function* () {
        if (!viewSets)
            return undefined;
        const selectedViewSet = viewSets.find((x) => x.id === screenId);
        if (!selectedViewSet)
            return undefined;
        const newViewSet = cloneDatum(selectedViewSet);
        const selectedTab = newViewSet.tabs.find((x) => x.id === layoutTabId);
        if (!selectedTab)
            return undefined;
        const selectedLayout = selectedTab.layouts.find((x) => x.viewId === viewId);
        // don't bother saving if no change
        if (!selectedLayout || selectedLayout.title === viewName)
            return undefined;
        // mutate inside of `newViewSet`
        selectedLayout.title = viewName;
        yield saveViewSet(newViewSet);
        return selectedLayout;
    });
    const handleFormSubmit = (formView) => __awaiter(void 0, void 0, void 0, function* () {
        if (!domainId)
            return;
        yield saveEditedLayout(formView.id, formView.name);
        setFormViewId(undefined);
        forceUpdate();
    });
    const handleEditListViewClose = () => {
        setListViewEditorOpen(false);
    };
    const handleOnListViewSave = (domainView) => __awaiter(void 0, void 0, void 0, function* () {
        yield saveEditedLayout((domainView === null || domainView === void 0 ? void 0 : domainView.guid) || listViewId, (domainView === null || domainView === void 0 ? void 0 : domainView.viewName) || '');
        setListViewEditorOpen(false);
        forceUpdate();
    });
    const handleOnRowDragEnter = (event) => {
        if (event.node.data.type === 'Tab')
            setIsDragStartATab(true);
        setScreenId(event.node.data.viewSetId);
    };
    const handleOnRowDragEnd = (event) => {
        const rowModel = event.api.getModel();
        const newTabSequence = [];
        rowModel.forEachNode((x) => {
            if (x.data.viewSetId === screenId && x.data.type === 'Tab')
                newTabSequence.push(x.data.tabId);
        });
        if (isDragStartATab) {
            const _map = new Map();
            const selectedViewSet = viewSets.filter((x) => x.id === screenId)[0];
            selectedViewSet.tabs.map((x) => _map.set(x.id, x));
            const updatedViewSet = Object.assign(Object.assign({}, selectedViewSet), { tabs: [] });
            newTabSequence.forEach((tabId) => {
                updatedViewSet.tabs.push(_map.get(tabId));
            });
            saveViewSet(updatedViewSet);
        }
        setIsDragStartATab(false);
    };
    const className = `ag-theme-balham ag-theme-react-ui-library row-spacing-normal`;
    const getContextMenuItems = ({ node }) => {
        if (!node || !node.data)
            return DefaultContextMenuItems;
        const { data, parent } = node;
        switch (data.type) {
            case 'Tab': {
                if (!data.tabId || !parent || !parent.data || !parent.data.viewSetId)
                    break;
                return [
                    ...DefaultContextMenuItems,
                    {
                        name: 'Copy Tab Id',
                        action: () => navigator.clipboard.writeText(data.tabId),
                    },
                ];
            }
            default:
                break;
        }
        return DefaultContextMenuItems;
    };
    return (React.createElement("div", { style: { height: '100%' }, className: className },
        React.createElement(AgGridReact, { rowData: rowData, columnDefs: columnDefs, defaultColDef: defaultColumnDef, autoGroupColumnDef: autoGroupColumnDef, treeData: true, animateRows: true, groupDefaultExpanded: groupDefaultExpanded, getDataPath: getDataPath, rowDragManaged: true, components: frameworkComponents, getRowHeight: getRowHeight, onRowDragEnter: handleOnRowDragEnter, onRowDragEnd: handleOnRowDragEnd, getContextMenuItems: getContextMenuItems }),
        React.createElement(Modal, { forceFocusInsideTrap: false, onDismiss: () => setTabEditorOpen(false), isOpen: tabEditorOpen, layerProps: { eventBubblingEnabled: true } },
            React.createElement(ViewSetTabSettings, { domainId: domainId, tabSettingsData: tabData, onFormSubmit: handleTabEditorSubmit, onFormCancel: handleTabEditorCancel })),
        React.createElement(Modal, { forceFocusInsideTrap: false, onDismiss: () => setLayoutEditorOpen(false), isOpen: layoutEditorOpen, layerProps: { eventBubblingEnabled: true } },
            React.createElement(ViewSetLayoutSettings, { domainId: domainId, domainFields: domainFields, fieldConfigurationId: fieldConfigurationId, onFormSubmit: handleLayoutEditorSubmit, onFormCancel: handleLayoutEditorCancel })),
        domainId && fieldConfigurationId && formViewId && (React.createElement(FormViewModal, { domainFields: domainFields, formViewId: formViewId, isOpen: formViewId !== undefined, onDismiss: () => setFormViewId(undefined), onSaveForm: handleFormSubmit, domainId: domainId, fieldConfigurationId: fieldConfigurationId, childGrids: childGrids })),
        React.createElement(ConfigureListViewEditor, { isOpen: listViewEditorOpen, onClose: handleEditListViewClose, listViewId: listViewId, onSave: handleOnListViewSave })));
};
export default ConfigureViewSetRenderer;
