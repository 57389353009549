import React from 'react';
import { DefaultMultiSelectEntryFieldRenderer } from '@samc/react-ui-form/lib/fields/MultiSelectEntryField/MultiSelectEntryField';
// eslint-disable-next-line react/display-name
export const LegacyMultiSelectEntryFieldRenderer = React.forwardRef((props, _ref) => {
    const { onChange, valueProperty } = props;
    const internalOnChange = React.useCallback((item, bypassDebounce) => {
        if (Array.isArray(item) && valueProperty) {
            const currencyFields = item.map((x) => (Object.assign(Object.assign({}, x), { [valueProperty]: x })));
            onChange(currencyFields, bypassDebounce);
        }
    }, [onChange, valueProperty]);
    return React.createElement(DefaultMultiSelectEntryFieldRenderer, Object.assign({ ref: _ref }, props, { onChange: internalOnChange }));
});
// eslint-disable-next-line react/display-name
export const getLegacyMultiSelectEntryFieldRenderer = () => (props, ref) => React.createElement(LegacyMultiSelectEntryFieldRenderer, Object.assign(Object.assign({}, props), { ref }));
export default LegacyMultiSelectEntryFieldRenderer;
