import { FunctionList, ValidateExpression } from '@samc/expressions-core';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { useDomain } from '@samc/screen-config-api';
import React from 'react';
const getExpressionsFromViewSetAction = (action) => {
    const results = [];
    const { displayExpression, linkTargetExpression } = action;
    if (!isNullOrUndefined(displayExpression))
        results.push(displayExpression);
    if (!isNullOrUndefined(linkTargetExpression))
        results.push(linkTargetExpression);
    return results;
};
const getExpressionsFromViewSetTab = (tab) => {
    const results = [];
    const { titleExpression, visibleExpression, layoutSelectionExpression } = tab;
    if (!isNullOrUndefined(titleExpression))
        results.push(titleExpression);
    if (!isNullOrUndefined(visibleExpression))
        results.push(visibleExpression);
    if (!isNullOrUndefined(layoutSelectionExpression))
        results.push(layoutSelectionExpression);
    return results;
};
export const getExpressionsFromViewSet = (viewSet) => {
    const { tabs, actions } = viewSet;
    const result = [];
    tabs.forEach((tab) => result.push(...getExpressionsFromViewSetTab(tab)));
    if (actions)
        actions.forEach((action) => result.push(...getExpressionsFromViewSetAction(action)));
    return result;
};
const getMissingViewSetFields = (viewSet, domain) => {
    if (!viewSet)
        return [];
    if (!domain)
        return [];
    const domainFields = domain.fields.map((f) => f.fieldName);
    const expressions = [];
    const missingFieldNames = new Set();
    expressions.push(...getExpressionsFromViewSet(viewSet));
    expressions
        .filter((e) => !isNullOrUndefined(e) && e.indexOf('[') >= 0)
        .forEach((expression) => {
        const { errors, seenFields, invalidFields } = ValidateExpression(expression, FunctionList, domainFields);
        // eslint-disable-next-line no-console
        if (errors.length > 0)
            console.warn({ ExpressionValidationError: errors });
        seenFields === null || seenFields === void 0 ? void 0 : seenFields.filter((f) => !(invalidFields === null || invalidFields === void 0 ? void 0 : invalidFields.includes(f))).forEach((f) => missingFieldNames.add(f));
    });
    const missingFieldArray = Array.from(missingFieldNames).sort();
    return missingFieldArray.map((mf) => ({
        viewFieldName: mf,
    }));
};
export const useMissingViewSetDependencies = (viewSet) => {
    var _a;
    const domainId = (_a = viewSet === null || viewSet === void 0 ? void 0 : viewSet.domainId) !== null && _a !== void 0 ? _a : '';
    const domain = useDomain(domainId).data;
    return React.useMemo(() => {
        const missingFormViewFields = getMissingViewSetFields(viewSet, domain);
        return missingFormViewFields;
    }, [domain, viewSet]);
};
export default useMissingViewSetDependencies;
