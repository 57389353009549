import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getListView } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const LIST_VIEW_KEY = `${SCREEN_CONFIG_API_KEY}list_view`;
export const useListView = (listViewId) => {
    const api = React.useContext(ApiContext);
    const query = (lvid) => lvid ? getListView(api.AggregatorApi, lvid, api.requestInit) : Promise.resolve(undefined);
    return useRequestBase({
        key: LIST_VIEW_KEY,
        query,
        requestContext: ApiContext,
        params: [listViewId],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
