import { ServersideFolderDropdown } from '@samc/folder-ui-core';
import { isNullOrUndefined } from '@samc/react-ui-core';
import React from 'react';
const _PicklistListViewPicker = (props, _ref) => {
    const { data, onChange, value, hasError, valueHasChanged } = props;
    const { domainId } = data !== null && data !== void 0 ? data : {};
    const hadDomainId = React.useRef(false);
    const selection = React.useMemo(() => {
        if (isNullOrUndefined(value))
            return [];
        return [String(value)];
    }, [value]);
    const onSelectionChanged = React.useCallback((item, isSelected) => {
        if (!isSelected)
            onChange(undefined, true);
        else
            onChange(item.id, true);
    }, [onChange]);
    // reset on domain change
    React.useEffect(() => {
        if (hadDomainId.current)
            onChange(undefined, true); // clear out selection if go from having domain to not
        if (domainId)
            hadDomainId.current = true;
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainId]);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!domainId)
        return React.createElement(React.Fragment, null);
    return (React.createElement(ServersideFolderDropdown, { selection: selection, onSelectionChanged: onSelectionChanged, spaceName: domainId, type: "DomainView", hasError: hasError, isUpdated: valueHasChanged }));
};
export const PicklistListViewPicker = React.forwardRef(_PicklistListViewPicker);
export default PicklistListViewPicker;
