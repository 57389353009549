var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isNullOrUndefined } from '@samc/react-ui-core';
import { useExportGenerator } from '../useExportGenerator/useExportGenerator';
/**
 * Get an exporter for the given QueryExecuteRequest which can request and download a corresponding data export
 * @param request The query request off of which to base the data export request
 * @returns an async callback that will request and download a data export reflecting the provided query execute request
 */
export const useQueryExporter = () => {
    const generateExport = useExportGenerator();
    return (executeRequest) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (isNullOrUndefined(executeRequest.primaryViewId))
            throw new Error('Cannot export a query which has no view specified');
        let filterExpression;
        if (!isNullOrUndefined(executeRequest.adhocFilter)) {
            const { expressionLang, advancedExpression } = executeRequest.adhocFilter;
            if (!isNullOrUndefined(expressionLang) && expressionLang !== 'Centric')
                throw new Error('Cannot export a query using a non-Centric filter expression');
            // Fall back to undefined to remove null as a possibility
            filterExpression = advancedExpression !== null && advancedExpression !== void 0 ? advancedExpression : undefined;
        }
        const orderByExpression = (_a = executeRequest.sorting.orderBy) === null || _a === void 0 ? void 0 : _a.scalarExpression;
        let sortOrderBy;
        if (!isNullOrUndefined(orderByExpression)) {
            // Only simple orderBy expressions (single field) are currently supported
            const matchResult = (_b = orderByExpression.match(/^\[(?<fieldName>[^\]]+)\]$/)) === null || _b === void 0 ? void 0 : _b.groups;
            if (matchResult === undefined) {
                // eslint-disable-next-line no-console
                console.error(`Complex sort expression \`${orderByExpression}\` is not currently supported for query exports`);
            }
            else {
                sortOrderBy = matchResult.fieldName;
            }
        }
        const request = {
            domainId: executeRequest.domainId,
            viewId: executeRequest.primaryViewId,
            filterIds: executeRequest.filterIds,
            filterExpression,
        };
        yield generateExport({
            request,
            sortOrder: executeRequest.sorting.order,
            sortOrderBy,
        });
    });
};
export default useQueryExporter;
