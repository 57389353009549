import React from 'react';
import { isNullOrUndefined, useAutoFillActionRunner } from '@samc/react-ui-core';
/**
 * This hook enables grid rows to be autofilled according to the provided AutoFillActions,
 * using cellValueChangedEvent to link back to the grid for tracking changes and
 * manipulating data.
 *
 * @param cellValueChangedEvent CellValueChangedEvent from ag-grid
 * @param actions AutoFillActions to be evaluated on cell value changes
 *
 */
export const useDropzoneFillEffect = (runnerEvent, actions, triggerColumn) => {
    var _a;
    const requiredDocTypes = ['REPORTINGPACKAGE', 'ARGUSMODEL', 'VALUATIONREPORT'];
    const setValueInRow = React.useCallback((field, value) => {
        if (isNullOrUndefined(runnerEvent))
            return;
        const { api, node } = runnerEvent;
        const editingCells = api.getEditingCells();
        const editingCell = editingCells.length === 1 ? editingCells[0] : undefined;
        const thisFieldIsBeingEdited = editingCell && (editingCell === null || editingCell === void 0 ? void 0 : editingCell.column.getColDef().field) === field;
        if (thisFieldIsBeingEdited)
            api.stopEditing();
        if (api.getColumn(field)) {
            if (field.toLocaleLowerCase() === 'version') {
                if (requiredDocTypes.find((reqDocType) => reqDocType === node.data.DocumentTypeId) &&
                    node.data.Version !== '1') {
                    node.setDataValue(field, '2');
                }
            }
            else {
                node.setDataValue(field, value);
            }
        }
        if (editingCell && thisFieldIsBeingEdited)
            api.startEditingCell({ rowIndex: editingCell.rowIndex, colKey: editingCell.column });
    }, [runnerEvent]);
    const { runner: actionRunner } = useAutoFillActionRunner(actions, setValueInRow);
    const rowData = runnerEvent === null || runnerEvent === void 0 ? void 0 : runnerEvent.data;
    const changedField = triggerColumn !== null && triggerColumn !== void 0 ? triggerColumn : (_a = runnerEvent === null || runnerEvent === void 0 ? void 0 : runnerEvent.colDef) === null || _a === void 0 ? void 0 : _a.field;
    // Run any autoFillActions that apply whenever a cell value changes
    React.useEffect(() => {
        if (changedField && rowData)
            actionRunner(new Set([changedField]), rowData);
    }, [actionRunner, changedField, rowData]);
};
export default useDropzoneFillEffect;
