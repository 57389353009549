import { HeaderContextProvider } from '@samc/react-ui-history';
import React from 'react';
import { ConfirmationModalWrapper, } from '../../../molecules/ConfirmationModalWrapper/ConfirmationModalWrapper';
/**
 * The confirmation view modal. Dismisses on save by default.
 */
export const ConfirmationViewModal = (props) => {
    const { isOpen, headerText, onDismiss, ViewModal } = props;
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(ConfirmationModalWrapper, { isOpen: isOpen, headerText: headerText, onDismiss: onDismiss },
            React.createElement(ViewModal.Contents, Object.assign({ dismissOnSave: true }, props)))));
};
export default ConfirmationViewModal;
