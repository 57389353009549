import { isFolder } from '@samc/folder-ui-core';
// do this b/c key not exported
const FolderTreeQueryKey = 'FOLDER_TREE';
export const getContextMenuItems = (iProps) => ({ node }) => {
    const { setTargetListViewId, setNewListViewFolderId } = iProps;
    if (!node)
        return [];
    const { data } = node;
    if (!data)
        return [];
    if (isFolder(data)) {
        return [{ action: () => setNewListViewFolderId(data.id), name: 'Add List View' }];
    }
    return [{ action: () => setTargetListViewId(data.sequentialItemId), name: 'Edit List View' }];
};
export const onRowClicked = (props, iProps) => (v) => {
    const { editOnClick, onSelectionChanged } = props;
    const { setTargetListViewId } = iProps;
    if (isFolder(v))
        return;
    const { sequentialItemId, id, body } = v;
    const view = Object.assign(Object.assign({}, JSON.parse(body)), { id });
    if (editOnClick)
        setTargetListViewId(sequentialItemId);
    if (onSelectionChanged)
        onSelectionChanged(view);
};
export const onClose = (iProps) => () => {
    const { setTargetListViewId, queryClient, setNewListViewFolderId } = iProps;
    setTargetListViewId(undefined);
    setNewListViewFolderId(undefined);
    // reload tree
    queryClient.invalidateQueries({ queryKey: [FolderTreeQueryKey], exact: false });
};
export default {};
