/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React from 'react';
import { EditLink } from '@samc/react-ui-grid';
import { useRefWrapper } from '@samc/react-ui-core';
import { linkType } from '../../enums/enumsTypes';
import { getFormViewLink } from '../../hooks/useGridCellLinks/useGridCellLinks';
import { DocCountLink } from '../../atoms/DocCountLink/DocCountLink';
export const validateValueAsNumber = (value) => {
    if (typeof value === 'number') {
        return value;
    }
    if (typeof value !== 'string') {
        return 0;
    }
    return Number.isNaN(Number(value)) ? 0 : Number(value);
};
const useFormLinks = (links, form, primaryKey, setCurrentEditLink, promptAndContinue) => {
    const promptAndContinueRef = useRefWrapper(promptAndContinue);
    const setCurrentEditLinkRef = useRefWrapper(setCurrentEditLink);
    return React.useMemo(() => {
        if (!(form === null || form === void 0 ? void 0 : form.fields) || !links || !(links === null || links === void 0 ? void 0 : links.length)) {
            return form;
        }
        const formTmp = Object.assign({}, form);
        const formFields = Object.assign({}, formTmp.fields);
        const fieldsKeys = Object.keys(formFields);
        let mappedFormFields = {};
        fieldsKeys.forEach((fieldKey) => {
            const selectedLink = links.filter((link) => link.fieldName === formFields[fieldKey].field)[0];
            if (selectedLink) {
                const link = getFormViewLink(selectedLink, (a) => promptAndContinueRef.current(a), (l) => setCurrentEditLinkRef.current(l), primaryKey);
                const onClick = () => link();
                switch (selectedLink.targetType) {
                    case linkType.Edit:
                        mappedFormFields = Object.assign(Object.assign({}, mappedFormFields), { [fieldKey]: Object.assign(Object.assign({}, formFields[fieldKey]), { onRenderField: () => {
                                    return React.createElement(EditLink, { onClick: onClick, styles: { root: { paddingLeft: '10px' } } });
                                } }) });
                        return;
                    case linkType.DocCountLink:
                        mappedFormFields = Object.assign(Object.assign({}, mappedFormFields), { [fieldKey]: Object.assign(Object.assign({}, formFields[fieldKey]), { onRenderField: (params) => {
                                    const validatedValue = validateValueAsNumber(params.value);
                                    return React.createElement(DocCountLink, { onClick: onClick, value: validatedValue });
                                } }) });
                        return;
                    default:
                        break;
                }
            }
            mappedFormFields = Object.assign(Object.assign({}, mappedFormFields), { [fieldKey]: formFields[fieldKey] });
        });
        formTmp.fields = mappedFormFields;
        return formTmp;
    }, [form, links, primaryKey, promptAndContinueRef, setCurrentEditLinkRef]);
};
export default useFormLinks;
