import React from 'react';
import { getFolderDropdownFormFieldRenderer } from '@samc/folder-ui-core';
import { InputType } from '@samc/screen-config-api';
import { getExpressionFormField } from '../../atoms/ExpressionField/ExpressionField';
import { FormSkeletonMappers } from '../../mappers/FormSkeletonMappers/FormSkeletonMappers';
import { getDocLinkCountFieldRenderer } from '../../molecules/DocLinkCountField/DocLinkCountField';
import { getStrataFileEntryFieldRenderer } from '../../molecules/GetStrataFileEntryField/GetStrataFileEntryField';
import { GetSelectEntryField } from '../../molecules/SelectEntryField/SelectEntryField';
import { getUnitOfCurrencyProperties } from '../../organisms/FormScreen/utilities';
import { getDataDrivenRuleOverridesForField } from '../../utils/AuthUtils/AuthUtils';
const getCustomRenderer = (member) => {
    var _a;
    const { inputType, domainId, customRenderer, onRenderField } = member;
    // eslint-disable-next-line react/display-name
    if (customRenderer) {
        return (props, ref) => React.createElement(customRenderer, Object.assign(Object.assign({}, props), { ref }));
    }
    if (onRenderField)
        return onRenderField;
    // Folder UI
    if (inputType === InputType.FormView && domainId) {
        return getFolderDropdownFormFieldRenderer({ spaceName: domainId, itemType: 'FormView' });
    }
    if (inputType === InputType.ListView && domainId) {
        return getFolderDropdownFormFieldRenderer({ spaceName: domainId, itemType: 'DomainView' });
    }
    if (inputType === InputType.DomainFilter && domainId) {
        return getFolderDropdownFormFieldRenderer({ spaceName: domainId, itemType: 'DomainFilter' });
    }
    // Others
    if ((_a = member.lookupProperties) === null || _a === void 0 ? void 0 : _a.domainId) {
        return GetSelectEntryField(member.lookupProperties.domainId, member.lookupProperties.viewId);
    }
    if (inputType === InputType.StrataFile) {
        return getStrataFileEntryFieldRenderer(member === null || member === void 0 ? void 0 : member.allowedFileTypes);
    }
    if (inputType === InputType.DocLinkCount) {
        return getDocLinkCountFieldRenderer();
    }
    if (inputType === InputType.Expression && member.domainId) {
        return getExpressionFormField(member.domainId, member.viewFieldName);
    }
    return undefined;
};
/**
 * Converts a FormView object into a Form Skeleton for react-ui-form package.
 * @param formView FormView to convert
 * @param filter Filter that can be passed into to filter out all picklists
 * @returns FormSkeleton ready to be passed into a DataEntryForm
 */
export const convertFormViewToFormSkeleton = (fv, filters) => {
    if (!fv)
        return undefined;
    return FormSkeletonMappers.FormSkeleton.fromFormView(fv, {
        filters,
        getCustomRenderer: (member) => getCustomRenderer(member),
        getUnitOfCurrencyProperties: ({ unitOfCurrencyProperties }) => getUnitOfCurrencyProperties(unitOfCurrencyProperties),
        getDataDrivenRuleOverridesGetterForField: ({ viewFieldName }) => getDataDrivenRuleOverridesForField(viewFieldName),
    });
};
export const mapFormViewMember = (member, filters) => FormSkeletonMappers.Field.fromFormViewMember(member, {
    filters,
    getCustomRenderer: (_m) => getCustomRenderer(_m),
    getUnitOfCurrencyProperties: ({ unitOfCurrencyProperties }) => getUnitOfCurrencyProperties(unitOfCurrencyProperties),
    getDataDrivenRuleOverridesGetterForField: ({ viewFieldName }) => getDataDrivenRuleOverridesForField(viewFieldName),
});
