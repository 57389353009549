/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { useStyletron } from 'styletron-react';
import { ApiContext } from '@samc/screen-config-api';
import { handleFileDownload } from '@samc/screen-config-docs';
import { FileInputField, useStrata } from '@samc/filestorage-react';
import { isNullOrUndefined, toastError, toastSuccess, ToolbarButton } from '@samc/react-ui-core';
import { useContextToken } from '../../hooks/useContextToken/useContextToken';
import { getFileInfo, getFormattedDate } from '../../organisms/modals/DocumentsModal/DocumentsModal.Utilities';
export const StrataFileEntryField = (props) => {
    const { value, onChange, disabled, onBlur, name, allowedFileTypes } = props;
    const [fileData, setFileData] = React.useState();
    const [css] = useStyletron();
    const { token } = useContextToken();
    const apisContext = React.useContext(ApiContext);
    const extraProps = {
        dropzoneProps: {
            allowedFileTypes: allowedFileTypes !== null && allowedFileTypes !== void 0 ? allowedFileTypes : '.jpg,.jpeg,.png,.gif',
            displayDeleteButton: false,
        },
    };
    const [dropzoneVisibility, setDropzoneVisibility] = React.useState(true);
    const { saveActions: { saveConfiguration }, } = useStrata();
    React.useEffect(() => {
        var _a;
        saveConfiguration({
            token,
            tenantId: `vms${(_a = apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()}`,
            strataUrl: `${location.protocol}//${location.host}${apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi}`,
            fileManagerUrl: '/manager-service',
            fileMetadataService: '/metadata-service/graphql',
            toastMessages: {
                toastMessageSuccess: toastSuccess,
                toastMessageError: toastError,
            },
        });
    }, [saveConfiguration, apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName, token, apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi]);
    React.useEffect(() => {
        getFileInfo(value, token, apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName, apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi).then((fileInfo) => {
            if (fileInfo && typeof fileInfo !== 'string') {
                setFileData(fileInfo);
            }
        });
    }, [apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi, apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName, token, value]);
    // Effect just to restart Strata component without major modification on their side
    React.useEffect(() => {
        if (!dropzoneVisibility) {
            setDropzoneVisibility(true);
        }
    }, [dropzoneVisibility]);
    const downloadFile = React.useCallback(() => {
        var _a, _b;
        if (!value) {
            return Promise.resolve();
        }
        return handleFileDownload([{ DocumentId: value }], token, (_a = apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName) !== null && _a !== void 0 ? _a : '', (_b = apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi) !== null && _b !== void 0 ? _b : '');
    }, [apisContext === null || apisContext === void 0 ? void 0 : apisContext.StrataApi, apisContext === null || apisContext === void 0 ? void 0 : apisContext.tenantName, token, value]);
    const formattedDate = React.useMemo(() => (fileData === null || fileData === void 0 ? void 0 : fileData.createdDatetime) ? getFormattedDate(fileData.createdDatetime, false) : undefined, [fileData === null || fileData === void 0 ? void 0 : fileData.createdDatetime]);
    return (React.createElement("div", { className: css({ maxWidth: '500px' }) },
        !isNullOrUndefined(value) && fileData && (React.createElement("div", { className: css({ fontSize: '12px', display: 'flex', margin: '10px 0', alignItems: 'center' }) },
            React.createElement("div", { className: css({ marginRight: '20px' }) },
                React.createElement("div", { className: css({ display: 'flex' }) },
                    React.createElement("p", { className: css({ minWidth: '90px' }) }, "File uploaded:"),
                    React.createElement("a", { href: "javascript:void(0)", className: css({ textDecoration: 'none' }), onClick: downloadFile }, fileData === null || fileData === void 0 ? void 0 : fileData.fileName)),
                React.createElement("div", { className: css({ display: 'flex' }) },
                    React.createElement("p", { className: css({ minWidth: '90px' }) }, "Uploaded Date:"),
                    React.createElement("p", null,
                        React.createElement("strong", null, formattedDate)))),
            React.createElement(ToolbarButton, { iconName: "Download", tooltip: "Download", iconStyle: { color: 'blue' }, onClick: downloadFile }),
            ' ',
            React.createElement(ToolbarButton, { iconName: "Delete", tooltip: "Delete", iconStyle: { color: 'blue' }, onClick: () => {
                    onChange('');
                    setDropzoneVisibility(false);
                } }))),
        dropzoneVisibility && (React.createElement(FileInputField, { onChange: onChange, name: name, value: value, disabled: disabled, onBlur: onBlur, extraProps: extraProps }))));
};
export const getStrataFileEntryFieldRenderer = (allowedFileTypes) => (props) => React.createElement(StrataFileEntryField, Object.assign(Object.assign({}, props), { allowedFileTypes }));
export default getStrataFileEntryFieldRenderer;
