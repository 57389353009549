import React from 'react';
export const TabOverrideContext = React.createContext(undefined);
export const useTabOverrides = () => React.useContext(TabOverrideContext);
export const TabOverrideContextProvider = ({ tabRenderer, renderingViewSetId, children, }) => {
    const parentContext = useTabOverrides();
    const parentIds = React.useMemo(() => {
        if (!parentContext)
            return [renderingViewSetId];
        const { renderingViewSetIds } = parentContext;
        return [...renderingViewSetIds, renderingViewSetId];
    }, [parentContext, renderingViewSetId]);
    // propogate the parent tab renderer
    const internalRenderer = React.useMemo(() => {
        if (tabRenderer)
            return tabRenderer;
        return parentContext === null || parentContext === void 0 ? void 0 : parentContext.tabRenderer;
    }, [parentContext === null || parentContext === void 0 ? void 0 : parentContext.tabRenderer, tabRenderer]);
    const value = React.useMemo(() => ({ renderingViewSetIds: parentIds, tabRenderer: internalRenderer }), [internalRenderer, parentIds]);
    return React.createElement(TabOverrideContext.Provider, { value: value }, children);
};
export default TabOverrideContext;
