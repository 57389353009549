import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getFieldConfigurationsByParentDomains } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const FIELD_CONFIGURATIONS_BY_PARENT_DOMAIN_KEY = `${SCREEN_CONFIG_API_KEY}field_configurations_by_parent_domain`;
export const useFieldConfigurationByParentDomains = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (domId) => domId
        ? getFieldConfigurationsByParentDomains(api.AggregatorApi, domId, api.requestInit)
        : Promise.resolve(undefined);
    return useRequestBase({
        key: FIELD_CONFIGURATIONS_BY_PARENT_DOMAIN_KEY,
        query,
        requestContext: ApiContext,
        params: [domainId],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
