var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { GridDropdown, ConvertGridFilterToFilterExpression } from '@samc/react-ui-grid';
import { useDomain, useFieldConfigurationByDomain, } from '@samc/screen-config-api';
import './RecordDropdown.css';
import { Spinner } from '@fluentui/react';
import { GetValueFromData, MessageBar, isNullOrUndefined } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
import QueryExecuteManager from '../../molecules/QueryExecuteManager/QueryExecuteManager';
import { mapDataSourceTypeToGridType } from '../../organisms/GridScreen/GridScreen.utilities';
export const REFERENCE_ID_FIELD = 'ReferenceId';
export const LONG_NAME_FIELD = 'LongName';
const getDomainViewMembers = (...fcms) => fcms
    .filter((fcm) => !!fcm)
    .map(({ viewFieldName }) => ({ scalarDisplayName: viewFieldName, scalarExpression: `[${viewFieldName}]` }));
const RecordDropdownInner = (props) => {
    const { primaryKeyValue, onValueChanged, filters, fieldConfiguration, doFetch, primaryKeyField } = props;
    const { evaluate } = useEvaluator();
    const ref = React.useRef(null);
    const selectedKeys = React.useMemo(() => {
        const output = [];
        if (!isNullOrUndefined(primaryKeyValue))
            output.push(primaryKeyValue);
        return output;
    }, [primaryKeyValue]);
    /**
     * The field configurations indexed by lowercase string
     */
    const indexableFields = React.useMemo(() => {
        const { fieldConfigurationMembers } = fieldConfiguration !== null && fieldConfiguration !== void 0 ? fieldConfiguration : {
            fieldConfigurationMembers: [],
        };
        return fieldConfigurationMembers.reduce((all, cur) => {
            return Object.assign(Object.assign({}, all), { [cur.viewFieldName.toLowerCase()]: cur });
        }, {});
    }, [fieldConfiguration]);
    const idField = indexableFields[primaryKeyField.toLowerCase()];
    const referenceIdField = indexableFields[REFERENCE_ID_FIELD.toLowerCase()];
    const longNameField = indexableFields[LONG_NAME_FIELD.toLowerCase()];
    const aggregatedIdField = referenceIdField !== null && referenceIdField !== void 0 ? referenceIdField : idField;
    const gridFields = React.useMemo(() => [
        ...(aggregatedIdField
            ? [
                {
                    field: aggregatedIdField.viewFieldName,
                    type: mapDataSourceTypeToGridType(aggregatedIdField),
                    headerName: String(aggregatedIdField.displayNameExpression
                        ? evaluate({}, aggregatedIdField.displayNameExpression, true)
                        : aggregatedIdField.viewFieldName),
                    width: 150,
                },
            ]
            : []),
        ...(longNameField
            ? [
                {
                    field: longNameField.viewFieldName,
                    type: mapDataSourceTypeToGridType(longNameField),
                    headerName: String(longNameField.displayNameExpression
                        ? evaluate({}, longNameField.displayNameExpression, true)
                        : longNameField.viewFieldName),
                    width: 250,
                    sort: 'asc',
                },
            ]
            : []),
    ], [aggregatedIdField, evaluate, longNameField]);
    const handleChange = (ev) => {
        var _a;
        const { selectedOptions } = ev;
        onValueChanged((_a = selectedOptions.at(0)) === null || _a === void 0 ? void 0 : _a.id);
    };
    const resolveSelection = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const [response] = yield doFetch(false, {
            start: 0,
            stop: 1,
        }, {
            expressionLang: 'Centric',
            advancedExpression: ids.map((id) => `[${primaryKeyField}] = '${id}'`).join(' OR '),
            advancedInd: true,
        });
        const { Data } = response;
        if (Data.length === 0)
            onValueChanged(undefined); // selection is bad
        return Data.map((d) => ({
            id: String(d.primaryKey),
            displayText: String(GetValueFromData(d, LONG_NAME_FIELD)),
            data: d,
        }));
    });
    const getOptions = (params) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { offset, filter } = params;
        const { limit, filterModel, sortModel } = filter !== null && filter !== void 0 ? filter : {};
        const { sort: order, colId: orderBy } = (_a = sortModel === null || sortModel === void 0 ? void 0 : sortModel.at(0)) !== null && _a !== void 0 ? _a : {};
        const [response] = yield doFetch(false, {
            start: offset,
            stop: offset + (limit !== null && limit !== void 0 ? limit : 100),
            order,
            orderBy,
        }, Object.keys(filterModel !== null && filterModel !== void 0 ? filterModel : {}).length > 0
            ? {
                advancedExpression: ConvertGridFilterToFilterExpression(filterModel, gridFields) || 'true',
                expressionLang: 'Centric',
                filterName: 'AdHoc',
                advancedInd: true,
            }
            : undefined);
        const options = (response.Data || []).map((d) => ({
            id: String(d.primaryKey),
            data: d,
            displayText: String(GetValueFromData(d, LONG_NAME_FIELD)),
        }));
        return {
            options,
            totalCount: response.TotalRecords || 0,
        };
    });
    // force reload on filter change
    const filterChangedKey = filters === null || filters === void 0 ? void 0 : filters.join('');
    React.useEffect(() => {
        const refVal = ref.current;
        if (!refVal)
            return;
        refVal.reload();
    }, [filterChangedKey]);
    return (React.createElement("div", { className: "screen-config-record-dropdown", style: { minWidth: '250px' } },
        React.createElement(GridDropdown, { getOptions: getOptions, fields: gridFields, onChange: handleChange, selectedKeys: selectedKeys, placeholder: "Select a record", multiSelect: false, selectRowOnClick: true, resolveSelection: resolveSelection, ref: ref })));
};
export const RecordDropdown = (props) => {
    const { domainId, filters } = props;
    const { data: domain, isLoading: isDomainLoading } = useDomain(domainId);
    const { data: fieldConfigurations, isLoading: isFieldConfigurationLoading } = useFieldConfigurationByDomain(domainId);
    const fieldConfiguration = fieldConfigurations === null || fieldConfigurations === void 0 ? void 0 : fieldConfigurations.at(0);
    const { primaryKey } = domain !== null && domain !== void 0 ? domain : { primaryKey: 'Id' };
    /**
     * The field configurations indexed by lowercase string
     */
    const indexableFields = React.useMemo(() => {
        const { fieldConfigurationMembers } = fieldConfiguration !== null && fieldConfiguration !== void 0 ? fieldConfiguration : {
            fieldConfigurationMembers: [],
        };
        return fieldConfigurationMembers.reduce((all, cur) => {
            return Object.assign(Object.assign({}, all), { [cur.viewFieldName.toLowerCase()]: cur });
        }, {});
    }, [fieldConfiguration]);
    const idField = indexableFields[primaryKey.toLowerCase()];
    const referenceIdField = indexableFields[REFERENCE_ID_FIELD.toLowerCase()];
    const longNameField = indexableFields[LONG_NAME_FIELD.toLowerCase()];
    const adhocListViewMembers = React.useMemo(() => getDomainViewMembers(idField, referenceIdField, longNameField), [idField, longNameField, referenceIdField]);
    if (isDomainLoading)
        return React.createElement(Spinner, { label: "Loading domain...", labelPosition: "right" });
    if (isFieldConfigurationLoading)
        return React.createElement(Spinner, { label: "Loading field configuration...", labelPosition: "right" });
    if (!domain)
        return React.createElement(MessageBar, { text: "Failed to load domain" });
    if (!fieldConfiguration)
        return React.createElement(MessageBar, { text: "Failed to load field configuration" });
    return (React.createElement(QueryExecuteManager, { domainId: domainId, adhocListViewMembers: adhocListViewMembers, filters: filters }, (queryRenderProps) => (React.createElement(RecordDropdownInner, Object.assign({}, props, { primaryKeyField: primaryKey, fieldConfiguration: fieldConfiguration, doFetch: queryRenderProps.doFetch })))));
};
export default RecordDropdown;
