// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    background-color:  #e6e6e6;
    background-position: center center;
    background-size: cover;
    background-image: none;
    padding-right: 15px;
}
.record-picker-header .screen-control-bar {
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
}
.record-picker-body .tab-bar {
    flex: 1 1 auto;
    margin-bottom: 5px;
    display: flex;
    background-color: white;
    padding-left: 10px;
}
.record-picker-body {
    background-color:  #e6e6e6;
    background-position: center center;
    background-size: cover;
}
.record-picker-body .subappbar.situs-subappbar {
    height: 30px;
    background-image: none;
}
.record-picker-body .subappbar-bottom {
    margin-left: 5px !important;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-screen-config-core-virtual-2101c279b0/1/packages/core/lib/molecules/RecordPicker/RecordPicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,0BAA0B;IAC1B,kCAAkC;IAClC,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,0BAA0B;IAC1B,kCAAkC;IAClC,sBAAsB;AAC1B;AACA;IACI,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,2BAA2B;AAC/B","sourcesContent":[".record-picker-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: baseline;\n    background-color:  #e6e6e6;\n    background-position: center center;\n    background-size: cover;\n    background-image: none;\n    padding-right: 15px;\n}\n.record-picker-header .screen-control-bar {\n    margin-left: auto;\n    margin-bottom: 0;\n    margin-top: 0;\n}\n.record-picker-body .tab-bar {\n    flex: 1 1 auto;\n    margin-bottom: 5px;\n    display: flex;\n    background-color: white;\n    padding-left: 10px;\n}\n.record-picker-body {\n    background-color:  #e6e6e6;\n    background-position: center center;\n    background-size: cover;\n}\n.record-picker-body .subappbar.situs-subappbar {\n    height: 30px;\n    background-image: none;\n}\n.record-picker-body .subappbar-bottom {\n    margin-left: 5px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
