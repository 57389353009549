export const generateTreeData = (jsonData) => {
    const rowData = [];
    if (jsonData !== undefined) {
        jsonData.forEach((viewSet) => {
            const viewSetName = viewSet.name;
            rowData.push({
                item: [viewSetName],
                type: 'Screen',
                viewSetId: viewSet.id,
            });
            viewSet.tabs.forEach((tab) => {
                rowData.push({
                    item: [viewSetName, tab.title],
                    tabId: tab.id,
                    type: 'Tab',
                    viewSetId: viewSet.id,
                });
                if (tab.layouts)
                    tab.layouts.forEach((layout) => {
                        rowData.push({
                            item: [viewSetName, tab.title, layout.title],
                            type: 'Layout',
                            viewId: layout.viewId,
                            layoutType: layout.type,
                            tabId: tab.id,
                            viewSetId: viewSet.id,
                        });
                    });
            });
        });
    }
    return rowData;
};
export const generateId = () => {
    return Math.random().toString(36).substr(2, 9);
};
export const tabIdAlreadyExists = (tabs, tabId) => {
    let alreadyExists = false;
    tabs.forEach((tab) => {
        if (tab.id === tabId)
            alreadyExists = true;
    });
    return alreadyExists;
};
export const mapViewSetTab = (updatedTab, viewSetTab) => {
    const _updatedTab = updatedTab;
    const _viewSetTab = viewSetTab;
    _viewSetTab.title = _updatedTab.title;
    _viewSetTab.titleExpression = _updatedTab.titleExpression;
    _viewSetTab.visibleExpression = _updatedTab.visibleExpression;
    _viewSetTab.layoutSelectionExpression = _updatedTab.layoutSelectionExpression;
    _viewSetTab.defaultLayout = _updatedTab.defaultLayout;
    _viewSetTab.baselineFilterExpression = _updatedTab.baselineFilterExpression;
    _viewSetTab.sidePanelForm = _updatedTab.sidePanelForm;
    _viewSetTab.controlVisibilities = _updatedTab.controlVisibilities;
};
export const getSelectedViewSet = (viewSets, screenId) => {
    return viewSets.filter((x) => x.id === screenId)[0];
};
export const getSelectedTab = (selectedViewSet, layoutTabId) => {
    return selectedViewSet.tabs.filter((x) => x.id === layoutTabId)[0];
};
