/* istanbul ignore file */
import { useRequestBase } from '@samc/react-ui-request';
import { ApiContext, useQBQuery } from '@samc/screen-config-api';
export const DOCUMENT_ENTITY_KEY = 'DocumentAssociatedEntity';
export const useDocumentAssociatedEntity = (id) => {
    const qbQuery = useQBQuery();
    const requestBody = {
        domainId: 'DocumentAssociatedEntity',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'entityTypeId',
                scalarExpression: '[EntityTypeId]',
            },
        ],
        sorting: {
            orderBy: {
                scalarExpression: '[Id]',
            },
            order: 'asc',
        },
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[Id] = '${id}'`,
        },
    };
    const result = useRequestBase({
        key: DOCUMENT_ENTITY_KEY,
        query: qbQuery,
        params: [requestBody],
        requestContext: ApiContext,
    });
    return result;
};
export default useDocumentAssociatedEntity;
