import React from 'react';
import { useDomain, useFieldConfiguration, } from '@samc/screen-config-api';
import { FunctionList, ValidateSeenFields } from '@samc/expressions-core';
import { isNullOrUndefined } from '@samc/react-ui-core';
const getExpressionsFromViewMembers = (viewMembers) => {
    return viewMembers.reduce((prev, current) => {
        const result = [...prev];
        const { requiredExpression, calculationCondition, calculationRule, links, editableExpression, defaultValueExpression, visibleExpression, displayNameExpression, hexFillRule, } = current;
        if (links)
            links.forEach((link) => {
                const { constraintExpression, displayNameExpression: linkDisplayNameExpression } = link;
                if (!isNullOrUndefined(constraintExpression))
                    result.push(constraintExpression);
                result.push(linkDisplayNameExpression);
            });
        return [
            ...result,
            ...(requiredExpression ? [requiredExpression] : []),
            ...(editableExpression ? [editableExpression] : []),
            ...(defaultValueExpression ? [defaultValueExpression] : []),
            ...(visibleExpression ? [visibleExpression] : []),
            ...(calculationCondition ? [calculationCondition] : []),
            ...(calculationRule ? [calculationRule] : []),
            ...(displayNameExpression ? [displayNameExpression] : []),
            ...(hexFillRule ? [hexFillRule] : []),
        ];
    }, []);
};
const getExpressionsFromAutoCompleteActions = (actions) => {
    return actions.reduce((prev, current) => {
        const { filterMap, resultMap, isActiveExpression } = current;
        const filterExpressions = filterMap.map((f) => f.sourceExpression);
        const resultExpressions = resultMap.map((r) => `[${r.sourceField}]`);
        return [
            ...prev,
            ...(isActiveExpression ? [isActiveExpression] : []),
            ...filterExpressions,
            ...resultExpressions,
        ];
    }, []);
};
const getExpressionsFromView = (view, viewMembers) => {
    return [
        ...getExpressionsFromViewMembers(viewMembers),
        ...getExpressionsFromAutoCompleteActions(view.autoCompleteActions || []),
    ];
};
/**
 * Adds additional view members to the set to include referenced members in expressions or currency/measurement fields.
 * @param domain the domain associated with the view
 * @param fieldConfiguration the field configuration associated with the view
 * @param view the view
 * @param viewMembers the view's members
 * @param createViewMember a function to use the domain and a field name to create a new view member
 * @returns all of the missing view members to be fetched
 */
export const getMissingViewMembers = (domain, fieldConfiguration, view, viewMembers, createViewMember) => {
    const expressions = getExpressionsFromView(view, viewMembers);
    const missingFieldNames = new Set(['ShortName']);
    const includedFieldNames = new Set();
    viewMembers.forEach((viewMember) => {
        const { viewFieldName, measurementProperties, currencyProperties } = viewMember;
        includedFieldNames.add(viewFieldName); // already accounted for
        if (measurementProperties && measurementProperties.unitOfMeasureFieldName)
            missingFieldNames.add(measurementProperties.unitOfMeasureFieldName);
        if (currencyProperties && currencyProperties.unitOfCurrencyFieldName)
            missingFieldNames.add(currencyProperties.unitOfCurrencyFieldName);
    });
    const domainFields = domain === null || domain === void 0 ? void 0 : domain.fields.map((f) => f.fieldName);
    new Set(expressions).forEach((expression) => {
        const { seenFields } = ValidateSeenFields(expression, FunctionList, domainFields);
        if (seenFields)
            seenFields.forEach((f) => missingFieldNames.add(f));
    });
    // add key fields
    if (fieldConfiguration)
        fieldConfiguration.fieldConfigurationMembers.forEach((m) => {
            if (m.isKeyField)
                missingFieldNames.add(m.viewFieldName);
        });
    const missingFieldArray = Array.from(missingFieldNames)
        .filter((f) => !includedFieldNames.has(f))
        .sort();
    const resultViewMembers = missingFieldArray
        .map((missingField) => domain === null || domain === void 0 ? void 0 : domain.fields.find((domainField) => missingField === domainField.fieldName))
        .filter((df) => !!df)
        .map((df) => (Object.assign(Object.assign({}, createViewMember(df, fieldConfiguration)), { visibleExpression: 'false' })));
    // Add dependencies of the fields just identified as missing, if any exist
    if (resultViewMembers.length > 0)
        resultViewMembers.push(...getMissingViewMembers(domain, fieldConfiguration, view, [...viewMembers, ...resultViewMembers], createViewMember));
    return resultViewMembers;
};
/**
 * Parses the expressions on the ViewMembers of the given View and returns a list of any fields which
 * are referenced by expressions or currency/measurement fields on those ViewMembers but are not themselves part of the View.
 * @param view The view for which to find any missing View dependencies.
 * @param viewMembers The initial set of viewMembers included in the view so that we don't add duplicates.
 * @param createViewMember Function for generating object of type T;
 * The primary reason this is a separate param is because FormViews and ListViews describe their members using different data structures.
 * @returns A list of any missing fields which are referenced by expressions or currency/measurement fields on the View.
 */
export const useMissingViewDependencies = (view, viewMembers, createViewMember) => {
    const { data: domain, isLoading: isDomainLoading } = useDomain(view === null || view === void 0 ? void 0 : view.domainId);
    const { data: fieldConfiguration, isLoading: isFieldConfigurationLoading } = useFieldConfiguration(view === null || view === void 0 ? void 0 : view.fieldConfigurationId);
    const missingViewMembers = React.useMemo(() => (view ? getMissingViewMembers(domain, fieldConfiguration, view, viewMembers, createViewMember) : []), [createViewMember, domain, fieldConfiguration, view, viewMembers]);
    return {
        missingViewMembers,
        isLoading: isDomainLoading || isFieldConfigurationLoading,
    };
};
export default useMissingViewDependencies;
