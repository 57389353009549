import React from 'react';
import { useQueryClient } from 'react-query';
import { ServersideFolderViewer, isFolder, } from '@samc/folder-ui-core';
import { useStyletron } from 'styletron-react';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { InputType, PicklistDisplaySetting, PicklistSetting, } from '@samc/picklist-api';
import { getServerSidePicklistFieldRenderer, usePicklistGridFields } from '@samc/picklist-core';
import { InputType as FormInputType } from '@samc/react-ui-form';
import { FolderType } from '@samc/folder-ui-api';
import * as Utils from './Utilities';
import { ListViewEditorModal } from '../../../molecules/modals/ListViewEditorModal/ListViewEditorModal';
import { usePicklistGridFieldConfigurations } from '../../../hooks/usePicklistGridFieldConfigurations/usePicklistGridFieldConfigurations';
import { useGridFieldsFromListview } from '../../../hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import { useGridInjector } from '../../../hooks/useGridInjector/useGridInjector';
const POMA_PORTFOLIO_PICKLIST_ID = 'POMA_Portfolio_Picklist';
const portfolioPicklistField = {
    picklistId: POMA_PORTFOLIO_PICKLIST_ID,
    displaySetting: PicklistDisplaySetting.CustomLongName,
    id: '_internal_portfolio_picklist',
    setting: PicklistSetting.MultiSelect,
};
const picklistFieldConfigurationMembers = {
    tag: {
        viewFieldName: 'tag',
        inputType: InputType.Picklist,
        picklistField: portfolioPicklistField,
    },
};
const picklistGridFields = [
    {
        field: 'tag',
        headerName: 'Portfolios',
        floatingFilter: true,
        valueGetter: ({ data }) => {
            const mappedData = data;
            if (!isFolder(mappedData))
                return null;
            if (mappedData.tagType !== 'Portfolio')
                return null;
            return mappedData.tag;
        },
    },
];
const picklistFormFields = [
    {
        field: 'tag',
        displayNameRule: "'Portfolios'",
        inputType: FormInputType.Text,
        width: 200,
        editableRule: `[type] <> ${FolderType.System}`,
        onRenderField: getServerSidePicklistFieldRenderer(portfolioPicklistField, [], usePicklistGridFieldConfigurations.withHooks({ useGridFieldsFromListview, useGridInjector })),
    },
    {
        field: 'tagType',
        visibleRule: 'false',
        calculationRule: "IIF(ISNULLOREMPTY([tag]), null, 'Portfolio')",
        calculationCondition: 'true',
        inputType: FormInputType.Text,
    },
];
export const ListViewFolderEditor = (props) => {
    const { domainId, className, editOnClick, getContextMenuItems, multiselect, newListViewFolderId, setNewListViewFolderId, setTargetListViewId, targetListViewId, } = props;
    const queryClient = useQueryClient();
    const [css] = useStyletron();
    const { frameworkComponents, wrappedFields } = usePicklistGridFields(picklistFieldConfigurationMembers, picklistGridFields);
    const extraColDefs = React.useMemo(() => {
        return wrappedFields.map((f) => (Object.assign(Object.assign({}, f), { tooltipComponentParams: f, hide: undefined })));
    }, [wrappedFields]);
    const iProps = {
        setTargetListViewId,
        queryClient,
        setNewListViewFolderId,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ServersideFolderViewer, { className: className, extraColumns: extraColDefs, frameworkComponents: frameworkComponents, spaceName: domainId, multiselect: multiselect, suppressCheckbox: editOnClick, typeDisplayName: "List View", typeDisplayNamePlural: "List Views", type: "DomainView", rowClassRules: { [css(Object.assign({}, (editOnClick && { cursor: 'pointer' })))]: (v) => !isFolder(v.data) }, getContextMenuItems: getContextMenuItems, onRowClicked: Utils.onRowClicked(props, iProps), folderEditorProps: { additionalFields: picklistFormFields } }),
        (!isNullOrUndefined(targetListViewId) || !isNullOrUndefined(newListViewFolderId)) && (React.createElement(ListViewEditorModal, { defaultFolderId: newListViewFolderId, onClose: Utils.onClose(iProps), listViewId: targetListViewId }))));
};
export default ListViewFolderEditor;
