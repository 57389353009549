import * as GenericApiContext from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
export const REQUIREDURIS = [
    'AggregatorApi',
    'FolderApi',
    'LiftSiftApi',
    'QueryAuthorizationApi',
    'ReportingApi',
    'TaskApi',
];
export const ApiContext = GenericApiContext.ApiContext;
export const ApiProvider = GenericApiContext.ApiProvider;
export const ApiConsumer = GenericApiContext.ApiConsumer;
export const useApiContext = (suppressErrors) => {
    GenericApiContext.useAssertApiKeys(REQUIREDURIS, suppressErrors);
    return GenericApiContext.useApiContext();
};
