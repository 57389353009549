import { useRequestGetter } from '@samc/react-ui-request';
import React from 'react';
import { getFormViews } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
import { FORM_KEY } from '../useFormView/useFormView';
export const FORM_VIEW_BY_DOMAIN_KEY = `${SCREEN_CONFIG_API_KEY}${FORM_KEY}_by_domain`;
export const useFormViewsByDomainRequest = () => {
    const api = React.useContext(ApiContext);
    const query = (dId) => getFormViews(api.AggregatorApi, dId, api.requestInit);
    const getter = useRequestGetter({
        query,
        key: FORM_VIEW_BY_DOMAIN_KEY,
    });
    return React.useCallback((dId) => getter(false, dId), [getter]);
};
export default useFormViewsByDomainRequest;
