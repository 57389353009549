var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { DomainRelationshipType, useDomainRelationships, useQBQuery, useDomainGetter, } from '@samc/screen-config-api';
import { SelectAndShowComponent } from '../../organisms/FormScreen/InnerFormField';
import { getFilterExpressions } from '../../organisms/FormScreen/utilities';
import { getValidExpressions } from '../../utils/ExpressionValidation/ExpressionValidationUtilities';
import { MergeFilters } from '../../molecules/QueryExecuteManager/MergeAdHocFilter';
/**
 * Gets the S&S form configuration to pass to the form
 * @param params The options
 * @returns the select and show configuration
 */
export const useSelectAndShowFormConfiguration = (params) => {
    const { parentRequestIdentifier, domainId, filters, primaryKey, onChange, onSelectionChange, data, parentScope, options, exportMode, taskResponses, initialSelectedItemValue, FormScreenWrapper, ServiceDrivenViewSet, GridScreenWrapper, } = params;
    const { submitAllAttributes, beforeReset, onModalSaved } = options !== null && options !== void 0 ? options : {};
    const childDomains = useDomainRelationships(undefined, domainId);
    const query = useQBQuery();
    const domainGetter = useDomainGetter();
    const callbacks = {
        onModalSaved,
        beforeReset,
        onChange,
    };
    // reduces re-renders
    const callbackRef = React.useRef(callbacks);
    callbackRef.current = callbacks;
    const memoedCallbacks = React.useMemo(() => ({
        onFormStateChange: (...p) => { var _a, _b; return (_b = (_a = callbackRef.current).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, ...p); },
        onModalSaved: (...p) => { var _a, _b; return (_b = (_a = callbackRef.current).onModalSaved) === null || _b === void 0 ? void 0 : _b.call(_a, ...p); },
        beforeReset: (...p) => { var _a, _b; return (_b = (_a = callbackRef.current).beforeReset) === null || _b === void 0 ? void 0 : _b.call(_a, ...p); },
    }), []);
    const getSelectAndShowOptions = React.useCallback((selectAndShow) => __awaiter(void 0, void 0, void 0, function* () {
        if (!childDomains.data)
            return [];
        const ssDomain = selectAndShow.id;
        const relationship = childDomains.data.find((r) => r.targetDomainId === ssDomain);
        const fullDomain = yield domainGetter(false, ssDomain);
        let keyField;
        let foreignKeyField;
        let relationshipType = DomainRelationshipType.Single;
        if (relationship) {
            relationshipType = relationship.relationshipType;
            keyField = relationship.sourceField; // the outer form is a parent
            foreignKeyField = relationship.targetField; // the ss is a child
        }
        if (!foreignKeyField)
            return [];
        let valueField = primaryKey;
        if (data && keyField && data[keyField])
            valueField = data[keyField];
        const pkFilter = getFilterExpressions(relationshipType, foreignKeyField, valueField);
        const validFilters = getValidExpressions(filters, fullDomain);
        const { baselineFilterExpression } = selectAndShow;
        const mergedFilters = MergeFilters([pkFilter, baselineFilterExpression, ...validFilters], 'SelectAndShow Filter');
        try {
            const result = yield query({
                adhocListViewMembers: [
                    { scalarDisplayName: 'LongName', scalarExpression: '[LongName]' },
                    { scalarExpression: '[Id]', scalarDisplayName: 'Id' },
                ],
                adhocFilter: mergedFilters,
                domainId: ssDomain,
                sorting: {
                    order: 'asc',
                    orderBy: { scalarExpression: '[SortOrder]' },
                },
                paging: { start: 0, stop: 2147483647 },
                summaryMode: false,
                primaryViewId: '-1',
            });
            return result.Data.map((l) => ({
                displayText: l.LongName,
                id: l.Id,
                data: l,
            }));
        }
        catch (e) {
            return [];
        }
    }), [childDomains.data, primaryKey, query, data, domainGetter, filters]);
    const renderSelectAndShowInner = React.useMemo(() => {
        const renderFn = (selectedId, selectAndShow, upperContentRenderer) => (React.createElement(SelectAndShowComponent, { FormScreenWrapper: FormScreenWrapper, selectAndShow: selectAndShow, selectedId: selectedId, dropdownRenderer: upperContentRenderer, parentRequestIdentifier: parentRequestIdentifier, parentScope: parentScope, exportMode: exportMode, submitAllAttributes: submitAllAttributes, taskResponses: taskResponses, onFormStateChange: memoedCallbacks.onFormStateChange, beforeReset: memoedCallbacks.beforeReset, onModalSaved: memoedCallbacks.onModalSaved, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper }));
        return renderFn;
    }, [parentRequestIdentifier, parentScope, exportMode, submitAllAttributes, taskResponses, memoedCallbacks]);
    return {
        getSelectAndShowOptions,
        renderSelectAndShow: renderSelectAndShowInner,
        onSelectionChange,
        initialSelectedItemValue,
    };
};
export default useSelectAndShowFormConfiguration;
