import React from 'react';
import { useListView } from '@samc/screen-config-api';
const _usePicklistGridFieldConfigurations = (params) => {
    const { picklistField, useGridFieldsFromListview, useGridInjector } = params;
    const { listViewId, gridFieldConfiguration } = picklistField;
    const { data: listView, isLoading: isLoadingListView } = useListView(listViewId);
    const { frameworkComponents, wrappedFields, isLoading: isLoadingGridFields, } = useGridFieldsFromListview({
        listView,
        suppressLinks: true,
        useGridInjector,
    });
    const fields = React.useMemo(() => {
        const output = [];
        if (wrappedFields)
            output.push(...wrappedFields);
        if (gridFieldConfiguration)
            output.push(...gridFieldConfiguration);
        return output;
    }, [gridFieldConfiguration, wrappedFields]);
    const domainViewMembers = React.useMemo(() => {
        if (!listView)
            return [];
        return listView.listViewMembers.map((m) => ({
            scalarDisplayName: m.viewFieldName,
            scalarExpression: m.scalarExpression || `[${m.viewFieldName}]`,
        }));
    }, [listView]);
    return { fields, frameworkComponents, isLoading: isLoadingGridFields || isLoadingListView, domainViewMembers };
};
export const usePicklistGridFieldConfigurations = Object.assign(_usePicklistGridFieldConfigurations, {
    /**
     * A quick and easy way to inject dependency hooks and get a constant callback.
     * @note should be called outside of a component function or memoized
     */
    withHooks: (hooks) => (params) => _usePicklistGridFieldConfigurations(Object.assign(Object.assign({}, params), hooks)),
});
export default usePicklistGridFieldConfigurations;
