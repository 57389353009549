var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Grid, useAgGridApi } from '@samc/react-ui-grid';
import { safeGridApiCall } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import React from 'react';
import { v4 } from 'uuid';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { ErrorTypeCellRenderer, ErrorTypeValueGetter } from '../TaskResponsePopupUtils';
const noop = () => undefined;
const validationTypeRenderer = (props) => React.createElement(ErrorTypeCellRenderer, Object.assign({}, props));
export const MessageBody = (props) => {
    const { messages, dataByDomain, domainNameMap, getMessageLocation } = props;
    const [gridApi, onBodyGridReady] = useAgGridApi();
    const exportData = React.useCallback(() => safeGridApiCall(gridApi, (api) => api.exportDataAsCsv()), [gridApi]);
    const [errorMessagesWithData, _setErrorMessagesWithData] = React.useState();
    // get location and modify record name
    React.useEffect(() => {
        const handler = () => __awaiter(void 0, void 0, void 0, function* () {
            const output = yield Promise.all(messages.map((message) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c;
                const { recordIdentifier, code, title, response, index } = message;
                const { domainId, requestIdentifier } = response;
                const data = recordIdentifier ? (_a = dataByDomain[domainId]) === null || _a === void 0 ? void 0 : _a[recordIdentifier] : undefined;
                let screenLocation = requestIdentifier;
                if (getMessageLocation)
                    screenLocation = yield getMessageLocation({ message, data });
                let recordShortName;
                if (data)
                    recordShortName =
                        (_c = (_b = data['ShortName']) !== null && _b !== void 0 ? _b : data['LongName']) !== null && _c !== void 0 ? _c : data['Name'];
                if (!recordShortName)
                    recordShortName = !isNullOrUndefined(index) ? `Row ${index + 1}` : recordIdentifier;
                if (data && domainNameMap && domainNameMap[domainId] && !recordShortName)
                    recordShortName = data[domainNameMap[domainId]];
                if (!recordShortName)
                    recordShortName = recordIdentifier !== null && recordIdentifier !== void 0 ? recordIdentifier : '-';
                return Object.assign(Object.assign({}, message), { data, recordShortName: String(recordShortName), generatedId: v4(), screenLocation, code: code !== null && code !== void 0 ? code : '-', title: title.substring(0, Math.min(500, title.length)) });
            })));
            _setErrorMessagesWithData(output);
        });
        handler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataByDomain, domainNameMap, messages]);
    const showLocation = !!getMessageLocation;
    const columns = React.useMemo(() => [
        ...(showLocation
            ? [
                {
                    headerName: 'Screen Location',
                    field: 'screenLocation',
                    width: 150,
                    suppressSizeToFit: true,
                },
            ]
            : []),
        {
            headerName: 'Applicable Record',
            field: 'recordShortName',
            width: 150,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Error Code',
            field: 'code',
            width: 125,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Validation Error',
            field: 'title',
            wrapText: true,
            autoHeight: true,
            minWidth: 200,
            // `wrapText` enables letter-boundary wrapping, so we're not using that.
            // Instead, set cellStyle here to enable word-boundary wrapping
            cellStyle: { whiteSpace: 'normal' },
        },
        {
            headerName: 'Validation Type',
            field: 'errorType',
            cellRenderer: validationTypeRenderer,
            valueGetter: ErrorTypeValueGetter,
            width: 140,
            suppressSizeToFit: true,
        },
    ], [showLocation]);
    if (!errorMessagesWithData)
        return React.createElement(Spinner, { size: SpinnerSize.medium });
    return (React.createElement(Grid, { onSubmit: noop, onClickExcelExport: exportData, data: errorMessagesWithData, idField: "generatedId", fields: columns, rowsPerPage: 15, suppressColumnFilters: false, suppressRowSelector: true, rowSelection: "none", autoHeight: true, rowSpacingValue: "tight", suppressEditToggle: true, suppressAddRowButton: true, suppressAggregateToggle: true, suppressClearFilter: true, suppressDeleteButton: true, suppressDeleteConfirmationModal: true, suppressRefresh: true, suppressRowSpacing: true, onBodyGridReady: onBodyGridReady, sizeColumnsToFit: true }));
};
