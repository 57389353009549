import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getFormView } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const FORM_KEY = `${SCREEN_CONFIG_API_KEY}form_view`;
export function useFormView(formViewId) {
    const api = React.useContext(ApiContext);
    const getter = (fvId) => fvId && !fvId.match(/^ *$/)
        ? getFormView(api.AggregatorApi, fvId, api.requestInit)
        : Promise.resolve(undefined);
    return useRequestBase({
        key: FORM_KEY,
        query: getter,
        requestContext: ApiContext,
        params: [formViewId],
        options: {
            staleTime: CacheTime.default,
        },
    });
}
