import React from 'react';
import { useAllPicklists } from '@samc/picklist-core';
import { useApplicationContext } from '@samc/screen-config-api';
export const usePreparedEvaluatorConfiguration = (members) => {
    const { currentUser: { id: currentUserId }, } = useApplicationContext();
    const picklists = useAllPicklists();
    const fieldToPicklistMap = React.useMemo(() => Object.keys(members || {}).reduce((previous, current) => {
        var _a, _b;
        if (!((_a = members === null || members === void 0 ? void 0 : members[current].picklistField) === null || _a === void 0 ? void 0 : _a.picklistId))
            return previous;
        return Object.assign(Object.assign({}, previous), { [current]: (_b = members[current].picklistField) === null || _b === void 0 ? void 0 : _b.picklistId });
    }, {}), [members]);
    const memberCount = members ? Object.keys(members).length : 0;
    const picklistFieldCount = Object.keys(fieldToPicklistMap).length;
    // if there are no picklist fields, we can signal that we are initialized immediately
    const isInitialized = memberCount > 0 && (picklistFieldCount === 0 || Object.keys(picklists).length > 0);
    return React.useMemo(() => ({
        isInitialized,
        picklists,
        fieldToPicklistMap,
        currentUserId,
    }), [isInitialized, picklists, fieldToPicklistMap, currentUserId]);
};
export default usePreparedEvaluatorConfiguration;
