var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-case-declarations */
import { useEvaluatorContext } from '@samc/expressions-react';
import { useHistoryContext } from '@samc/react-ui-history';
import { ApiContext, DomainRelationshipType, useDomain, useDomainRelationships, useFormView, } from '@samc/screen-config-api';
import React, { useMemo } from 'react';
import { evaluate } from '@samc/expressions-core';
import { handleFileDownload } from '@samc/screen-config-docs';
import { LinkType } from '../../enums/LinkType';
import { buildFilterExpression, getFieldsFromParent } from '../../organisms/FormScreen/utilities';
import { useContextToken } from '../useContextToken/useContextToken';
import { ModalType } from '../../enums/ModalType';
/**
 * Returns a link onClick handler, the current modal ID, and the modal close callback
 */
export const useLinkClicked = (data, domainId, promptAndContinue) => {
    var _a, _b;
    const { browserHistory } = useHistoryContext();
    const [formViewId, setModalId] = React.useState();
    const [modalText, setModalText] = React.useState('');
    const [tabBaselineFilter, setTabBaselineFilter] = React.useState(null);
    const [modalType, setModalType] = React.useState(ModalType.Modal);
    const formView = useFormView(formViewId);
    const domainRelationships = useDomainRelationships(domainId); // all relationships that target the given domain
    const domain = useDomain(domainId);
    const defaultEvaluatorConfig = useEvaluatorContext();
    const targetRelationships = useDomainRelationships((_a = formView.data) === null || _a === void 0 ? void 0 : _a.domainId, domainId);
    const targetDomain = useDomain((_b = formView === null || formView === void 0 ? void 0 : formView.data) === null || _b === void 0 ? void 0 : _b.domainId);
    const apisContext = React.useContext(ApiContext);
    const { token } = useContextToken();
    const tenantName = `${apisContext.tenantName}`;
    const [internalPkValue, setPkValue] = React.useState();
    const [refFieldId, setRefFieldId] = React.useState();
    const pkValue = React.useMemo(() => {
        var _a;
        if (!data)
            return undefined;
        const { data: relationships } = domainRelationships;
        const { data: formViewData } = formView;
        if (!formViewData)
            return undefined;
        if (formViewData.domainId === domainId) {
            if (!domain.data)
                return undefined;
            // on the same domain, use domain's pk
            const targetField = domain.data.primaryKey;
            return data[targetField];
        }
        if (!relationships)
            return undefined;
        const { domainId: sourceDomainId } = formViewData;
        const targetField = (_a = relationships.find((r) => r.sourceDomainId === sourceDomainId && r.relationshipType === DomainRelationshipType.Single)) === null || _a === void 0 ? void 0 : _a.targetField;
        if (!targetField)
            return undefined;
        return data[targetField];
    }, [data, domain.data, domainId, domainRelationships, formView]);
    const handleClick = React.useCallback((l, d, evaluatorConfig) => __awaiter(void 0, void 0, void 0, function* () {
        const { to, target, referencedFieldId, baselineFilterExpression: tabBaselineFilterExpression, text: headerText, } = l;
        if (!target)
            return;
        const innerPromptAndContinue = promptAndContinue !== null && promptAndContinue !== void 0 ? promptAndContinue : ((action) => action());
        innerPromptAndContinue(() => {
            var _a;
            switch (to) {
                case LinkType.ConfirmationModal:
                case LinkType.Modal:
                case LinkType.MultiSelectConfirmationModal:
                    if (domainId)
                        setModalId(target);
                    if (headerText)
                        setModalText(headerText);
                    if (to === LinkType.ConfirmationModal)
                        setModalType(ModalType.ConfirmationModal);
                    if (to === LinkType.MultiSelectConfirmationModal) {
                        setModalType(ModalType.MultiSelectConfirmationModal);
                        setRefFieldId(referencedFieldId);
                    }
                    break;
                case LinkType.Redirect:
                default:
                    const url = String(evaluate(d, target, true, evaluatorConfig !== null && evaluatorConfig !== void 0 ? evaluatorConfig : defaultEvaluatorConfig));
                    if (apisContext.StrataApi && url.includes(apisContext.StrataApi)) {
                        const idFile = (_a = url.split('/').pop()) !== null && _a !== void 0 ? _a : '';
                        handleFileDownload([{ DocumentId: idFile }], token, tenantName, apisContext.StrataApi);
                    }
                    else if (url.includes('#')) {
                        browserHistory.push(url);
                    }
                    else {
                        browserHistory.push(`${url}#`);
                    }
            }
            setTabBaselineFilter(tabBaselineFilterExpression);
        });
    }), [apisContext.StrataApi, browserHistory, defaultEvaluatorConfig, domainId, promptAndContinue, tenantName, token]);
    /**
     * This will build the default data based on the relationship from the current/parent form to
     * the modal that is being opened.
     * Use Case: Valuation form has a link/button to launch a Review Comment modal to add a new comment to a valuation
     */
    const linkDefaultData = useMemo(() => {
        if ((targetRelationships === null || targetRelationships === void 0 ? void 0 : targetRelationships.data) && (targetDomain === null || targetDomain === void 0 ? void 0 : targetDomain.data)) {
            return getFieldsFromParent(Object.assign({}, data), targetDomain.data, targetRelationships.data) || {};
        }
        return {};
    }, [data, targetDomain.data, targetRelationships.data]);
    /**
     * This will build up a filter based on the relationship from the current/parent form to
     * the modal that is being opened.
     * Use Case: Valuation form has a link/button to launch a Review Comment modal to add a new comment to a valuation
     */
    const linkFilter = useMemo(() => {
        let filters = [];
        if ((targetRelationships === null || targetRelationships === void 0 ? void 0 : targetRelationships.data) && (targetDomain === null || targetDomain === void 0 ? void 0 : targetDomain.data)) {
            filters = filters.concat(buildFilterExpression(Object.assign({}, data), targetDomain.data, targetRelationships.data));
        }
        if (tabBaselineFilter) {
            filters.push(tabBaselineFilter);
        }
        return filters;
    }, [data, targetDomain.data, targetRelationships.data, tabBaselineFilter]);
    const closeModal = React.useCallback(() => {
        setModalId(undefined);
        setPkValue(undefined);
    }, []);
    return [
        handleClick,
        formViewId,
        internalPkValue || pkValue,
        closeModal,
        modalType,
        linkFilter,
        linkDefaultData,
        modalText,
        setPkValue,
        refFieldId,
    ];
};
export default useLinkClicked;
