import { FilterTemplateEditor, CustomModal } from '@samc/vmsnext-querybuilder';
import React from 'react';
import { useLookupsForLiftAndSift } from '../../../hooks/useLookupsForExpressions/useLookupsForExpressions';
export const FilterEditorModal = (props) => {
    const { onClose, filterId, defaultFolderId, suppressApply } = props;
    useLookupsForLiftAndSift();
    return (React.createElement(CustomModal, { onDismiss: onClose },
        React.createElement(FilterTemplateEditor, { suppressApply: suppressApply, defaultValues: Object.assign({}, (defaultFolderId && { folder: { folderGuid: defaultFolderId } })), templateId: filterId, onClose: onClose })));
};
export default FilterEditorModal;
