import React from 'react';
import { useQueryClient } from 'react-query';
import { FILTER_KEY } from '@samc/screen-config-api/lib/useFilterByDomain/useFilterByDomain';
import { FILTERS_KEY } from '@samc/screen-config-api/lib/useFilters/useFilters';
import { DomainFilterContextProvider, getItemsFromLiftSiftFolder, FilterDrawerV2, Boxes, } from '@samc/vmsnext-querybuilder';
import { FilterEditorModal } from '../../modals/FilterEditorModal/FilterEditorModal';
const { FilterBox } = Boxes;
export const FilterControlBarItem = ({ enableDrawer, promptAndContinue, selectedFilterIds, setSelectedFilterIds, refreshFilter, }) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const filterContext = React.useContext(DomainFilterContextProvider);
    const { filterList, filterIds, activeFilterDetails } = filterContext;
    const queryClient = useQueryClient();
    const [{ defaultParentFolderId, editorPk, editorOpen }, setEditorState] = React.useState({
        editorOpen: false,
    });
    // Pre-fetch the filter list
    React.useEffect(() => {
        filterContext.fetchFilterTree();
        // Only run this effect when the component first loads.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // in effect if selected filters changes, call context to set it
    React.useEffect(() => {
        if (selectedFilterIds && filterList) {
            const items = getItemsFromLiftSiftFolder(filterList ? [filterList] : undefined);
            const domainFilters = items
                .filter((x) => x.guid && selectedFilterIds.includes(x.guid))
                .map((x) => x.id);
            const differences = domainFilters
                .filter((x) => !filterIds.includes(x))
                .concat(filterIds.filter((x) => !domainFilters.includes(x)));
            if (differences.length > 0) {
                filterContext.setFilterIds(domainFilters);
            }
        }
        // Only run this effect if the selectedFilterIds are different OR when filterList is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterIds, filterList]);
    // If filterIds (int) changes, tell the parent component about it
    React.useEffect(() => {
        if (filterIds && selectedFilterIds && setSelectedFilterIds && filterList) {
            const filters = activeFilterDetails;
            if (filters) {
                const newFilterIds = filters.filter((x) => x.guid).map((x) => x.guid);
                const differences = newFilterIds
                    .filter((x) => !selectedFilterIds.includes(x))
                    .concat(selectedFilterIds.filter((x) => !newFilterIds.includes(x)));
                if (differences.length > 0) {
                    setSelectedFilterIds(newFilterIds);
                }
            }
        }
        // Only run this effect if the active filters change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilterDetails]);
    const openDrawer = React.useCallback((open) => {
        if (open && promptAndContinue)
            promptAndContinue(() => setDrawerOpen(open));
        else
            setDrawerOpen(open);
    }, [promptAndContinue]);
    const onAddClicked = (_, parentFolderId, as) => {
        setEditorState({
            editorOpen: true,
            editorPk: undefined,
            afterSave: as,
            defaultParentFolderId: parentFolderId,
        });
    };
    const onEditClicked = (item, _, __, as) => {
        setEditorState({
            editorOpen: true,
            editorPk: item.id,
            afterSave: as,
            defaultParentFolderId: undefined,
        });
    };
    const onClose = () => {
        setEditorState((cur) => {
            var _a;
            (_a = cur.afterSave) === null || _a === void 0 ? void 0 : _a.call(cur);
            return { editorOpen: false };
        });
        setDrawerOpen(false);
        refreshFilter === null || refreshFilter === void 0 ? void 0 : refreshFilter();
    };
    const onItemSaved = (_, _action) => {
        queryClient.invalidateQueries(FILTER_KEY);
        queryClient.invalidateQueries(FILTERS_KEY);
        filterContext.fetchFilterTree();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterBox, { onClick: () => {
                if (enableDrawer)
                    openDrawer(true);
            } }),
        drawerOpen && (React.createElement(FilterDrawerV2, { onLinkEdit: onEditClicked, drawerOpen: drawerOpen, editorOpen: editorOpen, setDrawerOpen: openDrawer, onAddClicked: onAddClicked, suppressSelectionBox: true, folderViewerProps: {
                onItemSaved,
            } })),
        editorOpen && (React.createElement(FilterEditorModal, { defaultFolderId: defaultParentFolderId, onClose: onClose, filterId: editorPk }))));
};
export default FilterControlBarItem;
