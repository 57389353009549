import { DomainRelationshipType, TaskStatus, } from '@samc/screen-config-api';
import { GetValueFromData, SetValueFromData, isNullOrUndefined } from '@samc/react-ui-core';
import { getUpdatedMessageInfo } from '../GridScreen/GridScreen.utilities';
import { FormSkeletonMappers } from '../../mappers/FormSkeletonMappers/FormSkeletonMappers';
export function getUnitOfCurrencyProperties(unitOfCurrencyProperties) {
    const newUnitOfCurrencyProperties = { units: new Array() };
    if (unitOfCurrencyProperties) {
        if (unitOfCurrencyProperties.units.length > 0) {
            unitOfCurrencyProperties.units.forEach((uoc) => {
                const tempUoc = Object.assign(Object.assign({}, uoc), { unitOfCurrency: uoc.id });
                newUnitOfCurrencyProperties.units.push(tempUoc);
            });
        }
        return newUnitOfCurrencyProperties;
    }
    return unitOfCurrencyProperties;
}
/**
 * returns mapped API inputType
 * @param inputType UI inputType to be mapped to API inputType
 * @deprecated use {@link FormSkeletonMappers}
 */
export const mapUIInputType = FormSkeletonMappers.InputType.toBackendInputType; // backwards compat
/**
 * returns mapped UI inputType
 * @param inputType API inputType to be mapped to UI inputType
 * @deprecated use {@link FormSkeletonMappers}
 */
export const mapAPIInputType = FormSkeletonMappers.InputType.toFrontendInputType; // backwards compat
export const REQUEST_IDENTIFIER_SEPARATOR = ' -> ';
/**
 * Returns a request identifier string to represent a formview
 * @param formView
 * @returns
 */
export const getFormRequestIdentifier = () => 'Root Form';
export const getGridRequestIdentifier = (listView) => `${listView.domainId} Grid`;
/**
 * Returns a request identifier string to represent a gridField within a formview
 */
export const getGridFieldRequestIdentifier = (parentRequestIdentifier, gridFieldDomainId, gridFieldIndex) => `${parentRequestIdentifier}${REQUEST_IDENTIFIER_SEPARATOR}${gridFieldDomainId} Grid ${gridFieldIndex + 1}`;
const GridFieldRequestIdentifierRegex = new RegExp(`(?<parentRequestIdentifier>.*)${REQUEST_IDENTIFIER_SEPARATOR}(?<gridFieldDomainId>.+)\\sGrid\\s(?<gridFieldIndex>[0-9]+)`);
/**
 * Checks if the request identifier is a grid field (subgrid) request identifier under a form
 * @param requestIdentifier the request id to check
 * @returns false if it is not a grid field request id, otherwise a breakdown of the id
 */
export const isGridFieldRequestIdentifier = (requestIdentifier) => {
    const match = requestIdentifier.match(GridFieldRequestIdentifierRegex);
    if (!match)
        return false;
    const { groups } = match;
    if (!groups)
        return false;
    const { parentRequestIdentifier, gridFieldDomainId, gridFieldIndex: gridFieldIndexStr } = groups;
    return {
        parentRequestIdentifier,
        gridFieldDomainId,
        gridFieldIndex: Number(gridFieldIndexStr) - 1, // note: we add 1 above
    };
};
/**
 * Returns a request identifier string to represent a formview within a formview
 * @param domainId
 * @param formView
 * @returns
 */
export const getSubformRequestIdentifier = (parentRequestIdentifier, domainId, formView = 'Viewless') => `${parentRequestIdentifier}${REQUEST_IDENTIFIER_SEPARATOR}${domainId} Subform ${formView}`;
/**
 * Gets the parent of a given request identifier
 * @param requestIdentifier the current request identifier
 * @returns the parent request identifier, if there is one
 */
export const getParentRequestIdentifier = (requestIdentifier) => {
    const lastSeparatorIndex = requestIdentifier.lastIndexOf(REQUEST_IDENTIFIER_SEPARATOR);
    if (lastSeparatorIndex < 0)
        return undefined;
    return requestIdentifier.slice(0, lastSeparatorIndex);
};
/**
 * Finds a form/subform under a root form state with the matching request identifier.
 * If the request identifier is a grid field request identifier, will return the matching parent form with isGrid set.
 *
 * @param requestIdentifier the request identifier to search for
 * @param rootFormState
 * @returns undefined if there is no result, otherwise a response
 */
export const getFormUsingRequestIdentifier = (requestIdentifier, rootFormState) => {
    const { requestIdentifier: rootRequestIdentifier, innerFormStates } = rootFormState;
    let requestIdentifierToTest = requestIdentifier;
    const isGrid = isGridFieldRequestIdentifier(requestIdentifier);
    if (isGrid)
        requestIdentifierToTest = isGrid.parentRequestIdentifier;
    if (requestIdentifierToTest === rootRequestIdentifier) {
        return {
            formScreenState: rootFormState,
            isGrid,
        };
    }
    if (!requestIdentifierToTest.includes(`${rootRequestIdentifier}${REQUEST_IDENTIFIER_SEPARATOR}`))
        return undefined;
    const subFormState = innerFormStates[requestIdentifierToTest];
    if (subFormState) {
        return {
            formScreenState: subFormState,
            isGrid,
        };
    }
    const allInnerForms = Object.values(innerFormStates);
    for (let i = 0; i < allInnerForms.length; i++) {
        const innerForm = allInnerForms[i];
        if (requestIdentifierToTest.includes(`${innerForm.requestIdentifier}${REQUEST_IDENTIFIER_SEPARATOR}`)) {
            return getFormUsingRequestIdentifier(requestIdentifier, innerForm);
        }
    }
    return undefined;
};
/**
 * Returns the name of the domains designated ShortName field.
 * Returns the domain's primaryKey field if the ShortName field does not exist.
 * @param domain
 */
export const getDomainShortNameField = (domain) => {
    var _a;
    const { primaryKey: primaryKeyField, fields } = domain;
    return ((_a = fields.find((f) => f.fieldName === 'ShortName')) === null || _a === void 0 ? void 0 : _a.physicalName) || primaryKeyField;
};
/**
 * Hydrates in extra title/row information on grid-based request messages
 */
export const processMessagesFromTaskResponses = (responses, gridApis, 
/**
 * @deprecated primary key fields no longer needed
 */
_) => {
    return responses.map((response) => {
        const { messages: currentMessages, requestIdentifier, childResponses } = response;
        let updatedMessages = currentMessages;
        if (requestIdentifier.includes('Grid')) {
            updatedMessages = updatedMessages.map((msg) => {
                const { apparentRowIndex, title, row } = getUpdatedMessageInfo(msg, gridApis[requestIdentifier], msg.recordIdentifier);
                return Object.assign(Object.assign(Object.assign({}, msg), { title }), (row &&
                    !isNullOrUndefined(apparentRowIndex) && {
                    index: apparentRowIndex,
                }));
            });
        }
        const updatedChildResponses = processMessagesFromTaskResponses(Object.values(childResponses), gridApis).reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur.requestIdentifier]: cur })), {});
        return Object.assign(Object.assign({}, response), { childResponses: updatedChildResponses, messages: updatedMessages });
    });
};
/**
 * Outputs only the changed fields on the form object
 * @note does not persist id fields, necessarily
 */
const getDirtyFormData = (params) => {
    const { currentData, initialData, formViewMembers } = params;
    const output = {};
    formViewMembers.forEach((formViewMember) => {
        const { viewFieldName: fieldName } = formViewMember;
        const initialValue = GetValueFromData(initialData, fieldName);
        const newValue = GetValueFromData(currentData, fieldName);
        // eslint-disable-next-line eqeqeq
        if (initialValue != newValue || formViewMember.isKeyField || formViewMember.viewFieldName === 'ShortName')
            SetValueFromData(output, fieldName, newValue);
    });
    return output;
};
export const validateJoinValue = (value) => {
    if (typeof value !== 'string' && !(value instanceof String)) {
        throw new Error(`Cannot use value '${value}' to join subgrids--must be a string.`);
    }
    const valAsString = String(value);
    return valAsString;
};
export const getFilterExpressions = (domainRelationshipType, key, value) => {
    let filterExpressions;
    switch (domainRelationshipType) {
        case DomainRelationshipType.Multiple:
            filterExpressions = `intersects([${key}], '${value}')`;
            break;
        case DomainRelationshipType.SingleToMany:
            filterExpressions = `DoesContain('${value}', [${key}])`;
            break;
        default:
            filterExpressions = `[${key}] = '${value}'`;
            break;
    }
    return filterExpressions;
};
const getParentChildFields = (relationships) => {
    const parentChildFields = [];
    relationships === null || relationships === void 0 ? void 0 : relationships.forEach((r) => {
        parentChildFields.push({
            parentField: r.sourceField,
            childField: r.targetField,
            relationshipType: r.relationshipType,
        });
        if (Array.isArray(r.additionalRelatedFields)) {
            r.additionalRelatedFields.forEach((f) => {
                parentChildFields.push({
                    parentField: f.sourceField,
                    childField: f.targetField,
                    relationshipType: f.relationshipType,
                });
            });
        }
    });
    return parentChildFields;
};
export const buildFilterExpression = (formData, domain, relationships) => {
    const parentChildFields = getParentChildFields(relationships);
    const filterExpressions = [];
    parentChildFields === null || parentChildFields === void 0 ? void 0 : parentChildFields.forEach((current) => {
        const fieldInDomainExists = domain === null || domain === void 0 ? void 0 : domain.fields.find((x) => x.fieldName === current.childField);
        const fieldHasDataFromParentForm = formData === null || formData === void 0 ? void 0 : formData[current.parentField];
        if (fieldInDomainExists && fieldHasDataFromParentForm && fieldHasDataFromParentForm !== '-1') {
            filterExpressions.push(`${getFilterExpressions(current.relationshipType, current.childField, validateJoinValue(fieldHasDataFromParentForm))}`);
        }
    });
    return filterExpressions || [];
};
export const getFieldsFromParent = (formData, domain, relationships) => {
    const parentChildFields = getParentChildFields(relationships);
    const defaultData = parentChildFields === null || parentChildFields === void 0 ? void 0 : parentChildFields.reduce((previous, current) => {
        const fieldInDomainExists = domain === null || domain === void 0 ? void 0 : domain.fields.find((x) => x.fieldName === current.childField);
        const fieldDataFromParentForm = formData === null || formData === void 0 ? void 0 : formData[current.parentField];
        if (fieldInDomainExists && fieldDataFromParentForm) {
            return Object.assign(Object.assign({}, previous), { [current.childField]: fieldDataFromParentForm });
        }
        return previous;
    }, {});
    return defaultData;
};
const isDirtyGridRequest = (rq) => !!rq;
/**
 * Finds a response with a given request identifier
 * @param requestIdentifier the request identifier for which to find a response
 * @param allResponses all of the responses recieved
 * @returns the found response or undefined
 */
export const findResponseWithIdentifier = (requestIdentifier, allResponses) => {
    for (let i = 0; i < allResponses.length; i++) {
        const response = allResponses[i];
        if (response.requestIdentifier === requestIdentifier)
            return response;
        const { childResponses } = response;
        const target = childResponses[requestIdentifier];
        if (target)
            return target;
    }
    return undefined;
};
/**
 * Finds a request with a given request identifier (breadth first)
 * @param requestIdentifier the request identifier for which to find a request
 * @param allResponses all of the responses recieved
 * @returns the found request or undefined
 */
export const findRequestWithIdentifier = (requestIdentifier, allRequests) => {
    const queue = [...allRequests];
    while (queue.length) {
        const target = queue.shift();
        if (!target)
            return undefined;
        const { requestIdentifier: targetRequestIdentifier, payload: payloads } = target;
        if (targetRequestIdentifier === requestIdentifier)
            return target;
        Object.values(payloads).forEach((payload) => {
            const { __CHILD_REQUESTS__ } = payload;
            if (__CHILD_REQUESTS__)
                queue.push(...__CHILD_REQUESTS__);
        });
    }
    return undefined;
};
/**
 * @param rootState the state of the root FormScreenState node
 * @param api an API to access the form and its grids
 * @returns all of the necessary requests, with the root at index 0 or not present at all
 */
export const buildFormScreenRequests = (rootState, overrideCodes, params) => {
    const { submitAllAttributes } = params !== null && params !== void 0 ? params : {};
    // base case: no root
    if (!rootState)
        return [];
    const { rootFormState, innerFormStates, gridFieldRequests, isDirty, formView, requestIdentifier, initialData, defaultData, formApi, } = rootState;
    const { isDirty: rootIsDirty } = rootFormState;
    // base case: globally, the form is not dirty
    if (!isDirty)
        return [];
    // the key is the subform request identifier provided by FormScreen
    const innerRequests = Object.keys(innerFormStates).flatMap((key) => buildFormScreenRequests(innerFormStates[key], overrideCodes));
    const gridRequests = Object.values(gridFieldRequests)
        .filter(isDirtyGridRequest)
        .map((request) => {
        return Object.assign(Object.assign({}, request), (overrideCodes && { overrideCodes }));
    });
    const childRequests = innerRequests.concat(gridRequests);
    const { primaryKey, primaryKeyField, data } = rootState;
    const isNew = !primaryKey || primaryKey === '-1';
    if (rootIsDirty || isNew) {
        const { domainId, fieldConfigurationId, id: viewId, formViewMembers } = formView;
        // this way, we include side panel and related fields
        const allFormViewMembers = Object.values(formApi.getAllFields()).reduce((output, cur) => {
            const { field } = cur;
            if (output[field])
                return output;
            return Object.assign(Object.assign({}, output), { [field]: FormSkeletonMappers.Field.toFormViewMember(cur) });
        }, formViewMembers);
        let submitData;
        if (isNew || submitAllAttributes)
            submitData = data; // submit entire record on adds
        else {
            submitData = Object.assign(Object.assign({}, defaultData), getDirtyFormData({
                initialData,
                currentData: data,
                formViewMembers: Object.values(allFormViewMembers),
            }));
        }
        const rootRequest = {
            domainId,
            viewId,
            fieldConfigurationId,
            requestIdentifier,
            payload: {
                [primaryKey || '-1']: Object.assign(Object.assign(Object.assign({}, submitData), (primaryKeyField && {
                    [primaryKeyField]: primaryKey || '-1',
                })), (isNew && {
                    __ADDED__: true,
                    __CHILD_REQUESTS__: childRequests,
                })),
            },
            overrideCodes,
        };
        if (isNew)
            return [rootRequest];
        return [rootRequest, ...childRequests];
    }
    return childRequests;
};
export const getDomainNameMap = (domains, reqs) => {
    let result = {};
    reqs.forEach((req) => {
        const { domainId, payload } = req;
        Object.values(payload).forEach((record) => {
            const { __CHILD_REQUESTS__: childRequests } = record;
            if (childRequests) {
                const childMap = getDomainNameMap(domains, childRequests);
                result = Object.assign(Object.assign({}, result), childMap);
            }
        });
        result[domainId] = domains[domainId] ? getDomainShortNameField(domains[domainId]) : 'Id';
    });
    return result;
};
export const getGridFieldHeight = (gridCount, formSectionCount) => {
    if (gridCount === 1 && formSectionCount > 1) {
        return '50vh';
    }
    return gridCount > 1 ? '30vh' : '70vh';
};
/**
 * Recursively iterates through responses to a form task request and checks if they are all successful
 * @param taskResponses the responses
 */
export const isSuccessfulResponse = (taskResponses) => {
    return taskResponses.reduce((cur, taskResponse) => {
        var _a;
        const { statusCode, childResponses } = taskResponse;
        const { isSuccessful, successfulRequests, messages } = isSuccessfulResponse(Object.values(childResponses));
        const thisRequestHasSucceeded = statusCode === TaskStatus.Completed;
        return {
            isSuccessful: cur.isSuccessful && isSuccessful && thisRequestHasSucceeded,
            successfulRequests: cur.successfulRequests.concat(successfulRequests.concat(thisRequestHasSucceeded ? [taskResponse.requestIdentifier] : [])),
            messages: cur.messages.concat(messages.concat((_a = taskResponse.messages) !== null && _a !== void 0 ? _a : [])),
        };
    }, { isSuccessful: true, successfulRequests: [], messages: [] });
};
