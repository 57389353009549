import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getFiltersByDomain } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const FILTERS_KEY = `${SCREEN_CONFIG_API_KEY}filters`;
export const useFilters = (domainId) => {
    const api = React.useContext(ApiContext);
    const getter = (dId) => getFiltersByDomain(api.AggregatorApi, dId, api.requestInit);
    return useRequest(FILTERS_KEY, getter, ApiContext, domainId);
};
