/* eslint-disable no-console */
import React from 'react';
import { MapToListViewMember, useDomain, useFieldConfiguration, } from '@samc/screen-config-api';
import { useDeepCompareMemo } from '../useDeepCompareMemo/useDeepCompareMemo';
/**
 * Get fields that are missing from list view members
 * @param potentialMissingFields
 * @param listViewMembers
 * @returns
 */
const getMissingFields = (potentialMissingFields, listViewMembers) => potentialMissingFields.filter((f) => !listViewMembers.some((field) => field.viewFieldName === f));
const getPotentialRelatedFields = (errors, potentialRelatedFields) => {
    let fields = [...potentialRelatedFields];
    errors.forEach((task) => {
        fields = fields.concat(task.fields || []);
    });
    return Array.from(new Set(fields));
};
const setExistingListViewMembersVisible = (fieldList, missingFields, potentialRelatedFields, errors, domain) => {
    let hasOverrides = false;
    const overrides = fieldList
        .filter((f) => !missingFields.some((field) => field === f.viewFieldName))
        .map((lvm) => {
        // potentialRelatedFields are just the fields that have errors associated with them
        const hasError = potentialRelatedFields.some((x) => x === lvm.viewFieldName);
        if (hasError)
            hasOverrides = true;
        // rows that have errors
        const targetIds = errors
            .filter((e) => { var _a; return (_a = e.fields) === null || _a === void 0 ? void 0 : _a.includes(lvm.viewFieldName); })
            .map((e) => e.id)
            .filter((id) => !!id);
        return Object.assign(Object.assign({}, lvm), { 
            // sets invisible columns with errors to visible so the user can see them
            visibleExpression: hasError ? 'true' : lvm.visibleExpression, 
            // sets records with errors to editable so the user can set them
            // this may have an undesirable effect if the required rule is not in sync with the editable rule
            // the required rule will trigger an error, but if we don't want the field to be editable yet, this will override that.
            editableExpression: hasError && targetIds.length > 0
                ? `/*useRelatedFields*/ (${targetIds
                    .map((id) => `[${domain === null || domain === void 0 ? void 0 : domain.primaryKey}] = '${id}'`)
                    .join(' OR ')}) ${lvm.editableExpression ? ` OR (${lvm.editableExpression})` : ''}`
                : lvm.editableExpression });
    });
    return [hasOverrides, overrides];
};
const getMissingListViewMembers = (missingFields, errors, domain, fieldConfiguration) => missingFields
    .map((f) => domain === null || domain === void 0 ? void 0 : domain.fields.find((field) => field.fieldName === f))
    .filter((df) => !!df)
    .map((df) => {
    const targetIds = errors
        .filter((e) => { var _a; return (_a = e.fields) === null || _a === void 0 ? void 0 : _a.includes(df.fieldName); })
        .map((e) => e.id)
        .filter((id) => !!id);
    return Object.assign(Object.assign({}, MapToListViewMember(df, fieldConfiguration)), { visibleExpression: 'true', editableExpression: targetIds.map((id) => `[${domain === null || domain === void 0 ? void 0 : domain.primaryKey}] = '${id}'`).join(' OR ') });
});
/**
 * Returns a list of ListViewMembers referenced by listView expressions but not present as
 * listViewMembers.
 * @param listView
 * @returns
 */
export const useRelatedFields = (domainId, fieldConfigurationId, errors, existingFields) => {
    const domain = useDomain(domainId).data;
    const fieldConfiguration = useFieldConfiguration(fieldConfigurationId).data;
    const potentialFieldsRef = React.useRef([]);
    // // fields that triggered errors
    const potentialRelatedFields = useDeepCompareMemo(() => {
        const fields = getPotentialRelatedFields(errors, potentialFieldsRef.current);
        potentialFieldsRef.current = fields;
        return fields;
    }, [errors]);
    const existingListViewMembers = useDeepCompareMemo(() => {
        const missingFields = getMissingFields(potentialRelatedFields, existingFields);
        const [hasOverrides, newExistingFields] = setExistingListViewMembersVisible(existingFields, missingFields, potentialRelatedFields, errors, domain);
        return hasOverrides ? newExistingFields : existingFields;
    }, [domain, errors, potentialRelatedFields, existingFields]);
    const relatedFields = useDeepCompareMemo(() => {
        const missingFields = getMissingFields(potentialRelatedFields, existingFields);
        const missingListViewMembers = getMissingListViewMembers(missingFields, errors, domain, fieldConfiguration);
        return missingListViewMembers;
    }, [domain, fieldConfiguration, errors, potentialRelatedFields, existingFields]);
    return [relatedFields, existingListViewMembers];
};
export default useRelatedFields;
