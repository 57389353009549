import React from 'react';
function useDeepCompareMemoize(value) {
    const ref = React.useRef(value);
    if (JSON.stringify(ref.current) !== JSON.stringify(value)) {
        ref.current = value;
    }
    return ref.current;
}
/**
 * A deep compare version of useMemo. I recommend using React's default useMemo first and then only use
 * this if there isn't a way to deal with what you are doing.
 *
 * This does not get linting rules on the depenencies! Be dilligent in ensuring dependencies are correct
 *
 * This does not handle functions either! If you need to use a function inside, use a Ref to the function
 *
 * @param factory
 * @param dependencies
 * @returns
 */
export function useDeepCompareMemo(factory, dependencies) {
    return React.useMemo(factory, [useDeepCompareMemoize(dependencies)]);
}
export default useDeepCompareMemo;
