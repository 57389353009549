import React from 'react';
import { useFormBuilderContext } from '@samc/react-ui-form-builder';
import { useParentFormViews, useFormViews, useDomainRelationships, DomainRelationshipType, useChildFormViews, } from '@samc/screen-config-api';
import { InputType } from '@samc/react-ui-form';
import { SearchableDropdown } from '@samc/react-ui-core';
import { MultiColumnRenderer } from '@samc/react-ui-core/lib/atoms/SearchableDropdown/Renderers';
import { useStyletron } from 'styletron-react';
import { LinkType } from '../../enums/LinkType';
const STATIC_EMPTY_ARRAY = [];
const ModalTargetDropdownHeader = () => {
    const [css] = useStyletron();
    return (React.createElement("div", { className: css({
            display: 'flex',
            fontWeight: 'bold',
            paddingTop: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
            minWidth: '300px',
        }) },
        React.createElement("div", { className: css({ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }) },
            React.createElement("span", { className: css({ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }) }, "Domain")),
        React.createElement("div", { className: css({ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }) },
            React.createElement("span", { className: css({ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }) }, "Form View"))));
};
export const ModalTargetDropdown = (props) => {
    const { value, extraProps, disabled, isReadOnly, onChange } = props;
    const { formViews } = extraProps;
    const [internalValue, setInternalValue] = React.useState(value);
    React.useEffect(() => {
        setInternalValue(value);
    }, [value]);
    const options = React.useMemo(() => formViews.map((i) => ({
        displayText: i.name,
        id: String(i.id),
        data: i,
        hoverText: `Domain: ${i.domainId}, Form View: ${i.name}`,
    })), [formViews]);
    const handleChange = React.useCallback((option) => {
        setInternalValue(option.id);
        onChange(option.id);
    }, [onChange]);
    const renderer = React.useCallback((option) => {
        var _a, _b;
        return (React.createElement(MultiColumnRenderer, { columns: [((_a = option.data) === null || _a === void 0 ? void 0 : _a.domainId) || '', ((_b = option.data) === null || _b === void 0 ? void 0 : _b.name) || ''], option: option, maxWidth: 200 }));
    }, []);
    const upperContentRenderer = React.useCallback(() => React.createElement(ModalTargetDropdownHeader, null), []);
    return (React.createElement(SearchableDropdown, { upperContentRenderer: upperContentRenderer, options: options, selectedKeys: internalValue ? [internalValue] : STATIC_EMPTY_ARRAY, rowRenderer: renderer, disabled: disabled || isReadOnly, onChange: handleChange, placeholder: "Select a Form", emptyText: "No forms found..." }));
};
export const DomainSpecificModalTargetDropdown = (props) => {
    const { extraProps } = props;
    const { childFormViews } = extraProps;
    const formBuilderContext = useFormBuilderContext();
    const domainId = React.useMemo(() => {
        const { selectedFieldPath, selectedTab, form } = formBuilderContext;
        const { parentIndex } = selectedFieldPath !== null && selectedFieldPath !== void 0 ? selectedFieldPath : {};
        if (parentIndex === undefined)
            return undefined;
        const tab = selectedTab === null || selectedTab === void 0 ? void 0 : selectedTab.sections[parentIndex];
        const gridFieldIndex = tab === null || tab === void 0 ? void 0 : tab.gridFieldIndex;
        if (gridFieldIndex === undefined)
            return undefined;
        const gridField = form.gridFields[gridFieldIndex];
        const domain = gridField.domainId;
        return domain;
    }, [formBuilderContext]);
    const newProps = Object.assign(Object.assign({}, props), { extraProps: Object.assign(Object.assign({}, extraProps), { formViews: childFormViews.filter((x) => x.domainId === domainId) }) });
    // eslint-disable-next-line react/jsx-props-no-spreading
    return React.createElement(ModalTargetDropdown, Object.assign({}, newProps));
};
// eslint-disable-next-line react/display-name
export const getModalTargetDropdownRenderer = () => (props) => React.createElement(ModalTargetDropdown, props);
// eslint-disable-next-line react/display-name
export const getDomainSpecificModalTargetDropdownRenderer = () => (props) => React.createElement(DomainSpecificModalTargetDropdown, props);
export const useLinkConfiguration = (domainId, fields) => {
    var _a;
    const domainRelationships = useDomainRelationships(domainId);
    const legalDomains = React.useMemo(() => {
        return (domainRelationships.data || []).reduce((result, cur) => {
            if (cur.relationshipType !== DomainRelationshipType.Single)
                return result;
            return result.add(cur.sourceDomainId);
        }, new Set());
    }, [domainRelationships.data]);
    const parentFormViews = useParentFormViews(domainId);
    const domainFormViews = useFormViews(domainId);
    const childFormViews = useChildFormViews(domainId);
    const formViews = React.useMemo(() => {
        if (!parentFormViews.data || !domainFormViews.data)
            return [];
        return parentFormViews.data.filter((f) => legalDomains.has(f.domainId)).concat(domainFormViews.data);
    }, [domainFormViews.data, legalDomains, parentFormViews.data]);
    const childForms = (_a = childFormViews.data) !== null && _a !== void 0 ? _a : STATIC_EMPTY_ARRAY;
    return React.useMemo(() => (Object.assign({ [Number(LinkType.Redirect)]: {
            displayNameRule: "'Link Redirect Path'",
            field: 'linkTarget',
            editableRule: 'true',
            inputType: InputType.Expression,
            extraProps: Object.assign({}, (fields && {
                fields: Object.keys(fields).map((item) => fields[item].field),
            })),
            optionText: 'Redirect',
        } }, (formViews.length && {
        [Number(LinkType.Modal)]: {
            displayNameRule: "'Link Modal'",
            field: 'linkTarget',
            editableRule: 'true',
            inputType: InputType.Text,
            extraProps: {
                formViews,
            },
            onRenderField: getModalTargetDropdownRenderer(),
            optionText: 'Modal',
        },
        [Number(LinkType.ConfirmationModal)]: {
            displayNameRule: "'Link Modal'",
            field: 'linkTarget',
            editableRule: 'true',
            inputType: InputType.Text,
            extraProps: {
                formViews,
            },
            onRenderField: getModalTargetDropdownRenderer(),
            optionText: 'Confirmation Modal',
        },
        [Number(LinkType.MultiSelectConfirmationModal)]: {
            displayNameRule: "'Link Modal'",
            field: 'linkTarget',
            editableRule: 'true',
            inputType: InputType.Text,
            extraProps: {
                childFormViews: childForms,
            },
            onRenderField: getDomainSpecificModalTargetDropdownRenderer(),
            optionText: 'MultiSelect Confirmation Modal',
        },
    }))), [fields, formViews, childForms]);
};
export default useLinkConfiguration;
