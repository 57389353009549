import { InputType, LayoutType, getFormSkeleton } from '@samc/react-ui-form';
import { getFolderDropdownFormFieldRenderer } from '@samc/folder-ui-core';
import { getExpressionFormField } from '../ExpressionField/ExpressionField';
export const getFormConfiguration = (formDomainId, gridDomainId) => getFormSkeleton({
    title: 'Grid Details',
    fields: {
        viewId: Object.assign({ displayNameRule: "'View'", field: 'viewId', inputType: InputType.Text, editableRule: 'false' }, (gridDomainId && {
            editableRule: 'true',
            onRenderField: getFolderDropdownFormFieldRenderer({
                spaceName: gridDomainId,
                itemType: 'DomainView',
            }),
        })),
        domainId: {
            displayNameRule: "'Domain Id'",
            field: 'domainId',
            inputType: InputType.Text,
            editableRule: 'false',
        },
        sourceField: {
            displayNameRule: "'Source (Form) Field'",
            field: 'sourceField',
            inputType: InputType.Text,
            editableRule: 'false',
        },
        targetField: {
            displayNameRule: "'Target (Grid) Field'",
            field: 'targetField',
            inputType: InputType.Text,
            editableRule: 'false',
        },
        baselineFilterExpression: {
            displayNameRule: "'Baseline Filter Expression'",
            field: 'baselineFilterExpression',
            inputType: InputType.Expression,
            editableRule: 'true',
            onRenderField: gridDomainId
                ? getExpressionFormField(gridDomainId, 'Baseline Filter Expression', false) // we pass this as a filter to the grid, so it's on the grid domain
                : undefined,
        },
    },
    gridFields: [],
    tabs: [
        {
            title: 'Configurations',
            sections: [
                {
                    titleExpression: "'Grid Data Options'",
                    fieldLayout: {
                        type: LayoutType.VerticalStack,
                        children: [
                            {
                                field: 'domainId',
                                type: LayoutType.VerticalStack,
                                children: [],
                            },
                            {
                                field: 'viewId',
                                type: LayoutType.VerticalStack,
                                children: [],
                            },
                            {
                                field: 'sourceField',
                                type: LayoutType.VerticalStack,
                                children: [],
                            },
                            { field: 'targetField', type: LayoutType.VerticalStack, children: [] },
                            {
                                field: 'baselineFilterExpression',
                                type: LayoutType.VerticalStack,
                                children: [],
                            },
                        ],
                    },
                },
            ],
            layout: {
                index: 0,
                type: LayoutType.VerticalStack,
                children: [{ index: 0, type: LayoutType.VerticalStack, children: [] }],
            },
        },
    ],
});
export default {};
