import React from 'react';
import { useDomain, useFieldConfigurationByDomain } from '@samc/screen-config-api';
import { ProgressIndicator, MessageBar, MessageBarType } from '@fluentui/react';
import { useStyletron } from 'styletron-react';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { ErrorBoundary } from '@samc/react-ui-core';
import { ConfigureDomain, } from '../../organisms/ConfigureDomain/ConfigureDomain';
import { DomainDetailBody } from '../../organisms/bodies/DomainDetailBody/DomainDetailBody';
import { QueryBuilderContextProvider } from '../../organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
import { usePreparedEvaluatorConfigurationByDomain } from '../../hooks/usePreparedEvaluatorConfigurationByDomain/usePreparedEvaluatorConfigurationByDomain';
export const DomainDetailPage = (props) => {
    const { domainId, initialSelectedTabId, onTabChanged } = props;
    const fieldConfigurationResponse = useFieldConfigurationByDomain(domainId);
    const domainResponse = useDomain(domainId);
    const [domain, setDomain] = React.useState();
    const [fieldConfigurationId, setFieldConfigurationId] = React.useState();
    const [css] = useStyletron();
    const evaluatorConfig = usePreparedEvaluatorConfigurationByDomain(domainId);
    React.useEffect(() => {
        if (Array.isArray(fieldConfigurationResponse.data) && fieldConfigurationResponse.data.length > 0) {
            setFieldConfigurationId(fieldConfigurationResponse.data[0].id);
        }
    }, [domainId, fieldConfigurationResponse.data]);
    React.useEffect(() => {
        setDomain(domainResponse.data);
    }, [domainResponse.data]);
    if (fieldConfigurationResponse.isLoading || domainResponse.isLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading Domain and Fields..." });
    }
    if (fieldConfigurationResponse.isError || domainResponse.isError || !domain || !fieldConfigurationId) {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.error }, `Error Loading: ${fieldConfigurationResponse.error}`));
    }
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: css({ display: 'flex', flex: 1, overflow: 'hidden', padding: '12px' }) },
            React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
                React.createElement(QueryBuilderContextProvider, { domainId: domain.sequentialId },
                    React.createElement(ConfigureDomain, { key: domainId, fieldConfigurationId: fieldConfigurationId, domainFields: domain.fields, initialSelectedTabId: initialSelectedTabId, onTabChanged: onTabChanged }, (renderProps) => (React.createElement(DomainDetailBody, Object.assign({ key: domainId, domain: domain, fieldConfigurationId: fieldConfigurationId }, renderProps)))))))));
};
export default DomainDetailPage;
