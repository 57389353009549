import React from 'react';
import { useEvaluator } from '@samc/expressions-react';
/**
 * Logic for reducing a ViewSet object into the pieces needed for rendering a ViewSet component.
 * @param viewSet The ViewSet object to reduce.
 * @returns A ViewSetRenderConfiguration
 */
export const useViewSetRenderConfiguration = (viewSet, data) => {
    const { evaluate } = useEvaluator();
    const visibleTabs = React.useMemo(() => {
        if (!viewSet)
            return [];
        return viewSet.tabs.filter((tab) => {
            try {
                return !tab.visibleExpression || evaluate(data || {}, tab.visibleExpression, false) !== false;
            }
            catch (_a) {
                // if the visible expression fails, mark it as visible by default
                return true;
            }
        });
    }, [data, evaluate, viewSet]);
    const selectedLayouts = React.useMemo(() => {
        return visibleTabs.map((tab) => {
            const layoutName = tab.layoutSelectionExpression && evaluate(data || {}, tab.layoutSelectionExpression, true);
            const selectedLayout = tab.layouts.find((l) => l.title === layoutName);
            return selectedLayout || tab.defaultLayout;
        });
    }, [data, evaluate, visibleTabs]);
    const viewSetIds = React.useMemo(() => selectedLayouts.map((l) => l === null || l === void 0 ? void 0 : l.viewId).filter((x) => x !== undefined), [selectedLayouts]);
    const viewSetTabsById = React.useMemo(() => visibleTabs.reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur.id]: cur })), {}), [visibleTabs]);
    const viewSetTabHeaders = React.useMemo(() => visibleTabs.map((l) => (l.titleExpression && evaluate(data || {}, l.titleExpression, true)) ||
        l.title ||
        'No Title or Title Expression'), [data, evaluate, visibleTabs]);
    const viewSetTabTypes = React.useMemo(() => selectedLayouts.map((l) => l === null || l === void 0 ? void 0 : l.type).filter((x) => x !== undefined), [selectedLayouts]);
    const viewSetTabSidePanelForms = React.useMemo(() => visibleTabs.map((vt) => vt.sidePanelForm), [visibleTabs]);
    const viewSetTabIds = React.useMemo(() => visibleTabs.map((v) => v.id), [visibleTabs]);
    const viewSetTabControlVisibilities = React.useMemo(() => visibleTabs.map((v) => v.controlVisibilities), [visibleTabs]);
    const viewSetTabBaselineFilterExpressions = React.useMemo(() => visibleTabs.map((v) => v.baselineFilterExpression), [visibleTabs]);
    return {
        viewSetIds,
        viewSetTabHeaders,
        viewSetTabTypes,
        viewSetTabSidePanelForms,
        viewSetTabIds,
        viewSetTabControlVisibilities,
        viewSetTabBaselineFilterExpressions,
        viewSetTabsById,
    };
};
export default useViewSetRenderConfiguration;
