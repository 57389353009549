import React from 'react';
import { useStyletron } from 'styletron-react';
import { Modal } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
import { HeaderContextProvider, useHeaderContext, } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import { ModalControlBar } from '../../ModalControlBar/ModalControlBar';
import { useSelectedRowsByGridContext } from '../../../../contexts/GridSelectionContext/GridSelectionContext';
import { documentLinksSectionTitle, getDocumentListView, getDocumentsSelectedRows, removeDocumentLinksDescription, } from '../DocumentsModal.Utilities';
const InnerViewModal = (props) => {
    const { viewId, isOpen, GridScreenWrapper, onDismiss, onSaved, headerText, showContent, style, ServiceDrivenViewSet, } = props;
    const dirtinessScope = React.useRef(null);
    const { promptAndContinue, save } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const { controlBarProps } = useHeaderContext();
    const { selectedRowsByGrids } = useSelectedRowsByGridContext();
    const [css] = useStyletron();
    const theme = useThemeContext();
    const selectedDocumentsFilter = React.useMemo(() => getDocumentsSelectedRows(selectedRowsByGrids), [selectedRowsByGrids]);
    const handleClose = React.useCallback(() => {
        if (onDismiss)
            onDismiss();
        // @ts-expect-error forces reset since the actions do not include the clean option
        if (onSaved)
            onSaved({ includeClean: true });
    }, [onDismiss, onSaved]);
    const handleModalDismiss = React.useCallback(() => {
        promptAndContinue(() => {
            handleClose();
        });
    }, [handleClose, promptAndContinue]);
    return (React.createElement(Modal, { forceFocusInsideTrap: false, layerProps: { eventBubblingEnabled: true }, isOpen: isOpen, onDismiss: handleModalDismiss, isBlocking: false },
        React.createElement("div", { ref: dirtinessScope, className: "view-modal", style: { height: '85vh', width: '85vw', display: 'flex', flexDirection: 'column' } },
            React.createElement(ModalControlBar, Object.assign({ headerText: headerText, onDismiss: handleClose, submit: () => save().then() }, controlBarProps)),
            React.createElement("div", { className: css({
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    alignSelf: 'stretch',
                    maxWidth: '100%',
                    marginLeft: '10px',
                    marginRight: '10px',
                }) },
                React.createElement("div", { className: css({
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '30px',
                        alignItems: 'center',
                    }) },
                    React.createElement("span", { className: css({
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: theme.TextColors.secondary.toString(),
                        }) }, documentLinksSectionTitle)),
                React.createElement("div", { className: css({
                        marginTop: '5px',
                        paddingTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'stretch',
                        flex: '1',
                        borderTop: '1px solid #d1d1d1',
                    }) },
                    React.createElement("p", null, removeDocumentLinksDescription),
                    React.createElement(GridScreenWrapper, { style: style, showContent: showContent, key: viewId, listViewId: getDocumentListView(viewId), filters: selectedDocumentsFilter, ServiceDrivenViewSet: ServiceDrivenViewSet }))))));
};
/**
 * Launches a modal to render Remove Document Links listing custom component
 * @param props
 * @returns
 */
export const RemoveDocumentLinksModal = (props) => {
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(InnerViewModal, Object.assign({}, props))));
};
export default RemoveDocumentLinksModal;
