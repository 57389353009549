/**
 * @param setState the state setter
 * @param action the action used to mutate state
 * @param afterSet the callback called on the value set
 */
export const onSetState = (setState, action, afterSet) => {
    setState((cv) => {
        let nv;
        if (typeof action === 'function')
            nv = action(cv);
        else
            nv = action;
        afterSet(nv);
        return nv;
    });
};
export default {};
