import React from 'react';
import { useGridCellLinks } from '../../hooks/useGridCellLinks/useGridCellLinks';
import { useGridInjector } from '../../hooks/useGridInjector/useGridInjector';
/**
 * Gets the necessary framework components and overrider function to inject custom
 * components into the field matrix as provided by useGridInjector. Also renders links.
 *
 * @param formView the formView containing the field matrices
 * @param setCurrentEditLink the callback to set a link
 * @param promptAndContinue the callback to prompt the user and complete an action
 * @param filters any filters applied to the formView
 * @param domain the domain object matching the domain of the formView
 * @param links any links applied to the domain
 * @returns the framework components, overrider, and an isReady flag. Do not render the form until isReady is true.
 */
export const useFieldMatrixOverrider = (formView, setCurrentEditLink, promptAndContinue, filters, domain, links) => {
    const { formViewMembers, fieldConfigurationId, fieldMatrices } = formView;
    /**
     * Fields that are utilized in any matrix on the form
     */
    const matrixFields = React.useMemo(() => {
        if (!fieldMatrices)
            return [];
        const fieldObject = Object.keys(fieldMatrices).reduce((mf, key) => {
            const newFields = Object.assign({}, mf);
            fieldMatrices[key].cells.forEach((col) => col.forEach((c) => {
                const { fieldName } = c;
                if (!fieldName)
                    return;
                const formField = formViewMembers[fieldName];
                if (!formField)
                    return;
                newFields[fieldName] = formField;
            }));
            return newFields;
        }, {});
        return Object.keys(fieldObject).map((f) => fieldObject[f]);
    }, [fieldMatrices, formViewMembers]);
    /**
     * List view members for matrix fields
     */
    const listViewMembers = React.useMemo(() => matrixFields.map((k, i) => (Object.assign(Object.assign({}, formViewMembers[k.viewFieldName]), { sequence: i }))), [formViewMembers, matrixFields]);
    /**
     * Fetches the links for any of the matrix fields
     */
    const gridCellLinks = useGridCellLinks(links, domain === null || domain === void 0 ? void 0 : domain.primaryKey, listViewMembers, setCurrentEditLink, promptAndContinue, domain === null || domain === void 0 ? void 0 : domain.fields);
    /**
     * Injects custom renderers/editors/etc. like picklists
     */
    const { wrappedFields, frameworkComponents: fieldMatrixFrameworkComponents, fieldConfigurationMembers, } = useGridInjector(fieldConfigurationId, gridCellLinks, filters);
    /**
     * Builds out an object of wrapped fields for easier indexing.
     */
    const wrappedFieldsObject = React.useMemo(() => wrappedFields === null || wrappedFields === void 0 ? void 0 : wrappedFields.reduce((obj, cur) => (Object.assign(Object.assign({}, obj), { [cur.field]: cur })), {}), [wrappedFields]);
    /**
     * Fetches the override GridFieldConfiguration for the fields in the matrix.
     */
    const fieldMatrixFieldOverrider = React.useCallback((c) => {
        const { fieldName } = c;
        if (!fieldName)
            return {};
        const wrappedField = wrappedFieldsObject === null || wrappedFieldsObject === void 0 ? void 0 : wrappedFieldsObject[fieldName];
        if (!wrappedField)
            return {};
        return wrappedField;
    }, [wrappedFieldsObject]);
    return {
        fieldMatrixFieldOverrider,
        fieldConfigurationMembers,
        fieldMatrixFrameworkComponents,
        isReady: !!wrappedFields,
    };
};
export default useFieldMatrixOverrider;
