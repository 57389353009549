import { createPath } from 'history';
/**
 * @param tabId the tab id (if in URI)
 * @param subtabId the subtab id (if in URI)
 * @returns the path tab-related path (not the whole pathname) expected in the URI
 *
 * @example 'AssetInformation' + 'Asset Info' -> '/AssetInformation/Asset%20Info'
 * @example undefined + 'Asset Info' -> '/'
 * @example 'AssetInformation' + undefined -> '/AssetInformation'
 * @example undefined + undefined -> '/'
 */
const getTabPath = (tabId, subtabId) => {
    const outputPieces = [];
    if (tabId) {
        outputPieces.push(tabId);
        if (subtabId)
            outputPieces.push(subtabId);
    }
    if (!outputPieces.length)
        return undefined;
    return outputPieces.map(encodeURIComponent).join('/');
};
/**
 * Replaces the last occurance of the needle
 * @param haystack the string to search
 * @param needle the substring to replace
 * @param replacement the replacement
 */
const replaceLast = (haystack, needle, replacement) => {
    const foundAt = haystack.lastIndexOf(needle);
    if (foundAt < 0)
        return haystack;
    const before = haystack.slice(0, foundAt);
    const after = haystack.slice(foundAt + needle.length);
    return `${before}${replacement}${after}`;
};
/**
 * Outputs a redirect string given the expected tab state and current location.
 * This output can be passed directly to the window location/router redirect function
 * and preserves search and hash strings.
 */
export const getTabRedirectString = (params) => {
    const { originalLocation, oldSubtabId, oldTabId, newSubtabId, newTabId } = params;
    const { pathname: originalPathname, search, hash } = originalLocation;
    const oldTabPath = getTabPath(oldTabId, oldSubtabId);
    const newTabPath = getTabPath(newTabId, newSubtabId);
    let newPathname;
    if (!oldTabPath) {
        // just append
        newPathname = originalPathname.replace(/(\/$|$)/, (_, end) => `/${newTabPath}${end}`);
    }
    else if (newTabPath) {
        // swap paths, but be careful of the case where the screen and tab have the same name (only replace last occurance)
        newPathname = replaceLast(originalPathname, oldTabPath, newTabPath);
    }
    else {
        // remove leading slash to avoid double slashes
        newPathname = replaceLast(originalPathname, `/${oldTabPath}`, '');
    }
    const newPath = createPath({ pathname: newPathname, search, hash });
    return newPath;
};
export default {};
