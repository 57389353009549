import React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getViewSet } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const VIEW_SET_KEY = `${SCREEN_CONFIG_API_KEY}view_set`;
export const useViewSet = (viewSetId) => {
    const api = React.useContext(ApiContext);
    const query = (vsId) => getViewSet(api.AggregatorApi, vsId, api.requestInit);
    return useRequestBase({
        key: VIEW_SET_KEY,
        query,
        requestContext: ApiContext,
        params: [viewSetId],
        options: {
            staleTime: CacheTime.default,
        },
    });
};
