import React from 'react';
import { useStrata } from '@samc/filestorage-react';
import { useDropzone } from 'react-dropzone';
import { useStyletron } from 'styletron-react';
import { useThemeContext } from '@samc/react-ui-theme';
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import { Cell, normalizeFileTypes, Row, } from './DropzoneGrid.Utilities';
import { useCustomSort } from '../../hooks/useCustomSort/useCustomSort';
const HeaderRow = ({ headerDefs, onStateChange, theme, isLoading }) => {
    const { onHeaderClick, sortState } = useCustomSort(onStateChange);
    const [css] = useStyletron();
    const iconClass = css({ fontSize: '12px', marginRight: '5px', alignSelf: 'flex-end' });
    return (React.createElement(Row, { "$isHeader": true, className: "dropzone-header-row" }, headerDefs.map((c, i) => (React.createElement(Cell, { className: "dropzone-grid-cell", onClick: isLoading ? undefined : () => onHeaderClick(c.fieldName), "$theme": theme, "$isHeader": true, "$flexWidth": c.flexWidth, "$isLoading": isLoading, "$index": i, key: c.cellKey },
        sortState.orderBy === c.fieldName && (React.createElement(Icon, { iconName: sortState.order === 'asc' ? 'SortUp' : 'SortDown', className: iconClass })),
        c.text)))));
};
const DropzoneRow = ({ rowCells, onFileDrop, theme }) => {
    const { saveActions: { getDocuments }, } = useStrata();
    const getOnDrop = (dropzoneId) => {
        const onDrop = (acceptedFiles, fileRejections, event) => {
            onFileDrop(acceptedFiles, fileRejections, event, dropzoneId);
            getDocuments(acceptedFiles);
        };
        return onDrop;
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: getOnDrop(rowCells[0].dropzoneId),
        accept: rowCells[0].allowedFileTypes,
    });
    return (React.createElement(Row, Object.assign({}, getRootProps(), { key: rowCells[0].cellKey }),
        React.createElement("input", Object.assign({}, getInputProps())),
        rowCells.map((c, i) => (React.createElement(Cell, { "$theme": theme, "$flexWidth": c.flexWidth, "$isDragActive": isDragActive, "$index": i, key: c.cellKey }, c.text)))));
};
export default function DropzoneGrid({ colDefs, rowDefs, isLoading, fileTypesField, idField, onFileDrop, gridTitle, gridHeight, onStateChange, }) {
    const [css] = useStyletron();
    const theme = useThemeContext();
    const headerDefs = React.useMemo(() => colDefs.map((c, i) => ({
        fieldName: c.field,
        text: c.headerText,
        flexWidth: c.flexWidth,
        cellKey: `${c.headerText}-${c.field}-${i}`,
    })), [colDefs]);
    const rowConfigs = React.useMemo(() => rowDefs.map((rowData, rowIndex) => {
        return colDefs.map((c, i) => (Object.assign(Object.assign({}, c), { text: rowData[c.field], allowedFileTypes: normalizeFileTypes(rowData[fileTypesField]), dropzoneId: rowData[idField], cellKey: `${rowIndex}-${i}` })));
    }), [colDefs, rowDefs, fileTypesField, idField]);
    return (React.createElement("div", { className: css({
            height: 'auto',
            margin: '10px 15px 8px 8px',
        }) },
        gridTitle && (React.createElement("div", { className: css({
                padding: '0px 5px 5px',
                fontWeight: 'bold',
                color: theme.TextColors.secondary.toString(),
            }) }, gridTitle)),
        React.createElement("div", { className: css({
                width: '100%',
                height: gridHeight !== null && gridHeight !== void 0 ? gridHeight : 'auto',
                overflowY: 'auto',
            }) },
            React.createElement(HeaderRow, { headerDefs: headerDefs, theme: theme, onStateChange: onStateChange, isLoading: isLoading }),
            React.createElement("div", null, isLoading ? (React.createElement(Spinner, { label: "Loading dropzones...", ariaLive: "assertive", labelPosition: "right", size: SpinnerSize.large, className: css({
                    marginTop: '15px',
                }) })) : (rowConfigs.map((rowCells) => (React.createElement(DropzoneRow, { theme: theme, key: rowCells[0].cellKey, rowCells: rowCells, onFileDrop: onFileDrop }))))))));
}
