import React from 'react';
import { useStyletron } from 'styletron-react';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { DocCountLink } from '../../atoms/DocCountLink/DocCountLink';
const DocLinkCountFieldInner = (props) => {
    const { value } = props;
    const [css] = useStyletron();
    const countVal = React.useMemo(() => {
        if (isNullOrUndefined(value))
            return 0;
        return Number(value);
    }, [value]);
    return (React.createElement("div", { className: css({ maxWidth: '500px' }) },
        React.createElement(DocCountLink, { onClick: () => undefined, styles: { root: { paddingLeft: '10px' } }, value: countVal })));
};
// eslint-disable-next-line react/display-name
export const getDocLinkCountFieldRenderer = () => (props) => React.createElement(DocLinkCountFieldInner, props);
/**
 * @deprecated use {@link getDocLinkCountFieldRenderer}
 */
export const GetDocLinkCountField = React.forwardRef(DocLinkCountFieldInner);
export default GetDocLinkCountField;
