import React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getFiltersByDomain } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { SCREEN_CONFIG_API_KEY } from '../ScreenConfigApiKey';
export const FILTER_KEY = `${SCREEN_CONFIG_API_KEY}filter`;
export const useFilterByDomain = (domainId) => {
    const api = React.useContext(ApiContext);
    const query = (dId) => getFiltersByDomain(api.AggregatorApi, dId, api.requestInit);
    return useRequest(FILTER_KEY, query, ApiContext, domainId);
};
export default useFilterByDomain;
