import React from 'react';
import {
    ServiceDrivenViewSet,
    GridSelectionProvider,
    ViewSetSubtabChangedEvent,
    getTabRedirectString,
} from '@samc/screen-config-core';
import { toastError, ToastMessage } from '@samc/react-ui-core';
import { EditingProvider } from '@samc/react-ui-grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRouterBlockerAndPromptForUnsavedChanges } from '@samc/react-ui-history';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { RoutingWrapper } from '@samc/react-ui-history/lib/molecules/RoutingWrapper/RoutingWrapper';
import { decodeBase64, decodeBase64ToObject } from '../../util';

// These URL query params are referenced in...
//  - https://samcado.visualstudio.com/VMSNext/_git/VMSNext?path=/src/Web/packages/single-spa-portfolio-selector/src/portfolioDomainConstants.ts
export const URL_PARAMNAME_FILTER = 'filter';
export const URL_PARAMNAME_DEFAULTS = 'defaults';
export const URL_PARAMNAME_PORTFOLIO_ID = 'PortfolioId';
export const URL_PARAMNAME_PRIMARY_KEY = 'primaryKeyValue';

interface ViewSetMainProps {
    onTabChanged: (newId: string) => void;
    onSubtabChanged: (ev: ViewSetSubtabChangedEvent) => void;
    tabId?: string;
    subtabId?: string;
}

const ViewSetMain = (props: ViewSetMainProps): React.ReactElement => {
    const { onTabChanged, onSubtabChanged, tabId, subtabId } = props;

    const { id } = useParams();

    const hashLocation = useLocation();
    const navigate = useNavigate();
    const { reset: resetHeader } = useHeaderContext();

    // run before other effects
    React.useLayoutEffect(() => {
        resetHeader(); // reset on load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useRouterBlockerAndPromptForUnsavedChanges();
    const { primaryKey, portfolioId } = React.useMemo(() => {
        const searchParams = new URLSearchParams(hashLocation.search);
        const _primaryKey = searchParams.get(URL_PARAMNAME_PRIMARY_KEY) || undefined;
        const _portfolioId = searchParams.get(URL_PARAMNAME_PORTFOLIO_ID) || undefined;

        return { primaryKey: _primaryKey, portfolioId: _portfolioId };
    }, [hashLocation.search]);

    const params = new URLSearchParams(hashLocation.search);

    const getBase64FilterExpressionFromUrl = (): string | null => {
        return params.has(URL_PARAMNAME_FILTER) ? params.get(URL_PARAMNAME_FILTER) : null;
    };

    const encodedFilterExpression = getBase64FilterExpressionFromUrl();

    const urlFilters = React.useMemo((): string[] => {
        // return nothing if not set
        if (!encodedFilterExpression || !encodedFilterExpression.length) return [];

        const decodedExpression = decodeBase64(encodedFilterExpression);

        // try getting the array of values
        try {
            const filterArray = JSON.parse(decodedExpression);
            if (filterArray && Array.isArray(filterArray)) {
                return filterArray;
            }
        } catch (e) {
            // intentionally left empty
        }

        // fall back to prior behavior and return single expression in array
        return [decodedExpression];
    }, [encodedFilterExpression]);

    const getBase64DefaultValuesFromUrl = (): string | null => {
        return params.has(URL_PARAMNAME_DEFAULTS) ? params.get(URL_PARAMNAME_DEFAULTS) : null;
    };

    const encodedDefaultValuesJson = getBase64DefaultValuesFromUrl();

    const defaultValues = React.useMemo(() => {
        if (!encodedDefaultValuesJson || !encodedDefaultValuesJson.length) {
            return undefined;
        }

        try {
            return decodeBase64ToObject<Record<string, unknown>>(encodedDefaultValuesJson);
        } catch {
            toastError(
                <ToastMessage
                    title="Default Data Decoding Error"
                    message={`Unable to decode or parse default data '${encodedDefaultValuesJson}'.`}
                />,
            );
            return undefined;
        }
    }, [encodedDefaultValuesJson]);

    const setPrimaryKey = React.useCallback(
        (value?: string): void => {
            const pkParams = new URLSearchParams(hashLocation.search);
            if (value) {
                pkParams.set(URL_PARAMNAME_PRIMARY_KEY, value);
                navigate(`${hashLocation.pathname}?${pkParams.toString()}`);
            } else {
                pkParams.delete(URL_PARAMNAME_PRIMARY_KEY);
                navigate(`${hashLocation.pathname}?${pkParams.toString()}`);
            }
        },
        [hashLocation.pathname, hashLocation.search, navigate],
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', overflow: 'auto', width: '100%' }}>
            {id ? (
                // key here is set this way so that when the filter is changed, it will recreate/reload the data
                <ServiceDrivenViewSet
                    key={`${id}_${encodedFilterExpression}`}
                    viewSetId={id}
                    filters={urlFilters}
                    primaryKeyValue={primaryKey}
                    setPrimaryKeyValue={setPrimaryKey}
                    defaultData={defaultValues}
                    initialSelectedTabId={tabId}
                    onTabChanged={onTabChanged}
                    parentScope={['ScreenConfig', portfolioId].filter((f): f is string => !!f).join('_')}
                    initialSelectedSubtabId={subtabId}
                    onSubtabChanged={onSubtabChanged}
                />
            ) : (
                <div>Please specify a ViewSet Id.</div>
            )}
        </div>
    );
};

export const ViewSetPage = (): React.ReactElement => {
    const originalLocation = useLocation();
    const { tabId: urlTabId, subtabId: urlSubtabId } = useParams();

    const { tabs } = useHeaderContext();
    const navigate = useNavigate();

    const onTabIdChanged = React.useCallback(
        (newId: string, userRequested?: boolean) => {
            if (newId === urlTabId) return; // do nothing if id doesn't change

            navigate(
                getTabRedirectString({
                    originalLocation,
                    oldTabId: urlTabId,
                    oldSubtabId: urlSubtabId,
                    newTabId: newId,
                    newSubtabId: undefined, // should clear it on tab change
                }),
                { replace: !userRequested },
            );
        },
        [navigate, originalLocation, urlSubtabId, urlTabId],
    );

    const onSubtabIdChanged = React.useCallback(
        (ev: ViewSetSubtabChangedEvent) => {
            const { newId, userRequested } = ev;

            if (newId === urlSubtabId) return; // do nothing if id doesn't change

            navigate(
                getTabRedirectString({
                    originalLocation,
                    oldTabId: urlTabId,
                    oldSubtabId: urlSubtabId,
                    newTabId: urlTabId,
                    newSubtabId: newId,
                }),
                { replace: !userRequested },
            );
        },
        [navigate, originalLocation, urlSubtabId, urlTabId],
    );

    const onTabIndexChanged = React.useCallback(
        (newIndex: number, userRequested: boolean) => {
            if (!tabs) return;

            const tab = tabs[newIndex];
            if (!tab) return;

            onTabIdChanged(tab.id, userRequested);
        },
        [onTabIdChanged, tabs],
    );

    return (
        <EditingProvider>
            <GridSelectionProvider>
                <RoutingWrapper onTabChanged={onTabIndexChanged}>
                    <ViewSetMain
                        onSubtabChanged={onSubtabIdChanged}
                        subtabId={urlSubtabId}
                        tabId={urlTabId}
                        onTabChanged={onTabIdChanged}
                    />
                </RoutingWrapper>
            </GridSelectionProvider>
        </EditingProvider>
    );
};

export default ViewSetPage;
