import { FunctionList, ValidateExpression } from '@samc/expressions-core';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { MapToFormViewMember, useDomain, } from '@samc/screen-config-api';
import React from 'react';
import { getMissingViewMembers } from '../useMissingViewDependencies/useMissingViewDependencies';
/**
 * Matches {...} in markdown
 */
const MARKDOWN_EXPRESSION_EXTRACTION_REGEX = /(?<=\{)[^}]+(?=\})/g;
const getExpressionsFromCustomText = (customText) => {
    const result = [];
    if (customText) {
        const matches = customText.match(MARKDOWN_EXPRESSION_EXTRACTION_REGEX);
        if (matches)
            matches.forEach((m) => result.push(m));
    }
    return result;
};
/**
 *
 * @param member any fields on the member that are not already on the ViewMember. Expressions on the ViewMember
 * can be extracted using useMissingViewDependencies
 * @returns
 */
const getExpressionsFromFormViewMembers = (member) => {
    const { defaultValueRuleOverride } = member;
    return [...(defaultValueRuleOverride ? [defaultValueRuleOverride] : [])];
};
const getExpressionsFromFormFieldLink = (link) => {
    const result = [];
    const { visibleRule, enabledRule, tooltip } = link;
    if (!isNullOrUndefined(visibleRule))
        result.push(visibleRule);
    if (!isNullOrUndefined(enabledRule))
        result.push(enabledRule);
    if (!isNullOrUndefined(tooltip))
        result.push(tooltip);
    return result;
};
const getExpressionsFromFormSectionLayouts = (sectionLayout) => {
    const { visibleExpression, link, children, customText } = sectionLayout;
    const result = [...(visibleExpression ? [visibleExpression] : []), ...getExpressionsFromCustomText(customText)];
    children.forEach((child) => result.push(...getExpressionsFromFormSectionLayouts(child)));
    if (link)
        result.push(...getExpressionsFromFormFieldLink(link));
    return result;
};
const getExpressionsFromFormViewSection = (formViewSection) => {
    const { titleExpression, visibleExpression, selectAndShow, fieldLayout, links } = formViewSection;
    const result = [...(titleExpression ? [titleExpression] : []), ...(visibleExpression ? [visibleExpression] : [])];
    if (selectAndShow) {
        // tooltip and button hyperlink are fetched against the S&S domain
        const { enabledRule } = selectAndShow;
        if (enabledRule)
            result.push(enabledRule);
    }
    if (links)
        result.push(...getExpressionsFromFormSectionLayouts(links));
    if (fieldLayout)
        result.push(...getExpressionsFromFormSectionLayouts(fieldLayout));
    return result;
};
const getExpressionsFromExportOptions = (exportOptions) => {
    const { titleExpression } = exportOptions;
    const result = [];
    if (!isNullOrUndefined(titleExpression))
        result.push(titleExpression);
    return result;
};
const getExpressionsFromFormTab = (tab) => {
    const { visibleExpression, sections, exportEnabledExpression, exportOptions } = tab;
    const result = [];
    if (!isNullOrUndefined(visibleExpression))
        result.push(visibleExpression);
    if (!isNullOrUndefined(exportEnabledExpression))
        result.push(exportEnabledExpression);
    if (exportOptions)
        result.push(...getExpressionsFromExportOptions(exportOptions));
    sections.forEach((section) => result.push(...getExpressionsFromFormViewSection(section)));
    return result;
};
const getExpressionsFromFieldMatrixCell = (cell) => {
    const { markdownText } = cell;
    const result = getExpressionsFromCustomText(markdownText);
    return result;
};
const getExpressionsFromFieldMatrixRowConfiguration = (rowConfig) => {
    const { visibilityExpression } = rowConfig;
    return [...(visibilityExpression ? [visibilityExpression] : [])];
};
const getExpressionsFromFieldMatrixColumnConfiguration = (colConfig) => {
    return getExpressionsFromFieldMatrixRowConfiguration(colConfig); // for now, col config is a superset of row config
};
const getExpressionsFromFieldMatrix = (matrix) => {
    const { rowConfigurations, columnConfigurations, cells } = matrix;
    const results = [];
    cells.forEach((col) => col.forEach((cell) => results.push(...getExpressionsFromFieldMatrixCell(cell))));
    if (rowConfigurations)
        rowConfigurations.forEach((row) => results.push(...getExpressionsFromFieldMatrixRowConfiguration(row)));
    if (columnConfigurations)
        columnConfigurations.forEach((col) => results.push(...getExpressionsFromFieldMatrixColumnConfiguration(col)));
    return results;
};
const getExpressionsFromGridField = (gridField) => {
    const results = [];
    const { addRowButtonEnabledExpression } = gridField;
    if (!isNullOrUndefined(addRowButtonEnabledExpression))
        results.push(addRowButtonEnabledExpression);
    return results;
};
export const getExpressionsFromFormView = (formView) => {
    const { formViewMembers, formLayout, fieldMatrices, gridFields } = formView;
    const result = [];
    if (fieldMatrices)
        Object.keys(fieldMatrices).forEach((key) => result.push(...getExpressionsFromFieldMatrix(fieldMatrices[key])));
    Object.keys(formViewMembers).forEach((key) => result.push(...getExpressionsFromFormViewMembers(formViewMembers[key])));
    if (formLayout)
        formLayout.tabs.forEach((tab) => result.push(...getExpressionsFromFormTab(tab)));
    gridFields === null || gridFields === void 0 ? void 0 : gridFields.forEach((gf) => {
        result.push(...getExpressionsFromGridField(gf));
    });
    return result;
};
const getMissingFormViewFields = (formView, domain, fieldConfiguration, additionalMembers = []) => {
    var _a;
    const { formViewMembers } = formView;
    if (!domain)
        return [];
    const domainFields = domain.fields.map((f) => f.fieldName);
    const domainFieldsSet = new Set(domainFields);
    /**
     * Field configuration members indexed by view field name
     */
    const fieldConfigurationMembers = ((_a = fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers) !== null && _a !== void 0 ? _a : []).reduce((all, fcm) => (Object.assign(Object.assign({}, all), { [fcm.viewFieldName]: fcm })), {});
    const expressions = [];
    const missingFieldNames = new Set(['ShortName']);
    const includedFieldNames = new Set(Object.keys(formViewMembers)
        .map((key) => formViewMembers[key].viewFieldName)
        .concat(additionalMembers.map((m) => m.viewFieldName)));
    expressions.push(...getExpressionsFromFormView(formView));
    expressions
        .filter((e) => !isNullOrUndefined(e))
        .forEach((expression) => {
        const { errors, seenFields } = ValidateExpression(expression, FunctionList, domainFields);
        // eslint-disable-next-line no-console
        if (errors.length > 0)
            console.warn({ ExpressionValidationError: errors });
        (seenFields !== null && seenFields !== void 0 ? seenFields : []).forEach((f) => {
            missingFieldNames.add(f);
        });
    });
    // Add any UoM or UoC fields to the list if they're not there already
    fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.fieldConfigurationMembers.flatMap((fcm) => {
        var _a, _b;
        return [
            (_a = fcm.measurementProperties) === null || _a === void 0 ? void 0 : _a.unitOfMeasureFieldName,
            (_b = fcm.currencyProperties) === null || _b === void 0 ? void 0 : _b.unitOfCurrencyFieldName,
        ];
    }).filter((x) => !!x).forEach((x) => {
        missingFieldNames.add(x);
    });
    const missingFieldArray = Array.from(missingFieldNames)
        .filter((f) => !includedFieldNames.has(f) && domainFieldsSet.has(f))
        .sort();
    return missingFieldArray.map((mf) => (Object.assign(Object.assign({}, fieldConfigurationMembers[mf]), { viewFieldName: mf, visibleExpression: 'false' })));
};
export const useMissingFormViewDependencies = (formView, fieldConfiguration, sidePanel) => {
    const { domainId } = formView;
    const domain = useDomain(domainId).data;
    return React.useMemo(() => {
        const { formViewMembers } = formView;
        const providedFormViewMembers = Object.values(formViewMembers);
        const missingFormViewMembers = getMissingFormViewFields(formView, domain, fieldConfiguration);
        if (sidePanel) {
            providedFormViewMembers.push(...Object.values(sidePanel.formViewMembers));
            missingFormViewMembers.push(...getMissingFormViewFields(sidePanel, domain, fieldConfiguration, providedFormViewMembers.concat(missingFormViewMembers)));
            missingFormViewMembers.push(...getMissingViewMembers(domain, fieldConfiguration, sidePanel, providedFormViewMembers.concat(missingFormViewMembers), MapToFormViewMember));
        }
        missingFormViewMembers.push(...getMissingViewMembers(domain, fieldConfiguration, formView, providedFormViewMembers.concat(missingFormViewMembers), MapToFormViewMember));
        return missingFormViewMembers;
    }, [formView, domain, fieldConfiguration, sidePanel]);
};
export default useMissingFormViewDependencies;
