import { Button, SitusHeader } from '@samc/react-ui-core';
import React from 'react';
import { useStyletron } from 'styletron-react';
import { useStrata } from '@samc/filestorage-react';
import { useDocuments } from '../../hooks/useDocuments/useDocuments';
export const DocumentModalContainer = (props) => {
    const { className, title, children } = props;
    const [css] = useStyletron();
    const { state, saveActions: { onSaveDocuments, onDismissDocuments }, } = useDocuments();
    const { saveActions: { getDocuments: setDropzoneFiles }, } = useStrata();
    const dismissDocumentsModal = React.useCallback(() => {
        onDismissDocuments();
        setDropzoneFiles(undefined);
    }, [onDismissDocuments, setDropzoneFiles]);
    return (React.createElement("div", { className: `${className} ${css({
            position: 'fixed',
            padding: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.3)',
            display: 'block',
            inset: '0px',
            zIndex: 100,
        })}`, "aria-hidden": "true" },
        React.createElement("section", { className: css({
                position: 'fixed',
                background: 'white',
                width: '90%',
                height: 'auto',
                minHeight: '75vh',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }), "aria-hidden": "true" },
            React.createElement("div", { className: css({
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }) },
                React.createElement(SitusHeader, { title: title },
                    React.createElement(Button, { buttonType: "secondary", text: "Cancel", onClick: dismissDocumentsModal }),
                    React.createElement(Button, { buttonType: "primary", text: "Save", onClick: onSaveDocuments, disabled: state.isSaveButtonDisabled })),
                children && children))));
};
export default DocumentModalContainer;
