var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDomain, useQBQuery } from '@samc/screen-config-api';
import { MergeFilters } from '../../molecules/QueryExecuteManager/MergeAdHocFilter';
import { getValidExpressions } from '../../utils/ExpressionValidation/ExpressionValidationUtilities';
/**
 * Hook to fetch form data (a single record)
 * @param formView the form we are fetching data for
 * @param primaryKeyValue the primary key/identity/sak value to fetch. Optional, if not passed this hook does nothing.
 * @returns [data, isLoading, refreshData, isError]
 */
export function useFormData(formView, primaryKeyValue, filters, 
/**
 * The default data to be set on a new record being added.
 */
defaultData, adhocFormViewMembers) {
    const [{ responseData, isLoading, isError }, setResult] = React.useState({
        isLoading: true,
        isError: false,
    });
    const data = React.useMemo(() => {
        if (isLoading || isError)
            return undefined;
        if (!defaultData && !responseData)
            return undefined;
        return Object.assign(Object.assign({}, defaultData), responseData);
    }, [defaultData, isError, isLoading, responseData]);
    const { data: domain } = useDomain(formView === null || formView === void 0 ? void 0 : formView.domainId);
    const qbQuery = useQBQuery();
    const domainId = formView === null || formView === void 0 ? void 0 : formView.domainId;
    const primaryKey = domain ? domain.primaryKey : undefined;
    const mergedFilter = React.useMemo(() => {
        const pkFilter = `[${primaryKey}] = '${primaryKeyValue}'`;
        const validFilters = getValidExpressions(filters, domain);
        return MergeFilters([pkFilter, ...validFilters], 'FormData Filter');
    }, [domain, filters, primaryKey, primaryKeyValue]);
    const reload = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!domainId || !domain || !formView)
            return;
        setResult({ isLoading: true, isError: false });
        const loadFormData = () => __awaiter(this, void 0, void 0, function* () {
            const { fields } = domain;
            const { formViewMembers } = formView;
            const domainFields = fields.reduce((all, field) => all.add(field.fieldName.toLowerCase()), new Set());
            const usedFields = Object.keys(formViewMembers);
            const adhocListViewMembers = [
                ...usedFields
                    .filter((f) => domainFields.has(f.toLowerCase())) // only request domain fields
                    .map((field) => {
                    const { viewFieldName } = formView.formViewMembers[field];
                    return {
                        scalarExpression: `[${viewFieldName}]`,
                        scalarDisplayName: viewFieldName,
                    };
                }),
                ...(adhocFormViewMembers || []).map((f) => ({
                    scalarExpression: `[${f.viewFieldName}]`,
                    scalarDisplayName: `${f.viewFieldName}`,
                })),
            ];
            return qbQuery({
                domainId,
                primaryViewId: '-1',
                filterIds: [],
                adhocListViewMembers,
                paging: { start: 0, stop: 1 },
                sorting: {},
                summaryMode: false,
                adhocFilter: mergedFilter,
            });
        });
        try {
            let output;
            if (primaryKeyValue && primaryKey) {
                const formResult = yield loadFormData();
                if (formResult)
                    output = formResult.Data.at(0);
            }
            setResult({ responseData: output, isLoading: false, isError: false });
        }
        catch (e) {
            setResult({ isLoading: false, isError: true });
        }
    }), [domain, domainId, formView, mergedFilter, adhocFormViewMembers, primaryKey, primaryKeyValue, qbQuery]);
    React.useEffect(() => {
        reload();
    }, [reload]);
    return [data, isLoading, reload, primaryKey, isError];
}
export default useFormData;
