var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/display-name */
import React from 'react';
import { DropDownCellEditor as CoreDropdownCellEditor } from '@samc/react-ui-grid';
import QueryExecuteManager from '../../QueryExecuteManager/QueryExecuteManager';
export const DropdownCellEditor = (props) => {
    const { params, displayProperty, valueProperty, domainId, viewId } = props;
    return (React.createElement(QueryExecuteManager
    // At the moment it appears Query/Execute fails if domain is string typed, so I am converting it until compatible.
    , { 
        // At the moment it appears Query/Execute fails if domain is string typed, so I am converting it until compatible.
        domainId: domainId, listViewId: viewId }, (queryRenderProps) => (React.createElement(CoreDropdownCellEditor, { params: params, onFetchMenuItems: () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield queryRenderProps.doFetch(false, { start: 0, stop: 100 });
            return result[0].Data;
        }), displayProperty: displayProperty, valueProperty: valueProperty }))));
};
export const GetDropdownCellEditor = (domainId, viewId, displayProperty, valueProperty) => {
    return (params) => {
        return (React.createElement(DropdownCellEditor, { params: params, domainId: domainId, viewId: viewId, displayProperty: displayProperty, valueProperty: valueProperty }));
    };
};
export const GetCoreDropdownCellEditor = (menuItems, displayProperty, valueProperty, confirmOptions) => {
    return (params) => {
        return (React.createElement(CoreDropdownCellEditor, { params: params, menuItems: menuItems, displayProperty: displayProperty, valueProperty: valueProperty, confirmOptions: confirmOptions }));
    };
};
export default DropdownCellEditor;
