import React from 'react';
import { DocumentModalContainer, DocumentsProvider } from '@samc/screen-config-docs';
import { AddDocumentsModal } from '../AddDocumentsModal/AddDocumentsModal';
import AttachDocumentsGrid from '../AttachDocumentsGrid/AttachDocumentsGrid';
import { getDocsFilters, getDocumentListView, getEntityIdFromQueryParams, isAttachableDocumentsView, } from '../DocumentsModal.Utilities';
import { useEntitlementsDocumentType } from '../../../../hooks/useEntitlementsDocumentType/useEntitlementsDocumentType';
export const AddDocumentsWrapper = (props) => {
    const { viewId, primaryKeyValue, onSaved, onDismiss, isOpen, filters, showContent, defaultFilterExpression, additionalKeyValue, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const addDocsFilters = React.useMemo(() => { var _a; return [...((_a = getDocsFilters(filters, viewId, primaryKeyValue, getEntityIdFromQueryParams())) !== null && _a !== void 0 ? _a : [])]; }, [filters, viewId, primaryKeyValue]);
    const attachedDocsFilters = React.useMemo(() => [...(filters !== null && filters !== void 0 ? filters : []), defaultFilterExpression].filter((e) => typeof e === 'string'), [filters, defaultFilterExpression]);
    const docEntitlementsFilter = useEntitlementsDocumentType();
    const documentTypeEntitlements = [...addDocsFilters, docEntitlementsFilter];
    if (isAttachableDocumentsView(viewId) && Math.sign(Number(primaryKeyValue)) !== -1) {
        return (React.createElement(DocumentsProvider, null,
            React.createElement(DocumentModalContainer, { title: "View and Upload Documents", className: "document-management-attach-file" },
                React.createElement(AttachDocumentsGrid, { viewId: viewId, isOpen: isOpen, onDismiss: onDismiss, onSaved: onSaved, primaryKeyValue: additionalKeyValue, showContent: showContent, filters: attachedDocsFilters, subtitle: "Attached Documents", GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }))));
    }
    if (Math.sign(Number(primaryKeyValue)) !== -1) {
        return (React.createElement(DocumentsProvider, null,
            React.createElement(DocumentModalContainer, { title: "Add Documents", className: "document-management-file-uploader" },
                React.createElement(AddDocumentsModal, { onDismiss: onDismiss, viewId: getDocumentListView(viewId), isOpen: isOpen, entityId: primaryKeyValue, onSaved: onSaved, filters: documentTypeEntitlements }))));
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null);
};
export default AddDocumentsWrapper;
