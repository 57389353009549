var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { v4 as uuid } from 'uuid';
import { toastError } from '@samc/react-ui-core';
import { localeDateFormat } from '@samc/react-ui-formatting';
import { getStrataFileInfo } from '@samc/screen-config-docs';
import { getFormRequestIdentifier } from '../../FormScreen/utilities';
import { getDisplayFormat, mapDataSourceTypeToGridType } from '../../GridScreen/GridScreen.utilities';
export var ViewTypeName;
(function (ViewTypeName) {
    ViewTypeName["AddDocuments"] = "AddDocuments";
    ViewTypeName["AddValuationDocuments"] = "AddValuationDocuments";
    ViewTypeName["AddDocumentLink"] = "AddDocumentLinks";
    ViewTypeName["AddGroupAssetDocumentLink"] = "AddGroupAssetDocumentLinks";
    ViewTypeName["DocCountNote"] = "DocCountNote";
    ViewTypeName["ReviewCommentDocuments"] = "ReviewCommentDocuments";
    ViewTypeName["AddCashFlowDocuments"] = "AddCashFlowDocuments";
    ViewTypeName["AddDEADocuments"] = "AddDEADocuments";
    ViewTypeName["AddSourceDocuments"] = "AddSourceDocuments";
    ViewTypeName["PolicyAndAdminDocumentsList"] = "PolicyAndAdminDocumentsList";
    ViewTypeName["SharedFilesDocumentsList"] = "SharedFilesDocumentsList";
    ViewTypeName["UserGuidesDocumentsList"] = "UserGuidesDocumentsList";
})(ViewTypeName || (ViewTypeName = {}));
export var ListViewTypeName;
(function (ListViewTypeName) {
    ListViewTypeName["AddDocuments"] = "AddDocumentsList";
    ListViewTypeName["AddValuationDocuments"] = "AddValuationDocumentsList";
    ListViewTypeName["AddDEADocuments"] = "AddDEADocumentsList";
    ListViewTypeName["AddCashFlowDocuments"] = "AddCashFlowDocumentsList";
    ListViewTypeName["DocCountNote"] = "DocCountNoteList";
    ListViewTypeName["RemoveDocumentLinks"] = "RemoveDocumentLinksList";
    ListViewTypeName["ReviewCommentDocuments"] = "ReviewCommentDocumentsList";
    ListViewTypeName["AddSourceDocuments"] = "AddSourceDocumentsList";
    ListViewTypeName["UserGuidesDocuments"] = "UserGuidesDocuments";
})(ListViewTypeName || (ListViewTypeName = {}));
export const removeDocumentLinksDescription = 'Select the document links you would like to remove and click the delete icon (trash can). If all links are removed, the document will be permanently deleted.';
export const documentLinksSectionTitle = 'Document Links';
export const strataModel = {
    dateAdded: '',
    description: '',
    documentDate: '',
    documentName: '',
    documentTypeId: '',
    entityId: '',
    entityLevelId: '',
    fileUuid: '',
    version: '',
    valuationId: '',
    entityTypeIds: '',
    portfolioIds: '',
    fundId: '',
    investmentId: '',
    assetId: '',
    loanId: '',
    companyId: '',
    contactId: '',
    staticDocument: '',
};
export const AddDocumentsViews = [
    ViewTypeName.AddDocuments,
    ViewTypeName.DocCountNote,
    ViewTypeName.AddValuationDocuments,
    ViewTypeName.ReviewCommentDocuments,
    ViewTypeName.AddCashFlowDocuments,
    ViewTypeName.AddSourceDocuments,
    ViewTypeName.AddDEADocuments,
];
export const DocumentLinksViews = [ViewTypeName.AddDocumentLink, ViewTypeName.AddGroupAssetDocumentLink];
export const AddValuationView = [
    ViewTypeName.AddValuationDocuments,
    ViewTypeName.AddCashFlowDocuments,
    ViewTypeName.AddSourceDocuments,
];
export const ReferenceDocumentsView = [
    ViewTypeName.PolicyAndAdminDocumentsList,
    ViewTypeName.SharedFilesDocumentsList,
    ViewTypeName.UserGuidesDocumentsList,
];
export const AddDEAView = [ViewTypeName.AddDEADocuments];
export const AttachableDocuments = [ViewTypeName.DocCountNote, ViewTypeName.ReviewCommentDocuments];
export const LinkedValuationDocuments = [ViewTypeName.ReviewCommentDocuments];
export const bytesToKilobytes = (bytes) => {
    if (!bytes)
        return 0;
    const KILOBYTE = 1024;
    return bytes / KILOBYTE;
};
export const isAddDocumentView = (viewId) => AddDocumentsViews.some((view) => view === viewId);
export const isDocumentLinkView = (viewId) => DocumentLinksViews.some((view) => view === viewId);
export const isAttachableDocumentsView = (viewId) => AttachableDocuments.some((view) => view === viewId);
export const isLinkedValuationDocumentsView = (viewId) => LinkedValuationDocuments.some((view) => view === viewId);
export const isReferenceDocumentView = (viewId) => ReferenceDocumentsView.some((view) => view === viewId);
export const displayNameRuleFormatter = (displayNameRule) => {
    if (displayNameRule === null || typeof displayNameRule === 'undefined')
        return '';
    return displayNameRule.split("'").join('');
};
export const mapListViewForStrataDocuments = (listView) => {
    if (!listView) {
        return [];
    }
    return listView.listViewMembers.map((member) => ({
        autoFillFileName: member.viewFieldName === 'FileName',
        autoFillUUID: member.viewFieldName === 'fileUuid',
        field: member.viewFieldName,
        headerName: member.scalarDisplayName,
        displayNameRule: displayNameRuleFormatter(member.displayNameExpression),
        displayFormat: getDisplayFormat(member.displayFormat, member.formatId, member.inputType),
        width: member.width,
        editableRule: member.editableExpression,
        visibleRule: member.visibleExpression,
        requiredFieldRule: member.requiredExpression,
        inputType: member.inputType,
        isKeyField: member.isKeyField,
        defaultValueRule: member.defaultValueExpression,
        tooltip: member.tooltip,
        expression: member.scalarExpression,
        editor: member.editor,
        horizontalAlignment: member.horizontalAlignment,
        type: mapDataSourceTypeToGridType(member),
    }));
};
export const handleSubmit = (listView, requestPayload, taskSubmit, gridData, processErrors, setServerErrors, setErrorToastIsVisible) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const request = {
        domainId: listView.domainId,
        fieldConfigurationId: listView.fieldConfigurationId,
        viewId: listView.id,
        requestIdentifier: getFormRequestIdentifier(),
        payload: requestPayload,
    };
    const [res] = yield taskSubmit(request);
    if (res.messageCount > 0) {
        setServerErrors({ data: gridData, errors: processErrors((_a = res.messages) !== null && _a !== void 0 ? _a : []) });
        setErrorToastIsVisible(true);
    }
    return res;
});
export const getEntityIdFromQueryParams = () => {
    if (!window.location.hash) {
        return '';
    }
    const queryParams = window.location.hash.split('?').pop();
    const pk = queryParams && queryParams.split('&').filter((key) => key.includes('primaryKeyValue'));
    const entityId = (pk && pk.length && pk[0].split('=').pop()) || '';
    return entityId;
};
export const submitAttachedDocuments = (listView, requestPayload, taskSubmit, setErrors, setErrorToastIsVisible) => __awaiter(void 0, void 0, void 0, function* () {
    const request = {
        domainId: listView.domainId,
        fieldConfigurationId: listView.fieldConfigurationId,
        viewId: listView.id,
        requestIdentifier: getFormRequestIdentifier(),
        payload: requestPayload,
    };
    const [response] = yield taskSubmit(request);
    if (response.messageCount > 0) {
        setErrors(response.messages);
        setErrorToastIsVisible(true);
    }
    return response;
});
export const handlePromptModal = (isSavedDisabled, handleClose, validatePrompt) => {
    if (isSavedDisabled) {
        handleClose();
    }
    else {
        validatePrompt();
    }
};
export const mapDocumentCreationPayload = (data, entityId, documentIndex, documentTypesData) => {
    const hashPath = window.location.hash.toLocaleLowerCase();
    const isReferenceDocumentPath = window.location.pathname.indexOf('reference') !== -1;
    const isCompanyPath = hashPath.indexOf('company') !== -1;
    const isDEAPath = hashPath.indexOf('debtequityanalysis') !== -1;
    const isESInputPath = hashPath.indexOf('esinput') !== -1;
    const isValuationGroupDocumentPath = hashPath.indexOf('valuationgroup') !== -1;
    const calcDocType = data.DocumentTypeId ? data.DocumentTypeId : '';
    const calcVersion = data.Version ? `__${data.Version === '1' ? 'Final' : 'Draft'}` : '';
    let documentType = {
        DocTypeCode: '',
        CategoryIds: '',
    };
    if ((data === null || data === void 0 ? void 0 : data.DocumentTypeId) && Array.isArray(documentTypesData)) {
        const tmpDocType = documentTypesData.filter((docType) => docType.id === data.DocumentTypeId);
        if (tmpDocType.length) {
            documentType = {
                DocTypeCode: tmpDocType[0].documentTypeCode,
                CategoryIds: tmpDocType[0].categoryIds,
            };
        }
    }
    let calcDate = '';
    if (data.DocumentDate) {
        const tmpDate = new Date(data.DocumentDate);
        const day = tmpDate.getDate();
        const month = tmpDate.toLocaleString('default', { month: 'long' });
        calcDate = `__${day < 10 ? `0${day}` : day}-${month.substring(0, 3)}-${tmpDate.getFullYear()}`;
    }
    const calcMethodology = data.MethodologyScenario ? `__${data.MethodologyScenario}` : '';
    const calcAppendText = data.NameAppendText ? `__${data.NameAppendText}` : '';
    const calculatedName = `${calcDocType}${calcVersion}${calcDate}${calcMethodology}${calcAppendText}`;
    const entityIds = [entityId || getEntityIdFromQueryParams()];
    if (isDEAPath || isESInputPath) {
        entityIds.push(data.ValuationId);
    }
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ Id: documentIndex, DocumentId: data.fileUuid, EntityIds: entityIds.join(', ') || null, FileName: data.FileName, Version: data.Version || null, CategoryIds: documentType.CategoryIds || null, DocumentTypeCode: documentType.DocTypeCode || null, DocumentDate: data.DocumentDate || null, DocDescription: data.DocDescription || null, DocumentTypeId: data.DocumentTypeId || null, NameAppendText: data.NameAppendText || null, CalculatedDocumentName: calculatedName || null, FileSize: bytesToKilobytes(data.fileSize) || 0 }, ('ValuationAssetId' in data && { ValuationAssetId: data.ValuationAssetId || null })), ('EntityTypeIds' in data && { EntityTypeIds: data.EntityTypeIds || null })), ('FundId' in data && { FundId: data.FundId || null })), ('InvestmentId' in data && { InvestmentId: data.InvestmentId || null })), ('AssetId' in data && { AssetId: data.AssetId || null })), ('LoanId' in data && { LoanId: data.LoanId || null })), ('ValuationId' in data && isCompanyPath ? {} : { ValuationId: data.ValuationId || null })), ('CompanyId' in data && { CompanyId: data.CompanyId || null })), ('ContactId' in data && { ContactId: data.ContactId || null })), (isReferenceDocumentPath ? { ClientId: entityIds.join('') } : {})), (isValuationGroupDocumentPath ? { ValuationGroupId: entityIds.join('') } : {})), ('Portfolio_Id' in data && { Portfolio_Id: data.Portfolio_Id || null })), ('MethodologyScenario' in data && { MethodologyScenario: data.MethodologyScenario || null })), ('StaticDocument' in data && { StaticDocument: data.StaticDocument || false })), { __CHILD_REQUESTS__: [], __ADDED__: true });
};
export const attachDocumentCreatePayload = (documentRow, entityNameId) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ Id: documentRow.Id || '', DocumentId: documentRow.DocumentId || '', EntityType: documentRow.EntityType || '', EntityIds: documentRow.EntityIds ? documentRow.EntityIds : entityNameId || '', FileName: documentRow.FileName, Version: documentRow.Version || '', DocumentDate: documentRow.DocumentDate || '', DocDescription: documentRow.DocDescription || '', DocumentTypeId: documentRow.DocumentTypeId || '' }, ('ValuationAssetId' in documentRow && { ValuationAssetId: documentRow.ValuationAssetId || '' })), ('EntityTypeIds' in documentRow && { EntityTypeIds: documentRow.EntityTypeIds || null })), ('FundId' in documentRow && { FundId: documentRow.FundId || null })), ('InvestmentId' in documentRow && { InvestmentId: documentRow.InvestmentId || null })), ('AssetId' in documentRow && { AssetId: documentRow.AssetId || null })), ('LoanId' in documentRow && { LoanId: documentRow.LoanId || null })), ('ValuationId' in documentRow && { ValuationId: documentRow.ValuationId || null })), ('CompanyId' in documentRow && { CompanyId: documentRow.CompanyId || null })), ('ContactId' in documentRow && { ContactId: documentRow.ContactId || null })), ('Portfolio_Id' in documentRow && { Portfolio_Id: documentRow.Portfolio_Id || null })), ('StaticDocument' in documentRow && { StaticDocument: documentRow.StaticDocument || false })), ('__DELETED__' in documentRow && { __DELETED__: true })));
export const mapDocumentGridData = (documentRow, entityNameId) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ Id: documentRow.fileUuid, DocumentId: documentRow.fileUuid, EntityIds: entityNameId || '', FileName: documentRow.FileName, Version: documentRow.Version || '', DocumentDate: documentRow.DocumentDate || '', DocDescription: documentRow.DocDescription || '', DocumentTypeId: documentRow.DocumentTypeId || '', FileSize: bytesToKilobytes(documentRow.fileSize) || 0 }, ('ValuationAssetId' in documentRow && { ValuationAssetId: documentRow.ValuationAssetId || null })), ('EntityTypeIds' in documentRow && { EntityTypeIds: documentRow.EntityTypeIds || null })), ('FundId' in documentRow && { FundId: documentRow.FundId || null })), ('InvestmentId' in documentRow && { InvestmentId: documentRow.InvestmentId || null })), ('AssetId' in documentRow && { AssetId: documentRow.AssetId || null })), ('LoanId' in documentRow && { LoanId: documentRow.LoanId || null })), ('ValuationId' in documentRow && { ValuationId: documentRow.ValuationId || null })), ('CompanyId' in documentRow && { CompanyId: documentRow.CompanyId || null })), ('ContactId' in documentRow && { ContactId: documentRow.ContactId || null })), ('Portfolio_Id' in documentRow && { Portfolio_Id: documentRow.Portfolio_Id || null })), ('MethodologyScenario' in documentRow && { MethodologyScenario: documentRow.MethodologyScenario || null })), ('StaticDocument' in documentRow && { StaticDocument: documentRow.StaticDocument || false })), { __CHILD_REQUESTS__: [], __ADDED__: true }));
export const dropzoneColDefs = [
    {
        field: 'categories',
        headerText: 'Categories',
        flexWidth: 1,
    },
    {
        field: 'shortName',
        headerText: 'Doc Type',
        flexWidth: 2,
    },
];
export const getOnStrataFileAdded = (setIsStrataDirty) => {
    return () => {
        setIsStrataDirty(true);
    };
};
export const getOnDZGridDrop = (dropzoneData) => {
    const onDZGridFileDropCb = (_acceptedFiles, fileRejections, _event, dropzoneId) => {
        var _a;
        if (fileRejections.length > 0) {
            const docType = dropzoneData.filter((dd) => dd.id === dropzoneId)[0];
            const errorMessage = (React.createElement("div", null,
                "Only file types with the following extensions are permitted for the",
                ' ',
                React.createElement("strong", null, docType.shortName),
                " Document Type: ", (_a = docType.fileTypeNames) === null || _a === void 0 ? void 0 :
                _a.replaceAll('.', ''),
                React.createElement("br", null),
                fileRejections.length > 1
                    ? `The following files were not accepted:`
                    : `The following file was not accepted:`,
                React.createElement("br", null),
                React.createElement("br", null),
                fileRejections.map(({ file: { name } }, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                React.createElement("div", { key: `${name}-${idx}` },
                    name,
                    React.createElement("br", null))))));
            toastError(errorMessage);
        }
    };
    return onDZGridFileDropCb;
};
export const getDocLinksParentDomain = (hashPath, viewId) => {
    switch (true) {
        case hashPath.indexOf('bid') !== -1:
            return viewId === 'AddGroupAssetDocumentLinks' ? 'POMA_Asset' : 'ValuationGroup';
        case hashPath.indexOf('investment') !== -1:
            return 'POMA_Investment';
        case hashPath.indexOf('asset') !== -1:
        case hashPath.indexOf('loan') !== -1:
            return 'POMA_Asset';
        case hashPath.indexOf('valuation') !== -1:
            if (hashPath.indexOf('debtequity') !== -1)
                return 'ValuationAsset';
            if (hashPath.indexOf('valuationgroup') !== -1)
                return 'ValuationGroup';
            return 'VALU_Valuation';
        default:
            return '';
    }
};
export const getDocLinksMemberKey = (hashPath, parentDomain, viewId) => {
    switch (true) {
        case hashPath.indexOf('bid') !== -1 && viewId === ViewTypeName.AddGroupAssetDocumentLink:
        case hashPath.indexOf('debtequity') !== -1 && parentDomain === 'ValuationAsset':
            return 'FundId';
        case hashPath.indexOf('bid') !== -1 && viewId !== ViewTypeName.AddGroupAssetDocumentLink:
        case hashPath.indexOf('valuationgroup') !== -1:
        case hashPath.indexOf('valuation') !== -1:
            return 'FundIds';
        default:
            return 'FundId';
    }
};
export const getDocLinksAdvancedExpression = (hashPath, entityId, viewId) => {
    switch (true) {
        case hashPath.indexOf('loan') !== -1:
            return `[LoanIds] = '${entityId}'`;
        case hashPath.indexOf('bid') !== -1 && viewId !== ViewTypeName.AddGroupAssetDocumentLink:
        case hashPath.indexOf('valuationgroup') !== -1:
            return `[ValuationGroupId] = '${entityId}'`;
        default:
            return entityId ? `[Id] = '${entityId}'` : null;
    }
};
export const getDocumentLinksSelectedRows = (selectedRowsByGrids) => {
    const selectedDocumentLinksRows = (Array.isArray(selectedRowsByGrids) &&
        selectedRowsByGrids.filter((grid) => grid.gridId === 'AddDocumentLinksList')) ||
        [];
    return selectedDocumentLinksRows.length > 0 ? selectedDocumentLinksRows[0].selectedRows : [];
};
export const getDocumentsSelectedRows = (selectedRowsByGrids) => {
    const tmpFilters = [];
    const selectedDocumentRows = selectedRowsByGrids.filter((grid) => { var _a; return (_a = grid === null || grid === void 0 ? void 0 : grid.gridId) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('documents'); });
    if (selectedDocumentRows && selectedDocumentRows.length) {
        selectedDocumentRows[0].selectedRows.forEach((selectedRow) => {
            tmpFilters.push(`${selectedRow.DocumentId}`);
        });
    }
    const mergedArray = [`([DocumentId] in (${tmpFilters.map((filter) => `'${filter}'`).join(', ')}))`];
    return tmpFilters.length === 0 ? [] : mergedArray;
};
export const getFundIdFromCurrentEntity = (entityId, executeQuery, viewId) => __awaiter(void 0, void 0, void 0, function* () {
    const hashPath = window.location.hash.toLocaleLowerCase();
    const parentDomain = yield getDocLinksParentDomain(hashPath, viewId);
    const fundKey = yield getDocLinksMemberKey(hashPath, parentDomain, viewId);
    const getQueryPayload = {
        domainId: parentDomain,
        primaryViewId: '-1',
        filterIds: [],
        adhocListViewMembers: [
            {
                scalarExpression: `[${fundKey}]`,
                scalarDisplayName: fundKey,
            },
        ],
        paging: {
            start: 0,
            stop: 1,
        },
        sorting: {},
        summaryMode: false,
        adhocFilter: {
            filterName: 'DocModal Filter',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: getDocLinksAdvancedExpression(hashPath, entityId, viewId),
        },
        cacheId: uuid(),
    };
    const result = yield executeQuery(getQueryPayload);
    const fundId = (result === null || result === void 0 ? void 0 : result.Data) && result.Data.length ? result.Data[0][fundKey] : '';
    return fundId;
});
export const getDomainIdKey = () => {
    const hashPath = window.location.hash.toLocaleLowerCase();
    const pathName = window.location.pathname.toLocaleLowerCase();
    switch (true) {
        case hashPath.indexOf('valuationgroup') >= 0:
        case pathName.indexOf('bid') !== -1:
        case pathName.indexOf('portfolio') >= 0:
        case pathName.indexOf('valuations') >= 0:
            return 'FundId';
        case hashPath.indexOf('company') !== -1:
            return 'CompanyId';
        case hashPath.indexOf('contact') !== -1:
            return 'ContactId';
        default:
            return '';
    }
};
export const getAssociatedEntityPicklistItemGetter = (entityIds, currentEntity, picklistId, basePicklistItemGetter, onItemsLoaded) => (params) => __awaiter(void 0, void 0, void 0, function* () {
    const { filters: filtersParam } = params;
    const newParams = Object.assign({}, params);
    if (!newParams.ids) {
        // filters by the curren entity and the parent(s) FundId(s) entity
        const filters = [
            Object.assign(`LOWER([Id]) <> '${currentEntity.toLowerCase()}'`, { idAllowed: true }),
            entityIds
                .split(',')
                .map((entityId) => `(LOWER([${getDomainIdKey()}]) = '${entityId.toLowerCase()}')`)
                .join(' OR '),
        ];
        if (filtersParam)
            filters.push(...filtersParam);
        newParams.filters = filters;
        if (!newParams.sortOverride) {
            newParams.sortOverride = {
                order: 'asc',
                orderBy: {
                    scalarExpression: '[CustomLongName]',
                },
            };
        }
    }
    const response = yield basePicklistItemGetter(picklistId, newParams);
    const { items } = response;
    onItemsLoaded === null || onItemsLoaded === void 0 ? void 0 : onItemsLoaded(items);
    return response;
});
export const getRowIndex = (rows, field, id) => {
    return rows && rows.length
        ? rows.findIndex((gridRow) => gridRow.data[field] === id)
        : -1;
};
export const generateStrataResponses = (strataGridErrors) => [
    {
        statusCode: 1,
        messageCount: strataGridErrors.length,
        messages: strataGridErrors,
        requestIdentifier: 'Add Documents Grid',
        domainId: 'Documents',
        prompts: [],
        childResponses: {},
        newIds: {},
        deletedIds: [],
        updatedIds: [],
    },
];
export const createStrataErrorMapped = (rowIndex, rowError, rowId) => ({
    code: 'ERR',
    title: `Row ${rowIndex + 1} - ${rowError}`,
    description: `Row ${rowIndex + 1} - ${rowError}`,
    stackTrace: '',
    type: 0,
    recordIdentifier: rowId,
    index: rowIndex,
    fields: null,
    overridable: false,
});
export const mapStrataErrors = (dataFromStrata, currentRows) => {
    return [
        ...dataFromStrata.data.map((row) => {
            const currentRowIndex = getRowIndex(currentRows, 'fileUuid', row.fileUuid);
            return createStrataErrorMapped(currentRowIndex, row.status, row.fileUuid);
        }),
    ];
};
export const generateStrataRequest = (strataData) => [
    {
        domainId: 'Documents',
        viewId: 'Documents',
        fieldConfigurationId: 'Documents',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        payload: strataData,
        requestIdentifier: 'Add Documents Grid',
    },
];
export const mapStrataData = (currentGridRows, entityId) => {
    let gridData = {};
    if (currentGridRows && Array.isArray(currentGridRows) && currentGridRows.length) {
        currentGridRows.forEach((row, idx) => {
            gridData = Object.assign(Object.assign({}, gridData), { [row.data.fileUuid]: Object.assign(Object.assign({}, mapDocumentGridData(row.data, entityId)), { rowIndex: idx }) });
        });
    }
    return gridData;
};
export const getFileNameFromData = (recordData, pkValue) => {
    const fileName = 'FileName';
    return recordData[pkValue] && recordData[pkValue][fileName] !== undefined
        ? `${recordData[pkValue][fileName]}`
        : undefined;
};
export const getAddDocumentsView = (gridId) => {
    if (isLinkedValuationDocumentsView(gridId)) {
        return ListViewTypeName.AddValuationDocuments;
    }
    return ListViewTypeName.AddDocuments;
};
export const getLinkedValuationFilter = (gridId, pk) => {
    if (isLinkedValuationDocumentsView(gridId)) {
        return `DoesContain('${pk}', [ValuationId])`;
    }
    return '';
};
export const getEntitiesValuationFilter = (gridId, pk) => {
    if (isLinkedValuationDocumentsView(gridId)) {
        return `DoesContain('${pk}', [ReviewCommentValuationId])`;
    }
    return '';
};
export const addTenantToUrl = (url, strataTenantId = '') => {
    if (!strataTenantId)
        return url;
    const auxArray = url.split('/');
    auxArray.splice(-1, 0, strataTenantId);
    const newURL = auxArray.join('/');
    return newURL;
};
export const getDocsFilters = (filters, viewId, currentEntityId, parentEntityId) => {
    if (!filters && !currentEntityId) {
        return undefined;
    }
    const definedFilters = [...(filters ? filters.slice() : [''])];
    if (!currentEntityId) {
        return definedFilters;
    }
    const updatedFilters = [...definedFilters];
    const needsValuationFilter = AddValuationView.some((view) => view === viewId);
    const needsDEAFilter = AddDEAView.some((view) => view === viewId);
    if (needsValuationFilter)
        updatedFilters.push(`DoesContain('${parentEntityId}', [ValuationId])`);
    if (needsDEAFilter)
        updatedFilters.push(`DoesContain('${currentEntityId}', [ValuationAssetId])`);
    return updatedFilters;
};
export const getFileInfo = (documentId, token, tenantName, strataApi) => __awaiter(void 0, void 0, void 0, function* () {
    if (!documentId || !token || !tenantName || !strataApi) {
        return new Promise((resolve) => {
            resolve();
        });
    }
    const fileInfo = yield getStrataFileInfo(documentId, token, tenantName, strataApi);
    return fileInfo;
});
export const getDocumentListView = (viewId) => {
    const values = Object.keys(ListViewTypeName);
    const index = values.findIndex((view) => view === viewId);
    return Object.values(ListViewTypeName)[index];
};
export const getFormattedDate = (date, showTime = false) => {
    const tmpDate = new Date(date);
    tmpDate.setUTCHours(tmpDate.getUTCHours() - tmpDate.getTimezoneOffset() / 60);
    tmpDate.setUTCMinutes(tmpDate.getUTCMinutes() - (tmpDate.getTimezoneOffset() - Math.trunc(tmpDate.getTimezoneOffset() / 60) * 60));
    return localeDateFormat(tmpDate, undefined, showTime);
};
