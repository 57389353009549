import React from 'react';
import { ToastIcon, Text, ErrorMessageType } from '@samc/react-ui-core';
const TaskMessageSummary = ({ message, isOverridden }) => {
    return (React.createElement("div", null,
        React.createElement(Text, null,
            React.createElement(ToastIcon, { toastType: ErrorMessageType[message.type].toLowerCase(), size: "small" }),
            React.createElement(Text, { weight: "bold" }, isOverridden ? ' [Override] ' : ' '),
            React.createElement(Text, { strike: isOverridden },
                React.createElement(Text, { weight: "bold" }, message.code),
                ": ",
                React.createElement(Text, { italic: true }, message.description))),
        React.createElement("br", null),
        message.type === ErrorMessageType.Error && !message.overridable && (React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'center' } },
            React.createElement(Text, { variant: "small" }, "* Hard Error - cannot be overridden")))));
};
export default TaskMessageSummary;
