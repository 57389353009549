// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-65abffee5a/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@samc\\/single-spa-screen-config .theme--custom.fluent-theme-custom {
    display: flex;
}

#single-spa-application\\:\\@samc\\/single-spa-screen-config .record-picker-header {
    padding: 6px;
}

#single-spa-application\\:\\@samc\\/single-spa-screen-config button, #single-spa-application\\:\\@samc\\/single-spa-screen-config input, #single-spa-application\\:\\@samc\\/single-spa-screen-config optgroup, #single-spa-application\\:\\@samc\\/single-spa-screen-config select, #single-spa-application\\:\\@samc\\/single-spa-screen-config textarea {
    line-height: normal;
}

/*
    To keep the ResizableDivider in Form from having a
    scrollbar when expanded, due to global styles in VMSNExt
*/
#single-spa-application\\:\\@samc\\/single-spa-screen-config Form .draggable-bottom {
    box-sizing: content-box !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/screen-config.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;;CAGC;AACD;IACI,kCAAkC;AACtC","sourcesContent":[".theme--custom.fluent-theme-custom {\n    display: flex;\n}\n\n.record-picker-header {\n    padding: 6px;\n}\n\nbutton, input, optgroup, select, textarea {\n    line-height: normal;\n}\n\n/*\n    To keep the ResizableDivider in Form from having a\n    scrollbar when expanded, due to global styles in VMSNExt\n*/\nForm .draggable-bottom {\n    box-sizing: content-box !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
