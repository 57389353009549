/* eslint-disable react/display-name */
import React from 'react';
import { InputType, CalculationFrequency, } from '@samc/screen-config-api';
import { DropDownCellEditor } from '@samc/react-ui-grid';
import { GetExpressionEditor } from '../../molecules/controls/ExpressionEditor/ExpressionEditor';
import { FetchItems } from './fetchItems';
import { useFieldConfigurationForm } from '../../field-configs/collections/useFieldConfigurationForm/useFieldConfigurationForm';
const editableExpression = '[isLocked] = false';
const inputValueFormatter = (params) => {
    const items = FetchItems.fetchInputTypes('id', 'type');
    const index = items.findIndex((x) => `${x.id}` === `${params.value}`);
    if (index >= 0) {
        return items[index].type;
    }
    return '';
};
const calculationFrequencyValueFormatter = (params) => {
    const items = FetchItems.fetchCalculationFrequency('id', 'type');
    const index = items.findIndex((x) => `${x.id}` === `${params.value}`);
    if (index >= 0) {
        return items[index].type;
    }
    return '';
};
export const InputTypeCellEditor = (props) => {
    const { params, domainFields } = props;
    const inputTypes = React.useMemo(() => {
        const { data } = params;
        const fieldConfigurationMember = data;
        const currentDomainField = domainFields.find((f) => f.fieldName === fieldConfigurationMember.viewFieldName);
        return FetchItems.fetchInputTypes('id', 'type', currentDomainField);
    }, [domainFields, params]);
    return React.createElement(DropDownCellEditor, { params: params, menuItems: inputTypes, displayProperty: "type", valueProperty: "id" });
};
export const useConfigureFieldsMeta = (domain, openPicklistConfiguration, fieldConfiguration) => {
    const { form: fieldConfigurationForm, onFormLoaded } = useFieldConfigurationForm(openPicklistConfiguration, fieldConfiguration, domain === null || domain === void 0 ? void 0 : domain.fields, domain === null || domain === void 0 ? void 0 : domain.primaryKey);
    const fields = React.useMemo(() => [
        {
            headerName: 'Field',
            field: 'viewFieldName',
            editableRule: 'false',
        },
        {
            headerName: 'Is Protected',
            field: 'isProtected',
            editableRule: 'true',
            type: 'boolean',
        },
        {
            headerName: 'Is Locked',
            field: 'isLocked',
            editableRule: 'false',
            type: 'boolean',
        },
        {
            headerName: 'Required Rule',
            field: 'requiredExpression',
            editableRule: editableExpression,
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Form Display Rule',
            field: 'displayNameExpression',
            editableRule: 'true',
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Editable Rule',
            field: 'editableExpression',
            editableRule: editableExpression,
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Visible Rule',
            field: 'visibleExpression',
            editableRule: 'true',
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Default Value Rule',
            field: 'defaultValueExpression',
            editableRule: editableExpression,
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Fill Rule',
            field: 'hexFillRule',
            editableRule: editableExpression,
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Is Key Field',
            field: 'isKeyField',
            editableRule: 'true',
            type: 'boolean',
        },
        {
            headerName: 'Format',
            field: 'formatId',
            editableRule: `([inputType] <> ${InputType.Date}) && ([inputType] <> ${InputType.DateTime}) && ([inputType] <> ${InputType.Time})`,
        },
        {
            headerName: 'Input Type',
            field: 'inputType',
            editableRule: editableExpression,
            editor: (params) => (React.createElement(InputTypeCellEditor, { params: params, domainFields: (domain === null || domain === void 0 ? void 0 : domain.fields) || [] })),
            valueFormatter: inputValueFormatter,
            isPopupEditor: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                valueFormatter: inputValueFormatter,
            },
        },
        {
            headerName: 'Allowed File Types',
            field: 'allowedFileTypes',
            editableRule: 'true',
            tooltip: 'Allowed File Types for Strata File',
        },
        {
            headerName: 'Tool Tip',
            field: 'tooltip',
            editableRule: 'true',
        },
        {
            headerName: 'Horizontal Alignment',
            field: 'horizontalAlignment',
            editableRule: editableExpression,
            editor: (params) => (React.createElement(DropDownCellEditor, { params: params, menuItems: FetchItems.fetchHorizontalAlignment('id', 'type'), displayProperty: "type", valueProperty: "id" })),
            valueFormatter: (params) => {
                const items = FetchItems.fetchHorizontalAlignment('id', 'type');
                const index = items.findIndex((x) => x.id === params.value);
                if (index >= 0) {
                    return items[index].type;
                }
                return '';
            },
            isPopupEditor: true,
        },
        {
            headerName: 'Calculation Frequency',
            field: 'calculationFrequency',
            editableRule: editableExpression,
            editor: (params) => (React.createElement(DropDownCellEditor, { params: params, menuItems: FetchItems.fetchCalculationFrequency('id', 'type'), displayProperty: "type", valueProperty: "id" })),
            isPopupEditor: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                valueFormatter: calculationFrequencyValueFormatter,
            },
            valueFormatter: (params) => {
                const items = FetchItems.fetchCalculationFrequency('id', 'type');
                const index = items.findIndex((x) => x.id === params.value);
                if (index >= 0) {
                    return items[index].type;
                }
                return '';
            },
        },
        {
            headerName: 'Calculation Rule',
            field: 'calculationRule',
            editableRule: `(${editableExpression}) AND ([calculationFrequency] <> ${CalculationFrequency.Never})`,
            editor: GetExpressionEditor(domain),
            width: 250,
        },
        {
            headerName: 'Calculation Condition',
            field: 'calculationCondition',
            editableRule: `(${editableExpression}) AND ([calculationFrequency] = ${CalculationFrequency.Conditionally})`,
            editor: GetExpressionEditor(domain),
            width: 250,
        },
    ], [domain]);
    return [fields, fieldConfigurationForm, onFormLoaded];
};
export default useConfigureFieldsMeta;
