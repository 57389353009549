import { getCombinedExpression } from '@samc/react-ui-core';
/**
 * Merges together AdHocFilters and filter expressions.
 * Note this only takes into account the advanced Expression of a filter. It does not merge DomainFilterMembers.
 * @param filters
 * @returns
 */
export const MergeFilters = (filters, filterName = 'Merged AdHoc Filter') => {
    const definedFilters = filters.filter((f) => f !== undefined && f !== null && f !== '');
    const definedExpressions = definedFilters
        .map((f) => (typeof f === 'string' ? f : f === null || f === void 0 ? void 0 : f.advancedExpression))
        .filter((f) => f !== undefined);
    return definedExpressions.length === 0
        ? undefined
        : {
            filterName,
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: getCombinedExpression(definedExpressions) || 'true',
        };
};
/**
 * Merges two filters together. Note this only takes into account the advanced Expression of a filter.
 * It does not merge DomainFilterMembers.
 * @param filter1
 * @param filter2
 * @returns
 */
export const MergeAdHocFilter = (filter1, filter2) => {
    return MergeFilters([filter1, filter2]);
};
