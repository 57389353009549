/**
 * Replaces the fieldName in a section layout and its children with undefined if it is in the provided set.
 * @param layout The section layout
 * @param fields the fields to remove
 * @returns the scrubbed section layout
 */
export const removeFieldNamesFromLayout = (layout, fields) => {
    if (!layout)
        return layout;
    const { field, children } = layout;
    const newLayout = Object.assign(Object.assign({}, layout), { field: field && fields.has(field) ? undefined : field });
    if (children.length === 0)
        return newLayout;
    newLayout.children = newLayout.children.map((c) => removeFieldNamesFromLayout(c, fields));
    return newLayout;
};
/**
 * Removes markdown formViewMembers in preparation to map to FormSkeleton, since markdown fields
 * will no longer have FormViewMembers in later versions.
 * @param formView the form view to filter
 * @returns A form view without FormViewMembers that represent markdown fields.
 */
export const removeMarkdownFormViewMembers = (formView) => {
    const { formViewMembers } = formView;
    const markdownFields = new Set();
    const fields = {};
    Object.keys(formViewMembers).forEach((fieldName) => {
        const fvm = formViewMembers[fieldName];
        if (fvm.isMarkdownText)
            markdownFields.add(fvm.viewFieldName);
        else
            fields[fieldName] = fvm;
    });
    const newFormView = Object.assign(Object.assign({}, formView), { formViewMembers: fields });
    const { formLayout } = newFormView;
    if (!formLayout)
        return newFormView;
    formLayout.tabs = formLayout.tabs.map((t) => (Object.assign(Object.assign({}, t), { sections: t.sections.map((s) => (Object.assign(Object.assign({}, s), { fieldLayout: removeFieldNamesFromLayout(s.fieldLayout, markdownFields) }))) })));
    return Object.assign(Object.assign({}, newFormView), { formLayout });
};
